import { observable, action, computed, useStrict } from 'mobx';
import axios from 'axios';
import Cookies from 'universal-cookie';


class AccomStore {

	constructor (initialData = {}) {
		this.apiAddress = 'http://localhost:3001'

		if(typeof window !=="undefined" && window.location.port !== '3007')
            this.apiAddress = 'https://api.thaodien.me'
        
	    this.marketplace = initialData.marketplace != null ? initialData.marketplace : ''
	    this.suburb = initialData.suburb != null ? initialData.suburb : ''
	    
	    if(initialData.accommodation != null){
	    	this.setAccommodation(initialData.accommodation.accommodation, false)
	    	this.updateSearchNo(initialData.accommodation.count);
    		this.setisMarketplace(true);
	    }

	}

	//Observable values can be watched by Observers
	@observable accommodation = [];
	@observable favourites = [];
	@observable categories = [];
	@observable suggestions = [];
	@observable loading = true;
	@observable singleSearch = '';
	@observable search = [];
    @observable skip  = 0;
    @observable limit = 50;
    @observable isMarketplace = false;
    @observable budget = '';
    @observable sortBy = 'Featured';
    @observable suggestlimit = 3000;
    @observable more  = true;
    @observable moreAvailable = true;
    @observable noSearchAccommodation = 0;
    @observable noFavAccommodation = 0;
    @observable noSearchCategories = 0;   
    @observable selectedCategory = '';
	@observable selectedAccommodation = {};
	// @observable apiAddress = 'https://api.thaodien.me';
	@observable apiAddress = 'http://localhost:3001';
	@observable marketplace = '';
	@observable suburb = '';
	@observable region = '';
	@observable token = '';

	@computed get getSelectedAccommodation() { return this.selectedAccommodation}
	@computed get selectedId() { 
		// console.log('selectedId',this.selectedAccommodation.toJSON(),this.selectedAccommodation,typeof this.selectedAccommodation)
		if(this.selectedAccommodation && typeof this.selectedAccommodation.slug !== "undefined"){
			return this.selectedAccommodation.slug; 
		}else{
			return '';
		}
	}
	@computed get favouriteList() {
		return this.favourites.toJSON();
	}
	@computed get accommodationList() {
		return this.accommodation.toJSON();
	}
	@computed get recomAccommodationList() {
		return this.accommodation.toJSON().filter(function(acc) { 
			return (
				acc.recomMarketplace === true
			)});
	}
	@computed get featuredAccommodationList() {
		return this.accommodation.toJSON().filter(function(acc) { 
			return (
				acc.featured === true
			)});
	}
	// @computed get accommodationList() {
	// 	return this.accommodation.toJSON()
 //                // .sort((a, b) => (a.categories[0].keyName > b.categories[0].keyName ? 1 : a.categories[0].keyName === b.categories[0].keyName ? 0 : -1));
	// }
	@computed get isLoading() {
		if(typeof this.loading === "undefined")
			return true;

        return this.loading
    }
    @action setLocalStorage(label,value){
        if(typeof window !=="undefined"){
            window.localStorage.setItem(label,value);
        }else{
            return false;
        }
    }
    @action getLocalStorage(label){
        if(typeof window !=="undefined"){
            return window.localStorage.getItem(label);
        }else{
            return '';
        }
    }
    @action removeLocalStorage(label){
        if(typeof window !=="undefined"){
            window.localStorage.removeItem(label);
        }else{
            return false;
        }
    }
    @action setLocationValue(label,value){
        if(typeof window !=="undefined"){
            window.location[label] = value;
        }else{
            return false;
        }
    }
    @action getLocationValue(label){
        if(typeof window !=="undefined"){
            return window.location[label];
        }else{
            return '';
        }
    }
    @action reloadPage(){
        if(typeof window !=="undefined")
            window.location.reload();
    }
    @action getAccommodation(marketplace = this.marketplace, append = false, limit = this.limit, sortBy =  this.sortBy, id="",  suburb = this.suburb) {
		this.limit = limit;
        
        var search;

        if(this.search)                          
        	search = this.search.toString();  
        
        if(this.search.length === 0)
        	search = this.singleSearch;

        var address = this.apiAddress;

        if(this.isMarketplace){
			address += '/api/accommodation';
		

	        var region  = this.region;
	        if(this.getLocalStorage("staff") && this.getLocalStorage("staff") !== null && this.getLocalStorage("staff") !== "" && this.getLocalStorage("staff").length > 3 && this.getLocalStorage("staff").indexOf('ip') > -1  && this.getLocalStorage("staff").indexOf('region') !== ""){
	        	var usr = JSON.parse(this.getLocalStorage("staff"));
	        	
	        	if(usr.region)
	        		region = usr.region;
	    	}
	    	axios.get(address + '?search=' + search + '&accommodation=' + this.selectedId + '&skip='+this.skip + '&limit='+ this.limit + '&region=' + region + '&budget=' + this.budget + '&marketplace=' + marketplace + '&sort=' + sortBy + '&id=' + id + '&suburb=' + suburb + '&atoken=' + this.token).then( response => {
	            if(!response.data || !response.data.accommodation.length > 0)// ||  response.data.accommodation.length < this.limit)
	            	this.more= false
	            this.updateSearchNo(response.data.count)
	            var accommodation = response.data.accommodation;
	            if((!sortBy || sortBy === 'Featured') && this.skip === 0)
	            	accommodation = accommodation.sort(function(a, b){return 0.5 - Math.random()});

	            this.setAccommodation(accommodation, append);
	        });
	    }
    }
    @action updateSort(marketplace = '',sort){
		this.sortBy = sort;
		this.getAccommodation(marketplace);
	}
	@action updateSearchNo(no){
		this.noSearchAccommodation = no;
	}
	@action updateFavAccommodationNo(no){
		this.noFavAccommodation = no;
	}
	@action getFavourites(slugz = [],  id='') {
		var slugs = [];
		if (slugs.length === 0 && this.getLocalStorage("favs") && this.getLocalStorage("favs") !== '' &&  typeof (this.getLocalStorage("favs") !== null)){
			var slugs_array = JSON.parse(this.getLocalStorage("favs"))
			slugs_array.forEach(function (slug) {
				slugs.push(slug.accommodation_slug)
			})
		}else{
			slugs = slugz;
		}
				
		var region  = this.region;

		var address = this.apiAddress;

        if(this.isMarketplace){
            address += '/api/accommodation';

			axios.post(address,{
				slugs:slugs,
				id:id,
				region:region,
	        	atoken:this.token
			}).then( response => {
				if(response.data && response.data.length > 0){
					this.updateFavAccommodationNo(response.data.count)
					this.setFavourites(response.data);
					this.markLoading(false);
				}
			});
		}
	}	
	@action getFromCategories(category,lang){
		var accommodation =  this.accommodation.toJSON().filter(function(acc) { 
			return (
				acc.categories.find(function (cat) {return cat.title_en === category.title_en; })
			) 
		});
		if(accommodation){
        	return accommodation.map(function (accommodation) {
        		return {slug:accommodation.slug,title:accommodation['title_'+lang],description:accommodation['description_'+lang],price:accommodation.price,image:accommodation.imageUrl}
        	});
        }
       	return [];
	}
	@action getFromSlug(slug){
		var accommodation =  this.accommodation.toJSON().filter(function(acc) { 
			return (
				acc.slug === slug
			) 
		});
		if(accommodation){
        	return accommodation[0]
        }
       	return [];
	}
	@action getSuggestions(marketplace='') {
		axios.get(this.apiAddress + '/api/accommodation/suggest?accommodation=' + this.selectedId + '&limit='+ this.suggestlimit + '&marketplace=' + marketplace + '&atoken=' + this.token).then( response => {
			if(response.data && response.data.search.length > 0){
				this.setSuggestions(response.data.search);
			}else{
				this.more= false
			}
		});
	}
	@action resetSettings(search=true){
		this.accommodation = [];
		this.favourites = [];
		this.categories = [];
		this.suggestions = [];
		this.markLoading(true);
		if(search)
			this.search = [];
		this.singleSearch = '';
	    this.skip  = 0;
	    this.limit = 50;
	    this.suggestlimit = 3000;
	    this.more  = true;
	    this.moreAvailable = true;
	    this.noSearchAccommodation = 0;
	    this.noFavAccommodation = 0;
	    this.noSearchCategories = 0;
	    this.selectedCategory = '';
		this.selectedAccommodation = {};
	}

    @action setSuggestions = (suggestion, append=false) => {
		this.suggestions = [...suggestion];
	}

	@action setFavourites = (favourites) => {
		this.favourites = [...favourites];
	}
	@action setMarketplace = (marketplace) => {
		this.marketplace = marketplace;
	}
	@action setisMarketplace = (isMarketplace) => {
		this.isMarketplace = isMarketplace;
	}
	@action setRegion = (region) => {
		this.region = region;
	}
	@action setSuburb = (suburb) => {
		this.suburb = suburb;
	}
	//In strict mode, only actions can modify mobx state
	@action setAccommodation = (accommodation, append=false) => {
		// check accommodation wholesale and update options if exists
		
		// accommodation = this.updateWholesalePrice(accommodation)

		if(append){
			this.accommodation = [...this.accommodation, ...accommodation]
		}else{
			this.accommodation = [...accommodation];
		}
		this.markLoading(false);
	}
	@action selectAccommodation = (accommodation) => {
		this.selectedAccommodation = accommodation;
	}
	@action clearSelectedAccommodation = () => {
		this.selectedAccommodation = {};
		this.getAccommodation();
	}

	@action addToSearch(marketplace='',value) {

        if(this.search.indexOf(value) > -1){
          this.search.remove(value)
        }else{
          this.search.push(value)
        }
        this.skip = 0;
        this.getAccommodation(marketplace);
    }

	@action addSingleSearch(marketplace='',value,id){
		this.markLoading(true)
		this.singleSearch = value;
		this.skip = 0;
		if (navigator.onLine)
	        this.getAccommodation(marketplace, false, this.limit, this.sortBy, id)
	}
	@action closeAccommodation(marketplace='',value,id='',suburb=''){
	    this.skip = 0
	    this.more = true
	    this.markLoading(true)
	    this.singleSearch = ''
		this.search = value;

	    if (navigator.onLine)
	    	this.getAccommodation(marketplace, false, this.limit, this.sortBy, id,'', suburb)
    }
	@action addSearch(value,marketplace='',id=''){
	    this.skip = 0
	    this.more = true
	    this.markLoading(true)
	    this.singleSearch = ''
		this.search = value;

		if(marketplace === '')
			marketplace = this.marketplace;

	    if (navigator.onLine)
	    	this.getAccommodation(marketplace, false, this.limit, this.sortBy, id)
    }
    @action clearSearch(marketplace='', id='') {
    	var searchChange = false;
    	if(this.search.length > 0)
    		searchChange = true;
    	this.skip = 0
        this.search = []
        if(searchChange)
    		this.getAccommodation(marketplace, false, this.limit, this.sortBy, id)
    }
    @action addBudget  = (value) => {
      if(this.budget === value){
        this.budget = ''
      }else{
        this.budget = value;
      }
      if (navigator.onLine)
    	this.getAccommodation();
    }
    @action clearBudget() {
        this.budget = '';
    }

    @action loadScroll(marketplace='',id='') {
    	if(this.more){// && this.accommodation.length >= this.limit){
            this.skip += this.limit
            this.markLoading(true)
            this.getAccommodation(marketplace,true,this.limit, this.sortBy,id);
        }
    }
    @action markLoading = (loading) => {
    	this.loading = loading
    }
}


let accom = null;

export function initializeAccomStore (data) {
	if(!typeof window !=="undefined"){
		accom = new AccomStore(data);
	}else{
		if(accom == null){
			accom = new AccomStore(data);				
		}
		if(typeof window !=="undefined" && window.location.port === '3007')
			window.accom = accom;
	}
	return accom;
}