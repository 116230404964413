/*global google*/
import React from "react";
import { inject, observer } from "mobx-react"
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap';

class CustomerSiteLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {token:this.props.token,site:this.props.site,Id:this.props.Id}
    }


    render() {
        var fontSize = 10;
		console.log(this.state.token)
		return (
			<Modal isOpen={true} toggle={this.props.toggleCustomerSiteLogin} className={this.props.className} size="lg">
            	<ModalHeader toggle={this.props.toggleCustomerSiteLogin}>
            	</ModalHeader>
            	<ModalBody>
					<div>
		                <iframe src={this.state.site + '#tasty_token_login=' + this.state.token + '='+ this.state.Id} width={'100%'} height={window.innerHeight-300} id="userSite" />
					</div>
				</ModalBody>
			</Modal>			
			);
 	 	}
	}

  export default CustomerSiteLogin;
