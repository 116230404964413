import { types, getParent } from 'mobx-state-tree'
import axios from 'axios';

import { Product } from "./ProductStore"

const categories = types.model('categories', {
    uuid: types.identifier,
    name: types.string,
    keyName: types.string
});

const tags = types.model('tags', {
    uuid: types.identifier,
    name: types.string,
    keyName: types.string
});
 
const address = types.model('address', {
    label_address:types.optional(types.string,''),
    form_address: types.optional(types.string,''),
    street: types.optional(types.string,''),
    ward: types.optional(types.string,''),
    suburb: types.optional(types.string,''),
    post_code: types.optional(types.string,''),
    city: types.optional(types.string,''),
    region: types.optional(types.string,''),
    country: types.optional(types.string,''),
    country_code: types.optional(types.string,''),
    gps:types.optional(types.array(types.number),[]),
    apt: types.optional(types.string,''),
    delivery_instructions: types.optional(types.string,''),
    selected:types.optional(types.boolean,false)
});

const location = types.model('location', {
    address: address,
    latitude: types.optional(types.number, 10.779658),
    longitude: types.optional(types.number, 106.699495),
});

const paymentMethod = types.model('paymentMethod', {
    slug: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    icon: types.optional(types.string, '')
});

const orderType = types.model('orderType', {
    text: types.optional(types.string, ''),
    icon: types.optional(types.string, '')
});



const btnNav = types.model('btnNav', {
    text: types.optional(types.string, ''),
    icon: types.optional(types.string, ''),
    css: types.optional(types.string, ''),
    page:types.optional(types.string, ''),
});

const home = types.model('home', {
    topBarMSG_en:types.optional(types.string,""),
    topBarMSG_vi:types.optional(types.string,""),
    topBarMSG_ja:types.optional(types.string,""),
    topBarMSG_zh:types.optional(types.string,""),
    topBarMSG_ko:types.optional(types.string,""),
    topBarMSGCSS:types.optional(types.string,JSON.stringify({backgroundColor:'#000',color:'#fff',textAlign:'center',fontSize:12,verticalAlign:'middle',width:'100%',height:20,position:'absolute',top:0,zIndex:2,marginLeft:'-50%',left:'50%'})),
    showMap: types.optional(types.boolean, true),
    showOrderTypes: types.optional(types.boolean, true),
    orderTypes: types.optional(types.array(orderType), [
        { text: 'delivery', icon: 'motorcycle' },
        { text: 'pickup', icon: 'shopping-bag' },
        { text: 'eatin', icon: 'cutlery' }
    ]),
    showBtmNav: types.optional(types.boolean, true),
    btnNavs: types.optional(types.array(btnNav), [{
            text: 'Restaurants',
            icon: 'building-o',
            page: 'stores',
            css: JSON.stringify({ backgroundColor: '#000', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #000', outline: 'none', lineHeight: '18px', position: 'relative', color: '#fff', margin: 5, width: '45%' })
        },
        {
            text: 'Get some food',
            icon: 'cutlery',
            page: 'products',
            css: JSON.stringify({ backgroundColor: '#fff', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #c7c7c7', outline: 'none', lineHeight: '18px', position: 'relative', color: '#999', margin: 5, width: '45%' })
        }
    ]),
    btnPrimCSS: JSON.stringify({ backgroundColor: '#000', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #000', outline: 'none', lineHeight: '18px', position: 'relative', color: '#fff', margin: 10, width: '100%' }),
    btnSecCSS: JSON.stringify({ backgroundColor: '#fff', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #c7c7c7', outline: 'none', lineHeight: '18px', position: 'relative', color: '#999', margin: 10, width: '100%' }),

    bgCSS: types.optional(types.string, JSON.stringify({ display: 'inline-block', width: '100%', textAlign: 'center', height: 956, background: 'url(./images/tacos.jpg)', backgroundSize: 'cover', backgroundPosition: 'top right', backgroundRepeat: 'no-repeat' })),
    primTxt: types.optional(types.string, 'Taste the difference....'),
    primTxtCSS: types.optional(types.string, JSON.stringify({ textAlign: 'center', color: '#fff', fontWeight: 300, fontSize: 52, margin: 10, position: 'relative', top: 10, textShadow: ' 0 2px 4px rgba(0,0,0,0.3)' })),

    secTxt: types.optional(types.string, 'Order From Local Restaurants You Love'),
    secTxtCSS: types.optional(types.string, JSON.stringify({color: '#fff', fontWeight: 300, fontSize: 24, marginBottom: 40, textAlign: 'center', textShadow: ' 0 2px 4px rgba(0,0,0,0.3)' }))
})

const products = types.model('products', {
  heroImageUrl:types.optional(types.string,""),
})

const stores = types.model('stores', {

})

const search = types.model('search', {

})

const cart = types.model('cart', {
    icon: types.optional(types.string, 'shopping-bag'),
})

const langItems = types.model('langItems', {
    address: types.optional(types.string, ''),
    text_en: types.optional(types.string, ''),
    text_vi: types.optional(types.string, ''),
    text_ja: types.optional(types.string, ''),
    text_zh: types.optional(types.string, ''),    
    text_ko: types.optional(types.string, '')

})

const menu = types.model('menu', {    
    topbarCSS: types.optional(types.string,JSON.stringify({width:'100%', textAlign:'center', zIndex:3, color:'#000 !important', backgroundColor:'#FFF', height:60, borderBottom:'1px solid #efefef'})),
    logoIMG: types.optional(types.string, '/static/images/tasty_logo.png'),
    logoCSS: types.optional(types.string, JSON.stringify({ maxHeight: 45, position: 'relative', top: -7, left: -1 })),    
    barsColor: types.optional(types.string, '#000'),
    txtColor: types.optional(types.string, '#000'),
    txtSecColor: types.optional(types.string, '#FFF'),
    txtSecBGColor: types.optional(types.string, '#000'),
    iconColor: types.optional(types.string, '#000'),
    bgColor: types.optional(types.string, '#FFF')
})


const customPages = types.model('customPages', {
  _id : types.optional(types.string,''),
  slug:types.optional(types.string, ''),
  menu_order:types.optional(types.number, 0),
  on_menu:types.optional(types.boolean, false),
  show_header:types.optional(types.boolean, false),
  title_en : types.optional(types.string, ''),    
  title_vi : types.optional(types.string, ''),
  title_ja : types.optional(types.string, ''),
  title_zh : types.optional(types.string, ''),    
  title_ko : types.optional(types.string, ''),      
  html_en : types.optional(types.string, ''),
  html_vi : types.optional(types.string, ''),
  html_ja : types.optional(types.string, ''),
  html_zh : types.optional(types.string, ''),    
  html_ko : types.optional(types.string, ''),    
  style : types.optional(types.string, ''),    
  js : types.optional(types.string, ''),    
  tags : types.array(types.optional(types.string,'')),
  createdAt: types.optional(types.string, '')
})

const layout = types.model('layout', {
    font: types.optional(types.string, 'sans-serif'),
    primFont: types.optional(types.string, 'sans-serif'),
    primTxtColor: types.optional(types.string, '#000'),
    primSize: types.optional(types.number, 52),
    primWeight: types.optional(types.number, 300),

    secTxtColor: types.optional(types.string, '#000'),
    secSize: types.optional(types.number, 24),
    secWeight: types.optional(types.number, 300),

    btnPrimCSS: JSON.stringify({ backgroundColor: '#000', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #000', outline: 'none', lineHeight: '18px', position: 'relative', color: '#fff', margin: 10, width: '100%' }),
    btnSecCSS: JSON.stringify({ backgroundColor: '#fff', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #c7c7c7', outline: 'none', lineHeight: '18px', position: 'relative', color: '#999', margin: 10, width: '100%' }),

    showInvoiceDetails: types.optional(types.boolean, true),
    
    paymentMethods: types.optional(types.array(paymentMethod), [
        { slug: 'cash', text: 'Cash', icon: '' },
        { slug: 'paypal', text: 'Paypal', icon: '' },
        { slug: 'bank', text:'Bank', icon: '' }
    ]),

    menuStyle: types.optional(types.string, 'side'),
    home: home,
    products: products,
    stores: stores,
    search: search,
    cart: cart,
    menu: menu,
    langItems: types.optional(types.array(langItems), []),
    customPages: types.optional(types.array(customPages),[])
}).actions(self => {
    function getTitle(address,lang) {
      var post =  self.langItems.filter(function(item) {
        return (item.address.toLowerCase() === address.toLowerCase());
      })
      if(post.length > 0)
        return post[0]['text_'+lang];

      return address;
    }
    return {
        getTitle
    }
});

const items = types.model("items", {
    link: types.optional(types.string,''),
    url: types.optional(types.string,''),
    type: types.optional(types.string,'image'),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),
    title_zh: types.optional(types.string, ""),    
    title_ko: types.optional(types.string, ""),
    subtitle_en: types.optional(types.string, ""),
    subtitle_vi: types.optional(types.string, ""),
    subtitle_ja: types.optional(types.string, ""),
    subtitle_zh: types.optional(types.string, ""),    
    subtitle_ko: types.optional(types.string, ""),
    style: types.optional(types.string, ""),
    showLogo: types.optional(types.boolean,false),
    mute: types.optional(types.boolean,true),
    width: types.optional(types.number,0),
    height: types.optional(types.number,0),
    delay: types.optional(types.number,3500),
    alt:types.optional(types.string,''),
})

const heroImage = types.model('heroImage', {
    height:types.optional(types.string,"full"),
    items: types.array(items)
});

const deliveryAreas = types.model('deliveryAreas', {
    uuid: types.optional(types.identifier, ''),
    name: types.optional(types.string, ""),
    keyName: types.optional(types.string, ""),
    imageUrl:types.optional(types.string, "")
});

var emptyLayout = {
    primFont: '#000',
    primTxtColor: '#000',
    primSize: 52,

    secTxtColor: '#000',
    secSize: 24,

    btnPrimBGColor: '#000',
    btnPrimTxtColor: '#fff',
    btnPrimSize: 14,

    btnSecTxtColor: '#fff',
    btnSecBGColor: '#000',
    btnSecSize: 14,

    menuStyle: 'side',
    home: {},
    products: {},
    stores: {},
    search: {},
    cart: {},
    menu: {}
}


const blogCategories = types.model("blogCategories", {
    order:types.optional(types.number,0),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),  
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""),    
    keyName: types.optional(types.string,""),
    image: types.optional(types.string, '')
});

const blogArticles = types.model("blogArticles", {
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),    
    title_zh: types.optional(types.string, ""),    
    title_ko: types.optional(types.string, ""),    
    sub_title_en:types.optional(types.string, ""),
    sub_title_vi:types.optional(types.string, ""),
    sub_title_ja:types.optional(types.string, ""),
    sub_title_zh:types.optional(types.string, ""),
    sub_title_ko:types.optional(types.string, ""),
    slug:types.identifier,
    type:types.optional(types.string, "standard"),
    image:types.optional(types.string, ""),
    imageStyle:types.optional(types.string, 'fullWidth'),
    text_en: types.optional(types.string, ""),
    text_vi: types.optional(types.string, ""),
    text_ja: types.optional(types.string, ""),    
    text_zh: types.optional(types.string, ""),    
    text_ko: types.optional(types.string, ""),    
    type: types.optional(types.string, 'blog'),
    categories: types.optional(types.array(types.string),[]),
    tags:types.optional(types.array(types.string),[]),
    date: types.optional(types.string, ''),
    createdAt: types.optional(types.string, '')
})

var types_list = ['Alcohol', 'American', 'Asian', 'Asian Fusion', 'BBQ', 'Bakery', 'Breakfast', 'Bubble Tea', 'Burgers', 'Cafe', 'Cantonese', 'Chinese', 'Coffee And Tea', 'Colombiana', 'Comfort Food', 'Cupcakes', 'Desserts', 'English', 'European', 'Fast Food', 'French','Frozen Yogurt', 'Halal', 'Healthy', 'Ice Cream', 'Indian', 'Indonesian', 'Italian', 'Japanese', 'Juice', 'Korean', 'Malaysian', 'Mediterranean', 'Mexican', 'Nepalese', 'North Indian', 'Pakistani', 'Pastry', 'Pizza', 'Portuguese', 'Salads', 'Sandwiches', 'Seafood', 'Singaporean', 'Smoothies', 'Street Food', 'Sushi', 'Taiwanese', 'Thai', 'Vegetarian', 'Vietnamese', 'Western', 'Wings'];
var accom_types = ['Apartment','Serviced Apartment'];

// const foodItems = types.model("foodItems", {
//   slug: types.optional(types.string, ""),
//   sku: types.optional(types.string, ""),
//   title_en: types.optional(types.string, ""),
//   title_vi: types.optional(types.string, ""),
//   title_ja: types.optional(types.string, ""),
//   title_zh: types.optional(types.string, ""),
//   title_ko: types.optional(types.string, ""),
//   itemDescription_en: types.optional(types.string, ""),
//   itemDescription_vi: types.optional(types.string, ""),
//   itemDescription_ja: types.optional(types.string, ""),
//   itemDescription_zh: types.optional(types.string, ""),
//   itemDescription_ko: types.optional(types.string, ""),
//   store_title: types.optional(types.string, ""),
//   store_slug: types.optional(types.string, ""),
//   imageUrl: types.optional(types.string, ""),
//   rating: types.optional(types.number,0),
//   price: types.optional(types.number,0),
//   priceBucket:types.optional(types.string, ""),
//   sale: types.optional(types.number,0),
//   types:types.optional(types.array(types.string),[]),
// });

export const MarketplaceStore = types
    .model('MarketplaceStore', {
        uuid: types.optional(types.string, ''),
        title: types.optional(types.string, 'Thao Dien'),
        description: types.optional(types.string, 'Thao Dien'),
        currency: types.optional(types.string, 'vnd'),
        cityName: types.optional(types.string, ''),
        country: types.optional(types.string, ''),
        slug: types.optional(types.string, ''),    
        website: types.optional(types.array(types.string),[]),
        phone: types.optional(types.string, ''),
        email: types.optional(types.string, ''),
        facebook: types.optional(types.string, ''),
        facebookLogin: types.optional(types.string, ""),
        facebookPixel: types.optional(types.string, ''),
        analytics: types.optional(types.string,""),
        sortBy: types.optional(types.array(types.string), ["Featured","Customer Rating","Price Ascending", "Price Descending", "Product Name A-Z", "Product Name Z-A", "Highest % Discount", "Restaurant"]),
        categories: types.optional(types.array(categories), []),
        tags: types.optional(types.array(tags), []),
        types: types.optional(types.array(types.string), types_list),
        typesLabel: types.optional(types.string, "Cuisines"),
        accomLabel: types.optional(types.string, "Type"),
        accomTypes: types.optional(types.array(types.string), accom_types),
        accomSortBy: types.optional(types.array(types.string), ["Featured","Customer Rating","Price Ascending", "Price Descending", "Name A-Z", "Name Z-A"]),
        logoImageUrl: types.optional(types.string, ''),
        mapImageUrl: types.optional(types.string, ''),
        heroImageUrl: types.optional(types.string, ''),
        heroVideoUrl: types.optional(types.string, ''),
        location: types.optional(location, { address: {}, latitude: 10.779658, longitude: 106.699495 }),
        regionId: types.optional(types.number, 1),
        regionsLabel:types.optional(types.string, 'Districts'),
        isMarketplaceMenuOpen: types.optional(types.boolean, true),
        isMarketplaceVisible: types.optional(types.boolean, true),
        disableSignup: types.optional(types.boolean, false),
        showLanguages: types.optional(types.boolean, true),
        closedEtaMessage: types.optional(types.string, ''),
        deliveryAreas: types.optional(types.array(deliveryAreas), [{ uuid: '', name: '', keyName: '', imageUrl:'' }]),        
        heroImage: types.optional(heroImage, { items: [] }),
        // recomStores: types.optional(heroImage, { items: [] }),
        featuredProducts: types.optional(types.array(types.reference(Product)),[]),
        // stories: types.optional(heroImage, { items: [] }),
        notify: types.optional(types.string, '/audio/notify.mp3'),
        notify2: types.optional(types.string, '/audio/applause.mp3'),
        layout: types.optional(layout, emptyLayout),
        blogArticles: types.optional(types.array(blogArticles),[]),
        events: types.optional(types.array(blogArticles),[]),
        blogCategories: types.optional(types.array(blogCategories),[]),
        blogSkip  : 0,
        blogLimit  : 10,
        blogMore  : true,
        loading: false
    })
    .views(self => ({
        get shop() {
            return getParent(self)
        },
        get isLoading() {
            return self.loading
        },
        get country_code(){
            if(self.location.address)
                return self.location.address.country_code || '';
        },
        get address() {
            return self.location.address || ''
        },
    }))
    .actions(self => {
        function contactForm(form){
            try{
                form['token'] = self.shop.staff.token;
                form['atoken'] = self.shop.token;
                form['store'] = self.shop.view.selectedStoreAddress;
                

                return axios.post(self.shop.apiAddress + '/api/marketplaces/contact',form).then( response => {
                    return response;
                });
            } catch (err) {
               console.error(" ", err)
            }
        }

        function getPost(slug) {
            
          var post =  self.blogArticles.filter(function(post) {
            return (post.slug.toLowerCase() === slug.toLowerCase());
          })
          if(post.length > 0){
            return post[0];
          }else{
            post =  self.events.filter(function(post) {
                return (post.slug.toLowerCase() === slug.toLowerCase());
            })
            if(post.length > 0)
                return post[0];
          }

          return false;
        }
        function markLoading(loading) {
            self.loading = loading
        }

        function updateMarketplace(json) {
            Object.keys(json).forEach(function(key) {
                if(key === "cityName" || key === "country"){
                    self[key]= json[key][0]
                }else{
                    self[key] = json[key]
                }                
            });
            if(self.shop.isMarketplace){
              self.shop.updateTitle(json.title)
              self.shop.updateDescription(json.description)
              self.shop.updateIcon(json.logoImageUrl)
            }
            if(typeof self.shop.storeStore !== "undefined" && self.shop.storeStore.stores.length === 0)
                self.shop.storeStore.loadStores();
        };

        function getLocationImage(){
            if(self.shop.staff.address){
                var image = self.deliveryAreas.find(x => x.name === self.shop.staff.address.suburb);
                if(image)
                    return image.toJSON().imageUrl;
            }
            
            return false;
            
        }

        const loadMarketplace = function loadMarketplace(region = 'Ho Chi Minh') {
            try {
                                  
                if (region === '' && self.shop.staff &&  typeof self.shop.staff.address !== 'undefined')
                    region = self.shop.staff.address.city;
                if (self.shop.staff) {
                    axios.get(self.shop.apiAddress + '/api/marketplaces?region=' + region +'&domain='+self.shop.domain + '&atoken=' + self.shop.token).then(response => {
                        if (response.data && response.data.length > 0) 
                            self.updateMarketplace(response.data[0]);
                        self.markLoading(false)
                    });
                } else {
                    setTimeout(function() {
                        axios.get(self.shop.apiAddress + '/api/marketplaces?region=' + region +'&domain='+self.shop.domain +'&atoken='+self.shop.token ).then(response => {
                            if (response.data && response.data.length > 0) 
                                self.updateMarketplace(response.data[0]);
                            self.markLoading(false)
                        });
                    }, 1000);
                }
            } catch (err) {
                console.error('Failed to load stores', err)
            }
        }

        function loadMarketplaceDash() {
            if(self.domain === "") 
                self.domain = self.shop.domain;

            var data = {}; 
            data['staff'] = self.shop.staff._id;
            data['token'] = self.shop.staff.token;
            data['atoken'] = self.shop.token;
            data['marketplace'] = self.shop.marketplace.slug;
            data['website'] = self.shop.getLocationValue('origin');
            try{                
                
                return axios.post(self.shop.apiAddress + '/api/marketplaces/dash',data).then( response => {
                    return response.data;
                });
            } catch (err) {
               console.error(" ", err)
            }
        }

        function updateBlogArticles(posts){    
            self.blogArticles = posts;
            self.updateLoading(false);
            return self.blogArticles;
        }
        function addSingleBlogArticles(posts){    
            posts.forEach(function (post) {
                self.blogArticles.push(post);
            })
            self.updateLoading(false);
            return posts[0];
        }
        function addBlogArticles(posts){    
            posts.forEach(function (post) {
                self.blogArticles.push(post);
            })
            self.updateLoading(false);
            return self.blogArticles;
        }
        function updateBlogSkip(value){   
            self.blogSkip = value;
        }
        function updateBlogMore(status){
            self.blogMore = status;
        }
        function updateLoading(status){   
            self.loading = status;
        }
        function loadPosts(slug='',category='', clear, search=''){
            if(clear)
                self.updateBlogArticles([])

            if(self.blogMore && self.blogArticles.length > 0 && slug ==='')
                self.updateBlogSkip((self.blogArticles.length + self.blogSkip))

            var region  = "";
            


            if(slug ==='')
                self.updateLoading(true);

            
            setTimeout(function() {
                self.updateLoading(false);
            }, 500);
            try{
                return axios.get(self.shop.apiAddress + '/api/marketplaces/blog?search=' + search + '&category=' + category + '&store=' + self.slug + '&region=' + region + '&domain='+self.shop.domain + '&marketplace=' + self.slug + '&postSlug=' + slug + '&limit=' + self.blogLimit + '&skip=' + self.blogSkip + '&token='+ self.shop.token).then( response => {
                    if(!response.data || !response.data.blogArticles.length > 0){
                        self.updateBlogMore(false);
                        self.updateLoading(false);
                    }

                    if(response.data && typeof response.data.blogArticles !== "undefined"){
                        if(response.data.blogArticles.length > 1 && clear === true){
                            return self.updateBlogArticles(response.data.blogArticles);
                        }else if(response.data.blogArticles.length === 1 && slug !== ''){
                            self.addSingleBlogArticles(response.data.blogArticles);
                        }else if(response.data.blogArticles.length > 0){
                            return self.addBlogArticles(response.data.blogArticles);                                    
                        }
                    }
                });
            }catch(error){
                console.log(error)
            }
        }

        function afterAttach(){
            
        }

        return {
            getPost,updateLoading,updateBlogArticles,updateBlogSkip,updateBlogMore,addBlogArticles,addSingleBlogArticles,loadPosts,
            updateMarketplace,
            markLoading,
            loadMarketplace,
            loadMarketplaceDash,
            getLocationImage,
            afterAttach
        }
    })