import React from "react"

class StaffQualifications extends React.Component {
    constructor(props) {
        super(props);

         var qualifications = [{qualification:'Cook',level:0},{qualification:'Barister',level:0},{qualification:'Waiter',level:0},{qualification:'Cashier',level:0},{qualification:'Shift Leader',leve:0},{qualification:'Delivery Driver',level:0},{qualification:'Orders Manager',level:0}]

        // qualifications.map(function (qualification, i) {
        //     console.log(staff)
        //     if(staff.qualifications && staff.qualifications.find(function (qual) {return qual.qualificationification === qualification.qualification; }))
        //         qualifications[i].level = staff.qualifications.find(function (qual) {return qual.qualificationification === qualification.qualification; }).level;
        // });

        if(this.props.staff){
            var staff = this.props.staff;
            qualifications.map(function (qual, i) {
                if(staff.qualifications && staff.qualifications.find(function (q) {return q.qualification === qual.qualification; })){
                    qualifications[i].level = staff.qualifications.find(function (q) {return q.qualification === qual.qualification; }).level;
                    qualifications[i].grantedBy = staff.qualifications.find(function (q) {return q.qualification === qual.qualification; }).grantedBy;
                }
            });
        }
        this.state = {qualifications:qualifications};
    }

    updateQualification(e){
        var qualifications = this.state.qualifications;
        qualifications.map(function (qual, i) {
            if(qualifications[i].qualification == e.currentTarget.name)
               qualifications[i].level = e.currentTarget.value;
        });
        this.setState(qualifications)
    }

    updateStaffQualifications(){
        this.props.shop.staff.updateStaffQualifications(this.props.staff._id,this.state.qualifications)
    }

    render() {
        var shop = this.props.shop;
        var staff = this.props.staff;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;
        var fontSize = 10;

        return (
            <div>
                <table>
                    <tbody>
                        <tr style={{fontWeight:'bold'}}>
                            <td>
                                Qualification
                            </td>
                           <td align="center">
                               Level
                            </td>
                            <td align="center">
                                Granted By
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                    {this.state.qualifications.map((qual, i) =>
                        <tr key={i}>
                            <td>
                                <span style={{textTransform:'capitalize',fontWeight:'normal'}}>{qual.qualification}</span>                                
                            </td>
                            <td align="center"> 
                               <select className="form-control" name={qual.qualification} defaultValue={qual.level} onChange={this.updateQualification.bind(this)}>
                                    <option value={0}>0</option>                                   
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>                      
                                </select>
                            </td>
                            <td style={{fontWeight:'normal',textAlign:'center'}}>
                                {qual.grantedBy}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>    
                <button type="button" style={{background:'#000',border:'#000'}} className="btn btn-dark pull-right" style={{float:'right',margin:10}} onClick={this.updateStaffQualifications.bind(this)}>Set Qualifications</button>
              <div className="clearfix"></div>
            </div>
        )
    }
}

  export default StaffQualifications;