// CheckoutForm.js
import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import { Button} from 'reactstrap';
// import AddressSection from './AddressSection';
import {CardElement} from 'react-stripe-elements';
class CheckoutForm extends React.Component {
  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    var stripe = this.props.stripe;

    stripe.createToken({name: this.props.name}).then(({token}) => {
      this.props.processCheckout(token);
    });

    // However, this line of code will do the same thing:
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
  }

  render() {
    var BtnColor = this.props.BtnColor
    var style = {base: {
                              iconColor: '#666',
                              color: '#000',
                              fontWeight: 400,
                              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                              fontSize: '14px',
                              fontSmoothing: 'antialiased',

                              ':-webkit-autofill': {
                                color: '#999',
                              },
                              '::placeholder': {
                                color: '#666',
                              },
                            },
                            invalid: {
                              iconColor: '#ff0000',
                              color: '#ff0000',
                            }
                          }

    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement style={style} onReady={(el) => el.focus()} />
        <div className="row">
          <div id="card-element"/>
          <div id="card-errors" role="alert"/>
        </div><br />
        <Button type="submit" style={{color:"#fff",backgroundColor:BtnColor,margin:5, padding:15, width:"100%", fontSize:13, height:50, borderRadius: 0,border: 'none'}}>{this.props.pay_title}</Button>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);