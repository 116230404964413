import React from "react"

class StaffProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {staff: this.props.staff}
    }
    updateField(value,event){
        var staff = this.state.staff;
        if(value.indexOf(".") > -1){
            staff[value.split(".")[0]][value.split(".")[1]] = event.target.value;
        }else{
            staff[value] = event.target.value;
        }
        this.setState({staff})
        
    }
    updateStaffProfile(){
        this.props.shop.staff.updateStaffProfile(this.state.staff)
    }
    render() {
        var shop = this.props.shop;
        var staff = this.state.staff;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;

        return (
            <div>
                <form>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label>Company</label>
                                {this.props.shop.isMarketplace && this.props.stores ?
                                    <select className="form-control" defaultValue={staff.store_slug} onChange={this.updateField.bind(this,'store_slug')}>
                                        <option value=''>All Stores</option>
                                          {this.props.stores.map((store,i) => 
                                            <option key={i} value={store.slug}>{store.title}</option>
                                          )}                      
                                     </select>
                                :
                                <input type="text" className="form-control" disabled placeholder="Company" defaultValue={staff.store_title && staff.store_title !== "" ? staff.store_title : 'All Stores'} />
                                }                                                    
                            </div>
                        </div>    
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" placeholder="Email" defaultValue={staff.email} onChange={this.updateField.bind(this,'email')} />
                            </div>
                        </div>
                        <div className="col-md-6 pl-1">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" placeholder="" defaultValue={''} onChange={this.updateField.bind(this,'password')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                       <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control" placeholder="First Name" defaultValue={staff.firstname} onChange={this.updateField.bind(this,'firstname')} />
                            </div>
                        </div>
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control" placeholder="Last Name" defaultValue={staff.lastname} onChange={this.updateField.bind(this,'lastname')}  />
                            </div>
                        </div>    
                        <div className="col-md-4 pl-1">
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="tel" className="form-control" placeholder="Phone" defaultValue={staff.phone} onChange={this.updateField.bind(this,'phone')}  />
                            </div>
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label>Facebook</label>
                                <input type="text" className="form-control" placeholder="Facebook" name="facebook" defaultValue={staff.facebook} onChange={this.updateField.bind(this,'facebook')}  />
                            </div>
                        </div>    

                        <div className="col-md-6 pl-1">
                            <div className="form-group">
                                <label>Start Area</label>
                                <select className="form-control" defaultValue={staff.startArea} onChange={this.updateField.bind(this,'startArea')}>
                                    {staff.permissions.map((permission, i) =>
                                        <option key={i} value={permission.area}>{permission.area}</option>
                                      )}                      
                                 </select>
                            </div>
                        </div>   
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Address</label>
                                <input type="text" className="form-control" placeholder="Home Address"  defaultValue={staff.address && staff.address.street}  onChange={this.updateField.bind(this,'address.street')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>Suburb</label>
                                <input type="text" className="form-control" placeholder="Suburb" defaultValue={staff.address && staff.address.suburb} onChange={this.updateField.bind(this,'address.suburb')} />
                            </div>
                        </div>
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>City</label>
                                <input type="text" className="form-control" placeholder="City" defaultValue={staff.address && staff.address.city} onChange={this.updateField.bind(this,'address.city')} />
                            </div>
                        </div>
                        <div className="col-md-4 px-1">
                            <div className="form-group">
                                <label>Country</label>
                                <input type="text" className="form-control" placeholder="Country"  defaultValue={staff.address && staff.address.country} onChange={this.updateField.bind(this,'address.country')} />
                            </div>
                        </div>                                                   
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label>DOB</label>
                                <input type="date" className="form-control" placeholder="DOB" defaultValue={staff.dob} onChange={this.updateField.bind(this,'dob')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>About Me</label>
                                <textarea rows="4" cols="80" className="form-control" placeholder="Staff Bio description" defaultValue={staff.description} onChange={this.updateField.bind(this,'description')}></textarea>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-info btn-fill pull-right" style={{float:'right',margin:10, background:'#000',border:'#333'}} onClick={this.updateStaffProfile.bind(this)}>Update Profile</button>
                    <div className="clearfix"></div>
                </form>
                
            </div>
        )
    }
}

  export default StaffProfile;