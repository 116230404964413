import React from "react"

import {Modal, ModalHeader, ModalBody,ModalFooter, Button, Table} from 'reactstrap'; 

// import Camera from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';

// import Webcam from "react-webcam";

// const FACING_MODE_USER = "user";
// const FACING_MODE_ENVIRONMENT = "environment";

import Camera, { DEVICE, FACING_MODE, PLACEMENT } from 'react-camera-ios';

// Styles
import 'react-camera-ios/build/styles.css';

const containerStyle = { display: 'flex', height: '300px', width: '300px' };

export class OrderPhoto extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {
        saving:false,
        image: '',
        photo_caption:'',
        videoConstraints: {
          width: '100%',
          height: '100%'
        },
        facingMode: FACING_MODE
      };
  }

  updatePhoto(dataUri){
    this.setState({image:dataUri})
  }
  updatePhotoCaption(caption){
     this.setState({photo_caption:caption})
  }
  savePhoto(){   
     if(this.state.saving === false){
      this.setState({saving:true})
      this.props.savePhoto(this.state.image,this.state.photo_caption);
    }
  }
  takePhoto(screenshot){
    // const screenshot = this.webcam.getScreenshot();
    this.setState({ image: screenshot });
  }
  retakePhoto(){
    this.setState({image:''})
  }
  // switchCamera(){
  //   if(this.state.facingMode === FACING_MODE_USER){
  //     this.setState({facingMode: FACING_MODE_ENVIRONMENT});
  //   }else{
  //     this.setState({facingMode: FACING_MODE_USER});
  //   }
  // }
  render () {
    
    var videoConstraints = this.state.videoConstraints;
    var facingMode = this.state.facingMode;

    console.log(videoConstraints,facingMode)

    return (
      <Modal isOpen={this.props.open} toggle={this.props.showCamera} className={this.props.className}>
        <ModalHeader toggle={this.props.showCamera}></ModalHeader>
        <ModalBody>
          <div className="" style={{width:700,minWidth:300,maxWidth:'100%',textAlign:'center', margin:0, position:'relative', background:'#fff', borderRadius:4}}>
            <div>
              <div style={{textAlign:'center'}}>
                {this.state.image === '' ?
                <Camera
                  device={DEVICE.MOBILE}
                  facingMode={FACING_MODE.ENVIRONMENT}
                  placement={PLACEMENT.COVER}
                  quality="1"
                  onError={error => console.log(error)}
                  onTakePhoto={dataUrl => console.log(dataUrl)}
                />
                :
                  <img src={this.state.image}  style={{width:450}}/>
                }
                {this.state.image === '' ?
                  <div id="container-circles" style={{bottom:90}}><div id="outer-circle"><div id="inner-circle" onClick={(e)=> {this.takePhoto();}}></div></div></div> 
                :
                  <div id="container-circles" style={{bottom:90}}><div id="outer-circle"><div id="inner-circle" onClick={(e)=> {this.savePhoto();}}>
                    <i className="fa fa-save" style={{cursor:'pointer',color:'#999',fontSize:18,position:'relative',left:1,top:13}} />
                  </div></div></div> 
                }
                {/*
                <div style={{position:'absolute', margin:10,bottom:0,right:4}}>
                  <img src={this.props.shop.imageDirectory + '/images/fontawesome/switch-camera.png'} alt='' style={{color:"#000",width:23,zIndex:1, cursor:'pointer',filter:'invert(1)'}} onClick={(e)=>{this.switchCamera()}}/>
                </div>
                */}
                <div style={{position:'absolute', margin:10, cursor:'pointer',bottom:4}}>
                  <i className="fal fa-sync-alt" style={{cursor:'pointer',color:'#fff',fontSize:18}} onClick={(e)=> {this.retakePhoto();}}></i>
                </div>
                 {this.state.image !== '' &&
                <div style={{position:'absolute', margin:10, cursor:'pointer',top:80,left:'25%'}}>
                  <input id="photo_caption" style={{padding: '8px 30px 8px 18px',textIndent:'0.01px',height: 44, width: 200,fontSize: 10,color:'#ccc',background: '#fff',border: '1px solid #E5E5E4',borderRadius: 0,outline: 'none',color: '#000'}} value={this.state.photo_caption} placeHolder={"Photo Caption"} onChange={(e) => { this.updatePhotoCaption(e.target.value)}} />
                </div>
              }
              </div>         
            </div>                        
          </div>
        </ModalBody>
      </Modal>      
      );
    }
  }

  export default OrderPhoto;