/*global google*/
import React from "react";
import { inject, observer } from "mobx-react"
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap';


export default @inject("shop","prods") @observer class SetDeliveryTime extends React.Component {
	constructor(props) {
	  	super(props);
	
	  	var deliveryDateTime = new Date();
	  	if(this.props.shop.cart.deliveryDateTime)
	  		deliveryDateTime = new Date(this.props.shop.cart.deliveryDateTime);

	  	this.state = {
			date:deliveryDateTime.toLocaleDateString("en-US"),
			time:''
		};
		// deliveryDateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12:false}
	}

	setDeliveryTime(){
		var date = new Date(this.state.date);
		date.setHours(parseInt(this.state.time.split(":")[0],10));
		date.setMinutes(parseInt(this.state.time.split(":")[1],10));
		
		this.props.shop.cart.setDeliveryTime(date);
		this.props.shop.view.toggleDeliveryTime();
	}

	updateDate(value){
		this.setState({date:value})
	}

	updateTime(value){
		this.setState({time:value})
	}

	render () {
		var shop = this.props.shop;
		var lang = shop.staff.lang;
		var layout = shop.marketplace.layout;
		var scheduleDaysAhead = 14;
		if(!shop.isMarketplace ){
          var store = shop.view.selectedStore;
          	if(store){
    			layout = Object.assign({}, layout,store.layout);
    			scheduleDaysAhead = store.scheduleDaysAhead;
    			var location = store.getLocation(shop.staff.address.suburb);
          	}
        }

        var future = new Date();
        future = future.setDate(future.getDate()+scheduleDaysAhead);
		var future_dates = [];
		for (var now = new Date(); now <= future; now.setDate(now.getDate() + 1)) {
		    future_dates.push(new Date(now));
		}

		var lang_date = 'en-US';
	    if(lang === 'vi')
	      lang_date = 'vi-VN'
	    if(lang === 'ja')
	      lang_date = 'ja-JP'
	    if(lang === 'zh')
	      lang_date = 'zh-CN'
	    if(lang === 'ko')
	      lang_date = 'ko-KR'


		var defaultDate = new Date();
		var todaysDate = new Date();
		var todaysTime = new Date();
		if(this.state.date)
			defaultDate = new Date(this.state.date);

		var select_times = [];
		for (let hr = 0; hr < 24; hr++) {
			if(defaultDate.setHours(0,0,0,0) !== todaysDate.setHours(0,0,0,0) || hr > todaysTime.getHours()){
				let hrStr = hr.toString().padStart(2, "0") + ":";
	  			let val = hrStr + "00";
	  			var day = defaultDate.getDay()-1;
	  			if(day === -1)
	  				day = 6
	  			if(store && location && location.deliveryHoursInfos[day]){
			 		if(val >=  location.deliveryHoursInfos[day].startTime && val <= location.deliveryHoursInfos[day].endTime){			 		
			  			select_times.push({time:val})
			  			val = hrStr + "30";
			  			select_times.push({time:val})
			  		}
		  		}else{
		  			select_times.push({time:val})
		  			val = hrStr + "30";
		  			select_times.push({time:val})
		  		}
	  		}
		}


		defaultDate = defaultDate.toLocaleDateString("en-US")
	
		return (
			<Modal isOpen={shop.view.deliveryTimeModal} toggle={shop.view.toggleDeliveryTime} className={this.props.className}>
            	<ModalHeader toggle={shop.view.toggleDeliveryTime}>
                	<span style={{fontWeight:300,fontSize:28, position:'relative',top:-10}}>{shop.cart.order_type === "delivery" ? shop.marketplace.layout.getTitle('delivery_time',lang) : shop.marketplace.layout.getTitle('booking_time',lang)}</span>
            	</ModalHeader>
            	<ModalBody>
					<div className="" style={{width:700,minWidth:300,maxWidth:'100%',textAlign:'center',height: 250, margin:0, position:'relative', background:'#fff', borderRadius:4}}>
						<div>
							<div style={{textAlign:'left'}}>
								<label style={{fontWeight:500}}>{shop.marketplace.layout.getTitle('date',shop.staff.lang)}</label>
								<div>
									<select id="delivery_date" style={{padding: '8px 30px 8px 18px',textIndent:'0.01px',height: 44, width: '100%',fontSize: 14,marginBottom: 24,background: '#fff',border: '1px solid #E5E5E4',borderRadius: 0,outline: 'none',color: '#000'}} defaultValue={defaultDate} onChange={(e) => { this.updateDate(e.target.value) }}>
										{future_dates.map((date, index) => (
									       <option value={date.toLocaleDateString("en-US")} key={index}>{date.toLocaleString(lang_date, { weekday:"short",day: "numeric", month: "short" })}</option>
									    ))}
									</select>
								</div>
							</div>
							<div style={{textAlign:'left'}}>
								<label style={{fontWeight:500}}>{shop.marketplace.layout.getTitle('time',shop.staff.lang)}</label>
								<div className="select">
									<select id="delivery_time" style={{padding: '8px 30px 8px 18px',textIndent:'0.01px',height: 44, width: '100%',fontSize: 14,marginBottom: 24,background: '#fff',border: '1px solid #E5E5E4',borderRadius: 0,outline: 'none',color: '#000'}} onChange={(e) => { this.updateTime(e.target.value) }} defaultValue={this.state.time}>										
										{select_times.length === 0 ? 
											<option value="">Delivery not available for this day</option>
											:
											<option value="">Select a Time</option>
										}
										{select_times.map((time, index) => (
											<option value={time.time} key={index}>
												{time.time}
											</option>
										))}
									</select>
								</div>
								<div className="form-caption form-caption--error"></div>
							</div>
						</div>
			      		<p>&nbsp;</p>
			      		<Button style={{color:"#fff",backgroundColor:layout.primTxtColor,margin:5, padding:15, width:"100%", fontSize:13, height:50, borderRadius: 0,border: 'none', position:'relative',top:-40}} onClick={(e)=> {this.setDeliveryTime();}} disabled={this.state.time === ''}>
			      			{shop.cart.order_type === "delivery" ? shop.marketplace.layout.getTitle('set_deliverytime',lang) : shop.marketplace.layout.getTitle('set_bookingtime',lang)}
			      		</Button>
					</div>
				</ModalBody>
			</Modal>			
			);
 	 	}
	}

