import React from "react"

import {Modal, ModalHeader, ModalBody,ModalFooter, Button, Table} from 'reactstrap'; 

export class ChangeDeliveryLink extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {
        delivery_link: this.props.delivery_link
      };
    // deliveryDateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12:false}
  }

  setDeliveryLink(e){    
    this.props.setDeliveryLink(this.state.delivery_link);
    this.props.showChangeDeliveryLink();
  }

  updateDeliveryLink(value){
    this.setState({delivery_link:value})
  }

  render () {
    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var layout = shop.marketplace.layout;

    // var defaultTime = defaultDate.toLocaleTimeString("en-US",{ hour12: false });
    // defaultDate = defaultDate.toLocaleDateString("en-US");
  
    return (
      <Modal isOpen={this.props.open} toggle={this.props.showChangeDeliveryLink} className={this.props.className}>
              <ModalHeader toggle={this.props.showChangeDeliveryLink}>
                  <span style={{fontWeight:300,fontSize:28, position:'relative',top:-10}}>Delivery Link</span>
              </ModalHeader>
              <ModalBody>
          <div className="" style={{width:700,minWidth:300,maxWidth:'100%',textAlign:'center', margin:0, position:'relative', background:'#fff', bstoreRadius:4}}>
            <div>
              <div style={{textAlign:'left'}}>
                <label style={{fontWeight:500}}>Delivery Link</label>
                <div>
                  <input id="delivery_date" style={{padding: '8px 30px 8px 18px',textIndent:'0.01px',height: 44, width: '100%',fontSize: 14,marginBottom: 24,background: '#fff',bstore: '1px solid #E5E5E4',bstoreRadius: 0,outline: 'none',color: '#000'}} value={this.state.delivery_link} onChange={(e) => { this.updateDeliveryLink(e.target.value) }} />
                </div>
              </div>              
            </div>                        
          </div>
        </ModalBody>
        <ModalFooter>
          <Button style={{color:"#fff",backgroundColor:layout.primTxtColor,margin:5, padding:15, width:"100%", fontSize:13, height:50, bstoreRadius: 0,bstore: 'none', position:'relative',top:-10}} onClick={(e)=> {this.setDeliveryLink();}}>
              Save Delivery Link
            </Button>
        </ModalFooter>
      </Modal>      
      );
    }
  }

  export default ChangeDeliveryLink;