import React from "react"

class CustomersProfileSide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        var shop = this.props.shop;
        var customer = this.props.customer;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;

        var sideImg = "https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&amp;fm=jpg&amp;h=300&amp;q=75&amp;w=400";

        return (
             <div className="card card-user">
                <div className="card image">
                    <img src={sideImg} alt="..." />
                </div>
                <div className="card-body">
                    <div className="author">
                        <a href="#">
                            {customer.imageUrl && customer.imageUrl !== "" ?
                                <img src={customer.imageUrl.indexOf("http") > -1 ? customer.imageUrl : shop.imageDirectory + customer.imageUrl} className="avatar border-gray" alt={customer.firstname + ' ' + customer.lastname}  />
                                : 
                                <span>
                                  &nbsp;
                                </span>
                              }
                              <br />
                            <h5 className="title">{customer.firstname + ' ' + customer.lastname}</h5>
                        </a>
                        <p className="description">
                            {customer.description}
                        </p>
                    </div>
                    <p className="description text-center">
                        {customer.lastSite && customer.lastSite !== "" ?
                          <span style={{fontWeight:'bold'}}>
                            {customer.lastSite}
                          </span>
                          :
                            <span style={{fontWeight:'bold'}}>
                            &nbsp;
                            </span>
                          }                        
                    </p>
                </div>
                <hr />
                <div className="button-container mr-auto ml-auto">
                    <a href={customer.facebook} target="_blank" className="btn btn-simple btn-link btn-icon">
                        <i className="fab fa-facebook-square" style={{color:'#9490D1'}}></i>
                    </a>
                    <a href={'tel:' + customer.phone} target="_blank" className="btn btn-simple btn-link btn-icon">
                        <i className="fas fa-phone-square" style={{color:'#9490D1'}}></i>
                    </a>
                </div>
            </div>
        )
    }
}

  export default CustomersProfileSide;