import React, { Component } from 'react';
import Promise from 'promise';
import Loader from 'react-loader';

class StepFour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked:true,
      saving: false
    };
    console.log(this.props.getStore())

    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  // This review screen had the 'Save' button, on clicking this is called
  isValidated() {
    // alert('isValidated')
    // typically this method needs to return true or false (to indicate if the local forms are validated, so StepZilla can move to the next step),
    // but in this example we simulate an ajax request which is async. In the case of async validation or server saving etc. return a Promise and StepZilla will wait
    // ... for the resolve() to work out if we can move to the next step
    // So here are the rules:
    // ~~~~~~~~~~~~~~~~~~~~~~~~
    // SYNC action (e.g. local JS form validation).. if you return:
    // true/undefined: validation has passed. Move to next step.
    // false: validation failed. Stay on current step
    // ~~~~~~~~~~~~~~~~~~~~~~~~
    // ASYNC return (server side validation or saving data to server etc).. you need to return a Promise which can resolve like so:
    // resolve(): validation/save has passed. Move to next step.
    // reject(): validation/save failed. Stay on current step
    // console.log('this.refs.checkbox', this.refs.checkbox.value)
    // if(this.refs.checkbox.value === true){
      this.setState({
        saving: true
      });

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.props.createStaff();
          this.props.updateStore({savedToCloud: true});  // Update store here (this is just an example, in reality you will do it via redux or flux)
          
          // call resolve() to indicate that server validation or other aync method was a success.
          // ... only then will it move to the next step. reject() will indicate a fail
          resolve();
          // reject(); // or reject
        }, 2000);
      });
    // }else{
    //   this.setState({checkedValMsg: 'Please check the box to confirm you agree'}); 
    // }
  }

  jumpToStep(toStep) {
    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.jumpToStep(toStep-1); // The StepZilla library injects this jumpToStep utility into each component
  }

  
  render() {
    
    var shop = this.props.shop;
    var lang = shop.staff.lang;

    let notValidClasses = {};
    const savingCls = this.state.saving ? 'saving col-md-12 show' : 'saving col-md-12 hide';
    if (typeof this.state.checkedVal === 'undefined' || this.state.checkedVal) {
          notValidClasses.checkedCls = 'no-error col-md-8';
      } else {
         notValidClasses.checkedCls = 'has-error col-md-8';
         notValidClasses.checkedValGrpCls = 'val-err-tooltip';
      }
    return (
      <div className="step step4 review">
        <div className="row">
          <form id="Form" className="form-horizontal">
            <div className="form-group">
              <label className="col-md-12 control-label">
                <div style={{fontSize:24, marginTop:10, fontWeight:600, marginBottom:5}}>Review your Details</div>
              </label>
            </div>
            <div className={savingCls}>
              <Loader loaded={!this.state.saving} lines={13} length={20} width={10} radius={30}
                corners={1} rotate={0} direction={1} color="#000" speed={1}
                trail={60} shadow={false} hwaccel={false} className="spinner"
                zIndex={2e9} top="50%" left="50%" scale={1.00}
                loadedClassName="loadedContent" />
            </div>
            <div className="form-group">
              <div className="col-md-12 control-label">
                <div className="col-md-12 txt">
                  <label style={{fontSize:14, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('name',lang)}</label>
                  <div style={{fontSize:14, marginTop:10, fontWeight:300, marginBottom:5}}>
                    {this.props.getStore().firstname + ' ' + this.props.getStore().lastname}
                  </div>                  
                </div>
                <div className="col-md-12 txt">
                  <label style={{fontSize:14, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('email',lang)}</label>
                  <div style={{fontSize:14, marginTop:10, fontWeight:300, marginBottom:5}}>
                    {this.props.getStore().email}
                  </div>
                </div>
                <div className="col-md-12 txt">
                  <label style={{fontSize:14, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('phone',lang)}</label>
                  <div style={{fontSize:14, marginTop:10, fontWeight:300, marginBottom:5}}>
                    {this.props.getStore().phone}
                  </div>
                </div>
                <div className="col-md-12 txt">
                  <label style={{fontSize:14, marginTop:10, fontWeight:600, marginBottom:5}}>{!shop.marketplace.layout.getTitle('address',lang) ? 'Address' : shop.marketplace.layout.getTitle('address',lang)}</label>
                    {this.props.getStore().address && 
                      <div style={{fontSize:14, marginTop:10, fontWeight:300, marginBottom:5}}>
                        {this.props.getStore().address.apt && this.props.getStore().address.apt + ' - '} {this.props.getStore().address.label_address}
                      </div> 
                    }
                </div>
                <p>&nbsp;</p>
                {shop.isMarketplace === true &&
                  <div style={{fontSize:14,paddingLeft:20}}>
                    <span>By clicking "Accept" I agree that:</span>
                    <ul className="docs-terms">
                        <li>I have read and accepted the <a href="Staff_Agreement.pdf" target="_blank" rel="noopener noreferrer">Staff Agreement</a></li>
                        <li>I have read and accepted the <a href="Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                        <li>I am at least 18 years old</li>
                    </ul>
                  </div>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
// import React from 'react'
 
// class StepFour extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {check:false};
//     }
    
//     isValidated() {
//       this.setState({
//         saving: true
//       });
//       return false;
//     }

//     render() {
//         return (
//         <div>
//           <div style={{fontSize:14}}>
//               <span>By clicking "Accept" I agree that:</span>
//               <ul className="docs-terms">
//                   <li>I have read and accepted the <a href="#">Staff Agreement</a></li>
//                   <li>I have read and accepted the <a href="#">Privacy Policy</a></li>
//                   <li>I am at least 18 years old</li>
//               </ul>
//               <label><input type="checkbox" 
//                           //   defaultChecked={this.state.checked} 
//                             checked={this.state.checked} 
//                             onChange={this.handleCheckedChanged} 
//                             autoFocus/>
//                             <span> Accept </span> </label>
//           </div>
//         </div>
//   )}
// }

export default StepFour