import { types, getParent } from "mobx-state-tree"

export const options = types.model("options", {
          tag:  types.optional(types.string,""),
          body: types.optional(types.string,""),
          icon: types.optional(types.string,""),
          lang: types.optional(types.string,""),
          dir:  types.optional(types.string,""),
          sound: types.optional(types.string,""),
    })

export const push = types.model("push", {
        title: types.optional(types.string,""),
        active:false,
        options: options
    })
    .actions(self => ({
        changePushState() {
            self.active = !self.active;
        }
    }))

    const empty = {
          tag: 'Order Confirmed',
          body: 'Your has been confirmed is should arrive in around 30 minutes',
          icon: '/tasty.png',
          lang: 'en',
          dir: 'ltr',
          sound: './sound.mp3'
    }

export const ViewStore = types
    .model({
        title:'Tasty -',
        description:'',
        page: "",
        path:"/",
        hash:"",
        push: types.optional(push, {title:'Your order has been confirmed!',options:empty}),
        selectedProductId: "",  
        selectedOrderId:"",
        selectedPostId:"",
        selectedCustomerId:"",
        storeAddress:"",
        accommodationAddress:"",
        schoolAddress:"",
        loginError:"",
        loginModal:false,
        locationModal:false,
        newAddressModal:false,
        deliveryTimeModal:false,
        loginModalType:'login',
        dropdownOpen:false,
        menuOpen: false,
        popoverOpen: false
    })
    .views(self => ({
        get shop() {
            return getParent(self)
        }, 
        get cartDisplayStatus(){
            return self.popoverOpen
        },  
        get isLoading() {
            return self.shop.isLoading
        },
        get currentUrl() {
            switch (self.page) {
                // case "splash":
                //     return "/"
                // case "getstarted":
                //     return "/getstarted"
                // case "products":
                //     return "/products"
                // case "product":
                //     return "/product/" + self.selectedProductId
                // case "blog":
                //     return "/blog"
                // case "account":
                //     return "/account"                    
                // case "post":
                //     return "/post/" + self.selectedPostId
                // case "stores":
                //     return "/stores"
                // case "store":
                //     return "/store/" + self.storeAddress
                // case "accommodations":
                //     return "/accommodations"
                //  case "accommodation":
                //     return "/accommodation/" + self.accommodationAddress
                // case "shops":
                //     return "/shops"
                // case "schools":
                //     return "/schools"
                // case "schools":
                //     return "/school/" + self.schoolAddress         
                case "stores":
                    return "/stores"
                case "staff":
                    return "/staff"
                case "schedules":
                    return "/schedules"
                case "customers":
                    return "/customers"
                case "products":
                    return "/products"
                case "orders":
                    return "/orders"
                // case "checkout":
                //     return "/checkout"
                // case "order":
                //     return "/order/" + self.selectedOrderId
                // case "favourites":
                    // return "/favourites"
                default:
                    if(self.shop.domain !=="" && self.shop.view.selectedStore){
                      //  search custom pages
                      var page = self.shop.view.selectedStore.layout.customPages.find(function (pg) {return pg.slug === self.shop.view.page;})
                      if(page){
                        return "/" + page.slug
                      }else{
                        return "/"
                      }
                    }else{
                        return "/"
                    }
            }
        },
        get selectedPost(){
            if(!self.shop.isMarketplace || self.storeAddress){
              return self.isLoading || !self.selectedPostId
                ? null
                : self.shop.stores.get(self.storeAddress).getPost(self.selectedPostId)
            }else{
                return self.shop.marketplace.getPost(self.selectedPostId)
            }
        },
        get selectedProduct() {
            return self.isLoading || !self.selectedProductId
                ? null
                : self.shop.products.get(self.selectedProductId)
        },
        get selectedOrder() {
            if(!self.shop.staff.token)
                return null;
            

            return self.isLoading || !self.selectedOrderId
                ? null
                : self.shop.staff.getOrder(self.selectedOrderId)
        },
        get selectedStoreAddress() {
            return self.storeAddress;
        },
        get selectedStore() {
            return self.shop.storeStore.isLoading || !self.storeAddress
                ? null
                : self.shop.stores.get(self.storeAddress)
        }        
    }))
    .actions(self => ({
        selectedPage(page = self.page){
            // console.log(self.shop.stores.get(self.storeAddress).getPage(page))
            if(!page && typeof window !=="undefined") page = window.location.pathname.replace("/","")
            if(!self.shop.isMarketplace || self.storeAddress){
              return self.isLoading || !page
                ? null
                : self.shop.stores.get(self.storeAddress).getPage(page)
            }
        },        
        updateTitle(title){
            self.title = title;
        },
        updatePath(path){
            self.path = path;
        },
        updateDescription(description){
            self.description = description;
        },
        changePushState() {
            self.push.changePushState();
        },
        updateStoreAddress(store){
            self.storeAddress = store
        },
        // toggleDropdown(dropdown = "") {
        //     if(dropdown === ""){
        //         self.dropdownOpen = !self.dropdownOpen;
        //     }else{
        //         self.dropdownOpen = dropdown;
        //     }
        // },
        showDropdown() {
            self.dropdownOpen = true;
            self.popoverOpen = false;
        },
        hideDropdown() {
            self.dropdownOpen = false;
        },
        toggleDropdown() {
            self.dropdownOpen = !self.dropdownOpen;
            if(self.dropdownOpen)
                self.popoverOpen = false;
        },
        toggleAccount() {
            self.loginModal = !self.loginModal;
            self.loginModalType = 'account';
        },
        toggleLogin() {
            self.loginModal = !self.loginModal;
            self.loginModalType = 'login';
        },
        toggleLocation() {
            self.locationModal = !self.locationModal;
        },
        toggleNewAddress() {
            self.newAddressModal = !self.newAddressModal;
        },
        toggleDeliveryTime() {
            self.deliveryTimeModal = !self.deliveryTimeModal;
        },
        closeSignup(){
            self.loginModal = false;
            self.loginModalType = 'login';
        },
        updateLoginError(error){
            self.loginError = error;
        },
        toggleSignup() { 
            self.loginModal = !self.loginModal;
            self.loginModalType = 'signup';
        },
        toggleReset() { 
            self.loginModal = !self.loginModal;
            self.loginModalType = 'resetpassword';
        },
        changeType(type) {
            self.updateLoginError('');
            self.loginModalType = type;
        },
        cartOpen() {
            self.popoverOpen = true;
            
            if(self.shop.cart.cartQty <= 0)
                self.popoverOpen = false;
        },
        cartPopup() {
            if(self.page !== "checkout"){
                self.popoverOpen = !self.popoverOpen;
                
                if(self.shop.cart.cartQty <= 0)
                    self.popoverOpen = false;
            }
        },
        openPage(page, clear = true){
            if(self.selectedStore){
                self.selectedStore.updateBlogSkip(0);
                self.selectedStore.updateBlogMore(true);
            }
            self.page = page
            if(clear)
                self.storeAddress = ''

            self.toggleMenu(false)
            self.popoverOpen=false
        },   
        openHomePage(){
            self.page = 'home'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openOrdersPage(){
            self.page = 'orders'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openCustomersPage(email=''){
            if(typeof email === 'string')
                self.selectedCustomerId = email
    
            self.page = 'customers'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        setCustomerId(val = ''){
            self.selectedCustomerId = val;
        },
        openProductsPage(){
            self.page = 'products'
            self.toggleMenu(false)
            self.popoverOpen=false
        },

        openStaffPage(){
            self.page = 'staff'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openStoresPage(){
            self.page = 'stores'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openSchedulePage(){
            self.page = 'schedule'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openProductsPageWithSearch(search){
            self.page = 'products'

            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openCheckoutPage(){
            // if(self.shop.cart.entries.length > 0){
                self.page ='checkout'
            // }else{
            //     setTimeout(function() {
            //         self.openPage('checkout',false)
            //     }, 2000);
            // }

            self.toggleMenu(false)
            self.popoverOpen=false
        },
        
        openAccommodationsPage(){
            self.page = 'accommodations'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openAccommodationPage(accommodation){
            if(self.shop.isMarketplace){
                self.page = "accommodation"
                self.accommodationAddress = accommodation                
            }else{
                self.page = 'home'
            }
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openAccommodationByAddress(address) {
            self.page = "accommodation"
            self.accommodationAddress = address
            self.toggleMenu(false)
            self.popoverOpen=false
        },    
        openBoutiquesPage(){
            self.page = 'shops'
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        updateHash(hash=''){
            if(typeof hash === "string")
                self.hash = hash;
        },
        openAccountPage(hash=''){
            self.page = 'account'
            if(typeof hash === "string")
                self.hash = hash
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openFavouritesPage(){
            self.page = 'favourites'

            self.toggleMenu(false)
            self.popoverOpen=false
        },
        openCategory(category){
          self.page = "products"
          
        },
        openProductPage(product) {
            self.selectedProductId = product.slug
            self.page = "product"
            self.shop.productStore.updateProducts([product])
        },
        openBlogPage(post = '') {
            if(post === '' ||  Object.keys(post).length === 0){
                self.page = 'blog'
                self.selectedPostId = ''
            }else{
                self.selectedPostId = post.slug
                self.page = "post"
            }
        },
        openProductPageById(id) {
            self.shop.productStore.addProduct(id)
            self.page = "product"
            self.selectedProductId = id
            
        },
        openOrderPage(id) {
            self.page = "order"
            self.selectedOrderId = id
            
        },        
        openPostPageById(slug) {
            // if(self.selectedStore){
                self.page = "post"
                // self.selectedStore.loadPosts(slug);
                self.selectedPostId = slug
            // }
        },
        openStorePage(store) {
            if(self.shop.isMarketplace){
                self.page = "store"
                self.storeAddress = store                
            }else{
                self.page = 'home'
            }
            self.toggleMenu(false)
            self.popoverOpen=false
        },        
        openStoreByAddress(address) {
            self.page = "store"
            self.storeAddress = address
            self.toggleMenu(false)
            self.popoverOpen=false
        },
        toggleMenu(toggle = "") {
            if(toggle === ""){
                self.menuOpen= !self.menuOpen;
            }else{
                self.menuOpen = toggle;
            }
        },
        toggleCart() {
            self.popoverOpen= !self.popoverOpen;
            self.popoverOpen=false
        },
        toggleMCart() {
            self.popoverMOpen= !self.popoverOpen;
            self.popoverOpen=false
        },
        afterCreate() {
            setTimeout(function() {
                if(typeof window !=="undefined" && (self.shop.getLocationValue('hash').indexOf('pwkey') > -1 ))
                    self.toggleReset();
            }, 1400);
        }
    }))
