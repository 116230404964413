import React from "react"

import "../../css/schedule.css"
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

class StoreSchedule extends React.Component {
    constructor(props) {
        super(props);
        

        const events = [
          {
            id: 0,
            title: 'Admin',
            hexColor: 'cfff95',
            start: new Date(2020, 1, 10, 9, 0, 0),
            end: new Date(2020, 1, 10, 17, 0, 0),
            resourceId: 1,
          },
          {
            id: 1,
            title: 'Marketing',
            hexColor: 'cfff95',
            allDay: true,
            start: new Date(2020, 1, 11, 9, 0, 0),
            end: new Date(2018, 1, 11, 16, 30, 0),
            resourceId: 1,
          },
          {
            id: 2,
            title: 'Accounting',
            hexColor: 'cfff95',
            start: new Date(2020, 1, 12, 8, 30, 0),
            end: new Date(2020, 1, 12, 17, 30, 0),
            resourceId: 1,
          },
          {
            id: 3,
            title: 'Head Barista',
            hexColor: 'cfff95',
            start: new Date(2020, 1, 13, 9, 0, 0),
            end: new Date(2020, 1, 13, 17, 30, 0),
            resourceId: 2,
          },
        ]

        const resourceMap = [
          { resourceId: 1, resourceTitle: 'Admin', resourceId: 2, resourceTitle: 'Barista' }
        ]

        this.state = {resourceMap:resourceMap,events:events}
    }
    eventStyleGetter(event, start, end, isSelected) {
      var backgroundColor = '#' + event.hexColor;
      var style = {
          backgroundColor: backgroundColor,
          borderRadius: '0px',
          opacity: 0.8,
          color: 'black',
          border: '0px',
          display: 'block'
      };
      return {
          style: style
      };
    }

    render() {
        var shop = this.props.shop;
        var store = this.props.store;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;
    
        const localizer = momentLocalizer(moment)
        return (
            <div>
              <Calendar
                events={this.state.events}
                localizer={localizer}
                defaultView={Views.WEEK}
                views={['work_week', 'week']}
                step={60}
                defaultDate={new Date()}
                resources={this.state.resourceMap}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                eventPropGetter={(this.eventStyleGetter)}
              />
              <button type="button" className="btn btn-info btn-fill pull-right" style={{float:'right',margin:10}}>SMS Schedule</button>
              <div className="clearfix"></div>
            </div>   
        )
    }
}

  export default StoreSchedule;