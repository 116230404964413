// import { action, observable } from 'mobx'
// import { useStaticRendering } from 'mobx-react'

// const isServer = typeof window === 'undefined'
// useStaticRendering(isServer)

// class Store {
//   @observable lastUpdate = 0
//   @observable light = false

//   constructor (isServer, initialData = {}) {
//     this.lastUpdate = initialData.lastUpdate != null ? initialData.lastUpdate : Date.now()
//     this.light = !!initialData.light
//   }

//   @action start = () => {
//     this.timer = setInterval(() => {
//       this.lastUpdate = Date.now()
//       this.light = true
//     }, 1000)
//   }

//   stop = () => clearInterval(this.timer)
// }


// let store = null
// export function initializeProdStore (initialData) {
//   // Always make a new store if server, otherwise state is shared between requests
//   if (isServer) {
//     return new Store(isServer, initialData)
//   }
//   if (store === null) {
//     store = new Store(isServer, initialData)
//   }
//   return store
// }
import { observable, action, computed, useStrict } from 'mobx';
import axios from 'axios';
import Cookies from 'universal-cookie';


class ProdStore {

	constructor (initialData = {}) {
		this.apiAddress = 'http://localhost:3001'

		if(typeof window !=="undefined" && window.location.port !== '3007')
            this.apiAddress = 'https://api.thaodien.me'
        
	    this.domain = initialData.domain != null ? initialData.domain : ''
	    this.marketplace = initialData.marketplace != null ? initialData.marketplace : ''
	    this.suburb = initialData.suburb != null ? initialData.suburb : ''
	    if(initialData.products != null)
	    	this.setProducts(initialData.products, false)

	    var main_domain = ['www.tastysaigon.com','tastysaigon.com','www.thaodien.me','www.sweets.vn'];
	    var main_domain_filter = main_domain.filter(s => this.domain.includes(s));

	    if(main_domain_filter.length > 0){
	        main_domain= main_domain_filter[0];
	        this.setisMarketplace(true);
	    }
	}

	//Observable values can be watched by Observers
	@observable products = [];
	@observable favourites = [];
	@observable categories = [];
	@observable suggestions = [];
	@observable loading = true;
	@observable singleSearch = '';
	@observable search = [];
    @observable skip  = 0;
    @observable limit = 50;
    @observable domain = '';
    @observable isMarketplace = false;
    @observable budget = '';
    @observable sortBy = 'Featured';
    @observable suggestlimit = 3000;
    @observable more  = true;
    @observable moreAvailable = true;
    @observable noSearchProducts = 0;
    @observable noFavProducts = 0;
    @observable noSearchCategories = 0;    
    @observable selectedStore ='';
    @observable selectedCategory = '';
	@observable selectedProduct = {};
	// @observable apiAddress = 'https://api.thaodien.me';
	@observable apiAddress = 'http://localhost:3001';
	@observable domain = '';
	@observable marketplace = '';
	@observable suburb = '';
	@observable region = '';
	@observable token = '';

	@computed get getDomain() { return this.domain}
	@computed get getSelectedProduct() { return this.selectedProduct}
	@computed get selectedId() { return this.selectedProduct.slug; }
	@computed get favouriteList() {
		return this.favourites.toJSON();
	}
	@computed get productList() {
		return this.products.toJSON();
	}
	@computed get featuredProductList() {
		return this.products.toJSON().filter(function(prod) { 
			return (
				prod.featuredMarketplace === true
			)});
	}
	@computed get storeProductList() {
		return this.products.toJSON()
                // .sort((a, b) => (a.categories[0].keyName > b.categories[0].keyName ? 1 : a.categories[0].keyName === b.categories[0].keyName ? 0 : -1));
	}
	@computed get isLoading() {
		if(typeof this.loading === "undefined")
			return true;

        return this.loading
    }
    @action setLocalStorage(label,value){
        if(typeof window !=="undefined"){
            window.localStorage.setItem(label,value);
        }else{
            return false;
        }
    }
    @action getLocalStorage(label){
        if(typeof window !=="undefined"){
            return window.localStorage.getItem(label);
        }else{
            return '';
        }
    }
    @action removeLocalStorage(label){
        if(typeof window !=="undefined"){
            window.localStorage.removeItem(label);
        }else{
            return false;
        }
    }
    @action setLocationValue(label,value){
        if(typeof window !=="undefined"){
            window.location[label] = value;
        }else{
            return false;
        }
    }
    @action getLocationValue(label){
        if(typeof window !=="undefined"){
            return window.location[label];
        }else{
            return '';
        }
    }
    @action reloadPage(){
        if(typeof window !=="undefined")
            window.location.reload();
    }
    @action getProducts(marketplace = this.marketplace, domain = this.domain, append = false, limit = this.limit, sortBy =  this.sortBy, id="", order_type='delivery', suburb = this.suburb) {
		this.limit = limit;
        
        var search;

        if(this.search)                          
        	search = this.search.toString();  
        
        if(this.search.length === 0)
        	search = this.singleSearch;

        var address = this.apiAddress;

        if(!this.isMarketplace && domain !==''){
        	this.domain = domain;
			address += '/api/products/shop';
		}else if(!this.isMarketplace && this.domain !== ''){
        	domain = this.domain;
			address += '/api/products/shop';
        }else{
        	domain = '';
			address += '/api/products';
        }

        var region  = this.region;
        if(this.getLocalStorage("staff") && this.getLocalStorage("staff") !== null && this.getLocalStorage("staff") !== "" && this.getLocalStorage("staff").length > 3 && this.getLocalStorage("staff").indexOf('ip') > -1  && this.getLocalStorage("staff").indexOf('region') !== ""){
        	var usr = JSON.parse(this.getLocalStorage("staff"));
        	
        	if(usr.region)
        		region = usr.region;
    	}
    	try{
	    	axios.get(address + '?search=' + search + '&store=' + this.selectedStore + '&skip='+this.skip + '&limit='+ this.limit + '&region=' + region + '&budget=' + this.budget + '&domain=' + domain + '&marketplace=' + marketplace + '&sort=' + sortBy + '&id=' + id + '&type=' + order_type + '&suburb=' + suburb + '&atoken=' + this.token).then( response => {
	            if(!response.data || !response.data.products.length > 0 || response.data.count === 0){// ||  response.data.products.length < this.limit)
		        	this.more= false;
		        	this.loading = false;
	            }else
	            	this.updateSearchNo(response.data.count)

	            var products = response.data.products;
	            if((!sortBy || sortBy === 'Featured') && this.skip === 0 && domain === "")
	            	products = products.sort(function(a, b){return 0.5 - Math.random()});

	            this.setProducts(products, append);
	        });
	    }catch(error){
	    	console.log(error)
	    }
    }
    @action updateSort(marketplace = '',domain ='',sort){
		this.sortBy = sort;
		this.skip = 0
	    this.more = true
	    this.markLoading(true)
	    console.log('updateSort')
		this.getProducts(marketplace,domain);
	}
	@action updateSearchNo(no){
		this.noSearchProducts = no;
	}
	@action updateFavProductsNo(no){
		this.noFavProducts = no;
	}
	@action getFavourites(slugz = [], domain ='', id='') {
		var slugs = [];
		if (slugs.length === 0 && this.getLocalStorage("favs") && this.getLocalStorage("favs") !== '' &&  typeof (this.getLocalStorage("favs") !== null)){
			var slugs_array = JSON.parse(this.getLocalStorage("favs"))
			slugs_array.forEach(function (slug) {
				slugs.push(slug.product_slug)
			})
		}else{
			slugs = slugz;
		}
				
		var region  = this.region;

		var address = this.apiAddress;

        if(!this.isMarketplace){
            address += '/api/products/shop-slugs-list';
        }else{
            address += '/api/products/slugs-list';
        }
        try{
			axios.post(address,{
				slugs:slugs,
				id:id,
				region:region,
	        	domain:domain,
	        	atoken:this.token
			}).then( response => {
				if(response.data && response.data.length > 0){
					this.updateFavProductsNo(response.data.count)
					this.setFavourites(response.data);
					this.markLoading(false);
				}
			});
		}catch(error){
			console.log(error)
		}
	}	
	@action getFromCategories(category,lang){
		var products =  this.products.toJSON().filter(function(prod) { 
			return (
				prod.categories.find(function (cat) {return cat.title_en === category.title_en; })
			) 
		});
		if(products){
        	return products.map(function (product) {
        		return {slug:product.slug,title:product['title_'+lang],description:product['itemDescription_'+lang],price:product.price,image:product.imageUrl, quantity:product.quantity, available: product.available}
        	});
        }
       	return [];
	}
	@action getFromSlug(slug){
		var product =  this.products.toJSON().filter(function(prod) { 
			return (
				prod.slug === slug
			) 
		});
		if(product){
        	return product[0]
        }
       	return [];
	}
	@action getSuggestions(domain ='',marketplace='') {
		try{
			axios.get(this.apiAddress + '/api/products/suggest?store=' + this.selectedStore + '&limit='+ this.suggestlimit + '&domain=' + domain + '&marketplace=' + marketplace + '&atoken=' + this.token).then( response => {
				if(response.data && response.data.search.length > 0){
					this.setSuggestions(response.data.search);
				}else{
					this.more= false
				}
			});
		}catch(error){
			console.log(error);
		}
	}
	@action resetSettings(search=true){
		this.products = [];
		this.favourites = [];
		this.categories = [];
		this.suggestions = [];
		this.markLoading(true);
		if(search)
			this.search = [];
		this.singleSearch = '';
	    this.skip  = 0;
	    this.limit = 50;
	    this.suggestlimit = 3000;
	    this.more  = true;
	    this.moreAvailable = true;
	    this.noSearchProducts = 0;
	    this.noFavProducts = 0;
	    this.noSearchCategories = 0;
	    this.selectedStore ='';
	    this.selectedCategory = '';
		this.selectedProduct = {};
	}

    @action setSuggestions = (suggestion, append=false) => {
		this.suggestions = [...suggestion];
	}

	@action setFavourites = (favourites) => {
		this.favourites = [...favourites];
	}

	@action updateWholesalePrice= (products) => {
		products.forEach(function(product, i) {
		// products.map(function (product, i) {
			if(product.wholesale === true && product.customizations.length > 0){
				product.customizations.forEach(function (customization, j) {
					customization.options.forEach(function (option, k) {
						if(typeof option.wholesale_price !== "undefined")
							products[i].customizations[j].options[k].price = option.wholesale_price;
					});
				});
			}
		});
		return products;
	}
	@action setDomain = (domain) => {
		this.domain = domain;
	}
	@action setMarketplace = (marketplace) => {
		this.marketplace = marketplace;
	}
	@action setisMarketplace = (isMarketplace) => {
		this.isMarketplace = isMarketplace;
	}
	@action setRegion = (region) => {
		this.region = region;
	}
	@action setSuburb = (suburb) => {
		this.suburb = suburb;
	}
	//In strict mode, only actions can modify mobx state
	@action setProducts = (products, append=false) => {
		// check products wholesale and update options if exists
		
		// products = this.updateWholesalePrice(products)

		if(append){
			this.products = [...this.products, ...products]
		}else{
			this.products = [...products];
		}
		this.markLoading(false);
	}
	@action selectProduct = (product) => {
		this.selectedProduct = product;
	}
	@action clearSelectedProduct = () => {
		this.selectedProduct = {};
		console.log('clearSelectedProduct')
		this.getProducts();
	}

	@action addToSearch(marketplace='',domain='',value) {

        if(this.search.indexOf(value) > -1){
          this.search.remove(value)
        }else{
          this.search.push(value)
        }
        this.skip = 0;
        console.log('addToSearch')
        this.getProducts(marketplace,domain);
    }

	@action addSingleSearch(marketplace='',domain='',value,id){
		this.markLoading(true)
		this.singleSearch = value;
		this.skip = 0;
		console.log('addSingleSearch')
		if (navigator.onLine)
	        this.getProducts(marketplace,domain, false, this.limit, this.sortBy, id)
	}
	@action closeRestaurants(marketplace='',domain='',value,id='',suburb=''){
	    this.skip = 0
	    this.more = true
	    this.markLoading(true)
	    this.singleSearch = ''
		this.search = value;
		console.log('closeRestaurants')
	    if (navigator.onLine)
	    	this.getProducts(marketplace,domain, false, this.limit, this.sortBy, id,'', suburb)
    }
	@action addSearch(value,marketplace='',domain='',id=''){
	    this.skip = 0
	    this.more = true
	    this.markLoading(true)
	    this.singleSearch = ''
		this.search = value;

		if(domain === '')
			domain = this.domain;

		if(marketplace === '')
			marketplace = this.marketplace;

		console.log('addSearch ', this.selectedStore)
	    if (navigator.onLine)
	    	this.getProducts(marketplace,domain, false, this.limit, this.sortBy, id)
    }
    @action clearSearch(marketplace='',domain='', id='') {
    	this.skip = 0
        this.search = []
        console.log('clearSearch')
    	this.getProducts(marketplace,domain, false, this.limit, this.sortBy, id, 'delivery')
    }
    @action addBudget  = (value) => {
      if(this.budget === value){
        this.budget = ''
      }else{
        this.budget = value;
      }
      console.log('addBudget')
      if (navigator.onLine)
    	this.getProducts();
    }
    @action clearBudget() {
        this.budget = '';
    }

    @action loadScroll(marketplace='',domain='',id='',order_type='delivery') {
    	if(this.more){// && this.products.length >= this.limit){
    		if(this.products.length < this.limit){
            	this.skip += this.products.length
    		}else{
    			this.skip += this.limit
    		}
            this.markLoading(true)
            console.log('loadScroll')
            this.getProducts(marketplace,domain,true,this.limit, this.sortBy,id,order_type);
        }
    }
    @action setSelectedStore = (selectedStore) => {
        this.selectedStore = selectedStore
    }
    @action markLoading = (loading) => {
    	this.loading = loading
    }
}


let prods = null;

export function initializeProdsStore (data) {
	if(!typeof window !=="undefined"){
		prods = new ProdStore(data);
	}else{
		if(prods == null){
			prods = new ProdStore(data);				
		}
		if(typeof window !=="undefined" && window.location.port === '3007')
			window.prods = prods;
	}
	prods.setSelectedStore(data.selectedStore)
	return prods;
}