import React from "react"

import StoreMessage from "./StoreMessages";
import StoreProfile from "./StoreProfile";
import StoreAvailability from "./StoreAvailability";
import StorePermissions from "./StorePermissions";
import StoreProfileSide from "./StoreProfileSide";
import StoreSchedule from "./StoreSchedule";
import StoreQualifications from "./StoreQualifications";

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class DetailedStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeTab:'1'}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.store !== this.state.store) {
      this.setState({ store: nextProps.store });
    }
  }

  toggle(no){
    this.setState({activeTab:no})
  }

  updateCompany(event){
    var _this = this;
    var store = event.target.value;
  }

  render() {
    var shop = this.props.shop;
    var store = this.props.store;
    var layout = this.props.layout;
    var lang = this.props.lang;
    var lang_date = this.props.lang_date;

    var fontSize = 10;

    return (
       <tr>
        <td colSpan="12">
          <div className="col-sm-10 offset-sm-1" style={{boxShadow:'0 0 1in -0.25in rgba(0, 0, 0, 0.5)', marginTop:65,paddingTop:10, marginBottom:50,margin:100}}>
            <Nav tabs>
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '1' })} style={{cursor:'pointer'}} onClick={() => { this.toggle('1'); }}>
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '2' })} style={{cursor:'pointer'}} onClick={() => { this.toggle('2'); }}>
                    Work Schedule
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '3' })} style={{cursor:'pointer'}} onClick={() => { this.toggle('3'); }}>
                    Qualifications
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '4' })} style={{cursor:'pointer'}} onClick={() => { this.toggle('4'); }}>
                    Permissions
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '5' })} style={{cursor:'pointer'}} onClick={() => { this.toggle('5'); }}>
                    Work Availabilty
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                    <div className="content" style={{margin:20, minHeight:800}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Edit Profile</h4>
                                        </div>
                                        <div className="card-body">
                                            <StoreProfile shop={shop} store={store} layout={layout} /> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <StoreProfileSide shop={shop} store={store} layout={layout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="content" style={{margin:20, minHeight:800}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Edit Qualifications</h4>
                                        </div>
                                        <div className="card-body">
                                            <StoreSchedule shop={shop} store={store} layout={layout} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <StoreProfileSide shop={shop} store={store} layout={layout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="content" style={{margin:20, minHeight:800}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Edit Qualifications</h4>
                                        </div>
                                        <div className="card-body">
                                            <StoreQualifications shop={shop} store={store} layout={layout} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <StoreProfileSide shop={shop} store={store} layout={layout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="4">
                    <div className="content" style={{margin:20, minHeight:800}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Edit Permissions</h4>
                                        </div>
                                        <div className="card-body">
                                            <StorePermissions shop={shop} store={store} layout={layout} />    
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <StoreProfileSide shop={shop} store={store} layout={layout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="content" style={{margin:20, minHeight:800}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Edit Qualifications</h4>
                                        </div>
                                        <div className="card-body">
                                            <StoreAvailability shop={shop} store={store} layout={layout} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <StoreProfileSide shop={shop} store={store} layout={layout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
              </TabContent>
          </div>          
        </td>
      </tr>      
        )
    }
}

  export default DetailedStore;