import React from 'react'
 
class StepFive extends React.Component {
    constructor(props) {
      super(props);
      this.state = {check:false};
    }
    
    isValidated() {
      return false;
    }

    componentWillMount(){
      this.props.shop.view.toggleLogin()
    }

    render() {
      // var _this = this;
      // setTimeout(function() {
      //   _this.props.closeSignup();
      // }, 12000);

        return (<div></div>)
       
  }
}

export default StepFive