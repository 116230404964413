/*global google*/
import React from "react";
import { inject, observer } from "mobx-react"
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap';

class NewStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {staff: 
        				{	_id: "0",
        					store_slug:'',
				          	password:'',
				          	startArea:'dashboard',
				        	firstname: '',
					        lastname: '',
					        description:'',
					        email: '',
					        verified:true,
					        address:  {},   
					        facebook:'',
					        phone: '',
					        dob:'01/10/2000'
					    }
        			}
    }
    updateField(value,event){
        var staff = this.state.staff;
        if(value.indexOf(".") > -1){
            staff[value.split(".")[0]][value.split(".")[1]] = event.target.value;
        }else{
            staff[value] = event.target.value;
        }
        this.setState({staff})
        
    }
    newStaff(){
        this.props.shop.staff.newStaff(this.state.staff)
        this.props.updateSearchStaff(this.state.staff.email);
        this.props.toggleNewStaff();
    }

    render() {
        var shop = this.props.shop;
        var staff = this.state.staff;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;
		
		return (
			<Modal isOpen={true} toggle={this.props.toggleNewStaff} className={this.props.className}>
            	<ModalHeader toggle={this.props.toggleNewStaff}>
                	<span style={{fontWeight:300,fontSize:28, position:'relative',top:-10}}>{shop.cart.order_type === "delivery" ? shop.marketplace.layout.getTitle('delivery_time',lang) : shop.marketplace.layout.getTitle('booking_time',lang)}</span>
            	</ModalHeader>
            	<ModalBody>
					<div>
		                <form>
		                    <div className="row">
		                        <div className="col-md-6 pr-1">
		                            <div className="form-group">
		                                <label>Company</label>
		                                {this.props.shop.isMarketplace && this.props.stores ?
		                                    <select className="form-control" defaultValue={staff.store_slug} onChange={this.updateField.bind(this,'store_slug')}>
		                                        <option value=''>All Stores</option>
		                                          {this.props.stores.map((store,i) => 
		                                            <option key={i} value={store.slug}>{store.title}</option>
		                                          )}                      
		                                     </select>
		                                :
		                                <input type="text" className="form-control" disabled placeholder="Company" defaultValue={staff.store_title && staff.store_title !== "" ? staff.store_title : 'All Stores'} />
		                                }                                                    
		                            </div>
		                        </div>    
		                    </div>
		                    <div className="row">
		                        <div className="col-md-6 pr-1">
		                            <div className="form-group">
		                                <label htmlFor="exampleInputEmail1">Email address</label>
		                                <input type="email" className="form-control" placeholder="Email" defaultValue={staff.email} onChange={this.updateField.bind(this,'email')} />
		                            </div>
		                        </div>
		                        <div className="col-md-6 pl-1">
		                            <div className="form-group">
		                                <label>Password</label>
		                                <input type="password" className="form-control" placeholder="" defaultValue={''} onChange={this.updateField.bind(this,'password')} />
		                            </div>
		                        </div>
		                    </div>
		                    <div className="row">
		                       <div className="col-md-4 pr-1">
		                            <div className="form-group">
		                                <label>First Name</label>
		                                <input type="text" className="form-control" placeholder="First Name" defaultValue={staff.firstname} onChange={this.updateField.bind(this,'firstname')} />
		                            </div>
		                        </div>
		                        <div className="col-md-4 pr-1">
		                            <div className="form-group">
		                                <label>Last Name</label>
		                                <input type="text" className="form-control" placeholder="Last Name" defaultValue={staff.lastname} onChange={this.updateField.bind(this,'lastname')}  />
		                            </div>
		                        </div>    
		                        <div className="col-md-4 pl-1">
		                            <div className="form-group">
		                                <label>Phone</label>
		                                <input type="tel" className="form-control" placeholder="Phone" defaultValue={staff.phone} onChange={this.updateField.bind(this,'phone')}  />
		                            </div>
		                        </div>                        
		                    </div>
		                    <div className="row">
		                        <div className="col-md-6 pr-1">
		                            <div className="form-group">
		                                <label>Facebook</label>
		                                <input type="text" className="form-control" placeholder="Facebook" name="facebook" defaultValue={staff.facebook} onChange={this.updateField.bind(this,'facebook')}  />
		                            </div>
		                        </div>     
		                        
		                    </div>
		                    <div className="row">
		                        <div className="col-md-12">
		                            <div className="form-group">
		                                <label>Address</label>
		                                <input type="text" className="form-control" placeholder="Home Address"  defaultValue={staff.address && staff.address.street}  onChange={this.updateField.bind(this,'address.street')} />
		                            </div>
		                        </div>
		                    </div>
		                    <div className="row">
		                        <div className="col-md-4 pr-1">
		                            <div className="form-group">
		                                <label>Suburb</label>
		                                <input type="text" className="form-control" placeholder="Suburb" defaultValue={staff.address && staff.address.suburb} onChange={this.updateField.bind(this,'address.suburb')} />
		                            </div>
		                        </div>
		                        <div className="col-md-4 pr-1">
		                            <div className="form-group">
		                                <label>City</label>
		                                <input type="text" className="form-control" placeholder="City" defaultValue={staff.address && staff.address.city} onChange={this.updateField.bind(this,'address.city')} />
		                            </div>
		                        </div>
		                        <div className="col-md-4 px-1">
		                            <div className="form-group">
		                                <label>Country</label>
		                                <input type="text" className="form-control" placeholder="Country"  defaultValue={staff.address && staff.address.country} onChange={this.updateField.bind(this,'address.country')} />
		                            </div>
		                        </div>                                                   
		                    </div>
		                    <div className="row">
		                        <div className="col-md-6 pr-1">
		                            <div className="form-group">
		                                <label>DOB</label>
		                                <input type="date" className="form-control" placeholder="DOB" defaultValue={staff.dob} onChange={this.updateField.bind(this,'dob')} />
		                            </div>
		                        </div>
		                    </div>
		                    <div className="row">
		                        <div className="col-md-12">
		                            <div className="form-group">
		                                <label>About Me</label>
		                                <textarea rows="4" cols="80" className="form-control" placeholder="Staff Bio description" defaultValue={staff.description} onChange={this.updateField.bind(this,'description')}></textarea>
		                            </div>
		                        </div>
		                    </div>
		                <button type="button" className="btn btn-info btn-fill pull-right" style={{float:'right',margin:10, background:'#000',border:'#333'}} onClick={this.newStaff.bind(this)}>Create New Staff Member</button>
		                    <div className="clearfix"></div>
						</form>
					</div>
				</ModalBody>
			</Modal>			
			);
 	 	}
	}

  export default NewStaff;
