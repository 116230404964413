import React from "react"

class StoreProfileSide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        var shop = this.props.shop;
        var store = this.props.store;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;

        var sideImg = "https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&amp;fm=jpg&amp;h=300&amp;q=75&amp;w=400";

        if(store.store.length > 0)
            sideImg = shop.imageDirectory + store.store[0].logoImageUrl;
        return (
             <div className="card card-user">
                <div className="card image">
                    <img src={sideImg} alt="..." />
                </div>
                <div className="card-body">
                    <div className="author">
                        <a href="#">
                            {store.imageUrl && store.imageUrl !== "" ?
                                <img src={store.imageUrl.indexOf("http") > -1 ? store.imageUrl : shop.imageDirectory + store.imageUrl} className="avatar border-gray" alt={store.firstname + ' ' + store.lastname}  />
                                : store.store.length > 0 ?
                                <img src={shop.imageDirectory + store.store[0].logoImageUrl} style={{width:45}} />
                              :
                                <span>
                                  &nbsp;
                                </span>
                              }
                              <br />
                            <h5 className="title">{store.firstname + ' ' + store.lastname}</h5>
                        </a>
                        <p className="description">
                            {store.description}
                        </p>
                    </div>
                    <p className="description text-center">
                        {store.store_title && store.store_title !== "" ?
                          <span style={{fontWeight:'bold'}}>
                            {store.store_title}
                          </span>
                          :
                            <span style={{fontWeight:'bold'}}>
                                All Stores
                            </span>
                          }
                          <br /><br />
                        {store.permissions.map((permission, i) =>
                            <span key={i} style={{textTransform:'Capitalize', fontSize:10}}>
                                <b>{permission.area}</b>  &nbsp;
                                {permission.access.replace('access', ' access')} &nbsp;  
                                {permission.grantedBy}<br />
                            </span>
                        )}
                    </p>
                </div>
                <hr />
                <div className="button-container mr-auto ml-auto">
                    <a href={store.facebook} target="_blank" className="btn btn-simple btn-link btn-icon">
                        <i className="fab fa-facebook-square" style={{color:'#9490D1'}}></i>
                    </a>
                    <a href={'tel:' + store.phone} target="_blank" className="btn btn-simple btn-link btn-icon">
                        <i className="fas fa-phone-square" style={{color:'#9490D1'}}></i>
                    </a>
                </div>
            </div>
        )
    }
}

  export default StoreProfileSide;