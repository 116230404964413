import React from "react";
import { inject, observer } from "mobx-react"

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import '../css/accountModal.css'

@inject("shop") @observer class AdminLogin extends React.Component {
  
   loginWithFacebook(response, openPage = true){
    var _this = this;
    if(response && typeof response.id !== "undefined"){
      return this.props.shop.staff.checkFacebook({facebookId:response.id,email:response.email,imageUrl:response.picture.data.url}).then(function(exists) {
        if(!exists)
          _this.props.shop.view.updateLoginError("Facebook Login Doesn't Exist");
        
      });     
    }
  }

  loginWithGoogle(response, openPage = true){
    var _this = this;
    if(response && typeof response.googleId !== "undefined"){
        return this.props.shop.staff.checkGoogle({googleId:response.googleId,email:response.profileObj.email,imageUrl:response.profileObj.imageUrl}).then(function(exists) {
        if(!exists)
          _this.props.shop.view.updateLoginError("Facebook Login Doesn't Exist");
      });
    }
  }

  staffLogin(){
    var _this = this;
    var login = this.props.shop.staff.staffLogin({email:this.refs.login_email.value,password:this.refs.login_password.value})
    // login.then(function(result){
      // if(result)
        // _this.props.shop.view.reRenderPage();
      //   window.location.reload()
    // });
  }

  render() {
    var _this = this;
    var shop = this.props.shop;
    var lang = shop.staff.lang;

    var layout = shop.marketplace.layout;

    var marketplace = shop.marketplace;

    if(!shop.isMarketplace ){
      var store = shop.view.selectedStore
      if(store)
        layout = store.layout;
    }
    var input_style = {"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"24px","width":"100%","padding":"18px 2px","background":"rgb(255, 255, 255)",border:'none',borderBottom:"1px solid rgb(229, 229, 228)","outline":"none","color":"rgb(0, 0, 0)","height":"44px","boxSizing":"border-box","borderRadius":"0px", marginBottom:10,marginTop:10};
    
    var signin_btn = {backgroundColor:layout.primTxtColor,fontSize:12,padding:"11px 20px" ,borderRadius:0,border:"2px solid" + layout.primTxtColor,textTransform:"uppercase",outline:"none",lineHeight:"18px",position:"relative", color: "#fff",margin:0, width:"100%"}

     return (
        <section id="adminlogin">
          <div style={{textAlign:'center',marginTop:100}}>
            <div className="" style={{display: 'inline-block',maxWidth:600,width:'80%',padding:20,border:'1px solid #efef',borderRadius:5}}>
              <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3" style={{textAlign:'center',height:50}}>
                    <img src={shop.imageDirectory + marketplace.logoImageUrl} style={{maxHeight:50}} />   
              </div>
              <div style={{textAlign:'left'}}>
                <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('email',lang)}</label>
                <input className="" name="email" placeholder="name@example.com" ref="login_email" style={input_style} type="text" />
              </div>              
              <div style={{textAlign:'left'}}>
                <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('password',lang)}</label>
                <input className="" name="password" ref="login_password" placeholder="At least 5 characters" style={input_style} type="password"  />
              </div>
              <div style={{textAlign:'center'}}>
                <div style={{textAlign:'center',color:'#ff0000',fontSize:12}}>{shop.view.loginError}</div>
                <button style={signin_btn} onClick={()=>{this.staffLogin()}}><span>{shop.marketplace.layout.getTitle('login',lang)}</span></button>
              </div>
              {/*
              <div style={{textAlign:'center'}}>
                <FacebookLogin
                  appId="2711062715627330"
                  fields="name,email,picture"
                  callback={this.loginWithFacebook.bind(this)}
                  cssClass="facebook-button-class"
                  icon="fa-facebook"
                /> 
                &nbsp;&nbsp;
                <GoogleLogin
                  clientId="418554825619-t91e73rmo5ttr1cgdvtkon7bqnqcj6ef.apps.googlestaffcontent.com"
                  buttonText="LOGIN WITH GOOGLE"
                  onSuccess={this.loginWithGoogle.bind(this)}
                  onFailure={this.loginWithGoogle.bind(this)}
                />
              </div>
              */}
            </div>
          </div>
        </section>  
    );
  }
}

export default AdminLogin;