import React from "react";

import InlineCss from "react-inline-css";
import moment from "moment";

export class OrderMessages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendSMS: false,
      sendEmail: false,
      sendNote: true,
      phone: this.props.phone,
      sender: this.props.store_phone,
      email: this.props.email,
      direction: "sent",
      subject: "",
      message: "",
      customer: this.props.customer,
    };
  }

  handleChangePhone(event) {
    this.setState({ phone: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeCustomer(event) {
    this.setState({ customer: event.target.value });
  }

  handleChangeSubject(event) {
    this.setState({ subject: event.target.value });
  }

  handleChangeMessage(event) {
    var tempMsg = event.target.value;
    tempMsg = tempMsg.replaceAll("@first_name", this.props.name.split(" ")[0]);
    this.setState({ message: tempMsg });
  }

  sendMessage() {
    this.props.sendMessage(this.state);
    this.setState({ message: "" });
  }

  showCamera() {
    this.props.showCamera();
  }

  setTemplateMessage(temp) {
    var message = { ...this.state, ...temp };
    for (var prop in message) {
      // console.log(message,prop)
      if (
        message.hasOwnProperty(prop) &&
        message[prop] &&
        message[prop].length > 0
      ) {
        var replaceArray = [
          "@uuid",
          "@store_title",
          "@store_phone",
          "@first_name",
          "@last_name",
          "@name",
          "@delivery_link",
        ];
        var replaceArrayValue = [
          this.props.uuid.substr(0, 6),
          this.props.store_title,
          this.props.store_phone,
          this.props.name.split(" ")[0],
          this.props.name.split(" ")[this.props.name.split(" ").length - 1],
          this.props.name,
          this.props.delivery_link,
        ];
        var finalAns = message[prop];
        var regex;
        for (var i = 0; i < replaceArray.length; i++) {
          regex = new RegExp(replaceArray[i], "g");
          finalAns = finalAns.replace(regex, replaceArrayValue[i]);
        }
        message[prop] = finalAns;
      }
    }
    console.log(message);
    this.setState(message);
  }

  //       const Message = new Schema({
  //   uuid: { type: String, default: uuid.v1 },
  //   customer: {type:String, default: ''},
  //   email: {type:String, default: ''},
  //   phone: {type:String, default: ''},
  //   sender: {type:String, default: ''},
  //   subject: {type:String, default: ''},
  //   message: {type:String, default: ''},
  //   staff: { type: Schema.Types.ObjectId, ref: 'Staff' },
  //   sent:{type:Boolean,default:false},
  //   read:{type:Boolean,default:false},
  //   notification:{type:Boolean,default:false},
  //   direction: {type:String, default: 'sent'},
  //   createdAt: {
  //     type: Date,
  //     default: Date.now
  //   },
  //   sendEmail:{type:Boolean,default:false},
  //   sendNote:{type:Boolean,default:false},
  //   sendSMS:{type:Boolean,default:false}
  // });

  deleteMessage(message_uuid) {
    var _this = this;
    if (window.confirm("Are you sure you wish to delete this message?")) {
      _this.props.shop.staff
        .deleteMessage(_this.props.uuid, message_uuid)
        .then((response) => {
          _this.props.loadOrders();
        });
    }
  }

  renderMessages(messages, photos) {
    let i = 0;
    let tempMessages = [];

    let messageCount = messages.length;
    console.log(messageCount);
    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.direction === "sent";
      let currentMoment = moment(current.createdAt);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.createdAt);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.direction === current.direction;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.createdAt);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.direction === current.direction;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          deleteMessage={this.deleteMessage.bind(this)}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  }

  render() {
    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var layout = shop.marketplace.layout;
    var fontSize = this.props.fontSize;

    return (
      <InlineCss
        stylesheet={`
      .message-list-container {
        padding: 10px;
        padding-bottom: 70px;
      }
      .message {
        display: flex;
        flex-direction: column;
      }

      .message .timestamp {
        display: flex;
        justify-content: center;
        color: #999;
        font-weight: 600;
        font-size: 10px;
        margin: 10px 0px;
        text-transform: uppercase;
      }

      .message .bubble-container {
        font-size: 14px;
        display: flex;
      }

      .message.mine .bubble-container {
        justify-content: flex-end;
      }

      .message.start .bubble-container .bubble {
        /* margin-top: 10px; */
        border-top-left-radius: 20px;
      }

      .message.end .bubble-container .bubble {
        border-bottom-left-radius: 20px;
        /* margin-bottom: 10px; */
      }

      .message.mine.start .bubble-container .bubble {
        margin-top: 10px;
        border-top-right-radius: 20px;
      }

      .message.mine.end .bubble-container .bubble {
        border-bottom-right-radius: 20px;
        margin-bottom: 10px;
      }

      .message .bubble-container .bubble {
        margin: 1px 0px;
        background: #f4f4f8;
        padding: 10px 15px;
        border-radius: 20px;
        max-width: 75%;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .message.mine .bubble-container .bubble {
        background: #007aff;
        color: white;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
      .toolbar {
        display: flex;
        align-items: center;

        background-color: white;
        font-weight: 500;
        border-bottom: 1px solid #eeeef1;

        position: sticky;
        top: 0px;
        margin-bottom:50px;
      }

      @supports (backdrop-filter: blur(20px)) {
        .toolbar {
          border: none;
          background-color: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(20px);
        }
      }

      .toolbar-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #000;
        margin: 0;
        font-size: 16px;
        font-weight: 800;
      }

      .left-items, .right-items {
        flex: 1;
        padding: 10px;
        display: flex;
      }

      .right-items {
        flex-direction: row-reverse;
      }

      .left-items .toolbar-button {
        margin-right: 20px;
      }

      .right-items .toolbar-button {
        margin-left: 20px;
      }

      .left-items .toolbar-button:last-child,
      .right-items .toolbar-button:last-child {
        margin: 0;
      }
      .toolbar-button {
        color: #007aff;
        font-size: 28px;
        transition: all 0.1s;
      }

      .toolbar-button:hover {
        cursor: pointer;
        color: #0063ce;
      }

      .toolbar-button:active {
        color: #007aff;
        opacity: 0.25;
      }
      .messenger {
        display: grid;
        width: 100%;
        height: 100vh;
        background: #eeeef1;

        grid-template-columns: 350px auto;
        grid-template-rows: 60px auto 60px;
        grid-column-gap: 1px;
        grid-row-gap: 1px;
      }

      .container {
        padding: 10px;
      }

      .scrollable {
        position: relative;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

      .sidebar {
        background: white;
        grid-row-start: 1;
        grid-row-end: span 3;
      }

      .content {
        background: white;
        grid-row-start: 1;
        grid-row-end: span 3;
      }

      .footer {
        grid-column-start: 2;
        background: white;
      }
      .compose {
        padding: 10px;
        display: flex;
        align-items: center;
        background: white;
        border-top: 1px solid #eeeef1;
        position: fixed;
        width: calc(100% - 20px);
        bottom: 0px;
      }

      @supports (backdrop-filter: blur(20px)) {
        .compose {
          border: none;
          background-color: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(20px);
        }
      }

      .compose-input {
        border: none;
        font-size: 14px;
        height: 40px;
        background-color: rgba(0, 0, 0, .05);
        border-radius: 18px;
        display: flex;
        flex: 1 1 auto;
        margin: 8px 8px 8px 0;
        min-width: 100px;
        padding: 0 8px 0 12px;
        color: rgba(0, 0, 0, .50);
        -webkit-text-fill-color: rgba(0, 0, 0, .50);
        width:84%;
        display:inline-block;
      }

      .compose-input::placeholder {
        opacity: 0.3;
      }

      .compose .toolbar-button {
        color: #bbbbbf;
        margin-left: 15px;
      }

      .compose .toolbar-button:hover {
        color: #99999c;
      }

      .message_icon:hover{
        opacity:1;
      }
      .message_icon{
        opacity:0.4;
      }
      .message_icon_selected{
        opacity:1;
      }
    `}
      >
        <div className="message-list">
          <div className="toolbar">
            <div className="left-items"></div>
            <h1 className="toolbar-title">
              {this.props.store_title + " , " + this.props.name}
            </h1>
            <div className="right-items">
              <i className="toolbar-button fal fa-info-circle"></i>
              <i className="toolbar-button fal fa-envelope"></i>
              <i className="toolbar-button fal fa-phone"></i>
            </div>
          </div>

          <div className="message-list-container">
            {this.renderMessages(this.props.messages)}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: 8 }}>
              <a
                onClick={(e) => {
                  this.showCamera();
                }}
              >
                <i
                  className="fal fa-camera"
                  style={{
                    fontSize: 28,
                    verticalAlign: "middle",
                    color: "#007aff",
                    margin: 5,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a>
                <i
                  className={
                    "message_icon " +
                    (this.state.sendSMS
                      ? " message_icon_selected fas fa-sms"
                      : " fal fa-sms")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ sendSMS: !this.state.sendSMS });
                  }}
                  style={{
                    fontSize: 28,
                    verticalAlign: "middle",
                    color: "#007aff",
                    margin: 5,
                    cursor: "pointer",
                  }}
                ></i>
              </a>
              &nbsp;
              <a>
                <i
                  className={
                    "message_icon " +
                    (this.state.sendEmail
                      ? "message_icon_selected fas fa-envelope "
                      : " fal fa-envelope ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ sendEmail: !this.state.sendEmail });
                  }}
                  style={{
                    fontSize: 28,
                    verticalAlign: "middle",
                    color: "#007aff",
                    margin: 5,
                    cursor: "pointer",
                  }}
                ></i>
              </a>
              &nbsp;
              <i
                className={
                  "message_icon fas fa-sticky-note message_icon_selected"
                }
                style={{
                  fontSize: 28,
                  verticalAlign: "middle",
                  color: "#007aff",
                  margin: 5,
                }}
              ></i>
            </div>
            {/*              <span style={{fontWeight:500,fontSize:10}}>
                  {this.props.messageTemplates && this.props.messageTemplates.map((temp,i) =>
                    <span key={i}><a onClick={(e)=>{this.setTemplateMessage(temp)}} style={{cursor:'pointer'}}>{temp.template_name}</a> &nbsp;|&nbsp;</span>
                  )}
                </span> */}
            <div style={{ flex: 1 }}>
              &nbsp;
              <input
                type="text"
                className="compose-input"
                placeHolder="Type a message, @uuid, @store_title, @store_phone, @first_name, @last_name, @name, @delivery_link"
                value={this.state.message}
                onChange={this.handleChangeMessage.bind(this)}
              />
              &nbsp;
              {this.state.message === "" ? (
                <i
                  className="fal fa-paper-plane message_icon message_icon_selected"
                  style={{
                    fontSize: 24,
                    verticalAlign: "middle",
                    color: "#007aff",
                    margin: 2,
                    opacity: 0.5,
                  }}
                ></i>
              ) : (
                <i
                  onClick={(e) => {
                    this.sendMessage();
                  }}
                  className="fas fa-paper-plane message_icon message_icon_selected"
                  style={{
                    fontSize: 24,
                    verticalAlign: "middle",
                    color: "#007aff",
                    margin: 2,
                  }}
                ></i>
              )}
            </div>
          </div>
        </div>
      </InlineCss>
    );
  }
}

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  render() {
    const { data, isMine, startsSequence, endsSequence, showTimestamp } =
      this.props;

    const friendlyTimestamp = moment(data.timestamp).format("LLLL");

    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
        ].join(" ")}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {data.image ? (
              <span>
                <img src={data.image} style={{ width: 70 }} />
                <br />
                <span style={{ fontSize: 10, textAlign: "center" }}>
                  {data.message}
                </span>
              </span>
            ) : (
              data.message
            )}
            <a
              onClick={(e) => {
                this.props.deleteMessage(data.uuid);
              }}
            >
              <i
                className="far fa-times-circle"
                style={{
                  fontSize: 20,
                  verticalAlign: "middle",
                  color: "#fff",
                  margin: 5,
                  marginLeft: 10,
                  marginRight: 0,
                  cursor: "pointer",
                }}
              ></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderMessages;
