import React from "react"

class StorePermissions extends React.Component {
    constructor(props) {
        super(props);

        var areas = [{area:'dashboard',access:'noaccess'},{area:'orders',access:'noaccess'},{area:'customers',access:'noaccess'},{area:'store',access:'noaccess'},{area:'pos',access:'noaccess'},{area:'stores',access:'noaccess'}]

        if(this.props.store){
            var store = this.props.store;
            areas.map(function (area, i) {
                if(store.permissions.find(function (perm) {return perm.area === area.area; })){
                    areas[i].access = store.permissions.find(function (perm) {return perm.area === area.area; }).access;
                    areas[i].grantedBy = store.permissions.find(function (perm) {return perm.area === area.area; }).grantedBy;
                }
            });
        }

        this.state = {areas:areas};
    }

    updatePermission(e){
        var areas = this.state.areas;
        areas.map(function (area, i) {
            if(areas[i].area == e.currentTarget.name)
               areas[i].access = e.currentTarget.value;
        });
        this.setState(areas)
    }

    updateStorePermissions(){
        this.props.shop.staff.updateStorePermissions(this.props.store._id,this.state.areas)
    }

    render() {
        var shop = this.props.shop;
        var store = this.props.store;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;

        return (
            <div>
                <table style={{fontWeight:'bold'}}>
                    <tbody>
                        <tr>
                            <td>
                                Which area of Tasty they can access ?
                            </td>
                            <td align="center">
                                View & Edit
                            </td>
                           <td align="center">
                                View Only
                            </td>
                           <td align="center">
                                No Access
                            </td>
                            <td align="center">
                                Last Granted By
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                    {this.state.areas.map((area, i) =>
                        <tr key={i}>
                            <td>
                                <span style={{textTransform:'capitalize',fontWeight:'normal'}}>{area.area}</span>                                
                            </td>
                            <td align="center">                 
                                <input type="radio" name={area.area} checked={area.access === "fullaccess" && true} onChange={this.updatePermission.bind(this)} value="fullaccess" />
                            </td>
                            <td align="center">                 
                                <input type="radio" name={area.area} checked={area.access === "readonly" && true} onChange={this.updatePermission.bind(this)} value="readonly" />
                            </td>
                            <td align="center">
                                <input type="radio" name={area.area} checked={area.access === "noaccess" && true} onChange={this.updatePermission.bind(this)} value="noaccess"  />
                            </td>
                            <td>
                                <span style={{textTransform:'capitalize',fontWeight:'normal'}}>{area.grantedBy}</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>    
                <button type="button" style={{background:'#000',border:'#000'}} className="btn btn-dark pull-right" style={{float:'right',margin:10}} onClick={this.updateStorePermissions.bind(this)}>Set Permissions</button>
              <div className="clearfix"></div>
            </div>
        )
    }
}

  export default StorePermissions;