import { isObservableArray } from "mobx";
import axios from "axios";
// getSnapshot
import { types, getParent, applySnapshot, detach } from "mobx-state-tree";
import { Product } from "./ProductStore";
import { v4 as uuidv4 } from "uuid";
import Cookies from "universal-cookie";

const address = types
  .model("address", {
    uuid: types.optional(types.identifier, uuidv4()),
    apt: types.optional(types.string, ""),
    delivery_instructions: types.optional(types.string, ""),
    label_address: types.optional(types.string, ""),
    form_address: types.optional(types.string, ""),
    street: types.optional(types.string, ""),
    ward: types.optional(types.string, ""),
    suburb: types.optional(types.string, ""),
    post_code: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    region: types.optional(types.string, ""),
    country: types.optional(types.string, ""),
    country_code: types.optional(types.string, ""),
    gps: types.optional(types.array(types.number), []),
    selected: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    updateLocation(gps) {
      if (isObservableArray(gps)) {
        self.gps = gps.toJSON();
      } else if (gps.length === 2) {
        self.gps = gps;
      }
    },
    updateAddress(address) {
      // console.log(typeof address.gps, address.gps.length)
      // if(typeof address.gps.toJSON() !== "undefined")
      self.updateLocation(address.gps);

      Object.keys(address).forEach(function (key, index) {
        if (key !== "uuid" && key !== "gps") {
          self[key] = address[key];
        }
      });
    },
  }));

const entryOptions = types
  .model("entryOptions", {
    uuid: types.optional(types.string, ""),
    slug: types.optional(types.string, ""),
    title: types.optional(types.string, ""),
    image: types.optional(types.string, ""),
    price: types.optional(types.number, 0),
    size: types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    updatePrice(price) {
      self.price = price;
    },
  }));

const CartEntry = types
  .model("CartEntry", {
    quantity: 0,
    entryOptions: types.optional(types.array(entryOptions), []),
    size: types.optional(types.boolean, false),
    product: types.reference(Product),
    categories: types.optional(types.array(types.string), []),
    preOrder: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    sku: types.optional(types.string, ""),
    store: types.optional(types.string, ""),
    store_slug: types.optional(types.string, ""),
    store_title: types.optional(types.string, ""),
    store_phone: types.optional(types.string, ""),
    location: types.optional(types.string, ""),
    deliveryAmt: types.optional(types.number, 0),
    total: types.number,
    token: types.optional(types.string, ""),
    website: types.optional(types.string, ""),
    order_placed_on: types.optional(types.string, ""),
    instructions: "",
  })
  .views((self) => ({
    get price() {
      return (self.product.price / 100) * self.quantity;
    },
    get isValidProduct() {
      return self.product.viewable;
    },
  }))
  .actions((self) => ({
    updateProduct(product) {
      self.product = product;
      var amount = product.price;
      self.entryOptions.forEach(function (entryOpt, i) {
        if (product.customizations[i]) {
          var product_option = product.customizations[i].options.find(function (
            opt
          ) {
            return entryOpt.uuid === opt.uuid;
          });
          if (typeof product_option !== "undefined") {
            amount += product_option.price;
            entryOpt.updatePrice(product_option.price / 100);
          }
        }
      });
      self.total = self.quantity * amount;
    },
    increaseQuantity(amount) {
      var total = self.total / self.quantity;
      if (self.quantity === 0) total = self.total;
      self.quantity += parseInt(amount, 10);
      //  Update total for entry
      self.total = self.quantity * total;
    },
    decreaseQuantity(amount) {
      var total = self.total / self.quantity;
      if (self.quantity === 0) total = self.total;
      self.quantity -= parseInt(amount, 10);
      //  Update total for entry
      self.total = self.quantity * total;
    },
    setQuantity(amount) {
      var total = self.total / self.quantity;
      if (self.quantity === 0) total = self.total;
      self.quantity = parseInt(amount, 10);
      //  Update total for entry
      self.total = self.quantity * total;
    },
  }));

const discount = types
  .model("discount", {
    name: types.optional(types.string, ""),
    code: types.optional(types.string, ""),
    status: types.optional(types.boolean, false),
    usedWhen: types.optional(types.string, ""), //orders anything, spends at least ($), orders at least (items), from this area
    usedWhenAmount: types.optional(types.number, 0), // amount for spends at least.
    usedWhenArea: types.optional(types.string, ""), // suburb, city or state for used when.
    customerGets: types.optional(types.string, ""), //percent, flat rate, flat rate item (off each item), free shipping, shipping percent. /(in future free product)
    discountAmount: types.optional(types.number, 0),
    discountOn: types.optional(types.string, ""), //complete order, category, product
    discountOnSlug: types.optional(types.string, ""), // product or category slug.
    startingOn: types.optional(types.string, ""),
    expiresOn: types.optional(types.string, "never"), //never, date, been used (per person)
    expiresDate: types.optional(types.string, ""),
    usedWithOtherDiscounts: types.optional(types.boolean, false), // check if other discounts applied to cart
    store: types.optional(types.string, ""),
    amount: types.optional(types.number, 0),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export const CartStore = types
  .model("CartStore", {
    uuid: types.optional(types.string, uuidv4()),
    user: types.optional(types.string, ""),
    name: "",
    phone: "",
    address: types.optional(address, {}),
    marketplace: types.optional(types.string, ""),
    domain: types.optional(types.string, ""),
    order_type: types.optional(types.string, "delivery"),
    order_description: types.optional(types.string, ""),
    payment_method: types.optional(types.string, "cash"),
    preOrder: types.optional(types.boolean, false),
    validPreOrder: types.optional(types.boolean, true),
    entries: types.array(CartEntry),
    total: types.optional(types.number, 0),
    discounts: types.optional(types.array(discount), []),
    freeShipping: types.optional(types.boolean, false),
    shippingDiscount: types.optional(types.number, 0),
    discountAmount: 0,
    checkoutLoading: false,
    deliveryDateTime: types.optional(types.string, ""),
    checkoutError: types.optional(types.string, ""),
    currency: types.optional(types.string, "vnd"),
    timestamp: types.optional(types.number, new Date().getTime()),
    loading: false,
  })
  .views((self) => ({
    get shop() {
      return getParent(self);
    },
    get isLoading() {
      return self.loading;
    },
    get cartQty() {
      return self.entries.reduce((sum, e) => sum + e.quantity, 0);
    },
    get cartEntries() {
      if (self.entries.length > 0) {
        const uniqueStores = [
          ...new Set(self.entries.map((item) => item.product.store.slug)),
        ];
        return uniqueStores;
      }
    },
    get subTotal() {
      return self.entries.reduce(function (sum, entry) {
        return sum + entry.total;
      }, 0);
    },
    get hasDiscount() {
      return self.discountAmount / 100 > 0;
    },
    get discount() {
      return self.hasDiscount ? self.discountAmount / 100 : 0;
    },
    get getTotal() {
      return self.subTotal - self.discountAmount;
    },
    get canCheckout() {
      var storeReachMin = true;

      const uniqueStores = [
        ...new Set(self.entries.map((item) => item.product.store.slug)),
      ];

      var available = true;

      self.entries.forEach(function (entry, i) {
        if (!entry.product.available) available = false;

        if (
          entry.product.store.location.disableDelivery === true &&
          self.order_type === "delivery"
        )
          available = false;

        if (
          !self.shop.storeStore.stores.get(entry.store_slug) ||
          !self.shop.storeStore.stores
            .get(entry.store_slug)
            .getLocation(self.address.suburb)
        ) {
          available = false;

          //  Check if delivery is asap but the store is closed.
        } else if (
          !self.shop.storeStore.stores
            .get(entry.store_slug)
            .getLocation(self.address.suburb).open
        ) {
          var startTime = new Date();
          var endTime = new Date(self.shop.cart.deliveryDateTime);
          var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
          var resultInMinutes = Math.round(difference / 60000);
          if (resultInMinutes < 60) {
            available = false;
            self.setCheckoutError(
              self.shop.storeStore.stores
                .get(entry.store_slug)
                .getLocation(self.address.suburb).title +
                " Closed - Please selected new delivery time"
            );
          }
        }

        if (self.order_type === "pickup" || self.order_type === "eatin")
          available = true;
      });

      uniqueStores.forEach(function (store) {
        var currentAmount = self.entries
          .filter(function (entry) {
            return entry.product.store.slug === store;
          })
          .reduce(function (sum, e) {
            var options = 0;
            if (typeof e.entryOptions !== "undefined")
              options = e.entryOptions.reduce(function (sum, e) {
                return sum + e.price;
              }, 0);

            return sum + e.quantity * (e.product.price / 100 + options);
          }, 0);
        if (
          !self.shop.stores.get(store) ||
          currentAmount < self.shop.stores.get(store).minimumOrderAmount
        )
          storeReachMin = false;
      });
      return (
        self.entries.length > 0 &&
        self.entries.every((entry) => entry.quantity > 0) &&
        storeReachMin &&
        available &&
        (self.order_type !== "delivery" || self.address.street !== "") &&
        self.validPreOrder
      );
    },

    get canOpenCheckout() {
      var storeReachMin = true;

      const uniqueStores = [
        ...new Set(self.entries.map((item) => item.product.store.slug)),
      ];

      var available = true;

      self.entries.forEach(function (entry, i) {
        if (!entry.product.available) available = false;

        if (self.order_type === "pickup" || self.order_type === "eatin")
          available = true;
      });

      uniqueStores.forEach(function (store) {
        var currentAmount = self.entries
          .filter(function (entry) {
            return entry.product.store.slug === store;
          })
          .reduce(function (sum, e) {
            var options = 0;
            if (typeof e.entryOptions !== "undefined")
              options = e.entryOptions.reduce(function (sum, e) {
                return sum + e.price;
              }, 0);

            return sum + e.quantity * (e.product.price / 100 + options);
          }, 0);
        if (
          !self.shop.stores.get(store) ||
          currentAmount < self.shop.stores.get(store).minimumOrderAmount
        )
          storeReachMin = false;
      });
      return (
        self.entries.length > 0 &&
        self.entries.every((entry) => entry.quantity > 0) &&
        storeReachMin &&
        available
      );
    },
  }))
  .actions((self) => ({
    setCheckoutError(error) {
      self.checkoutError = error;
    },
    clearCart() {
      self.entries.clear();
    },
    deliveryCost(store) {
      //  search for discount to give free freight or discount freight
      var delivery_cost = self.shop.storeStore.deliveryCost(store);
      self.discounts.forEach(function (discount, i) {
        if (discount.customerGets === "free shipping") delivery_cost = 0;
        else if (discount.customerGets === "shipping percent")
          delivery_cost -=
            (delivery_cost / 100) * (discount.discountAmount / 100);
      });
      return delivery_cost;
    },
    orderDeliveryCost(stores) {
      var deliveryCost = 0;
      stores.forEach(function (store, i) {
        deliveryCost += self.deliveryCost(store);
      });
      return deliveryCost;
    },
    storeSubtotal(store) {
      var subTotal = 0;
      self.entries.forEach(function (entry, i) {
        if (entry.store_slug === store.slug) subTotal += entry.total;
      });
      if (self.order_type === "delivery") {
        return self.deliveryCost(store) + subTotal / 100;
      } else {
        return subTotal / 100;
      }
    },
    checkPreOrder() {
      self.setCheckoutError("");

      var preOrderHours = 0;
      self.setValidPreOrder(true);
      self.setPreOrder(false);

      self.entries.forEach(function (entry, i) {
        if (entry.preOrder) {
          if (entry.product.preOrderMinHours > preOrderHours)
            preOrderHours = entry.product.preOrderMinHours;
          self.setPreOrder(true);
        }

        // Check if store location is open and if not what time it opens and set pre - Order to that time
        if (
          !self.shop.storeStore.stores.get(entry.store_slug) &&
          !self.shop.storeStore.stores
            .get(entry.store_slug)
            .getLocation(self.address.suburb)
        ) {
          self.setCheckoutError(
            entry.product["title_" + self.shop.staff.lang] +
              " " +
              self.shop.marketplace.layout.getTitle(
                "not_available",
                self.shop.staff.lang
              )
          );
          self.shop.alert(self.checkoutError, "", "error", "br", 10000);
        } else if (
          entry.product.store.open === false ||
          self.shop.storeStore.stores
            .get(entry.store_slug)
            .getLocation(self.address.suburb).open === false
        ) {
          var now = new Date();
          var deliveryDateTime = new Date();
          var requiredDelTime = new Date();
          var startTime = 0;
          var days = 0;

          if (self.deliveryDateTime !== "")
            deliveryDateTime = new Date(self.deliveryDateTime);

          if (
            now.getHours() <
            parseInt(
              self.shop.storeStore.stores
                .get(entry.store_slug)
                .getLocation(self.address.suburb).deliveryHoursInfos[
                now.getDay() + days
              ].startTime,
              10
            )
          ) {
            startTime = self.shop.storeStore.stores
              .get(entry.store_slug)
              .getLocation(self.address.suburb).deliveryHoursInfos[
              now.getDay() + days
            ].startTime;
            requiredDelTime.setDate(requiredDelTime.getDate());
            requiredDelTime.setHours(startTime.split(":")[0]);
            requiredDelTime.setMinutes(startTime.split(":")[1]);
            requiredDelTime.setSeconds("00");
          } else {
            while (startTime === 0) {
              startTime = self.shop.storeStore.stores
                .get(entry.store_slug)
                .getLocation(self.address.suburb).deliveryHoursInfos[
                now.getDay() + days
              ].startTime;
              days++;
            }

            requiredDelTime.setDate(requiredDelTime.getDate() + days);
            requiredDelTime.setHours(startTime.split(":")[0]);
            requiredDelTime.setMinutes(startTime.split(":")[1]);
            requiredDelTime.setSeconds("00");
          }

          var diffHours = (deliveryDateTime - requiredDelTime) / 36e5;

          if (diffHours < -0.1) {
            var lang = self.shop.staff.lang;
            var lang_date = "en-US";
            if (lang === "vi") lang_date = "vi-VN";
            if (lang === "ja") lang_date = "ja-JP";
            if (lang === "zh") lang_date = "zh-CN";
            if (lang === "ko") lang_date = "ko-KR";

            self.setValidPreOrder(false);
            if (diffHours < 24) {
              self.setCheckoutError(
                entry.store_title +
                  " is not open yet, orders need to be placed for after " +
                  startTime
              );
            } else if (diffHours < 48) {
              self.setCheckoutError(
                entry.store_title +
                  " is closed, place orders for tomorrow after " +
                  startTime
              );
            } else {
              self.setCheckoutError(
                "Store is closed orders need to be after " +
                  new Date(requiredDelTime).toLocaleDateString(lang_date, {
                    weekday: "short",
                    day: "numeric",
                    month: "short",
                  }) +
                  "  " +
                  new Date(requiredDelTime).toLocaleTimeString(lang_date, {}) +
                  "."
              );
            }
            self.shop.alert(self.checkoutError, "", "error", "br", 2000);
          }
        }
      });

      if (self.preOrder) {
        var today = new Date();
        var deliveryDateTime = new Date();
        if (self.deliveryDateTime !== "")
          deliveryDateTime = new Date(self.deliveryDateTime);

        var minDeliveryTime = today.setHours(today.getHours() + preOrderHours);

        var diffHours =
          (parseInt(deliveryDateTime.getTime(), 10) -
            parseInt(minDeliveryTime, 10)) /
          36e5;

        if (diffHours < 0.1) {
          self.setValidPreOrder(false);
          self.setCheckoutError(
            "Order contains an item that needs to be ordered " +
              preOrderHours +
              " hours in advance."
          );
          self.shop.alert(self.checkoutError, "", "error", "br", 10000);
        }
      }
    },
    setPreOrder(value) {
      self.preOrder = value;
    },
    setValidPreOrder(value) {
      self.validPreOrder = value;
    },
    setCheckoutLoading(set) {
      self.checkoutLoading = set;
    },
    markLoading(set) {
      self.loading = set;
    },
    storeReachMin(store) {
      var currentAmount = self.entries
        .filter(function (entry) {
          return entry.product.store.slug === store;
        })
        .reduce(function (sum, e) {
          var options = 0;
          if (typeof e.entryOptions !== "undefined")
            options = e.entryOptions.reduce(function (sum, e) {
              return sum + e.price;
            }, 0);

          return sum + e.quantity * (e.product.price / 100 + options);
        }, 0);
      return currentAmount >= self.shop.stores.get(store).minimumOrderAmount;
    },
    afterCreate() {
      if (
        self.getLocationValue("hash") === "cc" ||
        self.getLocationValue("hash") === "#cc"
      ) {
        setTimeout(function () {
          if (self.entries.length > 0) self.entries.clear();
          self.addCartToServer();
        }, 500);
      } else {
        setTimeout(function () {
          self.getCartFromServer();
        }, 2000);
      }
    },
    setLocalStorage(label, value) {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(label, value);
      } else {
        return false;
      }
    },
    getLocalStorage(label) {
      if (typeof window !== "undefined") {
        return window.localStorage.getItem(label);
      } else {
        return false;
      }
    },
    removeLocalStorage(label) {
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(label);
      } else {
        return false;
      }
    },
    setLocationValue(label, value) {
      if (typeof window !== "undefined") {
        window.location[label] = value;
      } else {
        return false;
      }
    },
    getLocationValue(label) {
      if (typeof window !== "undefined") {
        return window.location[label];
      } else {
        return "";
      }
    },
    reloadPage() {
      self.shop.reloadPage();
    },
    afterAttach() {
      self.domain = self.shop.domain;
      self.marketplace = self.shop.marketplace.slug;
      self.timestamp = new Date().getTime();

      // if(self.getLocationValue('hash') === 'cc' || self.getLocationValue('hash') === '#cc'){
      //     self.entries.clear();
      //     self.addCartToServer();
      // }else{
      //     self.getCartFromServer();
      // }

      // if (typeof window.localStorage !== "undefined") {
      //     when(
      //         () => !self.shop.isLoading,
      //         () => {
      //             self.readFromLocalStorage()
      //             reaction(
      //                 () => getSnapshot(self),
      //                 json => {
      //                     self.setLocalStorage("cart", JSON.stringify(json));
      //                     // self.addCartToServer(json);
      //                 }
      //             )
      //         }
      //     )
      // }
    },
    getCartFromServer() {
      const cookies = new Cookies();
      var token = cookies.get("cart_" + self.cookieID());

      if (
        token !== "undefined" &&
        typeof token !== "undefined" &&
        self.shop.apiAddress
      ) {
        try {
          axios
            .get(
              self.shop.apiAddress +
                "/api/stores/cart?token=" +
                token +
                "&atoken=" +
                self.shop.token
            )
            .then((resp) => {
              if (
                resp.data &&
                resp.data.cart &&
                resp.data.cart !== "null" &&
                resp.data.cart.website.indexOf("admin") > -1
              ) {
                self.setLocalStorage("cart", JSON.stringify(resp.data.cart));
                self.shop.productStore.updateProducts(resp.data.products);
                setTimeout(function () {
                  self.loadCartData(resp.data.cart);
                }, 1000);
              } else {
                self.removeLocalStorage("cart");
                cookies.remove("cart_" + self.cookieID(), { path: "" });
                self.markLoading(false);
              }
            });
        } catch (err) {
          console.error(" ", err);
        }
      } else {
        self.markLoading(false);
      }
    },
    updateCartProducts(products) {
      self.entries.forEach(function (entry, i) {
        var product = products.find(function (prod) {
          return entry.product.slug === prod.slug;
        });
        if (product)
          self.entries[i].updateProduct(self.shop.products.get(product.slug));
      });
      self.updateTotal();
    },
    cookieID() {
      var result = {};
      var regexParse = new RegExp("([a-z-0-9]{2,63}).([a-z.]{2,5})$");
      var urlParts = regexParse.exec(self.getLocationValue("hostname"));
      if (urlParts && urlParts.length > 0) {
        result.domain = urlParts[1];
        result.type = urlParts[2];
        result.subdomain = self
          .getLocationValue("hostname")
          .replace(result.domain + "." + result.type, "")
          .slice(0, -1);
        if (result.subdomain.split(".").length > 0)
          result.subdomain = result.subdomain.split(".")[1];

        var id = result.domain;

        if (typeof result.subdomain !== "undefined")
          id = result.subdomain + "-" + id;

        return id;
      } else {
        return "Tasty";
      }
    },
    addCartToServer(checkPromo = false) {
      var json = self.toJSON();
      const cookies = new Cookies();

      if (self.domain === "") self.domain = self.shop.domain;

      var cart = Object.assign({}, json);
      cart["user"] = self.user;
      cart["atoken"] = self.shop.token;
      cart["marketplace"] = self.shop.marketplace.slug;
      cart["website"] = self.getLocationValue("origin");
      try {
        return axios
          .post(self.shop.apiAddress + "/api/stores/cart", cart)
          .then((response) => {
            var token = response.data.token;
            cookies.remove("cart_" + self.cookieID());
            cookies.set("cart_" + self.cookieID(), token, { path: "/" });
            if (checkPromo) self.checkPromoDiscounts(token);

            return token;
          });
      } catch (err) {
        console.error(" ", err);
      }
    },
    processCheckout(stToken = {}) {
      const cookies = new Cookies();
      var token = cookies.get("cart_" + self.cookieID());
      var _this = this;

      if (token === "undefined") token = self.addCartToServer();

      if (token !== "undefined") {
        try {
          self.setCheckoutLoading(true);

          axios
            .post(self.shop.apiAddress + "/api/stores/checkout", {
              token: token,
              stToken: stToken,
              atoken: self.shop.token,
            })
            .then((resp) => {
              if (resp.data.success) {
                cookies.remove("cart_" + self.cookieID());
                self.removeLocalStorage("cart");
                // console.log(resp.data.redirect_link.indexOf('/order/'),resp.data.redirect_link.replace("/order/","").replace("#cc",""))
                // if(resp.data.redirect_link !=='' && resp.data.redirect_link.indexOf('/order/') > -1)
                //     shop.view.openOrderPage(resp.data.redirect_link.replace("/order/","").replace("#cc",""))
                // else
                if (resp.data.redirect_link !== "")
                  self.setLocationValue("href", resp.data.redirect_link);
                else self.setLocationValue("href", "/account#cc");
              } else {
                if (resp.data.message) {
                  self.shop.alert(resp.data.message, "", "error", "br");
                  if (resp.data.message === "System Error") self.reloadPage();
                }
                if (resp.data.error_items) {
                  resp.data.error_items.forEach(function (item) {
                    var prod = self.entries.find(function (entry) {
                      return entry.product === item;
                    });
                    self.removeProduct(prod);
                  });
                  self.setCheckoutLoading(false);
                }
                if (resp.data.message === "Payment Error") {
                  setTimeout(function () {
                    // location.reload();
                    self.setCheckoutLoading(false);
                  }, 500);
                } else if (resp.data.message === "System Error") {
                  self.shop.staff.signOut();
                  // self.setCheckoutLoading(false);
                  self.reloadPage();
                } else if (resp.data.message === "Cart Error") {
                  cookies.remove("cart_" + self.cookieID());
                  self.removeLocalStorage("cart");
                  // self.shop.staff.signOut()
                  setTimeout(function () {
                    self.clear();
                    self.setCheckoutLoading(false);
                  }, 1500);

                  // self.reloadPage();
                } else {
                  self.setCheckoutLoading(false);
                }
                // cookies.remove('cart_' + self.cookieID());
                // self.removeLocalStorage("cart");
                // self.reloadPage();
                // self.shop.staff.signOut()
                // self.entries.clear();
              }
            });
        } catch (err) {
          console.error(" ", err);
        }
      }
    },
    updateDiscountAmt(amount) {
      self.discountAmount = amount;
    },

    updateDiscounts(discounts) {
      self.discounts = discounts;
      self.addCartToServer();
    },

    removeDiscount(discount) {
      var discounts = self.discounts.filter(function (disc) {
        return disc.code !== discount.code;
      });
      if (discounts.length === 0) self.updateDiscountAmt(0);

      self.updateTotal();

      self.updateDiscounts(discounts);
    },

    processPromoCode(stToken = {}, code = "") {
      const cookies = new Cookies();
      var token = cookies.get("cart_" + self.cookieID());
      var _this = this;
      if (token === "undefined") {
        self.addCartToServer().then((token) => {
          self.sendPromoToServer(token, stToken, code);
        });
      } else {
        self.sendPromoToServer(token, stToken, code);
      }
    },

    sendPromoToServer(token, stToken = {}, code = "") {
      const cookies = new Cookies();
      if (code !== "" && token !== "undefined") {
        try {
          axios
            .post(self.shop.apiAddress + "/api/stores/promo_code", {
              token: token,
              stToken: stToken,
              promo_code: code,
              marketplace: self.shop.marketplace.slug,
              atoken: self.shop.token,
            })
            .then((resp) => {
              if (resp.data.success) {
                self.loadCartData(resp.data.cart);
                if (resp.data.message !== "")
                  self.shop.alert(resp.data.message, "", "error", "br", 10000);
              } else if (resp.data.message === "System Error") {
                self.shop.staff.signOut();
                self.reloadPage();
              } else if (resp.data.message === "Cart Error") {
                cookies.remove("cart_" + self.cookieID());
                self.removeLocalStorage("cart");
              } else {
                self.shop.alert(resp.data.message, "", "error", "br", 10000);
              }
            });
        } catch (err) {
          console.error(" ", err);
        }
      }
    },

    checkPromoDiscounts(token = "") {
      const cookies = new Cookies();
      if (token === "") token = cookies.get("cart_" + self.cookieID());

      // If discounts exist check if they are valid
      if (self.discounts.length > 0 && token !== "") {
        try {
          axios
            .post(self.shop.apiAddress + "/api/stores/check_discounts", {
              token: token,
              marketplace: self.shop.marketplace.slug,
              atoken: self.shop.token,
            })
            .then((resp) => {
              if (resp.data.success) {
                self.loadCartData(resp.data.cart);
              } else if (resp.data.message === "System Error") {
                self.shop.staff.signOut();
                self.reloadPage();
              } else if (resp.data.message === "Cart Error") {
                cookies.remove("cart_" + self.cookieID());
                self.removeLocalStorage("cart");
              } else {
                self.shop.alert(resp.data.message, "", "error", "br", 10000);
                console.log(resp);
              }
            });
        } catch (err) {
          console.error(" ", err);
        }
      }
    },

    changePaymentMethod(method) {
      //console.log('changePaymentMethod');
      self.payment_method = method;
      self.addCartToServer();
    },
    updateOrderDesc(desc) {
      self.order_description = desc;
      self.addCartToServer();
    },
    updateOrderType(type) {
      //console.log('updateOrderType');
      self.order_type = type;
      if (type !== "delivery") self.deliveryAmt = 0;

      self.addCartToServer();
    },
    // updateApt(apt){
    //     self.address.apt = apt;
    //     self.addCartToServer();
    // },
    // updateDeliveryInstructions(di){
    //     self.address.delivery_instructions = di;
    //     self.addCartToServer();
    // },
    updateAddressItem(item, value) {
      //console.log('updateAddressItem');
      self.address[item] = value;
      self.addCartToServer();
    },
    updateName(name) {
      //console.log('updateName');
      self.name = name;
      self.addCartToServer();
    },
    updatePhone(phone) {
      //console.log('updateOrderType');
      self.phone = phone;
      self.addCartToServer();
    },
    updateAddressFromStaff() {
      //console.log('updateAddressFromStaff');
      var address = self.shop.marketplace.address.toJSON();
      if (self.shop.staff.address) address = self.shop.staff.address.toJSON();
      self.updateAddress(address);
      self.addCartToServer();
    },
    updateAddress(address) {
      self.address.updateAddress(address);
      self.addCartToServer();
    },
    updateSubTotal(amount = 0) {},
    updateTotal(addtocart = false) {
      var subTotal = self.entries.reduce((sum, e) => sum + e.total, 0);
      self.updateSubTotal(subTotal);
      self.total = subTotal; // - (self.discount*100);
      if (addtocart) self.addCartToServer(true);
    },
    addProduct(
      product,
      quantity = 1,
      optvalues = {},
      instructions = "",
      notify = true,
      openCart = false,
      preOrder = false
    ) {
      self.shop.productStore.updateProducts([product]);
      product = self.shop.products.get(product.slug);

      var optionsTotal = 0;

      var options = [];
      var hasSize = false;
      for (var property in optvalues) {
        if (
          optvalues.hasOwnProperty(property) &&
          optvalues[property].length > 0
        ) {
          optvalues[property].forEach((sub_option) => {
            if (sub_option.size) hasSize = true;

            options.push(sub_option);
            optionsTotal += parseFloat(sub_option.price * 100);
          });

          // entryTotal = parseFloat(entryTotal) + (parseInt(quantity) * parseFloat(optvalues[property][0].price));
        }
      }
      // Object.values(optvalues).forEach((option) => { options.push(option); });
      let entry = self.entries.find(function (entry) {
        //let entry = self.entries.find(entry => (
        return (
          entry.product === product &&
          (product.customizations.length === 0 ||
            JSON.stringify(entry.entryOptions.toJSON()) ===
              JSON.stringify(options))
        );
      });

      // self.entries.find(function (entry,i) {
      //     //console.log('ent',(entry.product === product), (product.options.toJSON().length === 0 && ), JSON.stringify(entry.entryOptions) === JSON.stringify(options),)
      //     return (entry.product === product && (product.options.toJSON().length === 0 || JSON.stringify(entry.entryOptions) === JSON.stringify(options)))
      // });
      if (typeof entry === "undefined" || !entry) {
        var entryTotal = 1 * (product.price + optionsTotal);
        if (product.sale > 0) entryTotal = 1 * (product.sale + optionsTotal);

        var categories = product.categories.map((cat) => cat.title_en);

        self.entries.push({
          product: product,
          sku: product.sku,
          categories: categories,
          store: product.store._id,
          store_slug: product.store.slug,
          store_title: product.store.title,
          store_phone: product.store.phone,
          location: product.quantity.location,
          entryOptions: options,
          size: hasSize,
          instructions: instructions,
          total: entryTotal,
          preOrder: preOrder,
        });
        entry = self.entries[self.entries.length - 1];
      }
      entry.increaseQuantity(quantity);

      self.updateTotal();
      //console.log('addProduct');
      self.addCartToServer(true);

      self.checkPreOrder();

      if (openCart) self.shop.view.cartOpen();
      if (notify)
        self.shop.alert(
          "Added to cart",
          quantity +
            " x " +
            product["title_" + self.shop.staff.lang] +
            " " +
            ((product.price + optionsTotal) / 100).toLocaleString() +
            " " +
            self.shop.marketplace.currency,
          "info",
          "br"
        );
    },
    removeProduct(entry, notify = true) {
      let entryIndex = self.entries.findIndex((entryObj) => entryObj === entry);

      if (entryIndex > -1) {
        var quantity = self.entries[entryIndex].quantity;
        var title = self.shop.products.get(
          self.entries[entryIndex].toJSON().product
        )["title_" + self.shop.staff.lang];
        if (entryIndex > -1) detach(self.entries[entryIndex]);

        if (self.cartQty === 0) {
          self.shop.view.cartOpen();
          if (self.shop.view.currentUrl === "/checkout")
            self.shop.view.openProductsPage();
        }

        if (notify)
          self.shop.alert(
            "Removed from cart",
            quantity + " x " + title,
            "error",
            "br"
          );
      }
      self.updateTotal();
      self.addCartToServer(true);

      self.checkPreOrder();
    },
    cancelOrder(uuid = "") {
      var _this = this;
      if (uuid !== "") {
        try {
          axios
            .post(self.shop.apiAddress + "/api/orders/cancel", {
              token: self.shop.staff.token,
              uuid: uuid,
              atoken: self.shop.token,
            })
            .then((resp) => {
              self.shop.staff.updateOrderStatus(uuid, "Order Cancelled");
              self.shop.alert("Order Cancelled", "", "error", "br");
              self.shop.staff.fetchStaffDetails(
                self.shop.staff._id,
                self.shop.staff.token
              );
            });
        } catch (err) {
          console.error(" ", err);
        }
      }
    },
    openOrder(uuid = "") {
      var _this = this;
      if (uuid !== "") {
        try {
          axios
            .post(self.shop.apiAddress + "/api/orders/open", {
              token: self.shop.staff.token,
              uuid: uuid,
              atoken: self.shop.token,
            })
            .then((resp) => {
              self.shop.staff.updateOrderStatus(uuid, "Order Pending");
              self.shop.alert("Order Re-Opened", "", "error", "br");
              self.shop.staff.fetchStaffDetails(
                self.shop.staff._id,
                self.shop.staff.token
              );
            });
        } catch (err) {
          console.error(" ", err);
        }
      }
    },
    checkout() {
      self.shop.alert(
        "Bought " + self.cartQty + " products for " + self.total + " !"
      );
      self.shop.view.cartPopup();
      self.entries.clear();
    },
    setDeliveryTime(datetime) {
      self.deliveryDateTime = datetime.toString();
      self.checkPreOrder();
      self.addCartToServer();
    },
    clear() {
      self.entries.clear();
      if (self.shop.view.currentUrl === "/checkout")
        self.shop.view.openProductsPage();
    },
    validateCartEntries() {
      self.entries.forEach(function (entry, i) {
        if (!self.shop.stores.get(entry.product.store.slug)) detach(entry);
      });
    },
    readFromLocalStorage() {
      const cookies = new Cookies();
      if (
        cookies &&
        typeof cookies !== "undefined" &&
        cookies.get("cart_" + self.cookieID())
      ) {
        self.getCartFromServer();
      } else {
        self.loadCartData(JSON.parse(self.getLocalStorage("cart")));
      }
    },
    loadCartData(cart) {
      var cartData = JSON.stringify(cart);
      if (
        cart !== null &&
        cart.timestamp &&
        Math.floor(
          (new Date().getTime().toString() - cart.timestamp) / 1000 / 60 / 60
        ) > 24
      ) {
        self.addCartToServer();
        self.removeLocalStorage("cart");
        self.reloadPage();
      }
      if (cart !== null && typeof cart.entries !== "undefined") {
        if (self.shop.products.size > 0 && self.shop.stores.size > 0) {
          cart.entries.forEach(function (entry, i) {
            if (
              !self.shop.products.get(entry.product) ||
              !self.shop.stores.get(
                self.shop.products.get(entry.product).store.slug
              ) ||
              (!self.shop.stores.get(
                self.shop.products.get(entry.product).store.slug
              ).open &&
                !self.shop.stores.get(
                  self.shop.products.get(entry.product).store.slug
                ).canScheduleOrder)
            )
              cart.entries.splice(i, 1);
          });
          if (cartData) applySnapshot(self, cart);
          self.updateTotal();
        }
        // else{
        //     setTimeout(function() {
        //         var update_cart = true;
        //         cart.entries.forEach(function (entry, i) {
        //             if((!self.shop.products.get(entry.product)) || (self.shop.products.get(entry.product) && !self.shop.stores.get(self.shop.products.get(entry.product).store.slug)) || (!self.shop.stores.get(self.shop.products.get(entry.product).store.slug).open && !self.shop.stores.get(self.shop.products.get(entry.product).store.slug).canScheduleOrder)){
        //                 cart.entries.splice(i, 1)
        //             }else{
        //                 // update_cart = false;
        //             }
        //         });
        //         if (cartData && update_cart){
        //             applySnapshot(self, cart)
        //             self.setLocalStorage("cart", cartData);
        //         }
        //         self.updateTotal();
        //     },3500);
        // }
      }
      self.markLoading(false);
    },
  }));
