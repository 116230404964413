import React from "react"

import "../../css/schedule.css"
import "../../css/availability.scss"

import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

// import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const DragAndDropCalendar = withDragAndDrop(Calendar)

const events = [
    {
      id: 0,
      title: 'Available',
      hexColor:'cfff95',
      start: new Date(2020, 1, 10, 9, 0, 0),
      end: new Date(2020, 1, 10, 17, 0, 0),
      resourceId: 1,
    },
    {
      id: 1,
      title: 'Available',
      hexColor:'cfff95',
      allDay: true,
      start: new Date(2020, 1, 11, 9, 0, 0),
      end: new Date(2018, 1, 11, 16, 30, 0),
      resourceId: 1,
    },
    {
      id: 2,
      title: 'Available',
      hexColor:'cfff95',
      start: new Date(2020, 1, 12, 8, 30, 0),
      end: new Date(2020, 1, 12, 17, 30, 0),
      resourceId: 1,
    },
    {
      id: 3,
      title: 'Not Available',
      hexColor:'e57373',
      start: new Date(2020, 1, 13, 9, 0, 0),
      end: new Date(2020, 1, 13, 17, 30, 0),
      resourceId: 2,
    },
  ]

  const resourceMap = [
      { resourceId: 1, resourceTitle: 'Available' },
       { resourceId: 1, resourceTitle: 'Not Available' }
    ]

class StaffAvailability extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        events: events,
      }

      this.moveEvent = this.moveEvent.bind(this)
      this.newEvent = this.newEvent.bind(this)
    }

    moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
      const { events } = this.state

      const idx = events.indexOf(event)
      let allDay = event.allDay

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }

      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)

      this.setState({
        events: nextEvents,
      })

      // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    }

    resizeEvent = ({ event, start, end }) => {
      const { events } = this.state

      const nextEvents = events.map(existingEvent => {
        return existingEvent.id == event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      })

      this.setState({
        events: nextEvents,
      })

      //alert(`${event.title} was resized to ${start}-${end}`)
    }

    newEvent(event) {
      console.log(event.start,event.end)
      // let idList = this.state.events.map(a => a.id)
      // let newId = Math.max(...idList) + 1
      // let hour = {
      //   id: newId,
      //   title: 'New Event',
      //   allDay: event.slots.length == 1,
      //   start: event.start,
      //   end: event.end,
      // }
      // this.setState({
      //   events: this.state.events.concat([hour]),
      // })
    }

    eventStyleGetter(event, start, end, isSelected) {
      var backgroundColor = '#' + event.hexColor;
      var style = {
          backgroundColor: backgroundColor,
          borderRadius: '0px',
          opacity: 0.8,
          color: 'black',
          border: '0px',
          display: 'block'
      };
      return {
          style: style
      };
    }


    render() {
        var shop = this.props.shop;
        var staff = this.props.staff;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;
    
        const localizer = momentLocalizer(moment)
        return (
            <div>
              <div style={{float:'right', position:'absolute',right:-15,top:-35,margin:50}}>
                 <label className="switch">
                    <input type="checkbox" defaultChecked />
                    <span>
                        <em></em>
                        <strong></strong>
                    </span>
                </label>
              </div>
              <DragAndDropCalendar
                selectable
                localizer={localizer}
                events={this.state.events}
                onEventDrop={this.moveEvent}
                resizable
                onEventResize={this.resizeEvent}
                eventPropGetter={(this.eventStyleGetter)}
                onSelectSlot={this.newEvent}
                onDragStart={console.log}
                defaultView={Views.WEEK}
                views={['work_week', 'week']}
                step={60}
                defaultDate={new Date()}
                resources={this.state.resourceMap}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
              />
              <div className="clearfix"></div>
            </div>   
        )
    }
}

  export default StaffAvailability;