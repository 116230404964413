import React from "react"

class CustomersProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {customer: this.props.customer}
    }
    updateField(value,event){
        var customer = this.state.customer;
        if(value.indexOf(".") > -1){
            customer[value.split(".")[0]][value.split(".")[1]] = event.target.value;
        }else{
            customer[value] = event.target.value;
        }
        this.setState({customer})
        
    }
    updateCustomersProfile(){
        this.props.shop.staff.updateCustomersProfile(this.state.customer)
    }
    render() {
        var shop = this.props.shop;
        var customer = this.state.customer;
        var layout = this.props.layout;
        var lang = this.props.lang;
        var lang_date = this.props.lang_date;

        var fontSize = 10;

        return (
            <div>
                <form>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label>Company</label>
                                <input type="text" className="form-control" disabled placeholder="Company" defaultValue={customer.lastSite && customer.lastSite !== "" ? customer.lastSite : ''} />
                            </div>
                        </div>    
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email Address</label>
                                <input type="email" className="form-control" placeholder="Email" defaultValue={customer.email} onChange={this.updateField.bind(this,'firstname')} />
                            </div>
                        </div>
                        <div className="col-md-6 pl-1">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" placeholder="" defaultValue={''} onChange={this.updateField.bind(this,'password')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                       <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control" placeholder="First Name" defaultValue={customer.firstname} onChange={this.updateField.bind(this,'firstname')} />
                            </div>
                        </div>
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control" placeholder="Last Name" defaultValue={customer.lastname} onChange={this.updateField.bind(this,'lastname')}  />
                            </div>
                        </div>    
                        <div className="col-md-4 pl-1">
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="tel" className="form-control" placeholder="Phone" defaultValue={customer.phone} onChange={this.updateField.bind(this,'phone')}  />
                            </div>
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label>Facebook</label>
                                <input type="text" className="form-control" placeholder="Facebook" name="facebook" defaultValue={customer.facebook} onChange={this.updateField.bind(this,'facebook')}  />
                            </div>
                        </div>    

                        <div className="col-md-6 pl-1">
                            <div className="form-group">
                                <label>
                                    
                                </label>
                            </div>
                        </div>   
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Tax Business Name</label>
                                <input type="text" className="form-control" placeholder="Tax Business Name"  defaultValue={customer.taxBusinessName}  onChange={this.updateField.bind(this,'taxBusinessName')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>Tax Code</label>
                                <input type="text" className="form-control" placeholder="Tax Business Name"  defaultValue={customer.taxCode}  onChange={this.updateField.bind(this,'taxCode')} />
                            </div>
                        </div>
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>Tax Address</label>
                                <input type="text" className="form-control" placeholder="Tax Business Address" defaultValue={customer.taxAddress && customer.taxAddress} onChange={this.updateField.bind(this,'taxAddress')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pr-1">
                            <div className="form-group">
                                <label>City</label>
                                <input type="text" className="form-control" placeholder="City" defaultValue={customer.addresses[0] && customer.addresses[0].city} onChange={this.updateField.bind(this,'addresses[0].city')} />
                            </div>
                        </div>
                        <div className="col-md-4 px-1">
                            <div className="form-group">
                                <label>Country</label>
                                <input type="text" className="form-control" placeholder="Country"  defaultValue={customer.addresses[0] && customer.addresses[0].country} onChange={this.updateField.bind(this,'addresses[0].country')} />
                            </div>
                        </div>                                                   
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-1">
                            <div className="form-group">
                                <label>DOB</label>
                                <input type="date" className="form-control" placeholder="DOB" defaultValue={customer.dob} onChange={this.updateField.bind(this,'dob')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>About</label>
                                
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-info btn-fill pull-right" style={{float:'right',margin:10, background:'#000',border:'#333'}} onClick={this.updateCustomersProfile.bind(this)}>Update Profile</button>
                    <div className="clearfix"></div>
                </form>
                
            </div>
        )
    }
}

  export default CustomersProfile;