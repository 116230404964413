import React from "react";

import moment from "moment";
import InlineCss from "react-inline-css";
import Switch from "react-switch";
import ProductsPhoto from "./products/ProductsPhoto";

import DetailedProduct from "./products/DetailedProduct";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

import "../css/products.css";

class Products extends React.Component {
  constructor(props) {
    super(props);
    var showSide = false;
    var currentView = "active";
    if (window.screen.width < 800) showSide = false;
    var showSearch = true;
    var style = "table";
    if (this.props.currentView) currentView = this.props.currentView;
    if (this.props.style) style = this.props.style;
    if (typeof this.props.showSearch !== "undefined")
      showSearch = this.props.showSearch;
    if (typeof this.props.showSide !== "undefined")
      showSide = this.props.showSide;

    var search = this.getHashValue("search");
    if (!search) search = "";

    var selectedStore = this.getHashValue("store");

    if (!selectedStore) selectedStore = "";

    this.state = {
      products: [],
      loading: true,
      selectedStore: selectedStore,
      sort: "order",
      search: search,
      style: style,
      showSide: showSide,
      currentView: currentView,
      showSearch: showSearch,
      format: "list",
      userToken: "",
      userSite: "",
      fontSize: 10,
      limit: 25,
    };
  }
  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  }

  componentWillMount() {
    var _this = this;
    var shop = this.props.shop;
    if (!this.props.products) {
      shop.staff
        .loadProducts(
          this.state.selectedStore,
          this.state.sort,
          this.state.limit,
          this.state.search
        )
        .then((response) => {
          if (response) _this.setState({ products: response.products });
        });
    } else {
      this.setState({ products: this.props.products });
    }
    // if(this.props.stores)this.setState({stores:this.props.stores})
    this.interval = setInterval(() => {
      shop.staff
        .loadProducts(
          _this.state.selectedStore,
          _this.state.sort,
          _this.state.limit,
          _this.state.search
        )
        .then((response) => {
          if (response) _this.setState({ products: response.products });
        });
    }, 60000);
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  updateSelectedStore(event) {
    var _this = this;
    var store = event.target.value;
    window.location.hash = "store=" + store + "&search=" + this.state.search;
    this.props.shop.staff
      .loadProducts(store, this.state.sort, this.state.limit, this.state.search)
      .then((response) => {
        if (response)
          _this.setState({ products: response.products, selectedStore: store });
      });
  }
  updateSort(event) {
    var _this = this;
    var sort = event.target.value;
    this.props.shop.staff
      .loadProducts(
        this.state.selectedStore,
        sort,
        this.state.limit,
        this.state.search
      )
      .then((response) => {
        if (response)
          _this.setState({ products: response.products, sort: sort });
      });
  }
  updateProductsStatus(event) {
    var _this = this;
    window.location.hash = "search=" + _this.state.search;
    this.props.shop.staff
      .loadProducts(this.state.selectedStore, this.state.sort, this.state.limit)
      .then((response) => {
        if (response) _this.setState({ products: response.products });
      });
  }
  updateLimit(event) {
    var _this = this;
    var limit = event.target.value;
    this.props.shop.staff
      .loadProducts(
        this.state.selectedStore,
        this.state.sort,
        limit,
        this.state.search
      )
      .then((response) => {
        if (response)
          _this.setState({ products: response.products, limit: limit });
      });
  }
  searchProducts(event) {
    var _this = this;
    var search = event.target.value;
    window.location.hash =
      "search=" + search + "&store=" + this.state.selectedStore;
    this.props.shop.staff
      .loadProducts(
        this.state.selectedStore,
        this.state.sort,
        this.state.limit,
        search
      )
      .then((response) => {
        if (response)
          _this.setState({ products: response.products, search: search });
      });
  }
  loadProducts() {
    var _this = this;
    this.props.shop.staff
      .loadProducts(
        this.state.selectedStore,
        this.state.sort,
        this.state.limit,
        this.state.search
      )
      .then((response) => {
        if (response) _this.setState({ products: response.products });
      });
  }

  render() {
    var shop = this.props.shop;
    var today = new Date();
    var fontSize = this.state.fontSize;
    var latestProducts;
    if (
      this.state.products &&
      this.state.products.length > 0 &&
      this.state.sort === "latest"
    ) {
      latestProducts = this.state.products[this.state.products.length - 1];
    } else if (this.state.products && this.state.products.length > 0) {
      latestProducts = this.state.products[0];
    }

    var input_style = {
      borderRadius: 5,
      transition: "all 400ms ease",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "24px",
      background: "rgb(255, 255, 255)",
      border: "1px solid rgba(200, 200, 200,0.5)",
      outline: "none",
      color: "rgba(0, 0, 0,0.5)",
      height: 35,
      boxSizing: "border-box",
      display: "inline-block",
      padding: 5,
      maxWidth: 170,
    };

    return (
      <InlineCss
        stylesheet={`
            .CalendarDay__selected_span {
              background: #82e0aa; //background
              color: white; //text
              border: 1px solid $light-red; //default styles include a border
            }

            // Will edit selected date or the endpoints of a range of dates
            .CalendarDay__selected {
              background: $dark-red;
              color: white;
            }

            // Will edit when hovered over. _span style also has this property
            .CalendarDay__selected:hover {
              background: orange;
              color: white;
            }

            // Will edit when the second date (end date) in a range of dates
            // is not yet selected. Edits the dates between your mouse and said date
            .CalendarDay__hovered_span:hover,
            .CalendarDay__hovered_span {
              background: brown;
            }

            .table thead th{
              border:none;
            }

            `}
      >
        <div
          className="row"
          style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
        >
          <div
            className={
              this.state.showSide
                ? "col-sm-12 col-lg-10"
                : "col-sm-12 col-lg-12"
            }
          >
            <div className="row" style={{ marginBottom: 70 }}>
              <div
                className="col-sm-3 col-md-2 col-lg-2"
                style={{ marginTop: 50 }}
              >
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    marginRight: 30,
                    marginTop: 10,
                    marginLeft: 30,
                  }}
                >
                  Products
                </span>
              </div>
              <div
                className="col-sm-8 col-md-9 col-lg-9"
                style={{ marginTop: 50 }}
              >
                <span
                  style={{
                    display: this.state.showSearch ? "inline-block" : "none",
                  }}
                >
                  {shop.isMarketplace &&
                    shop.storeStore.storeAvailableStores.length > 1 && (
                      <select
                        style={input_style}
                        value={this.state.selectedStore}
                        onChange={this.updateSelectedStore.bind(this)}
                      >
                        <option value="">All Stores</option>
                        {shop.storeStore.storeAvailableStores.map(
                          (store, i) => (
                            <option key={i} value={store.slug}>
                              {store.title}
                            </option>
                          )
                        )}
                      </select>
                    )}
                  &nbsp;
                  <input
                    ref="products"
                    type="text"
                    name="products"
                    placeholder="Search Products"
                    style={input_style}
                    defaultValue={this.state.search}
                    onChange={this.searchProducts.bind(this)}
                  />
                  &nbsp;
                  <select
                    style={input_style}
                    value={this.state.sort}
                    onChange={this.updateSort.bind(this)}
                  >
                    <option value="order">Order</option>
                    <option value="title">Title</option>
                    <option value="price_asc">Price Asc</option>
                    <option value="price_desc">Price Desc</option>
                  </select>
                  &nbsp;
                  <select
                    style={input_style}
                    value={this.state.limit}
                    onChange={this.updateLimit.bind(this)}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="all">All</option>
                  </select>
                  &nbsp;&nbsp; &nbsp;&nbsp;
                </span>
              </div>
              <div className="col-sm-1" style={{ textAlign: "left" }}>
                <div
                  style={{
                    color: "#9490D1",
                    fontSize: 10,
                    marginTop: 60,
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  + New Products
                </div>
              </div>
            </div>
            <a
              style={{
                cursor: "pointer",
                position: "absolute",
                marginTop: 2,
                left: window.screen.width < 800 ? "95%" : "80%",
                top: 0,
              }}
              onClick={(e) => {
                this.setState({ showSearch: !this.state.showSearch });
              }}
            >
              <i
                className={
                  this.state.showSearch
                    ? "fas fa-caret-up"
                    : "fas fa-caret-down"
                }
                style={{
                  color: "rgba(200, 200, 200,1)",
                  fontSize: fontSize + 10,
                }}
              ></i>
            </a>
            <div className="row">
              <div className="col-sm-6" style={{ marginBottom: 50 }}>
                <span
                  style={{
                    color: "#9490D1",
                    fontSize: 14,
                    marginRight: 20,
                    cursor: "pointer",
                    textTransform: "uppercase",
                    marginLeft: 30,
                  }}
                >
                  Products
                </span>
                {/*
                      <span style={{color:this.state.currentView === 'active' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'active'})}}>Active Now</span>
                      <span style={{color:this.state.currentView === 'cancelled' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'cancelled'})}}>Cancelled</span>
                      <span style={{color:this.state.currentView === 'map' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'map'})}}>Map View</span>
                      */}
              </div>
              <div
                className="col-sm-6"
                style={{ marginBottom: 50, textAlign: "right" }}
              >
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ style: "grid" });
                  }}
                >
                  <i
                    className="fal fa-th"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.style === "grid"
                          ? "#9490D1"
                          : "rgba(200, 200, 200,0.5)",
                      fontSize: 14,
                      padding: 2,
                    }}
                  ></i>
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ style: "table" });
                  }}
                >
                  <i
                    className="fal fa-th-list"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.style === "table"
                          ? "#9490D1"
                          : "rgba(200, 200, 200,0.5)",
                      fontSize: 14,
                      padding: 2,
                    }}
                  ></i>
                </a>
                <br />
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ fontSize: fontSize + 1 });
                  }}
                >
                  <i
                    className="fal fa-plus"
                    aria-hidden="true"
                    style={{
                      color: "rgba(200, 200, 200,0.5)",
                      fontSize: 12,
                      padding: 2,
                    }}
                  ></i>
                </a>
                &nbsp;&nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ fontSize: fontSize - 1 });
                  }}
                >
                  <i
                    className="fal fa-minus"
                    aria-hidden="true"
                    style={{
                      color: "rgba(200, 200, 200,0.5)",
                      fontSize: 12,
                      padding: 2,
                    }}
                  ></i>
                </a>
                &nbsp;
              </div>
            </div>
            <div
              className="table-responsive"
              style={{ minHeight: 500, overflowY: "hidden" }}
            >
              {this.state.products && (
                <table
                  className={
                    this.state.style === "table" ? "table" : "products_table"
                  }
                  style={{
                    fontSize: fontSize,
                    color: "#666",
                    width: "100%",
                    transition: "0.4s",
                  }}
                  border="0"
                >
                  {this.state.style === "table" && (
                    <thead>
                      <tr
                        style={{
                          margin: 10,
                          color: "rgba(0, 0, 0,0.5)",
                          fontSize: 12,
                          fontWeight: 200,
                        }}
                      >
                        <th
                          scope="col"
                          style={{
                            width: 20,
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          &nbsp;
                        </th>
                        <th
                          scope="col"
                          style={{
                            minwidth: 75,
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          &nbsp;
                        </th>
                        <th
                          scope="col"
                          style={{
                            minwidth: 75,
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          &nbsp;
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Wholesale
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Types
                        </th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                  )}
                  {this.state.style === "table" ? (
                    <tbody>
                      {this.state.products.map((product, i) => (
                        <ProductItem
                          i={i}
                          total={this.state.products.length}
                          total_products={this.state.products.length}
                          product={product}
                          fontSize={this.state.fontSize}
                          style={this.state.style}
                          shop={shop}
                          key={i}
                          loadProducts={this.loadProducts.bind(this)}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="11">
                          <div className="row">
                            {this.state.products.map((product, i) => (
                              <ProductItem
                                i={i}
                                total={this.state.products.length}
                                total_products={this.state.products.length}
                                product={product}
                                fontSize={this.state.fontSize}
                                style={this.state.style}
                                shop={shop}
                                key={i}
                                loadProducts={this.loadProducts.bind(this)}
                                stores={this.state.stores}
                              />
                            ))}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              )}
            </div>
          </div>
          {window.screen.width > 800 && (
            <a
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 10,
                top: 5,
              }}
              onClick={(e) => {
                this.setState({ showSide: !this.state.showSide });
              }}
            >
              <i
                className={
                  this.state.showSide
                    ? "fas fa-caret-right"
                    : "far fa-caret-left"
                }
                style={{ color: "#9490D1", fontSize: fontSize + 4 }}
              ></i>
            </a>
          )}
        </div>
      </InlineCss>
    );
  }
}

class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    var product = this.props.product;

    var _this = this;

    this.state = {
      product: product,
      deliveryModal: false,
      showMore: "",
      showCamera: false,
      changeName: false,
      showMenu: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product !== this.state.product) {
      var product = nextProps.product;

      var _this = this;
      this.setState({ product: product });
    }
  }
  showMore() {
    this.setState({ showMore: !this.state.showMore });
  }

  showCamera() {
    this.setState({ showCamera: !this.state.showCamera });
  }

  savePhoto(image, caption) {
    var _this = this;
    var shop = this.props.shop;
    shop.staff
      .saveProductPhoto(this.props.product.uuid, image, caption)
      .then((response) => {
        shop.alert("Photo Saved", "", "info", "br");
        _this.setState({ showCamera: false });
        _this.props.loadProducts();
      });
  }

  deletePhoto(uuid, photo_uuid) {
    var _this = this;
    if (window.confirm("Are you sure you wish to delete this photo?")) {
      this.props.shop.staff.deletePhoto(uuid, photo_uuid).then((response) => {
        _this.props.loadProducts();
      });
    }
  }
  render() {
    var product = this.state.product;
    var store = this.props.store;
    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var layout = shop.marketplace.layout;

    var fontSize = this.props.fontSize;

    var lang_date = "en-US";
    if (lang === "vi") lang_date = "vi-VN";
    if (lang === "ja") lang_date = "ja-JP";
    if (lang === "zh") lang_date = "zh-CN";
    if (lang === "ko") lang_date = "ko-KR";

    var product_color = "rgb(255, 128, 66)";

    if (this.props.style === "table") {
      return (
        <React.Fragment>
          <tr style={{ marginTop: 30, marginBottom: 30 }}>
            <td style={{ padding: 0, width: 20 }}>
              <input type={"checkbox"} style={{ margin: 10 }} />
            </td>
            <td>
              <a
                onClick={(e) => {
                  product.store_website !== "" &&
                    window.open("https://" + product.store_website, "_blank");
                }}
                style={{
                  cursor: product.store_website !== "" ? "cursor" : "normal",
                }}
              >
                {product.store_logo ? (
                  <img
                    src={
                      product.store_logo.indexOf("http") > -1
                        ? product.store_logo
                        : shop.imageDirectory + product.store_logo
                    }
                    alt={product.store_title}
                    style={{
                      width: 55,
                      height: 55,
                      borderRadius: 55,
                      border: "5px white solid",
                    }}
                  />
                ) : (
                  <span>{product.store_title}</span>
                )}
              </a>
            </td>
            <td>
              {product.imageUrl && product.imageUrl !== "" ? (
                <img
                  src={
                    product.imageUrl.indexOf("http") > -1
                      ? product.imageUrl
                      : shop.imageDirectory + product.imageUrl
                  }
                  alt={product.name}
                  style={{
                    width: 75,
                    height: 75,
                    borderRadius: 75,
                    border: "5px white solid",
                  }}
                />
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
            <td>
              <div
                style={{
                  fontSize: fontSize,
                  borderRadius: 5,
                  color: "#fff",
                  color: "rgb(50, 50, 50)",
                  padding: 2,
                  paddingLeft: 5,
                  minWidth: 80,
                  border: "solid 0px",
                }}
                onClick={(e) => {
                  this.setState({ changeName: true });
                }}
              >
                {product.title_en}
                <br />
                {/*product.quantity.map((quantity,i) =>
                  <span style={{fontSize:fontSize-2}} key={i}>
                    {store.location && store.location.length > 2 && store.location.filter(function(quan) { return quan._id == quantity.location; })[0].title}
                    &nbsp;-&nbsp;
                    {quantity.quantity}
                    <br />
                  </span>
                  )*/}
              </div>
            </td>
            <td>
              {(product.price / 100).toLocaleString()}
              {shop.marketplace.currency}
            </td>
            <td>
              {(product.wholesale_price
                ? product.wholesale_price / 100
                : 0
              ).toLocaleString()}
              {shop.marketplace.currency}
            </td>
            <td>
              <b>{product.available ? "Available" : "Not Available"}</b>
              <br />
              <b>{product.viewable ? "Viewable" : "Not Viewable"}</b>
            </td>
            <td style={{ textAlign: "center", position: "relative" }}>
              {/*product.customizations.length > 0 &&
              <Accordion allowZeroExpanded={true} style={{width:'100%'}}>
                {product.customizations.map((customiz,i) =>
                  <AccordionItem key={'customization_' + i} uuid={'customization_' + i}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                          {customiz.title_en}
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {customiz.options.map((option,j) =>
                      <span key={j}>
                        {option.quantity &&
                          <span>
                            <span><b style={{fontSize:fontSize+1}}>{option.title_en}</b>
                              {option.price > 0 && <span><br />r:{(option.price/100).toLocaleString()}{shop.marketplace.currency},&nbsp;</span> }
                              {option.wholesale_price > 0 && <span>w:{(option.wholesale_price/100).toLocaleString()}{shop.marketplace.currency}<br /></span>}
                              <br />
                            </span>
                            {option.quantity.map((quantity,k) =>
                              <span key={k}>
                                {store.location && store.location.filter(function(quan) { return quan._id == quantity.location; })[0].title}
                                &nbsp;-&nbsp;
                                {quantity.quantity}
                                <br />
                              </span>
                            )}
                          </span>
                        }
                      </span>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
                )}
              </Accordion>
              */}
            </td>
            <td style={{ textAlign: "center", position: "relative" }}>
              <Switch
                checked={product.available ? product.available : false}
                onChange={(e) => {
                  product.available = e;
                  shop.staff.updateAvailability(product.uuid, e);
                }}
                onColor="#999"
                onHandleColor="#000"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
              />
            </td>
            {/* <td style={{ textAlign: "center", position: "relative" }}>
              {product.store_website &&
              product.store_website.indexOf("http") === -1 ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    window.open(
                      "https://" +
                        product.store.website +
                        "/product/" +
                        product.slug,
                      "_blank"
                    );
                  }}
                >
                  View
                </a>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    window.open(
                      product.store.website + "/product/" + product.slug,
                      "_blank"
                    );
                  }}
                >
                  View
                </a>
              )}
            </td> */}
            <td>
              {!this.state.showMore ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="far fa-caret-up"
                    style={{ color: "rgb(50, 50, 50)", fontSize: fontSize + 5 }}
                  ></i>
                </a>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="fas fa-caret-down"
                    style={{ color: "rgb(50, 50, 50)", fontSize: fontSize + 5 }}
                  ></i>
                </a>
              )}
            </td>
          </tr>
          {(this.state.showMore === true ||
            (this.state.showMore !== false &&
              this.props.total_products === 1)) && (
            <DetailedProduct
              product={product}
              layout={layout}
              shop={shop}
              showMore={this.showMore.bind(this)}
              deletePhoto={this.deletePhoto.bind(this)}
              lang={lang}
              fontSize={fontSize}
              lang_date={lang_date}
              showCamera={this.showCamera.bind(this)}
              stores={
                shop.isMarketplace && this.props.stores && this.props.stores
              }
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div
          className="col-sm-4 col-md-2"
          style={{
            padding: 10,
            boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.5)",
            border: "1px solid " + product_color,
            background: "#fff",
            borderRadius: 2,
            marginLeft: 5,
          }}
        >
          <div className="row" style={{ margin: 0 }}>
            <div style={{ padding: 0, width: "20%", margin: 2 }}>
              <div
                style={{
                  color: "#fff",
                  backgroundColor: product_color,
                  borderRadius: 5,
                  padding: 2,
                  marginBottom: 4,
                  fontSize: fontSize + 2,
                  fontWeight: "bold",
                }}
              >
                #{product.title_en}
              </div>
            </div>
            <div style={{ padding: 0, width: "20%", margin: 2 }}></div>
            <div style={{ textAlign: "center", width: 95, margin: 2 }}>
              <span style={{ width: 95, display: "inline-block" }}></span>
            </div>

            <div className="col-sm-12">
              {!this.state.showMore ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="down-arrow"
                    style={{
                      borderColor: "rgb(50, 50, 50)",
                      fontSize: fontSize,
                    }}
                  ></i>
                </a>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="up-arrow"
                    style={{
                      borderColor: "rgb(50, 50, 50)",
                      fontSize: fontSize,
                    }}
                  ></i>
                </a>
              )}
            </div>
            {this.state.showMore && (
              <DetailedProduct
                product={product}
                layout={layout}
                shop={shop}
                showMore={this.showMore.bind(this)}
                deletePhoto={this.deletePhoto.bind(this)}
                lang={lang}
                lang_date={lang_date}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

export default Products;
