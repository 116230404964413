import React from "react";
import { observer, inject } from "mobx-react";
import "../css/animate.css";
import { Badge } from "reactstrap";
import Headroom from "./react-headroom";
import MiniCart from "./MiniCart";
import AccountModal from "./AccountModal";
import SetLocation from "./SetLocation";
import InlineCss from "react-inline-css";
import classnames from "classnames";

import { activePath } from "../utils/activePath";

import $ from "jquery";

import "../css/slidenavbar.css";

export default
@inject("shop", "prods", "accom")
@observer
class SlideNavBar extends React.Component {
  constructor(props) {
    super(props);

    var menuTextColor = "#fff";
    if (this.props.shop.view.selectedStore)
      menuTextColor = this.props.shop.view.selectedStore.layout.menuTextColor;

    if (this.props.shop.view.page === "home") {
      this.state = {
        isOpen: false,
        topNav: true,
        menuColor: menuTextColor,
        menuSize: 13,
        logoWidth: 125,
        menuBGColor: "transparent",
      };
    } else {
      this.state = {
        isOpen: false,
        topNav: true,
        menuColor: "#000",
        menuSize: 13,
        menuBGColor: "#fbfbfb",
        logoWidth: 250,
      };
    }
  }
  changeLang(lang) {
    this.props.shop.staff.setLang(lang);
  }
  signOut() {
    var _this = this;
    this.props.shop.staff.signOut();
    // window.location.reload()
  }

  resize = () => this.forceUpdate();

  componentDidMount() {
    var shop = this.props.shop;

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.onScroll, false);
      window.addEventListener("resize", this.resize);

      window.scrollTo(0, 0);

      $(".contact_link").click(function () {
        if (!shop.isMarketplace) {
          if (shop.getLocationValue("pathname") === "/") {
            $("html, body").animate(
              {
                scrollTop: $("#locations").offset().top,
              },
              2000
            );
          } else {
            shop.setLocationValue("href", "/#locations");
          }
        } else {
          shop.view.openPage("contact", false);
        }
      });
      if (
        shop.getLocationValue("hash") !== "" &&
        shop.getLocationValue("hash") !== "#cc" &&
        shop.getLocationValue("hash").indexOf("#search") === -1 &&
        shop.getLocationValue("hash").indexOf("#category") === -1 &&
        shop.getLocationValue("hash").indexOf("=") === -1
      ) {
        if ($(shop.getLocationValue("hash")).length > 0) {
          $("html, body").animate(
            {
              scrollTop: $(shop.getLocationValue("hash")).offset().top,
            },
            2000
          );
        }
      }
    }

    loadMenu();
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.onScroll, false);
      window.removeEventListener("resize", this.resize);
    }
  }

  onScroll = () => {
    if (
      !this.props.shop.isMarketplace &&
      this.props.shop.view.page === "home" &&
      typeof window !== "undefined"
    ) {
      var menuTextColor = this.props.shop.stores.get(
        Object.keys(this.props.shop.stores.toJSON())[0]
      ).layout.menuTextColor;
      if (window.scrollY >= 650) {
        this.setState({
          menuColor: "#000",
          menuSize: 13,
          menuBGColor: "#fbfbfb",
          logoWidth: 50,
        });
      } else {
        this.setState({
          menuColor: menuTextColor,
          menuSize: 13,
          menuBGColor: "transparent",
          logoWidth: 125,
        });
      }
    }
  };

  loadNewLang(event) {
    if (typeof window !== "undefined")
      window.location.href =
        "https://" +
        event.target.value +
        "." +
        this.props.shop.view.selectedStore.website.replace("www.", "");
  }

  loadLanguages() {
    var store = this.props.shop.view.selectedStore;

    if (!store.languages) {
      return <div></div>;
    } else {
      var en, vi, ja, zh, ko;
      store.languages.map(function (lng, i) {
        if (lng === "EN")
          en = (
            <option
              data-content='<span className="flag-icon flag-icon-us"></span> English'
              value="en"
            >
              English
            </option>
          );
        if (lng === "VN")
          vi = (
            <option
              data-content='<span className="flag-icon flag-icon-vi"></span> Vietnamese'
              value="vi"
            >
              Tiếng Việt
            </option>
          );
        if (lng === "JP")
          ja = (
            <option
              data-content='<span className="flag-icon flag-icon-ja"></span> Japanese'
              value="ja"
            >
              日本人
            </option>
          );
        if (lng === "CN")
          zh = (
            <option
              data-content='<span className="flag-icon flag-icon-cn"></span> Chinese'
              value="zh"
            >
              中文
            </option>
          );
        if (lng === "KO")
          ko = (
            <option
              data-content='<span className="flag-icon flag-icon-ko"></span> Korean'
              value="ko"
            >
              한국어
            </option>
          );
      });
      return (
        <select
          className=""
          data-width="fit"
          style={{
            borderColor: "#ccc",
            marginTop: 13,
            borderRadius: 2,
            background: "transparent",
          }}
          value={this.props.shop.staff.lang}
          onChange={this.loadNewLang.bind(this)}
        >
          {en}
          {vi}
          {ja}
          {zh}
          {ko}
        </select>
      );
    }
  }

  loadNewMarketLang(event) {
    if (typeof window !== "undefined")
      window.location.href =
        "https://" +
        event.target.value +
        "." +
        window.location.hostname.replace("www.", "");
  }

  loadMarketLanguages() {
    var en, vi, ja, zh, ko;
    en = (
      <option
        data-content='<span className="flag-icon flag-icon-us"></span> English'
        style={{ color: "#000" }}
        value="en"
      >
        English
      </option>
    );
    vi = (
      <option
        data-content='<span className="flag-icon flag-icon-vi"></span> Vietnamese'
        style={{ color: "#000" }}
        value="vi"
      >
        Tiếng Việt
      </option>
    );
    ja = (
      <option
        data-content='<span className="flag-icon flag-icon-ja"></span> Japanese'
        style={{ color: "#000" }}
        value="ja"
      >
        日本人
      </option>
    );
    zh = (
      <option
        data-content='<span className="flag-icon flag-icon-cn"></span> Chinese'
        style={{ color: "#000" }}
        value="zh"
      >
        中文
      </option>
    );
    ko = (
      <option
        data-content='<span className="flag-icon flag-icon-ko"></span> Korean'
        style={{ color: "#000" }}
        value="ko"
      >
        한국어
      </option>
    );

    return (
      <select
        className=""
        data-width="fit"
        style={{
          borderColor: "#ccc",
          marginTop: 13,
          borderRadius: 2,
          background: "transparent",
          color: "#fff",
        }}
        value={this.props.shop.staff.lang}
        onChange={this.loadNewMarketLang.bind(this)}
      >
        {en}
        {vi}
        {ja}
        {zh}
        {ko}
      </select>
    );
  }

  render() {
    var logoIMG = "";
    var shop = this.props.shop;
    var accom = this.props.accom;
    var marketplace = shop.marketplace;
    var store = shop.view.selectedStore;

    var lang = shop.staff.lang;

    var marketplace_layout = shop.marketplace.layout;
    var layout = marketplace_layout;
    var menu_layout = layout.menu;
    var home = layout.home;

    var txtSecBGColor = menu_layout.txtSecBGColor;

    if (!shop.isMarketplace && store) {
      logoIMG = store.logoImageUrl;
      layout = store.layout;
      home = layout.home;
      txtSecBGColor = layout.primTxtColor;
      var _this = this;
      // setTimeout(function(){
      //   _this.setState({topNav:false})
      // }, 10000);
    }

    var navigation_style = {
      menu_list_style: {
        display: "block",
        outline: "none",
        color: menu_layout.txtColor,
      },
      staffTopMenu: {
        color: menu_layout.txtColor,
        borderRadius: 0,
        margin: 10,
        width: 130,
      },
      staffTopDisplay: { marginTop: 13, fontSize: 16, textAlign: "right" },
      subMenu: {
        fontSize: 14,
        fontWeight: 300,
        textAlign: "left",
        padding: 12,
        paddingLeft: 20,
        color: menu_layout.txtColor,
        background: menu_layout.bgColor,
      },
      dropDownValues: { padding: 0, border: "1px solid #efefef" },
      cartBadge: {
        backgroundColor: txtSecBGColor,
        color: menu_layout.txtSecColor,
        float: "right",
        position: "relative",
        left: -1,
        zIndex: 2,
        top: -5,
        fontSize: 10,
      },
      staffIcon: { fontSize: 28, color: menu_layout.txtColor },
    };

    var menuColor = "#000";
    var menuSize = "13px";
    var menBGColor = this.state.menuBGColor;
    var logoWidth = this.state.logoWidth;

    if (
      store &&
      store.layout.home.showHeaderVideo === true &&
      store.heroVideoUrl !== "" &&
      shop.getWindowValue("innerWidth") > 800 &&
      shop.view.page === "home"
    ) {
      menuColor = this.state.menuColor;
      menuSize = this.state.menuSize + "px";
      logoWidth = this.state.logoWidth;
      menBGColor = this.state.menuBGColor;
    }

    var divStyle = {};

    if (shop.view.page === "home")
      divStyle = { position: "absolute", right: 0, zIndex: 2, width: "100%" };

    return (
      <InlineCss
        stylesheet={
          `
        @import url("https://fonts.googleapis.com/css?family=K2D");
        .menu__toggler {
          position: absolute;
          top: 20px;
          left: 20px;
          z-index: 999;
          height: 28px;
          width: 28px;
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .menu__toggler span,
        .menu__toggler span::before,
        .menu__toggler span::after {
          position: absolute;
          content: '';
          width: 28px;
          height: 2.5px;
          background: ` +
          layout.primTxtColor +
          `;
          border-radius: 20px;
          transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
        }
        .menu__toggler span::before {
          top: -8px;
        }
        .menu__toggler span::after {
          top: 8px;
        }
        .menu__toggler.active > span {
          background: transparent;
        }
        .menu__toggler.active > span::before, .menu__toggler.active > span::after {
          background: ` +
          layout.primTxtColor +
          `;
          top: 0px;
        }
        .menu__toggler.active > span::before {
          -webkit-transform: rotate(-225deg);
                  transform: rotate(-225deg);
        }
        .menu__toggler.active > span::after {
          -webkit-transform: rotate(225deg);
                  transform: rotate(225deg);
        }

        /*
         * SLIDING MENU PANEL
         */
        .menu {
          position: fixed;
          left: -40%;
          z-index: 998;
          color: ` +
          layout.primTxtColor +
          `;
          // background: rgba(250, 250, 250, 0.7);
          background: rgba(204, 204, 204, 0.8);
          -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
          width: 40%;
          height: 100%;
          padding: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: 300ms left cubic-bezier(0.77, 0, 0.175, 1);
        }
        @media only screen and (max-width: 600px) {
          .menu {
            width: 250px;
            left: -250px;
            padding: 50px;
          }
        }
        .menu.active {
          left: 0;
        }
        .menu p {
          font-size: 1.4rem;
          margin-bottom: 1rem;
        }

        *,
        *::before,
        *::after {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        .slideMenContainer ul {
          list-style-type: none;
          padding: 0;
        }

        .slideMenContainer {
          display: flex;
          align-items: left;
          top:-3px;
          justify-content: left;
          position: fixed;
          z-index:1000;
        }
        .slideMenContainer ul.slide_menu {
          height: 50px;
          width: 50px;
        }
        .slideMenContainer ul.slide_menu li {
          border-bottom: solid 1px ` +
          txtSecBGColor +
          `;
          position: relative;
          background: white;
          margin: 10px;
          height: 8px;
          width: 50px;
          transition: transform .3s, width .3s;
        }
        .slideMenContainer .active_menu li:nth-child(1) {
          background-color: ` +
          txtSecBGColor +
          `;
          top: 18px;
          transform: rotate(135deg);
          transition: transform .3s;
        }
        .slideMenContainer .active_menu li:nth-child(2) {
          width: 0;
          transition: width .3s;
        }
        .slideMenContainer .active_menu li:nth-child(3) {
          background-color: ` +
          txtSecBGColor +
          `;
          bottom: 18px;
          transform: rotate(-135deg);
          transition: transform .3s;
        }
        .slideMenContainer ul.links {
          // overflow: hidden;
          margin-left: 0px;
          transition: width .4s, margin-left .4s;
        }
        .slideMenContainer ul.links li {
          margin: 5px;
        }
        .slideMenContainer ul.links li a {
          color: white;
          text-shadow: 2px 2px ` +
          txtSecBGColor +
          `;
          text-decoration: none;
          transition: color .5s;
          text-transform: uppercase;
          font-size: 28px;
          font-family: ` +
          layout.primFont +
          `,'Montserrat', sans-serif;
          transition: color .5s, margin-left .5s;
        }
        .slideMenContainer .menu_title{
          color: ` +
          txtSecBGColor +
          `;
          text-shadow: 2px 2px ` +
          "#fff" +
          `;
          text-decoration: none;
          transition: color .5s;
          text-transform: uppercase;
          font-size: 48px;
          font-family: ` +
          layout.primFont +
          `,'Montserrat', sans-serif;
          transition: color .5s, margin-left .5s;
        }
        .slideMenContainer ul.links li a:hover , .slideMenContainer ul.links li.active a{
          // color: ` +
          txtSecBGColor +
          `;
          text-decoration:underline;
          margin-left: 20px;
          transition: color .5s, margin-left .5s;
        }
        .slideMenContainer .active_links {
          width: 470px !important;
          margin-left: 25px !important;
          transition: width .4s, margin-left .4s;
        }
        `
        }
      >
        <div style={divStyle}>
          <div className="slideMenContainer">
            <div className="menu__toggler">
              <span></span>
            </div>
            {shop.isMarketplace && layout.menu.logoIMG !== "" && (
              <a
                href={"/orders"}
                onClick={(e) => {
                  e.preventDefault();
                  shop.view.openPage("orders", false);
                }}
                style={{ position: "relative", left: 60, top: 17 }}
              >
                <img
                  src={shop.imageDirectory + marketplace.logoImageUrl}
                  style={{ maxHeight: 35, filter: "brightness(0.25)" }}
                />
              </a>
            )}
            <div
              className="menu"
              onMouseOut={(e) => {
                e.preventDefault(); /*toggleMenu();*/
              }}
            >
              <h1 className="menu_title">
                {layout.menu.logoIMG !== "" ? (
                  <img
                    src={shop.imageDirectory + layout.menu.logoIMG}
                    style={{ width: 50, position: "relative" }}
                  />
                ) : (
                  <span>{marketplace.title}</span>
                )}
              </h1>
              <ul className="links">
                <li className={shop.view.page === "home" ? "active" : ""}>
                  <a
                    href={"/orders"}
                    onClick={(e) => {
                      e.preventDefault();
                      shop.view.openPage("orders", false);
                    }}
                  >
                    {marketplace_layout.getTitle("home", lang)}
                  </a>
                </li>
                {shop.staff.permissions.map((perm, i) => (
                  <li
                    key={i}
                    className={shop.view.page === perm.area ? "active" : ""}
                  >
                    <a
                      href={"/" + perm.area}
                      onClick={(e) => {
                        e.preventDefault();
                        shop.view.openPage(perm.area, false);
                      }}
                    >
                      {marketplace_layout.getTitle(perm.area, lang)}
                    </a>
                  </li>
                ))}
                {shop.staff.token !== "" && (
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        this.signOut();
                      }}
                    >
                      {marketplace_layout.getTitle("signout", lang)}
                    </a>
                  </li>
                )}
                <li style={{ fontSize: 28 }}>{this.loadMarketLanguages()}</li>
              </ul>
            </div>
          </div>
          {shop.cart.cartQty > 0 && (
            <div
              style={{
                float: "right",
                position: "fixed",
                top: 10,
                right: 5,
                zIndex: 1000,
              }}
            >
              {/*<div onClick={(e) => shop.view.cartPopup()}  onMouseOver={(e) => shop.view.cartOpen()}>*/}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  shop.view.cartPopup();
                }}
              >
                <Badge color="default" style={navigation_style.cartBadge} pill>
                  {shop.cart.cartQty}
                </Badge>
                <img
                  src={
                    shop.imageDirectory +
                    "/images/fontawesome/shopping-bag-light.svg"
                  }
                  alt=""
                  style={{
                    width: 50,
                    paddingRight: 20,
                    float: "right",
                    position: "relative",
                    left: 30,
                    top: 0,
                    color: menuColor,
                  }}
                />
              </a>
              <div style={{ position: "relative", top: -40 }}>
                <MiniCart />
              </div>
            </div>
          )}
        </div>
        <div style={{ position: "absolute", zIndex: 1000 }}>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          {shop.view.loginModal && <AccountModal className="accountModal" />}
          <SetLocation />
        </div>
        {shop.isMarketplace && (
          <div>
            <div
              className=""
              style={{
                marginTop: 10,
                paddingTop: 0,
                paddingBottom: 0,
                textAlign: "center",
                borderBottom: "solid #ccc 1px",
              }}
            >
              {/* <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3" style={{textAlign:'center',height:50}}>
                  {marketplace.logoImageUrl === "" || marketplace.title === "Thao Dien" ?
                      <a href={"/home"} onClick={(e) => {e.preventDefault();shop.view.openHomePage()}} style={{color:layout.primTxtColor,position:'relative',top:10,fontWeight:600,fontSize:18,textTransform:'uppercase'}}>
                        {marketplace.title} Admin
                      </a>
                  :
                    <a href={"/home"} onClick={(e) => {e.preventDefault();shop.view.openHomePage()}}>
                      <img src={shop.imageDirectory + marketplace.logoImageUrl} style={{maxHeight:50}} />
                    </a>
                  }

                </div> */}
            </div>
            {/*
              <Headroom style={{width:'100%', textAlign:'center',background:'#fff', height:70}}>
                <div className="row d-none d-md-block" style={{marginTop:10,paddingTop:25,paddingBottom:20,marginBottom:30,textAlign:'center'}}>
                  <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2" style={{textAlign:'center',fontWeight:600,fontSize:13}}>
                    <div className="row">
                      <div className="col-sm" style={{textAlign:'center'}}>
                        <a href={"/products"} onClick={(e) => {
                          e.preventDefault();
                          if(shop.view.page !== 'home' && shop.view.page !== '')
                            this.props.prods.resetSettings();
                            this.props.prods.clearSearch(this.props.shop.marketplace.slug,this.props.shop.domain, this.props.shop.staff._id);
                            shop.view.openPage('products',true);
                        }} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}}>
                          {marketplace_layout.getTitle('eat',lang)}
                        </a>
                      </div>
                      <div className="" style={{textAlign:'center',width:10}}>
                        -
                      </div>
                      <div className="col-sm" style={{textAlign:'center',color:layout.primTxtColor}}>
                        <a href={"/stores"} onClick={(e) => {e.preventDefault();shop.view.openPage('stores',true);}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}}>
                          {marketplace_layout.getTitle('dine',lang)}
                        </a>
                      </div>
                       <div className="" style={{textAlign:'center',width:10}}>
                        -
                      </div>
                      <div className="col-sm" style={{textAlign:'center'}}>
                        <a href={"/stores"} onClick={(e) => {e.preventDefault();shop.view.openPage('relax',false);this.props.prods.clearSearch(this.props.shop.marketplace.slug,this.props.shop.domain, this.props.shop.staff._id);}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}}>
                          {marketplace_layout.getTitle('relax',lang)}
                        </a>
                      </div>
                      <div className="" style={{textAlign:'center',width:10}}>
                        -
                      </div>
                      <div className="col-sm" style={{textAlign:'center'}}>
                        <a href={"/accommodations"} onClick={(e) => {e.preventDefault();shop.view.openPage('accommodations',false);accom.clearSearch(this.props.shop.marketplace.slug,this.props.shop.domain, this.props.shop.staff._id);accom.clearSelectedAccommodation()}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}}>
                          {marketplace_layout.getTitle('live',lang)}
                        </a>
                      </div>
                      <div className="" style={{textAlign:'center',width:10}}>
                        -
                      </div>
                      <div className="col-sm" style={{textAlign:'center'}}>
                        <a href={"/shops"} onClick={(e) => {e.preventDefault();shop.view.openPage('shops',false);accom.clearSearch(this.props.shop.marketplace.slug,this.props.shop.domain, this.props.shop.staff._id);accom.clearSelectedAccommodation()}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}}>
                          {marketplace_layout.getTitle('shops',lang)}
                        </a>
                      </div>
                      <div className="" style={{textAlign:'center',width:10}}>
                        -
                      </div>
                      <div className="col-sm" style={{textAlign:'center'}}>
                        <a href={"/stores"} onClick={(e) => {e.preventDefault();shop.view.openPage('learn',false);this.props.prods.clearSearch(this.props.shop.marketplace.slug,this.props.shop.domain, this.props.shop.staff._id);}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}} style={{textAlign:'center',color:layout.primTxtColor,textTransform:'uppercase'}}>
                          {marketplace_layout.getTitle('learn',lang)}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Headroom>
              */}
          </div>
        )}
      </InlineCss>
    );
  }
}

function toggleMenu() {
  const toggler = document.querySelector(".menu__toggler");
  const menu = document.querySelector(".menu");

  toggler.classList.toggle("active");
  menu.classList.toggle("active");
}

function loadMenu() {
  const toggler = document.querySelector(".menu__toggler");
  const menu = document.querySelector(".menu");

  toggler.addEventListener("click", () => {
    toggler.classList.toggle("active");
    menu.classList.toggle("active");
  });
}
