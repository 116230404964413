import React from "react";
import { observer, inject } from "mobx-react";

import { reaction } from "mobx";

import Loader from "react-loader";
import $ from "jquery";
import MessengerCustomerChat from "react-messenger-customer-chat";

import striptags from "striptags";

import NavBar from "./NavBar";
import SlideNavBar from "./SlideNavBar";

// import Products from "./Products"
// import ProductDetails from "./ProductDetails"
// import PostDetails from "./PostDetails"
// import Accommodation from "./Accommodation"
// import Stores from "./Stores"
// import HomePage from "./HomePage"
// import Favourites from "./Favourites"
// import Checkout from "./Checkout"
// import Order from "./Order"
// import NavigationBar from "./NavigationBar";
// import GetStarted from "./GetStarted";
// import MarketHome from "./MarketHome";
// import Blog from "./Blog";
// import CustomPage from "./CustomPage";
// import Account from "./Account";

import Dashboard from "./Dashboard";
import MarketDashboard from "./MarketDashboard";
import Orders from "./Orders";
import Products from "./Products";
import Customers from "./Customers";
import Staff from "./Staff";
import Stores from "./Stores";
import Schedules from "./Schedules";

import AdminLogin from "./AdminLogin";

import {
  ATTRIBUTE_NAMES,
  HELMET_ATTRIBUTE,
  HELMET_PROPS,
  HTML_TAG_MAP,
  REACT_TAG_MAP,
  SELF_CLOSING_TAGS,
  TAG_NAMES,
  TAG_PROPERTIES,
} from "../utils/HelmetConstants.js";

import "../css/bootstrap.min.css";
import "../css/all.min.css";

import "../css/layout.css";

import * as Sentry from "@sentry/browser";

// export default ({ children, title = 'Home' }) => (

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <div></div>;
      // You can render any custom fallback UI
      // return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

@inject(["shop"])
@observer
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ignore: true,
      title: "",
      lastTrackedPath: "",
      showScroll: false,
      error: null,
    };

    var shop = this.props.shop;
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      window.onerror = function (msg, src, lineno, colno, error) {
        /*your code*/
        window.localStorage.removeItem("staff");
        window.localStorage.removeItem("cart");
        if (typeof window !== "undefined" && window.location.port !== "3007")
          window.location.reload();
      };
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log("error", error);
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    if (typeof window !== "undefined") {
      window.localStorage.removeItem("cart");
      window.localStorage.removeItem("staff");
    }
    console.log(error, errorInfo);
    setTimeout(function () {
      this.props.shop.reloadPage();
      // alert('Error Reloading Page...')
    }, 500);
  }

  componentDidMount() {
    /**
     * Routing
     */
    var _this = this;
    var shop = this.props.shop;

    // reaction(
    //   () => shop.view.currentUrl,
    //   path => {
    //       if (window.location.pathname !== path) window.history.pushState(null, null, path)
    //   }
    // )
    reaction(
      () => shop.view.currentUrl,
      (path) => {
        if (window.location.pathname !== path)
          window.history.pushState(null, null, path);
      }
    );

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);

      $(window).scroll(
        function (event) {
          var st = $(window).scrollTop();
          if (st > shop.getWindowValue("innerHeight") - 100) {
            this.setState({ showScroll: true });
          } else {
            this.setState({ showScroll: false });
          }
        }.bind(this)
      );
    }
  }

  scrollToTop() {
    // window.scrollTo(0,0);
    if (typeof window !== "undefined")
      $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  render() {
    if (this.state.error) {
      //render fallback UI

      if (typeof window !== "undefined") {
        window.localStorage.removeItem("cart");
        window.localStorage.removeItem("staff");
      }
      console.log(this.state.error);
      setTimeout(function () {
        this.props.shop.reloadPage();
        // alert('Error Reloading Page...')
      }, 500);

      return (
        <div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <center>
            <a onClick={() => Sentry.showReportDialog()}>
              <h1>
                That wasn't supposed to happen...
                <br />
                <br />
                <span style={{ textDecoration: "underline" }}>
                  Let us know about it..
                </span>
              </h1>
            </a>
          </center>
        </div>
      );
    } else {
      var shop = this.props.shop;

      var layout = shop.marketplace.layout;
      if (!shop.isMarketplace && layout) {
        var store = shop.view.selectedStore;
        if (store) {
          layout = store.layout;
        }
      }

      var primFont = "Arial";
      var helmet = getHelmetInfo(shop, this.props.router);

      if (!helmet) helmet = { title: "", meta: [], link: [] };
      // shop.setLocationValue('href','/');
      // return (<div></div>);

      if (!shop.staff.token || shop.staff.token === "") {
        return (
          <ErrorBoundary>
            <div style={{ textAlign: "center" }}>
              <AdminLogin />
            </div>
          </ErrorBoundary>
        );
      } else {
        return (
          <ErrorBoundary>
            <div>
              <header style={{ fontFamily: primFont }}>
                {!shop.isLoading && (
                  <div>
                    {shop.isMarketplace ? (
                      <div>
                        {layout.menuStyle === "slide" ? (
                          <SlideNavBar />
                        ) : (
                          <SlideNavBar />
                        )}
                      </div>
                    ) : (
                      <div>
                        {layout.menuStyle === "slide" ? (
                          <SlideNavBar />
                        ) : (
                          <NavBar />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </header>
              <div
                id="outer-container"
                style={{ height: "100%", fontFamily: primFont }}
              >
                <div
                  className="App"
                  style={{
                    position: "relative",
                    minHeight: shop.getWindowValue("innerHeight"),
                  }}
                >
                  <div>
                    {shop.isLoading &&
                    layout &&
                    typeof layout.loader !== "undefined" &&
                    layout.loader !== "" ? (
                      <div
                        style={{
                          margin: "auto",
                          position: "fixed",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: layout.loaderBGColor,
                        }}
                      >
                        <img
                          src={this.props.shop.imageDirectory + layout.loader}
                          style={{
                            margin: "auto",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                          }}
                          alt="Loading"
                        />
                      </div>
                    ) : shop.isLoading ||
                      (!shop.isMarketplace &&
                        shop.view.selectedStore === null) ? (
                      <Loader
                        loaded={!shop.isLoading}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                      />
                    ) : (
                      renderPage(shop, window.location.pathname)
                    )}
                  </div>
                  {/*
                        this.props.children
                      */}
                </div>
              </div>
              {shop.snackMessage.title !== "" && (
                <div>
                  {shop.snackMessage.link !== "" && (
                    <a href={shop.snackMessage.link}>
                      <div
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          position: "fixed",
                          bottom: 0,
                          legt: 0,
                          right: 0,
                          backgroundColor: layout.primTxtColor,
                          padding: 15,
                          width: "100%",
                          fontSize: 18,
                          height: 60,
                          borderRadius: 0,
                          border: "none",
                          zIndex: 5,
                        }}
                        onClick={(e) => {
                          shop.clearAlert();
                        }}
                      >
                        <span>{shop.snackMessage.title}</span>&nbsp;&nbsp;
                        <span>{shop.snackMessage.message}</span>
                        <span
                          className="closeNotify"
                          onClick={(e) => {
                            shop.clearAlert();
                          }}
                        ></span>
                      </div>
                    </a>
                  )}
                  {shop.snackMessage.link !== "" && (
                    <div
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        position: "fixed",
                        bottom: 0,
                        legt: 0,
                        right: 0,
                        backgroundColor: layout.primTxtColor,
                        padding: 15,
                        width: "100%",
                        fontSize: 18,
                        height: 60,
                        borderRadius: 0,
                        border: "none",
                        zIndex: 5,
                      }}
                      onClick={(e) => {
                        shop.clearAlert();
                      }}
                    >
                      <span>{shop.snackMessage.title}</span>&nbsp;&nbsp;
                      <span>{shop.snackMessage.message}</span>
                      <a
                        className="closeNotify"
                        onClick={(e) => {
                          shop.clearAlert();
                        }}
                      ></a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </ErrorBoundary>
        );
      }
    }
  }
}

export default Layout;

function formatHelmet(title, site_name, description, image, icon, url, color) {
  if (title !== "") {
    title = title + " - " + site_name;
  } else {
    title = site_name;
  }

  return {
    site_name: site_name,
    title: title,
    meta: [
      { property: "title", content: title },
      { property: "description", content: description },
      { property: "theme-color", content: color },
      { property: "og:site_name", content: site_name },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:type", content: "website" },
      { property: "og:url", content: url },
      { property: "og:image", content: image },
    ],
    link: [
      { rel: "apple-touch-icon", href: icon },
      { rel: "shortcut icon", href: icon },
    ],
  };
}

function getHelmetInfo(shop, router) {
  var lang = shop.staff.lang;

  var image;

  var path = "https://" + shop.href;

  var url = path + window.location.pathname;

  var color = shop.marketplace.layout.primTxtColor;
  // if (!shop.isMarketplace) color = shop.view.selectedStore.layout.primTxtColor;

  var description = shop.description;

  var page = window.location.pathname.replace("/", "");

  if (page === "") page = shop.staff.landing_page;

  var slug;

  if (typeof window !== "undefined") page = shop.view.page.replace("/", "");

  if (page.indexOf("/") > -1) {
    page = page.split("/")[0];
    slug = page.split("/")[1];
  }

  // if(typeof router.query.slug !== "undefined")
  // slug = router.query.slug;

  switch (page) {
    case "":
      if (!shop.isMarketplace) {
        return formatHelmet(
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      } else {
        return formatHelmet(
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      }
    case "products":
      description = "View all products from " + shop.title + ".";
      return formatHelmet(
        shop.marketplace.layout.getTitle("products", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "product":
      if (!typeof window !== "undefined" && !shop.view.selectedProduct)
        shop.view.openProductPageById(slug);

      const product = shop.view.selectedProduct;
      if (product) {
        description = striptags(product["itemDescription_" + lang]).substring(
          0,
          100
        );
        return formatHelmet(
          product["title_" + lang],
          shop.title,
          description,
          shop.imageDirectory + product.imageUrl,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      } else {
        return formatHelmet(
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      }
    case "post":
      if (!typeof window !== "undefined" && !shop.view.selectedPost)
        shop.view.openPostPageById(slug);

      const post = shop.view.selectedPost;
      if (post) {
        if (post["title_" + lang]) {
          image = shop.icon;
          if (post.image !== "") image = post.image;

          description = striptags(post["text_" + lang]).substring(0, 100);
          return formatHelmet(
            post["title_" + lang],
            shop.title,
            description,
            shop.imageDirectory + image,
            shop.imageDirectory + shop.icon,
            url,
            color
          );
        }
      } else {
        return formatHelmet(
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      }
    case "store":
      if (shop.view.selectedStore) {
        image = shop.icon;
        if (shop.view.selectedStore.layout.home.aboutImage.length > 0)
          image = shop.view.selectedStore.layout.home.aboutImage[0];
        return formatHelmet(
          shop.view.selectedStore.title,
          shop.title,
          shop.view.selectedStore.description,
          shop.imageDirectory + image,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      }
      break;
    case "stores":
      description = "View all stores available on " + shop.title + ".";
      return formatHelmet(
        shop.marketplace.layout.getTitle("stores", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "accommodations":
      description = "View all accommodations in " + shop.title + ".";
      return formatHelmet(
        shop.marketplace.layout.getTitle("accommodations", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "favourites":
      description = "View all your favourite items on " + shop.title + ".";
      return formatHelmet(
        shop.marketplace.layout.getTitle("favourites", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "checkout":
      return formatHelmet(
        shop.marketplace.layout.getTitle("checkout", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "order":
      if (typeof window !== "undefined" && !shop.view.selectedOrder)
        shop.view.openOrderPage(slug);

      const order = shop.view.selectedOrder;
      if (order) {
        return formatHelmet(
          order.description,
          shop.title,
          order.description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      } else {
        break;
      }
    case "home":
      if (!shop.isMarketplace) {
        var slug = page;
        if (typeof slug !== "undefined") slug = slug;

        //  search custom pages
        var page = shop.view.selectedStore.layout.customPages.find(function (
          pg
        ) {
          return pg.slug === slug;
        });
        if (page) {
          return formatHelmet(
            page["title_" + lang],
            shop.title,
            striptags(page["text_" + lang]).substring(0, 100),
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            url,
            color
          );
        } else {
          return formatHelmet(
            "",
            shop.view.selectedStore.title,
            description,
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            url,
            color
          );
        }
      } else {
        return formatHelmet(
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      }
      break;
    case "blog":
      return formatHelmet(
        shop.marketplace.layout.getTitle("blog", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "account":
      return formatHelmet(
        shop.marketplace.layout.getTitle("account", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    case "getstarted":
      return formatHelmet(
        shop.marketplace.layout.getTitle("getstarted", lang),
        shop.title,
        description,
        shop.imageDirectory + shop.icon,
        shop.imageDirectory + shop.icon,
        url,
        color
      );
    default:
      if (!shop.isMarketplace) {
        var slug = page;
        if (typeof slug !== "undefined") slug = slug;

        //  search custom pages
        var page = shop.view.selectedStore.layout.customPages.find(function (
          pg
        ) {
          return pg.slug === slug;
        });
        if (page) {
          return formatHelmet(
            page["title_" + lang],
            shop.title,
            striptags(page["text_" + lang]).substring(0, 100),
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            url,
            color
          );
        } else {
          return formatHelmet(
            "",
            shop.view.selectedStore.title,
            description,
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            url,
            color
          );
        }
      } else {
        return formatHelmet(
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          url,
          color
        );
      }
  }
}
// )
function renderPage(shop, path) {
  var lang = shop.staff.lang;
  var image;

  if (typeof window !== "undefined") path = shop.view.page;

  var color = shop.marketplace.layout.primTxtColor;
  if (!shop.isMarketplace) color = shop.view.selectedStore.layout.primTxtColor;

  var page = shop.view.page;

  if (page === "") page = shop.staff.landing_page;

  var description = shop.description;

  var perm;
  switch (page) {
    case "":
      perm = shop.staff.permissions.find((x) => x.area === "dashboard");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        if (
          shop.isMarketplace &&
          (shop.staff.type === "marketplace_user" ||
            shop.staff.type === "marketplace_admin")
        ) {
          helmet(
            path,
            "",
            shop.title,
            description,
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            color
          );
          return <MarketDashboard />;
        } else {
          helmet(
            path,
            "",
            shop.title,
            description,
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            color
          );
          return <Dashboard />;
        }
      } else {
        break;
      }
    case "orders":
      perm = shop.staff.permissions.find((x) => x.area === "orders");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        helmet(
          path,
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          color
        );
        return <Orders shop={shop} />;
      } else {
        break;
      }
    case "staff":
      perm = shop.staff.permissions.find((x) => x.area === "staff");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        helmet(
          path,
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          color
        );
        return <Staff shop={shop} />;
      } else {
        break;
      }
    case "stores":
      perm = shop.staff.permissions.find((x) => x.area === "staff");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        helmet(
          path,
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          color
        );
        return <Stores shop={shop} />;
      } else {
        break;
      }
    case "schedules":
      perm = shop.staff.permissions.find((x) => x.area === "schedules");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        helmet(
          path,
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          color
        );
        return <Schedules shop={shop} />;
      } else {
        break;
      }
    case "customers":
      perm = shop.staff.permissions.find((x) => x.area === "customers");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        helmet(
          path,
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          color
        );
        return <Customers shop={shop} />;
      } else {
        break;
      }
    case "products":
      perm = shop.staff.permissions.find((x) => x.area === "products");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        helmet(
          path,
          "",
          shop.title,
          description,
          shop.imageDirectory + shop.icon,
          shop.imageDirectory + shop.icon,
          color
        );
        return <Products shop={shop} />;
      } else {
        break;
      }
    default:
      perm = shop.staff.permissions.find((x) => x.area === "dashboard");
      if (
        perm &&
        (perm.access === "readonly" || perm.access === "fullaccess")
      ) {
        if (
          shop.isMarketplace &&
          (shop.staff.type === "marketplace_user" ||
            shop.staff.type === "marketplace_admin")
        ) {
          helmet(
            path,
            "",
            shop.title,
            description,
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            color
          );
          return <MarketDashboard />;
        } else {
          helmet(
            path,
            "",
            shop.title,
            description,
            shop.imageDirectory + shop.icon,
            shop.imageDirectory + shop.icon,
            color
          );
          return <Dashboard />;
        }
      } else {
        shop.view.openPage(shop.staff.landing_page);
        break;
      }
  }
}

function updateTags(type, tags) {
  const headElement = document.head || document.querySelector(TAG_NAMES.HEAD);
  const tagNodes = headElement.querySelectorAll(`${type}[${HELMET_ATTRIBUTE}]`);
  const oldTags = Array.prototype.slice.call(tagNodes);
  const newTags = [];
  let indexToDelete;

  if (tags && tags.length) {
    tags.forEach((tag) => {
      const newElement = document.createElement(type);

      for (const attribute in tag) {
        if (tag.hasOwnProperty(attribute)) {
          if (attribute === TAG_PROPERTIES.INNER_HTML) {
            newElement.innerHTML = tag.innerHTML;
          } else if (attribute === TAG_PROPERTIES.CSS_TEXT) {
            if (newElement.styleSheet) {
              newElement.styleSheet.cssText = tag.cssText;
            } else {
              newElement.appendChild(document.createTextNode(tag.cssText));
            }
          } else {
            const value =
              typeof tag[attribute] === "undefined" ? "" : tag[attribute];
            newElement.setAttribute(attribute, value);
          }
        }
      }
      newElement.setAttribute(HELMET_ATTRIBUTE, "true");

      // Remove a duplicate tag from domTagstoRemove, so it isn't cleared.
      if (
        oldTags.some((existingTag, index) => {
          indexToDelete = index;
          return newElement.isEqualNode(existingTag);
        })
      ) {
        oldTags.splice(indexToDelete, 1);
      } else {
        newTags.push(newElement);
      }
    });
  }

  oldTags.forEach((tag) => tag.parentNode.removeChild(tag));
  newTags.forEach((tag) => headElement.appendChild(tag));

  return {
    oldTags,
    newTags,
  };
}

function helmet(path, title, site_name, description, image, icon, color) {
  var origin = "/";
  if (typeof window !== "undefined") origin = window.location.origin;

  if (title !== "") {
    title = title + " - " + site_name;
  } else {
    title = site_name;
  }

  var helmet_data = {
    site_name: site_name,
    title: title,
    meta: [
      { property: "title", content: title },
      { property: "description", content: description },
      { property: "theme-color", content: color },
      { property: "og:site_name", content: site_name },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:type", content: "website" },
      { property: "og:url", content: path },
      { property: "og:image", content: origin + image },
    ],
    link: [
      { rel: "apple-touch-icon", href: icon },
      { rel: "shortcut icon", href: icon },
    ],
  };
  // baseTag: updateTags(TAG_NAMES.BASE, baseTag),

  if (typeof window !== "undefined") {
    updateTags(TAG_NAMES.META, helmet_data.meta);
    updateTags(TAG_NAMES.LINK, helmet_data.link);

    document.title = helmet_data.title;
  }
  // helmet_data.meta.forEach(function (tag, i) {
  //   const elementTag = document.getElementsByTagName(tag.property)[0];
  //   console.log(elementTag)

  // });
  // const elementTag = document.getElementsByTagName(tagName)[0];
  // elementTag.setAttribute(attribute, value);
}
function stripHtml(html) {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}
