import React from "react"

import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Table} from 'reactstrap'; 

import CustomersMessage from "./CustomersMessages";
import InlineCss from "react-inline-css";

export class sendMessage extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {
        sendSMS:false,
        sendEmail:false,
        sendNote:true,
        phone:this.props.phone,
        sender:this.props.store_phone,
        email:this.props.email,
        direction:'sent',
        subject:'',
        message:'',
        customer:this.props.customer
      };
  }

  handleChangePhone(event) {
    this.setState({phone: event.target.value});
  }

  handleChangeEmail(event) {
    this.setState({email: event.target.value});
  }

   handleChangeCustomer(event) {
    this.setState({customer: event.target.value});
  }

  handleChangeSubject(event) {
    this.setState({subject: event.target.value});
  }

  handleChangeMessage(event) {
    this.setState({message: event.target.value});
  }

  sendMessage(){
    this.props.sendMessage(this.state)
  }

  setTemplateMessage(temp){
    var message = {...this.state, ...temp }
    for (var prop in message) {
      if (message.hasOwnProperty(prop) && message[prop].length > 0) {
        var replaceArray = ["@uuid","@store_title","@store_phone","@first_name","@last_name","@name","@delivery_link"];
        var replaceArrayValue = [this.props.uuid.substr(0, 6),this.props.store_title,this.props.store_phone,this.props.name.split(" ")[0],this.props.name.split(" ")[this.props.name.split(" ").length -1],this.props.name,this.props.delivery_link];
        var finalAns = message[prop];
        var regex; 
        for (var i = 0; i < replaceArray.length; i++) {
          regex = new RegExp(replaceArray[i], "g");
          finalAns = finalAns.replace(regex, replaceArrayValue[i]);
        }
        message[prop] = finalAns;
      }
    }
    console.log(message)
    this.setState(message);
  }


  render () {
    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var layout = shop.marketplace.layout;
   
    var customer = this.props.customer;
    return (
    <InlineCss stylesheet={`
      .message_icon:hover{
        opacity:1;        
      }
      .message_icon{
        opacity:0.4;
      }
      .message_icon_selected{
        opacity:1;
      }
    `}>
      <Modal isOpen={this.props.open} toggle={this.props.showSendMessage} className={this.props.className}>
        <ModalHeader toggle={this.props.showSendMessage}></ModalHeader>
        <ModalBody>
            <CustomersMessage uuid={customer.uuid} phone={customer.phone} email={customer.email} open={true}  customer={shop.staff._id} customer_id={customer.customer} customer={customer.name} store_title={customer.store_title} store_phone={customer.store_phone} name={customer.name} delivery_link={customer.delivery_link} shop={shop} messages={customer.messages} messageTemplates={customer.store[0].messageTemplates} sendMessage={this.props.sendMessage} showCamera={this.props.showCamera}  imageDirectory={this.props.imageDirectory} />
        </ModalBody>        
      </Modal> 
      </InlineCss>     
      );
    }
  }

  export default sendMessage;