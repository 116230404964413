import React from 'react'

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

class StepOne extends React.Component {
      constructor(props) {
    super(props);

    this.state = {
      email: props.getStore().email,
      password: props.getStore().password,
      exists: false
    };

    this._validateOnDemand = true; // this flag enables onBlur validation as staff fills forms

    this.validationCheck = this.validationCheck.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this._validateData = this._validateData.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  isValidated() {
    const staffInput = this._grabStaffInput(); // grab staff entered vals
    let isDataValid = false;
    var _this = this;

    // if(staffInput.email !== ''){
    //   this._validateData(staffInput).then(function(validateNewInput) {
    //     console.log("validateNewInput",validateNewInput, staffInput)
    //     // if full validation passes then save to store and pass as valid
    //     if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
    //         if (_this.props.getStore().email !== staffInput.email || _this.props.getStore().password !== staffInput.password) { // only update store of something changed
    //           _this.props.updateStore({
    //             ...staffInput,
    //             savedToCloud: false // use this to notify step4 that some changes took place and prompt the staff to save again
    //           });  // Update store here (this is just an example, in reality you will do it via redux or flux)
    //         }        
    //         isDataValid = true;
    //     }else {
    //         // if anything fails then update the UI validation state but NOT the UI Data State
    //         // _this.setState(Object.assign(staffInput, validateNewInput, _this._validationErrors(validateNewInput)));
    //     }
    //     console.log('here 2', isDataValid)
    //     return isDataValid;
    //   });
    // }else{
      const validateNewInput = this._validateData(staffInput); // run the new input against the validator
      // console.log("validateNewInput",validateNewInput, staffInput)
      // if full validation passes then save to store and pass as valid
      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
          if (this.props.getStore().email !== staffInput.email || this.props.getStore().password !== staffInput.password) { // only update store of something changed
            this.props.updateStore({
              ...staffInput,
              savedToCloud: false // use this to notify step4 that some changes took place and prompt the staff to save again
            });  // Update store here (this is just an example, in reality you will do it via redux or flux)
          }        
          isDataValid = true;
      }else {
          // if anything fails then update the UI validation state but NOT the UI Data State
          this.setState(Object.assign(staffInput, validateNewInput, this._validationErrors(validateNewInput)));
      }
      return isDataValid;
    // }
  }

  validationCheck() {
    var _this = this; 

    if (!this._validateOnDemand)
      return;

    this.props.shop.staff.checkEmail({email:this.refs.email.value}).then(function(exists) {
      _this.setState({exists:exists});
    });

    const staffInput = this._grabStaffInput(); // grab staff entered vals
    // if(staffInput.email !== ''){
    //   this._validateData(staffInput).then(function(validateNewInput) {
    //     _this.setState(Object.assign(staffInput, validateNewInput, _this._validationErrors(validateNewInput)));
    //   });
    // }else{
      const validateNewInput = this._validateData(staffInput)
      this.setState(Object.assign(staffInput, validateNewInput, this._validationErrors(validateNewInput)));
    // }
  }

   _validateData(data) {
    // var email = data.email;
    // if(data.email){
    //   //  Check if email is already in use
    //   return this.props.shop.staff.checkEmail({email:email}).then(function(exists) {
        return  {
          passwordVal: (data.password.length >  5), // required: anything besides N/A
          emailVal: (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email)), // required: regex w3c uses in html5
          emailVal2: (!this.state.exists), // required: anything besides N/A
        }
    //   })
    // }else{
    //   return  {
    //       passwordVal: (data.password.length >  5), // required: anything besides N/A
    //       emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email), // required: regex w3c uses in html5          
    //     }
    // }
  }

  _validationErrors(val) {
    const errMsgs = {
      passwordValMsg: val.passwordVal ? '' : 'A password needs to be longer then 5 characters',
      emailValMsg: val.emailVal ? '' : 'A valid email is required',
      emailValMsg2: val.emailVal2 ? '' : 'Email is already in use try logging instead'
    }
    return errMsgs;
  }

  _grabStaffInput() {
    return {
      password: this.refs.password.value,
      email: this.refs.email.value
    };
  }

  loginWithFacebook(response){
    if(this.props.loginWithFacebook(response,false)){
      var _this = this;
      setTimeout(function() {
        _this.props.next();
      }, 500);
    }
  }

  loginWithGoogle(response){
    if(this.props.loginWithGoogle(response,false)){
      var _this = this;
      setTimeout(function() {
        _this.props.next();
      }, 500);
    }
  }

  render() {

    var shop = this.props.shop;
    var lang = shop.staff.lang;

    // explicit class assigning based on validation
    let notValidClasses = {};

    if (typeof this.state.passwordVal === 'undefined' || this.state.passwordVal) {
      notValidClasses.passwordCls = 'no-error col-md-8';
    }
    else {
       notValidClasses.passwordCls = 'has-error col-md-8';
       notValidClasses.passwordValGrpCls = 'val-err-tooltip';
    }

    // if (typeof this.state.emailVal === 'undefined' || this.state.emailVal) {
    //     notValidClasses.emailCls = 'no-error col-md-8';
    // }
    // else {
       notValidClasses.emailCls = 'has-error col-md-8';
       notValidClasses.emailValGrpCls = 'val-err-tooltip';
    // }
        var input_style = {"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"24px","width":"100%","padding":"18px 2px","background":"rgb(255, 255, 255)",border:'none',borderBottom:"1px solid rgb(229, 229, 228)","outline":"none","color":"rgb(0, 0, 0)","height":"44px","boxSizing":"border-box","borderRadius":"0px", marginBottom:10};
        return (
            <div>
              <div>
                <h4 style={{fontWeight:300,fontSize:20, marginBottom:20}}>{shop.marketplace.layout.getTitle('account',lang)}</h4>
                <p className="" style={{fontSize:14}}>{shop.marketplace.layout.getTitle('why_create_account',lang)}</p>
                <div>
                  <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('email',lang)}</label>
                    <input
                        ref="email"
                        autoComplete="off"
                        type="email"
                        placeholder="john.smith@example.com"
                        className="form-control"
                        style={input_style}
                        required
                        defaultValue={this.state.email}
                        onBlur={this.validationCheck} />
                    {this.state.emailValMsg ?
                      <div className={notValidClasses.emailValGrpCls} style={{color:'red'}}>{this.state.emailValMsg}</div>
                    :
                      <div className={notValidClasses.emailValGrpCls} style={{color:'red'}}>{this.state.emailValMsg2}</div>
                    }
                </div>
                <div>
                  <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('password',lang)}</label>
                  <input
                        ref="password"
                        autoComplete="off"
                        type="password"
                        placeholder={shop.marketplace.layout.getTitle('enter_password',lang)}
                        className="form-control"
                        style={input_style}
                        required
                        defaultValue={this.state.password}
                        onBlur={this.validationCheck} />
                  <div className={notValidClasses.passwordValGrpCls} style={{color:'red'}}>{this.state.passwordValMsg}</div>
                </div>
                <div style={{textAlign:'center'}}>
                  <FacebookLogin
                    appId="2711062715627330"
                    fields="name,email,picture"
                    callback={this.loginWithFacebook.bind(this)}
                    cssClass="facebook-button-class"
                    textButton="SIGNUP WITH FACEBOOK"
                    icon="fa-facebook"
                  /> 
                  &nbsp;&nbsp;
                  <GoogleLogin
                    clientId="418554825619-t91e73rmo5ttr1cgdvtkon7bqnqcj6ef.apps.googlestaffcontent.com"
                    buttonText="SIGNUP WITH GOOGLE"
                    onSuccess={this.loginWithGoogle.bind(this)}
                    onFailure={this.loginWithGoogle.bind(this)}
                  />
                </div>
              </div>
            </div>
        )
    }
}

export default StepOne