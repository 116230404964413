import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import classnames from "classnames";
import Switch from "react-switch";
import { useDropzone } from "react-dropzone";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

import NewOption from "./NewOption";

class DetailedProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      noProducts: 1,
      editing: false,
      loaded: false,
      newOption: false,
    };
  }

  componentWillMount() {
    this.props.shop.staff
      .getProduct(this.props.product.uuid)
      .then((product) => {
        this.setState({
          loaded: true,
          product: product.product[0],
          noProducts: product.noProducts,
        });
      });
  }
  componentWillUnmount() {
    // this.props.shop.productStore.removeProduct(this.state.product.slug)
  }

  componentDidMount() {}

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.product !== this.state.product && this.state.editing === false) {
  //     this.setState({ product: nextProps.product });
  //   }
  // }

  updateProduct(product_data) {
    var product = this.state.product;
    if (product_data.image_type === "imageUrl") {
      product["imageUrl"] = product_data.prod_data;
    } else if (product_data.image_type === "heroImage") {
      product["heroImage"]["items"].push(product_data.prod_data);
    } else if (product_data.image_type === "productImages") {
      product["productImages"]["pItems"].push(product_data.prod_data);
    }
    this.setState({ product: product, editing: true });
  }

  deleteProdImg(product_uuid, image_type, image_id, image_url) {
    if (window.confirm("Are you sure you to delete the image?")) {
      var product = this.state.product;
      if (image_type === "imageUrl") {
        product["imageUrl"] = "";
      } else if (image_type === "heroImage") {
        var heroImages = product["heroImage"]["items"];
        product["heroImage"]["items"] = heroImages.filter(
          (item) => item._id !== image_id
        );
      } else if (image_type === "productImages") {
        var productImages = product["productImages"]["pItems"];
        product["productImages"]["pItems"] = productImages.filter(
          (item) => item.uuid !== image_id
        );
      }
      this.setState({ product: product, editing: true });
      this.props.shop.staff.deleteProdImg(
        product_uuid,
        image_type,
        image_id,
        image_url,
        product.store_slug
      );
    }
  }

  saveProductDetails() {
    this.props.shop.staff.saveProductDetails(this.state.product);
    this.setState({ editing: false });
  }

  updateProductDetails(field, value, type) {
    var product = this.state.product;
    if (field === "price" || field === "wholesale_price") value = value * 100;

    if (type === "number") {
      value = parseInt(value);
    } else if (type === "boolean") {
      // value = parseInt(value);
    }
    // product.updateProductField(field,value);
    product[field] = value;
    this.setState({ product: product, editing: true });
  }

  updateProductCategory(cat_uuid, field, value) {
    var product = this.state.product;
    var cat_index = product["categories"].findIndex((x) => x.uuid === cat_uuid);
    if (cat_index) {
      product["categories"][cat_index][field] = value;
    } else {
      var new_category = [];
      new_category[field] = value;
      product["categories"].push(new_category);
    }
    this.setState({ product: product, editing: true });
  }

  updateOptionQuantityDetails(quan, opt_uuid, cust_uuid, field, value) {
    var product = this.state.product;
    var cust_index = product["customizations"].findIndex(
      (x) => x.uuid === cust_uuid
    );
    var opt_index = product["customizations"][cust_index]["options"].findIndex(
      (x) => x.uuid === opt_uuid
    );
    //check if quantities exist if they do search for location if they don't creat the object.
    if (
      typeof product["customizations"][cust_index]["options"][opt_index][
        "quantity"
      ] === "undefined"
    )
      product["customizations"][cust_index]["options"][opt_index]["quantity"] =
        [quan];

    var quan_index = product["customizations"][cust_index]["options"][
      opt_index
    ]["quantity"].findIndex((x) => x.location === quan.location);

    product["customizations"][cust_index]["options"][opt_index]["quantity"][
      quan_index
    ][field] = value;
    if (field === "retail_quantity")
      product["customizations"][cust_index]["options"][opt_index]["quantity"][
        quan_index
      ]["quantity"] = value;
    this.setState({ product: product, editing: true });
  }

  updateProductQuantity(index, field, value) {
    var product = this.state.product;
    product["quantity"][index][field] = value;

    if (field === "retail_quantity")
      product["quantity"][index]["quantity"] = value;
    this.setState({ product: product, editing: true });
  }

  updateCustomizationDetails(cust_uuid, field, value) {
    var product = this.state.product;
    var cust_index = product["customizations"].findIndex(
      (x) => x.uuid === cust_uuid
    );
    product["customizations"][cust_index][field] = value;
    this.setState({ product: product, editing: true });
  }
  updateOptionDetails(opt_uuid, cust_uuid, field, value) {
    var product = this.state.product;
    var cust_index = product["customizations"].findIndex(
      (x) => x.uuid === cust_uuid
    );
    var opt_index = product["customizations"][cust_index]["options"].findIndex(
      (x) => x.uuid === opt_uuid
    );

    if (field === "price" || field === "wholesale_price") value = value * 100;

    product["customizations"][cust_index]["options"][opt_index][field] = value;

    this.setState({ product: product, editing: true });
  }

  createOption(option, customization_uuid) {
    var product = this.state.product;
    var cust_index = product["customizations"].findIndex(
      (x) => x.uuid === customization_uuid
    );
    console.log(cust_index, customization_uuid, option);
    product["customizations"][cust_index]["options"].push(option);
    this.setState({ product: product, editing: true, newOption: false });
  }

  deleteProdOptionImg(
    opt_uuid,
    cust_uuid,
    product_uuid,
    image_type,
    image_id,
    image_url
  ) {
    console.log(
      "deleteProdOptionImg(",
      opt_uuid,
      cust_uuid,
      product_uuid,
      image_type,
      image_id,
      image_url
    );
    if (window.confirm("Are you sure you to delete the image?")) {
      var product = this.state.product;
      if (image_type === "option.productImages") {
        var cust_index = product["customizations"].findIndex(
          (x) => x.uuid === cust_uuid
        );
        var opt_index = product["customizations"][cust_index][
          "options"
        ].findIndex((x) => x.uuid === opt_uuid);

        var productImages =
          product["customizations"][cust_index]["options"][opt_index][
            "productImages"
          ]["pItems"];
        product["customizations"][cust_index]["options"][opt_index][
          "productImages"
        ]["pItems"] = productImages.filter((item) => item.uuid !== image_id);
      }
      this.setState({ product: product, editing: true });
      this.props.shop.staff.deleteProdOptionImg(
        product_uuid,
        image_type,
        cust_uuid,
        opt_uuid,
        image_id,
        image_url,
        product.store_slug
      );
    }
  }

  openNewOption() {
    this.setState({ newOption: true });
  }

  closeNewOption() {
    this.setState({ newOption: false });
  }

  render() {
    var shop = this.props.shop;
    var product = this.state.product;

    var fontSize = 10;
    if (
      !this.state.loaded ||
      !product ||
      !product.slug ||
      !shop.stores.get(product.store_slug)
    )
      return (
        <tr>
          <td colSpan="12" style={{ textAlign: "center" }}>
            Loading..
          </td>
        </tr>
      );

    var languages = shop.stores.get(product.store_slug).languages.toJSON();

    return (
      <tr>
        <td colSpan="12">
          <section className="product_details">
            <div
              className="col-sm-12"
              style={{
                boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.1)",
                marginTop: 65,
                marginBottom: 50,
                margin: 10,
                border: "1px solid #efefef",
                padding: 25,
                borderRadius: 5,
              }}
            >
              <Tabs>
                <TabList>
                  <Tab>{product.title_en} Details</Tab>
                  <Tab>Customizations</Tab>
                  <Tab>Quantities</Tab>
                  <Tab>Images</Tab>
                </TabList>
                <TabPanel id="details">
                  <div
                    className="content"
                    style={{ margin: 20, minHeight: 800 }}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Title
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <Tabs>
                                <TabList>
                                  {languages.map((lang, i) => (
                                    <Tab key={i}>{lang}</Tab>
                                  ))}
                                </TabList>
                                {languages.map((lang, i) => (
                                  <TabPanel key={i}>
                                    <input
                                      type="text"
                                      defaultValue={
                                        product["title_" + lang.toLowerCase()]
                                      }
                                      placeholder={lang + " Product Title"}
                                      className="product_details"
                                      onBlur={(e) => {
                                        this.updateProductDetails(
                                          "title_" + lang.toLowerCase(),
                                          e.target.value,
                                          "text"
                                        );
                                      }}
                                    />
                                    <br />
                                  </TabPanel>
                                ))}
                              </Tabs>
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Description
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <Tabs>
                                <TabList>
                                  {languages.map((lang, i) => (
                                    <Tab key={i}>{lang}</Tab>
                                  ))}
                                </TabList>
                                {languages.map((lang, i) => (
                                  <TabPanel key={i}>
                                    <textarea
                                      placeholder={
                                        lang + " Product Description"
                                      }
                                      className="product_details"
                                      onBlur={(e) => {
                                        this.updateProductDetails(
                                          "itemDescription_" +
                                            lang.toLowerCase(),
                                          e.target.value,
                                          "text"
                                        );
                                      }}
                                      defaultValue={
                                        product[
                                          "itemDescription_" +
                                            lang.toLowerCase()
                                        ]
                                      }
                                    ></textarea>
                                    <br />
                                  </TabPanel>
                                ))}
                              </Tabs>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Slug
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="text"
                                defaultValue={product.slug}
                                placeholder="Product Address"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "slug",
                                    e.target.value,
                                    "text"
                                  );
                                }}
                              />
                              <br />
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Sku
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="text"
                                defaultValue={product.sku}
                                placeholder="Product Sku"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "sku",
                                    e.target.value,
                                    "text"
                                  );
                                }}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Available
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <label htmlFor="material-switch">
                                <Switch
                                  checked={
                                    product.available
                                      ? product.available
                                      : false
                                  }
                                  onChange={(e) => {
                                    this.updateProductDetails(
                                      "available",
                                      e,
                                      "boolean"
                                    );
                                  }}
                                  onColor="#999"
                                  onHandleColor="#000"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                />
                              </label>
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Viewable
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <label htmlFor="material-switch">
                                <Switch
                                  checked={
                                    product.viewable ? product.viewable : false
                                  }
                                  onChange={(e) => {
                                    this.updateProductDetails(
                                      "viewable",
                                      e,
                                      "boolean"
                                    );
                                  }}
                                  onColor="#999"
                                  onHandleColor="#000"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                />
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Video Url
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="text"
                                defaultValue={product.videoUrl}
                                placeholder="Product Video Url"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "videoUrl",
                                    e.target.value,
                                    "text"
                                  );
                                }}
                              />
                              <br />
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Price Bucket
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="text"
                                defaultValue={product.priceBucket}
                                placeholder="Product Price Bucket $,$$,$$$"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "priceBucket",
                                    e.target.value,
                                    "text"
                                  );
                                }}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Price
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="number"
                                defaultValue={
                                  product.price ? product.price / 100 : 0
                                }
                                placeholder="Product Price"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "price",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              />
                              <br />
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Wholesale Price
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="number"
                                defaultValue={
                                  product.wholesale_price
                                    ? product.wholesale_price / 100
                                    : 0
                                }
                                placeholder="Product Price"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "wholesale_price",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Allow Oversell
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <label htmlFor="material-switch">
                                <Switch
                                  checked={
                                    product.allow_oversell
                                      ? product.allow_oversell
                                      : false
                                  }
                                  onChange={(e) => {
                                    this.updateProductDetails(
                                      "allow_oversell",
                                      e,
                                      "boolean"
                                    );
                                  }}
                                  onColor="#999"
                                  onHandleColor="#000"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                />
                              </label>
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Allow Pre Order
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <label htmlFor="material-switch">
                                <Switch
                                  checked={
                                    product.allow_preorder
                                      ? product.allow_preorder
                                      : false
                                  }
                                  onChange={(e) => {
                                    this.updateProductDetails(
                                      "allow_preorder",
                                      e,
                                      "boolean"
                                    );
                                  }}
                                  onColor="#999"
                                  onHandleColor="#000"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                />
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">Order</label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <select
                                type="number"
                                defaultValue={product.order}
                                placeholder="Product Order"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "order",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              >
                                {[...Array(this.state.noProducts)].map(
                                  (x, i) => (
                                    <option key={i + 1} value={i + 1}>
                                      {i + 1}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Featured
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <label htmlFor="material-switch">
                                <Switch
                                  checked={
                                    product.featured ? product.featured : false
                                  }
                                  onChange={(e) => {
                                    this.updateProductDetails(
                                      "featured",
                                      e,
                                      "boolean"
                                    );
                                  }}
                                  onColor="#999"
                                  onHandleColor="#000"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                />
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Minimum
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="number"
                                defaultValue={product.minimum}
                                placeholder="Product Minimum"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "minimum",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              />
                              <br />
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Wholesale Minimum
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="number"
                                defaultValue={product.wholesale_minimum}
                                placeholder="Product Minimum"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "wholesale_minimum",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Pre Order Min Hours
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <input
                                type="number"
                                defaultValue={product.preOrderMinHours}
                                placeholder="Pre Order Min Hours"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "preOrderMinHours",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              />
                              <br />
                            </div>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Rating
                              </label>
                            </div>
                            <div className="col-md-3 prod_label">
                              <select
                                type="number"
                                defaultValue={product.rating}
                                placeholder="Product Rating"
                                className="product_details"
                                onChange={(e) => {
                                  this.updateProductDetails(
                                    "rating",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              >
                                {[...Array(5)].map((x, i) => (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                ))}
                              </select>
                              <input
                                type="number"
                                min="0"
                                max="5"
                                defaultValue={product.rating}
                                placeholder="Product Minimum"
                                className="product_details"
                                onBlur={(e) => {
                                  this.updateProductDetails(
                                    "rating",
                                    e.target.value,
                                    "number"
                                  );
                                }}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Categories
                              </label>
                            </div>
                            <div className="col-md-9">
                              {product.categories.map((cat, indx) => (
                                <div
                                  className="col-md-12"
                                  key={indx}
                                  style={{
                                    border: "1px solid #efefef",
                                    margin: 5,
                                    borderRadius: 5,
                                  }}
                                >
                                  <Tabs>
                                    <TabList>
                                      {languages.map((lang, i) => (
                                        <Tab key={i}>{lang}</Tab>
                                      ))}
                                    </TabList>
                                    {languages.map((lang, indx) => (
                                      <TabPanel key={indx}>
                                        <input
                                          type="text"
                                          defaultValue={
                                            cat["title_" + lang.toLowerCase()]
                                          }
                                          placeholder={lang + " Category Title"}
                                          className="product_details"
                                          onBlur={(e) => {
                                            this.updateProductCategory(
                                              cat.uuid,
                                              "title_" + lang.toLowerCase(),
                                              e.target.value,
                                              "text"
                                            );
                                          }}
                                        />
                                        <br />
                                      </TabPanel>
                                    ))}
                                  </Tabs>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel id="customizations">
                  <div
                    className="content"
                    style={{ margin: 20, minHeight: 800 }}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        {product.customizations && (
                          <Accordion
                            allowZeroExpanded={true}
                            preExpanded={["customization_0"]}
                            style={{ width: "100%" }}
                          >
                            {product.customizations.map(
                              (customization, cust_indx) => (
                                <AccordionItem
                                  key={"customization_" + cust_indx}
                                  uuid={"customization_" + cust_indx}
                                >
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      {customization.title_en}
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div
                                      className="col-md-12"
                                      key={cust_indx}
                                      style={{
                                        border: "1px solid #efefef",
                                        margin: 5,
                                        borderRadius: 5,
                                      }}
                                    >
                                      <div className="row">
                                        <div className="col-md-3 prod_label_grey">
                                          <label className="product_details">
                                            Customization Title
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label">
                                          <Tabs>
                                            <TabList>
                                              {languages.map((lang, i) => (
                                                <Tab key={i}>{lang}</Tab>
                                              ))}
                                            </TabList>
                                            {languages.map((lang, i) => (
                                              <TabPanel key={i}>
                                                <input
                                                  type="text"
                                                  defaultValue={
                                                    customization[
                                                      "title_" +
                                                        lang.toLowerCase()
                                                    ]
                                                  }
                                                  onBlur={(e) => {
                                                    this.updateCustomizationDetails(
                                                      customization.uuid,
                                                      "title_" +
                                                        lang.toLowerCase(),
                                                      e.target.value,
                                                      "text"
                                                    );
                                                  }}
                                                  placeholder={
                                                    lang +
                                                    " Customization Title"
                                                  }
                                                  className="product_details"
                                                />
                                                <br />
                                              </TabPanel>
                                            ))}
                                          </Tabs>
                                        </div>
                                        <div className="col-md-3 prod_label_grey">
                                          <label className="product_details">
                                            Customization Order
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label">
                                          <select
                                            type="number"
                                            defaultValue={customization.order}
                                            placeholder="Option Order"
                                            className="product_details"
                                            onChange={(e) => {
                                              this.updateCustomizationDetails(
                                                customization.uuid,
                                                "order",
                                                e.target.value,
                                                "number"
                                              );
                                            }}
                                          >
                                            {[
                                              ...Array(
                                                product.customizations.length +
                                                  1
                                              ),
                                            ].map((x, i) => (
                                              <option key={i + 1} value={i + 1}>
                                                {i + 1}
                                              </option>
                                            ))}
                                          </select>
                                          <br />
                                        </div>
                                        {/*}
                                    <div className="col-md-3 prod_label_grey">
                                        <label className="product_details">Message</label>
                                    </div>
                                    <div className="col-md-3 prod_label">
                                        <label htmlFor="material-switch">
                                        <Switch
                                          checked={customization.message ? customization.message : false}
                                          onChange={(e)=>{this.updateCustomizationDetails(customization.uuid,'message',e,'boolean')}}
                                          onColor="#999"
                                          onHandleColor="#000"
                                          handleDiameter={30}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={20}
                                          width={48}
                                          className="react-switch"
                                        />
                                      </label>
                                    </div> */}
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 prod_label_grey">
                                          <label className="product_details">
                                            Allow Oversell
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label">
                                          <label htmlFor="material-switch">
                                            <Switch
                                              checked={
                                                customization.allow_oversell
                                                  ? customization.allow_oversell
                                                  : false
                                              }
                                              onChange={(e) => {
                                                this.updateCustomizationDetails(
                                                  customization.uuid,
                                                  "allow_oversell",
                                                  e,
                                                  "boolean"
                                                );
                                              }}
                                              onColor="#999"
                                              onHandleColor="#000"
                                              handleDiameter={30}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={20}
                                              width={48}
                                              className="react-switch"
                                            />
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label_grey">
                                          <label className="product_details">
                                            Is a size option?
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label">
                                          <label htmlFor="material-switch">
                                            <Switch
                                              checked={
                                                customization.size
                                                  ? customization.size
                                                  : false
                                              }
                                              onChange={(e) => {
                                                this.updateCustomizationDetails(
                                                  customization.uuid,
                                                  "size",
                                                  e,
                                                  "boolean"
                                                );
                                              }}
                                              onColor="#999"
                                              onHandleColor="#000"
                                              handleDiameter={30}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={20}
                                              width={48}
                                              className="react-switch"
                                            />
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 prod_label_grey">
                                          <label className="product_details">
                                            Customization Minimum Permitted
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label">
                                          <input
                                            type="number"
                                            defaultValue={
                                              customization.minPermitted
                                            }
                                            placeholder="Customization Minimum Permitted"
                                            className="product_details"
                                            onBlur={(e) => {
                                              this.updateCustomizationDetails(
                                                customization.uuid,
                                                "minPermitted",
                                                e.target.value,
                                                "number"
                                              );
                                            }}
                                          />
                                          <br />
                                        </div>
                                        <div className="col-md-3 prod_label_grey">
                                          <label className="product_details">
                                            Customization Maxiumum Permitted
                                          </label>
                                        </div>
                                        <div className="col-md-3 prod_label">
                                          <input
                                            type="number"
                                            defaultValue={
                                              customization.maxPermitted
                                            }
                                            placeholder="Customization Maxiumum Permitted"
                                            className="product_details"
                                            onBlur={(e) => {
                                              this.updateCustomizationDetails(
                                                customization.uuid,
                                                "maxPermitted",
                                                e.target.value,
                                                "number"
                                              );
                                            }}
                                          />
                                          <br />
                                        </div>
                                      </div>
                                      <div className="row">
                                        {this.state.newOption && (
                                          <div className="row">
                                            <NewOption
                                              languages={languages}
                                              noOptions={
                                                customization.options.length
                                              }
                                              createOption={this.createOption.bind(
                                                this
                                              )}
                                              customization_uuid={
                                                customization.uuid
                                              }
                                              close={this.closeNewOption.bind(
                                                this
                                              )}
                                              style={{ minWidth: 720 }}
                                              fontSize={fontSize}
                                            />
                                          </div>
                                        )}
                                        {customization.options && (
                                          <Accordion
                                            allowZeroExpanded={true}
                                            preExpanded={[
                                              cust_indx + "_option_0",
                                            ]}
                                            style={{ width: "100%", margin: 5 }}
                                          >
                                            <label
                                              className="product_details"
                                              style={{ marginLeft: 5 }}
                                            >
                                              Options
                                            </label>
                                            <div
                                              style={{
                                                color: "#000",
                                                fontSize: 10,
                                                marginTop: 35,
                                                cursor: "pointer",
                                                fontWeight: "normal",
                                                float: "right",
                                                marginRight: 20,
                                              }}
                                              onClick={this.openNewOption.bind(
                                                this
                                              )}
                                            >
                                              + New Option
                                            </div>

                                            <br />
                                            {customization.options.map(
                                              (option, opt_indx) => (
                                                <AccordionItem
                                                  key={
                                                    cust_indx +
                                                    "_option_" +
                                                    opt_indx
                                                  }
                                                  uuid={
                                                    cust_indx +
                                                    "_option_" +
                                                    opt_indx
                                                  }
                                                >
                                                  <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                      {option.title_en}
                                                    </AccordionItemButton>
                                                  </AccordionItemHeading>
                                                  <AccordionItemPanel>
                                                    <div
                                                      className="col-md-12"
                                                      key={opt_indx}
                                                      style={{
                                                        border:
                                                          "1px solid #efefef",
                                                        margin: 5,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      <div
                                                        className="row"
                                                        style={{}}
                                                      >
                                                        <Tabs>
                                                          <TabList>
                                                            <Tab>
                                                              Option Details
                                                            </Tab>
                                                            <Tab>
                                                              Quantities
                                                            </Tab>
                                                            <Tab>Images</Tab>
                                                          </TabList>
                                                          <TabPanel
                                                            style={{
                                                              paddingLeft: 15,
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <div className="row">
                                                              <div className="col-md-3 prod_label_grey">
                                                                <label className="product_details">
                                                                  Option Title
                                                                </label>
                                                              </div>
                                                              <div className="col-md-3 prod_label">
                                                                <Tabs>
                                                                  <TabList>
                                                                    {languages.map(
                                                                      (
                                                                        lang,
                                                                        i
                                                                      ) => (
                                                                        <Tab
                                                                          key={
                                                                            i
                                                                          }
                                                                        >
                                                                          {lang}
                                                                        </Tab>
                                                                      )
                                                                    )}
                                                                  </TabList>
                                                                  {languages.map(
                                                                    (
                                                                      lang,
                                                                      i
                                                                    ) => (
                                                                      <TabPanel
                                                                        key={i}
                                                                      >
                                                                        <input
                                                                          type="text"
                                                                          defaultValue={
                                                                            option[
                                                                              "title_" +
                                                                                lang.toLowerCase()
                                                                            ]
                                                                          }
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            this.updateOptionDetails(
                                                                              option.uuid,
                                                                              customization.uuid,
                                                                              "title_" +
                                                                                lang.toLowerCase(),
                                                                              e
                                                                                .target
                                                                                .value,
                                                                              "text"
                                                                            );
                                                                          }}
                                                                          placeholder={
                                                                            lang +
                                                                            " Option Title"
                                                                          }
                                                                          className="product_details"
                                                                        />
                                                                        <br />
                                                                      </TabPanel>
                                                                    )
                                                                  )}
                                                                </Tabs>
                                                              </div>
                                                              <div className="col-md-3 prod_label_grey">
                                                                <label className="product_details">
                                                                  Option Slug
                                                                </label>
                                                              </div>
                                                              <div className="col-md-3 prod_label">
                                                                <input
                                                                  type="text"
                                                                  defaultValue={
                                                                    option.slug
                                                                  }
                                                                  placeholder="Option Slug"
                                                                  className="product_details"
                                                                  onBlur={(
                                                                    e
                                                                  ) => {
                                                                    this.updateOptionDetails(
                                                                      option.uuid,
                                                                      customization.uuid,
                                                                      "slug",
                                                                      e.target
                                                                        .value,
                                                                      "text"
                                                                    );
                                                                  }}
                                                                />
                                                                <br />
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <div className="col-md-3 prod_label_grey">
                                                                <label className="product_details">
                                                                  Option Sku
                                                                </label>
                                                              </div>
                                                              <div className="col-md-3 prod_label">
                                                                <input
                                                                  type="text"
                                                                  defaultValue={
                                                                    option.sku
                                                                  }
                                                                  placeholder="Option Sku"
                                                                  className="product_details"
                                                                  onBlur={(
                                                                    e
                                                                  ) => {
                                                                    this.updateOptionDetails(
                                                                      option.uuid,
                                                                      customization.uuid,
                                                                      "sku",
                                                                      e.target
                                                                        .value,
                                                                      "text"
                                                                    );
                                                                  }}
                                                                />
                                                                <br />
                                                              </div>
                                                              <div className="col-md-3 prod_label_grey">
                                                                <label className="product_details">
                                                                  Option Order
                                                                </label>
                                                              </div>
                                                              <div className="col-md-3 prod_label">
                                                                <select
                                                                  type="number"
                                                                  defaultValue={
                                                                    option.order
                                                                  }
                                                                  placeholder="Option Order"
                                                                  className="product_details"
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.updateOptionDetails(
                                                                      option.uuid,
                                                                      customization.uuid,
                                                                      "order",
                                                                      e.target
                                                                        .value,
                                                                      "number"
                                                                    );
                                                                  }}
                                                                >
                                                                  {[
                                                                    ...Array(
                                                                      customization
                                                                        .options
                                                                        .length +
                                                                        1
                                                                    ),
                                                                  ].map(
                                                                    (x, i) => (
                                                                      <option
                                                                        key={
                                                                          i + 1
                                                                        }
                                                                        value={
                                                                          i + 1
                                                                        }
                                                                      >
                                                                        {i + 1}
                                                                      </option>
                                                                    )
                                                                  )}
                                                                </select>
                                                                <br />
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <div className="col-md-3 prod_label_grey">
                                                                <label className="product_details">
                                                                  Option Retail
                                                                  Price
                                                                </label>
                                                              </div>
                                                              <div className="col-md-3 prod_label">
                                                                <input
                                                                  type="number"
                                                                  defaultValue={
                                                                    option.price /
                                                                    100
                                                                      ? option.price /
                                                                        100
                                                                      : 0
                                                                  }
                                                                  placeholder="Option Retail Price"
                                                                  className="product_details"
                                                                  onBlur={(
                                                                    e
                                                                  ) => {
                                                                    this.updateOptionDetails(
                                                                      option.uuid,
                                                                      customization.uuid,
                                                                      "price",
                                                                      e.target
                                                                        .value,
                                                                      "number"
                                                                    );
                                                                  }}
                                                                />
                                                                <br />
                                                              </div>
                                                              <div className="col-md-3 prod_label_grey">
                                                                <label className="product_details">
                                                                  Option
                                                                  Wholesale
                                                                  Price
                                                                </label>
                                                              </div>
                                                              <div className="col-md-3 prod_label">
                                                                <input
                                                                  type="number"
                                                                  defaultValue={
                                                                    option.wholesale_price /
                                                                    100
                                                                      ? option.wholesale_price /
                                                                        100
                                                                      : 0
                                                                  }
                                                                  placeholder="Option Wholesale Price"
                                                                  className="product_details"
                                                                  onBlur={(
                                                                    e
                                                                  ) => {
                                                                    this.updateOptionDetails(
                                                                      option.uuid,
                                                                      customization.uuid,
                                                                      "wholesale_price",
                                                                      e.target
                                                                        .value,
                                                                      "number"
                                                                    );
                                                                  }}
                                                                />
                                                                <br />
                                                              </div>
                                                            </div>
                                                          </TabPanel>
                                                          {option.quantity &&
                                                          option.quantity
                                                            .length > 0 ? (
                                                            <TabPanel
                                                              style={{
                                                                paddingLeft: 15,
                                                                width: "100%",
                                                              }}
                                                            >
                                                              {option.quantity.map(
                                                                (
                                                                  quan,
                                                                  quan_indx
                                                                ) => (
                                                                  <div
                                                                    className="col-md-12"
                                                                    key={
                                                                      quan_indx
                                                                    }
                                                                    style={{
                                                                      borderRadius: 5,
                                                                      border:
                                                                        "1px solid #efefef",
                                                                      marginTop: 5,
                                                                    }}
                                                                  >
                                                                    <div className="row">
                                                                      <div className="col-md-3 prod_label_grey">
                                                                        <label className="product_details">
                                                                          Location
                                                                        </label>
                                                                      </div>
                                                                      <div className="col-md-3 prod_label">
                                                                        <input
                                                                          type="text"
                                                                          readOnly
                                                                          defaultValue={shop.stores
                                                                            .get(
                                                                              product.store_slug
                                                                            )
                                                                            .getLocationTitle(
                                                                              quan.location
                                                                            )}
                                                                          placeholder="Quantity Location"
                                                                          className="product_details"
                                                                        />
                                                                        <br />
                                                                      </div>
                                                                    </div>
                                                                    <div className="row">
                                                                      <div className="col-md-3 prod_label_grey">
                                                                        <label className="product_details">
                                                                          Option
                                                                          Retail
                                                                          Quantity
                                                                        </label>
                                                                      </div>
                                                                      <div className="col-md-3 prod_label">
                                                                        <input
                                                                          type="number"
                                                                          defaultValue={
                                                                            quan.retail_quantity
                                                                              ? quan.retail_quantity
                                                                              : 0
                                                                          }
                                                                          placeholder="Option Retail Quantity"
                                                                          className="product_details"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            this.updateOptionQuantityDetails(
                                                                              quan,
                                                                              option.uuid,
                                                                              customization.uuid,
                                                                              "retail_quantity",
                                                                              e
                                                                                .target
                                                                                .value,
                                                                              "number"
                                                                            );
                                                                          }}
                                                                        />
                                                                        <br />
                                                                      </div>
                                                                      <div className="col-md-3 prod_label_grey">
                                                                        <label className="product_details">
                                                                          Option
                                                                          Wholesale
                                                                          Quantity
                                                                        </label>
                                                                      </div>
                                                                      <div className="col-md-3 prod_label">
                                                                        <input
                                                                          type="number"
                                                                          defaultValue={
                                                                            quan.wholesale_quantity
                                                                              ? quan.wholesale_quantity
                                                                              : 0
                                                                          }
                                                                          placeholder="Option Wholesale Quantity"
                                                                          className="product_details"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            this.updateOptionQuantityDetails(
                                                                              quan,
                                                                              option.uuid,
                                                                              customization.uuid,
                                                                              "wholesale_quantity",
                                                                              e
                                                                                .target
                                                                                .value,
                                                                              "number"
                                                                            );
                                                                          }}
                                                                        />
                                                                        <br />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                )
                                                              )}
                                                            </TabPanel>
                                                          ) : (
                                                            <TabPanel
                                                              style={{
                                                                paddingLeft: 15,
                                                                width: "100%",
                                                              }}
                                                            >
                                                              {product.quantity
                                                                .length > 0 ? (
                                                                <div>
                                                                  {product.quantity.map(
                                                                    (
                                                                      quan,
                                                                      quan_indx
                                                                    ) => (
                                                                      <div
                                                                        className="col-md-12"
                                                                        key={
                                                                          quan_indx
                                                                        }
                                                                      >
                                                                        <div className="row">
                                                                          <div className="col-md-3 prod_label_grey">
                                                                            <label className="product_details">
                                                                              Location
                                                                            </label>
                                                                          </div>
                                                                          <div className="col-md-3 prod_label">
                                                                            <input
                                                                              type="text"
                                                                              readOnly
                                                                              defaultValue={shop.stores
                                                                                .get(
                                                                                  product.store_slug
                                                                                )
                                                                                .getLocationTitle(
                                                                                  quan.location
                                                                                )}
                                                                              placeholder="Quantity Location"
                                                                              className="product_details"
                                                                            />
                                                                            <br />
                                                                          </div>
                                                                        </div>
                                                                        <div className="row">
                                                                          <div className="col-md-3 prod_label_grey">
                                                                            <label className="product_details">
                                                                              Option
                                                                              Retail
                                                                              Quantity
                                                                            </label>
                                                                          </div>
                                                                          <div className="col-md-3 prod_label">
                                                                            <input
                                                                              type="number"
                                                                              defaultValue={
                                                                                quan.retail_quantity
                                                                                  ? quan.retail_quantity
                                                                                  : 0
                                                                              }
                                                                              placeholder="Option Retail Quantity"
                                                                              className="product_details"
                                                                              onBlur={(
                                                                                e
                                                                              ) => {
                                                                                this.updateProductQuantity(
                                                                                  quan.location,
                                                                                  option.uuid,
                                                                                  customization.uuid,
                                                                                  "retail_quantity",
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                                  "number"
                                                                                );
                                                                              }}
                                                                            />
                                                                            <br />
                                                                          </div>
                                                                          <div className="col-md-3 prod_label_grey">
                                                                            <label className="product_details">
                                                                              Option
                                                                              Wholesale
                                                                              Quantity
                                                                            </label>
                                                                          </div>
                                                                          <div className="col-md-3 prod_label">
                                                                            <input
                                                                              type="number"
                                                                              defaultValue={
                                                                                quan.wholesale_quantity
                                                                                  ? quan.wholesale_quantity
                                                                                  : 0
                                                                              }
                                                                              placeholder="Option Wholesale Quantity"
                                                                              className="product_details"
                                                                              onBlur={(
                                                                                e
                                                                              ) => {
                                                                                this.updateProductQuantity(
                                                                                  quan.location,
                                                                                  option.uuid,
                                                                                  customization.uuid,
                                                                                  "wholesale_quantity",
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                                  "number"
                                                                                );
                                                                              }}
                                                                            />
                                                                            <br />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              ) : (
                                                                <div></div>
                                                              )}
                                                            </TabPanel>
                                                          )}
                                                          <TabPanel
                                                            style={{
                                                              paddingLeft: 15,
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <Previews
                                                              label={
                                                                "Upload Option Product Images"
                                                              }
                                                              uuid={
                                                                product.uuid
                                                              }
                                                              type={
                                                                "option.productImages"
                                                              }
                                                              updateProduct={this.updateProduct.bind(
                                                                this
                                                              )}
                                                              image_title={
                                                                product.slug
                                                              }
                                                              cust_uuid={
                                                                customization.uuid
                                                              }
                                                              opt_uuid={
                                                                option.uuid
                                                              }
                                                              image_name={
                                                                product.slug +
                                                                "-"
                                                                  .concat(
                                                                    option.slug !==
                                                                      ""
                                                                      ? option.slug
                                                                      : option.title_en.replace(
                                                                          /\s+/g,
                                                                          "-"
                                                                        )
                                                                  )
                                                                  .toLowerCase() +
                                                                "-".concat(
                                                                  typeof option.productImages ===
                                                                    "undefined" ||
                                                                    typeof option
                                                                      .productImages
                                                                      .pItems ===
                                                                      "undefined"
                                                                    ? 0
                                                                    : option
                                                                        .productImages
                                                                        .pItems
                                                                        .length
                                                                )
                                                              }
                                                              shop={shop}
                                                            />
                                                            {option.productImages.pItems.map(
                                                              (img, i) => (
                                                                <div
                                                                  key={i}
                                                                  style={{
                                                                    backgroundImage:
                                                                      "url(" +
                                                                      shop.imageDirectory +
                                                                      img.url +
                                                                      ")",
                                                                    backgroundSize:
                                                                      "contain",
                                                                    width: 100,
                                                                    height: 100,
                                                                    position:
                                                                      "relative",
                                                                    display:
                                                                      "inline-block",
                                                                    margin: 5,
                                                                  }}
                                                                >
                                                                  <i
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      right: 10,
                                                                      top: 10,
                                                                      color:
                                                                        "#000",
                                                                      cursor:
                                                                        "pointer",
                                                                      fontSize:
                                                                        fontSize +
                                                                        2,
                                                                    }}
                                                                    className="fas fa-times"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      this.deleteProdOptionImg(
                                                                        option.uuid,
                                                                        customization.uuid,
                                                                        product.uuid,
                                                                        "option.productImages",
                                                                        img.uuid,
                                                                        img.url
                                                                      );
                                                                    }}
                                                                  ></i>
                                                                </div>
                                                              )
                                                            )}
                                                          </TabPanel>
                                                        </Tabs>
                                                      </div>
                                                    </div>
                                                  </AccordionItemPanel>
                                                </AccordionItem>
                                              )
                                            )}
                                          </Accordion>
                                        )}
                                      </div>
                                    </div>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              )
                            )}
                          </Accordion>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel id="quantities">
                  <div
                    className="content"
                    style={{ margin: 20, minHeight: 800 }}
                  >
                    <div className="container-fluid">
                      {product.quantity.length > 0 ? (
                        <div className="row">
                          {product.quantity.map((quan, i) => (
                            <div className="col-md-12" key={i}>
                              <div className="row">
                                <div className="col-md-3 prod_label_grey">
                                  <label className="product_details">
                                    Location
                                  </label>
                                </div>
                                <div className="col-md-3 prod_label">
                                  <input
                                    type="text"
                                    readOnly
                                    defaultValue={shop.stores
                                      .get(product.store_slug)
                                      .getLocationTitle(quan.location)}
                                    placeholder="Quantity Location"
                                    className="product_details"
                                  />
                                  <br />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3 prod_label_grey">
                                  <label className="product_details">
                                    Product Retail Quantity
                                  </label>
                                </div>
                                <div className="col-md-3 prod_label">
                                  <input
                                    type="number"
                                    defaultValue={product.retail_quantity}
                                    placeholder="Product Retail Quantity"
                                    className="product_details"
                                    onBlur={(e) => {
                                      this.updateQuantityDetails(
                                        "retail_quantity",
                                        e.target.value,
                                        "number"
                                      );
                                    }}
                                  />
                                  <br />
                                </div>
                                <div className="col-md-3 prod_label_grey">
                                  <label className="product_details">
                                    Product Wholesale Quantity
                                  </label>
                                </div>
                                <div className="col-md-3 prod_label">
                                  <input
                                    type="number"
                                    defaultValue={product.wholesale_quantity}
                                    placeholder="Product Wholesale Quantity"
                                    className="product_details"
                                    onBlur={(e) => {
                                      this.updateQuantityDetails(
                                        "wholesale_quantity",
                                        e.target.value,
                                        "number"
                                      );
                                    }}
                                  />
                                  <br />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel id="images">
                  <div
                    className="content"
                    style={{ margin: 20, minHeight: 800 }}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row" style={{ marginTop: 2 }}>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Main Product Image
                              </label>
                            </div>
                            <div className="col-md-9">
                              <Previews
                                label={"Upload Products Main Image"}
                                uuid={product.uuid}
                                type={"imageUrl"}
                                updateProduct={this.updateProduct.bind(this)}
                                image_title={product.slug}
                                image_name={product.slug + "-imageUrl"}
                                shop={shop}
                                cust_uuid=""
                                opt_uuid=""
                              />
                              {product.imageUrl !== "" && (
                                <div
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      shop.imageDirectory +
                                      product.imageUrl +
                                      ")",
                                    backgroundSize: "contain",
                                    width: 150,
                                    height: 150,
                                    position: "relative",
                                    display: "inline-block",
                                    borderRadius: 5,
                                    margin: 5,
                                  }}
                                >
                                  <i
                                    style={{
                                      position: "absolute",
                                      right: 10,
                                      top: 10,
                                      color: "#000",
                                      cursor: "pointer",
                                      fontSize: fontSize + 2,
                                    }}
                                    className="fas fa-times"
                                    onClick={(e) => {
                                      this.deleteProdImg(
                                        product.uuid,
                                        "imageUrl",
                                        "",
                                        product.imageUrl
                                      );
                                    }}
                                  ></i>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: 2 }}>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Hero Images
                              </label>
                            </div>
                            <div className="col-md-9">
                              <Previews
                                label={"Upload Products Hero Images"}
                                uuid={product.uuid}
                                type={"heroImage"}
                                updateProduct={this.updateProduct.bind(this)}
                                image_title={product.slug}
                                image_name={
                                  product.slug +
                                  "-hero-".concat(
                                    typeof product.heroImage === "undefined" ||
                                      typeof product.heroImage.items ===
                                        "undefined"
                                      ? 0
                                      : product.heroImage.items.length
                                  )
                                }
                                shop={shop}
                                cust_uuid=""
                                opt_uuid=""
                              />
                              {product.heroImage && (
                                <span>
                                  {product.heroImage.items.map((img, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        backgroundImage:
                                          "url(" +
                                          shop.imageDirectory +
                                          img.url +
                                          ")",
                                        backgroundSize: "contain",
                                        width: 100,
                                        height: 100,
                                        position: "relative",
                                        display: "inline-block",
                                        margin: 5,
                                      }}
                                    >
                                      <i
                                        style={{
                                          position: "absolute",
                                          right: 10,
                                          top: 10,
                                          color: "#000",
                                          cursor: "pointer",
                                          fontSize: fontSize + 2,
                                        }}
                                        className="fas fa-times"
                                        onClick={(e) => {
                                          this.deleteProdImg(
                                            product.uuid,
                                            "heroImage",
                                            img._id,
                                            img.url
                                          );
                                        }}
                                      ></i>
                                    </div>
                                  ))}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: 2 }}>
                            <div className="col-md-3 prod_label_grey">
                              <label className="product_details">
                                Product Images
                              </label>
                            </div>
                            <div className="col-md-9">
                              <Previews
                                label={"Upload Option Product Images"}
                                uuid={product.uuid}
                                type={"productImages"}
                                updateProduct={this.updateProduct.bind(this)}
                                image_title={product.slug}
                                image_name={
                                  product.slug +
                                  "-product-".concat(
                                    typeof product.productImages ===
                                      "undefined" ||
                                      typeof product.productImages.pItems ===
                                        "undefined"
                                      ? 0
                                      : product.productImages.pItems.length
                                  )
                                }
                                shop={shop}
                                cust_uuid=""
                                opt_uuid=""
                              />
                              {product.productImages.pItems.map((img, i) => (
                                <div
                                  key={i}
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      shop.imageDirectory +
                                      img.url +
                                      ")",
                                    backgroundSize: "contain",
                                    width: 150,
                                    height: 150,
                                    position: "relative",
                                    display: "inline-block",
                                    borderRadius: 5,
                                    margin: 5,
                                  }}
                                >
                                  <i
                                    style={{
                                      position: "absolute",
                                      right: 10,
                                      top: 10,
                                      color: "#000",
                                      cursor: "pointer",
                                      fontSize: fontSize + 2,
                                    }}
                                    className="fas fa-times"
                                    onClick={(e) => {
                                      this.deleteProdImg(
                                        product.uuid,
                                        "productImages",
                                        img.uuid,
                                        img.url
                                      );
                                    }}
                                  ></i>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              <div className="row">
                <div className="col-md-12" style={{ textAlign: "right" }}>
                  <button
                    onClick={(e) => {
                      this.saveProductDetails();
                    }}
                    style={{
                      backgroundColor: "#000",
                      border: "none",
                      color: "#fff",
                      margin: 5,
                      width: 150,
                      height: 25,
                      borderRadius: 5,
                    }}
                  >
                    Save Product Details
                  </button>
                </div>
              </div>
            </div>
          </section>
        </td>
      </tr>
    );
  }
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Previews(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      var uuid = props.uuid;
      var type = props.type;
      var image_name = props.image_name;
      var image_title = props.image_title;
      var cust_uuid = props.cust_uuid;
      var opt_uuid = props.opt_uuid;
      // var {uuid,type,image_name,image_title,cust_uuid,opt_uuid} = props;
      props.shop.staff
        .uploadProductImage(
          uuid,
          acceptedFiles[0],
          type,
          image_name,
          image_title,
          cust_uuid,
          opt_uuid
        )
        .then((response) => {
          props.updateProduct(response);
        });
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>{props.label}</p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}

export default DetailedProduct;
