import { types, getParent, detach } from "mobx-state-tree"
import axios from 'axios';

const categories = types.model("categories", {
    uuid: types.identifier,
    order:types.optional(types.number,0),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),  
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""),    
    keyName: types.optional(types.string,""),
    image: types.optional(types.string, '')
});

const tags = types.model("tags", {
    uuid: types.identifier,
    name: types.optional(types.string,""),
    keyName: types.string
});

const address = types.model("address", {
    label_address:types.optional(types.string,''),
    form_address: types.optional(types.string,''),
    street: types.optional(types.string,''),
    ward: types.optional(types.string,''),
    suburb: types.optional(types.string,''),
    post_code: types.optional(types.string,''),
    city: types.optional(types.string,''),
    region: types.optional(types.string,''),
    country: types.optional(types.string,''),
    country_code: types.optional(types.string,''),
    gps:types.optional(types.array(types.number),[]),
    apt: types.optional(types.string,''),
    delivery_instructions: types.optional(types.string,''),
    selected:types.optional(types.boolean,false)
});

const etaRange = types.model("etaRange", {
    min: types.optional(types.number,0),
    max: types.optional(types.number,0),
    raw: types.optional(types.number,0),
});

const items = types.model("items", {
    link: types.optional(types.string,''),
    url: types.optional(types.string,''),
    type: types.optional(types.string,'image'),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),
    title_zh: types.optional(types.string, ""),    
    title_ko: types.optional(types.string, ""),
    subtitle_en: types.optional(types.string, ""),
    subtitle_vi: types.optional(types.string, ""),
    subtitle_ja: types.optional(types.string, ""),
    subtitle_zh: types.optional(types.string, ""),    
    subtitle_ko: types.optional(types.string, ""),
    style: types.optional(types.string, "{}"),
    showLogo: types.optional(types.boolean,false),
    mute: types.optional(types.boolean,true),
    width: types.optional(types.number,0),
    height: types.optional(types.number,0),
    delay: types.optional(types.number,3500),
    alt:types.optional(types.string,''),
})
.actions(self => {
  function updateURL(value,uuid){    
    self.url = value
  }
  return {
    updateURL
  }
});

const heroImage = types.model("heroImage", {
    height:types.optional(types.string,"full"),
    items: types.array(items)
});

const openHours = types.model("openHours", {
    "day": types.optional(types.string,""),
    "startTime": types.optional(types.string,""),
    "endTime": types.optional(types.string,""),
    "durationOffset": types.optional(types.number,0),
    "incrementStep": types.number
});
const deliveryAreas = types.model("deliveryAreas", {
    uuid: types.identifier,
    name: types.optional(types.string,""),
    keyName: types.optional(types.string,""),
    cost: types.number
});

const orderType = types.model('orderType', {
    text: types.optional(types.string, ''),
    icon: types.optional(types.string, '')
});


const btnNav = types.model('btnNav', {
    text: types.optional(types.string, ''),
    icon: types.optional(types.string, ''),
    css: types.optional(types.string, ''),
    page:types.optional(types.string, ''),
});

const cart = types.model('cart', {
    icon: types.optional(types.string, 'shopping-bag'),
})

const home = types.model('home', {
    menuTextColor: types.optional(types.string, '#fff'),
    showHeaderVideo: types.optional(types.boolean, true),
    headerVideoOption: types.optional(types.string,"grid"),
    showSlider: types.optional(types.boolean, true),
    showCategories: types.optional(types.boolean, true),
    categoriesText_en:types.optional(types.string, 'OUR RANGE'),
    categoriesText_vi:types.optional(types.string, 'Sản phẩm của chúng tôi'),
    categoriesText_ja:types.optional(types.string, '私たちの範囲'),
    categoriesText_zh:types.optional(types.string, '我们的范围'),
    categoriesText_ko:types.optional(types.string, '우리의 범위'),
    showFeatured: types.optional(types.boolean, true),
    featuredText_en: types.optional(types.string, 'POPULAR PRODUCTS'),
    featuredText_vi: types.optional(types.string, 'Sản phẩm nổi bật'),
    featuredText_ja: types.optional(types.string, '一般的な製品'),
    featuredText_zh: types.optional(types.string, '大众产品'),
    featuredText_ko: types.optional(types.string, '인기 제품'),
    showReviews: types.optional(types.boolean, true),
    showAbout: types.optional(types.boolean, true),
    showMenu: types.optional(types.boolean, false),
    showMenuText_en: types.optional(types.string, 'Shop Menu'),
    showMenuText_vi: types.optional(types.string, 'Thực đơn đầy đủ'),
    showMenuText_ja: types.optional(types.string, 'フルメニュー'),
    showMenuText_zh: types.optional(types.string, '商店菜单'),
    showMenuText_ko: types.optional(types.string, '가게 메뉴'),
    showBlog: types.optional(types.boolean, false),
    blogText_en: types.optional(types.string, 'BLOG'),
    blogText_vi: types.optional(types.string, 'BLOG'),
    blogText_ja: types.optional(types.string, 'ブログ'),
    blogText_zh: types.optional(types.string, '博客'),
    blogText_ko: types.optional(types.string, '블로그'),
    aboutTextHeader_en: types.optional(types.string, 'ABOUT'),
    aboutText_en: types.optional(types.string, ''),
    aboutTextHeader_vi: types.optional(types.string, 'TRONG KHOẢNG'),
    aboutText_vi: types.optional(types.string, ''),
    aboutTextHeader_ja: types.optional(types.string, '約'),
    aboutText_ja: types.optional(types.string, ''),
    aboutTextHeader_zh: types.optional(types.string, '关于'),
    aboutText_zh: types.optional(types.string, ''),
    aboutTextHeader_ko: types.optional(types.string, '약'),
    aboutText_ko: types.optional(types.string, ''),


    aboutImage: types.optional(types.array(types.optional(types.string, '')),[]),
    aboutImageStyle:types.optional(types.string, 'side'),
    showInstagram: types.optional(types.boolean, true),
    showTripadvisor: types.optional(types.boolean, false),
    showFacebookFeed: types.optional(types.boolean, true),
    showYoutubeFeed: types.optional(types.boolean, true),
    showLocations: types.optional(types.boolean, true),
    showContact: types.optional(types.boolean, true),
    showStoresMap: types.optional(types.boolean, true),
    showFacebookMessenger: types.optional(types.boolean, true),
    topBarMSG_en:types.optional(types.string,""),
    topBarMSG_vi:types.optional(types.string,""),
    topBarMSG_ja:types.optional(types.string,""),
    topBarMSG_zh:types.optional(types.string,""),
    topBarMSG_ko:types.optional(types.string,""),
    topBarMSGCSS:types.optional(types.string,JSON.stringify({backgroundColor:'#000',color:'#fff',textAlign:'center',fontSize:12,verticalAlign:'middle',width:'100%',height:20,position:'absolute',top:0,zIndex:2,marginLeft:'-50%',left:'50%'})),
    showMap: types.optional(types.boolean, true),
    showOrderTypes: types.optional(types.boolean, true),

    orderTypes: types.optional(types.array(orderType), [
        { text: 'delivery', icon: 'motorcycle' },
        { text: 'pickup', icon: 'shopping-bag' },
        { text: 'eatin', icon: 'cutlery' }
    ]),
    showBtmNav: types.optional(types.boolean, true),
    btnNavs: types.optional(types.array(btnNav), [{
            text: 'Restaurants',
            icon: 'building-o',
            page: 'stores',
            css: JSON.stringify({ backgroundColor: '#000', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #000', outline: 'none', lineHeight: '18px', position: 'relative', color: '#fff', margin: 5, width: '45%' })
        },
        {
            text: 'Get some food',
            icon: 'cutlery',
            page: 'products',
            css: JSON.stringify({ backgroundColor: '#fff', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #c7c7c7', outline: 'none', lineHeight: '18px', position: 'relative', color: '#999', margin: 5, width: '45%' })
        }
    ]),
    btnPrimCSS: JSON.stringify({ backgroundColor: '#000', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #000', outline: 'none', lineHeight: '18px', position: 'relative', color: '#fff', margin: 10, width: '100%' }),
    btnSecCSS: JSON.stringify({ backgroundColor: '#fff', fontSize: 12, padding: '11px 20px', borderRadius: 2, border: '1px solid #c7c7c7', outline: 'none', lineHeight: '18px', position: 'relative', color: '#999', margin: 10, width: '100%' }),
    bgCSS: types.optional(types.string, JSON.stringify({ display: 'inline-block', width: '100%', textAlign: 'center', height: 956, background: 'url(./images/tacos.jpg)', backgroundSize: 'cover', backgroundPosition: 'top right', backgroundRepeat: 'no-repeat' })),
    primTxt: types.optional(types.string, 'Taste the difference....'),
    primTxtCSS: types.optional(types.string, JSON.stringify({ textAlign: 'center', color: '#fff', fontWeight: 300, fontSize: 52, margin: 10, position: 'relative', top: 10, textShadow: ' 0 2px 4px rgba(0,0,0,0.3)' })),

    secTxt: types.optional(types.string, 'Order From Local Restaurants You Love'),
    secTxtCSS: types.optional(types.string, JSON.stringify({color: '#fff', fontWeight: 300, fontSize: 24, marginBottom: 40, textAlign: 'center', textShadow: ' 0 2px 4px rgba(0,0,0,0.3)' }))    
})

const products = types.model('products', {
  heroImageUrl:types.optional(types.string,""),
})

const customPages = types.model('customPages', {
  _id : types.optional(types.string,''),
  slug:types.optional(types.string, ''),
  menu_order:types.optional(types.number, 0),
  on_menu:types.optional(types.boolean, false),
  show_header:types.optional(types.boolean, false),
  title_en : types.optional(types.string, ''),    
  title_vi : types.optional(types.string, ''),
  title_ja : types.optional(types.string, ''),
  title_zh : types.optional(types.string, ''),    
  title_ko : types.optional(types.string, ''),      
  html_en : types.optional(types.string, ''),
  html_vi : types.optional(types.string, ''),
  html_ja : types.optional(types.string, ''),
  html_zh : types.optional(types.string, ''),    
  html_ko : types.optional(types.string, ''),    
  style : types.optional(types.string, ''),    
  js : types.optional(types.string, ''),    
  tags : types.array(types.optional(types.string,'')),
  createdAt: types.optional(types.string, '')
})

const paymentMethod = types.model('paymentMethod', {
    slug: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    icon: types.optional(types.string, '')
});


const layout = types.model('layout', {
    font: types.optional(types.string, 'sans-serif'),

    prodItemOption: types.optional(types.boolean, false),

    showLanguages: types.optional(types.boolean, true),
    primFont: types.optional(types.string, 'sans-serif'),
    primTxtColor: types.optional(types.string, '#000'),
    primSize: types.optional(types.number, 52),
    primWeight: types.optional(types.number, 300),

    secTxtColor: types.optional(types.string, '#000'),
    secSize: types.optional(types.number, 24),
    secWeight: types.optional(types.number, 300),

    loader:types.optional(types.string, ''),
    loaderBGColor:types.optional(types.string, ''),

    btnPrimCSS: JSON.stringify({ backgroundColor: '#000', fontSize: 12, padding: '15px 20px', borderRadius: 2, border: '1px solid #000', outline: 'none', lineHeight: '18px', position: 'relative', color: '#fff', margin: 10 }),
    btnSecCSS: JSON.stringify({ backgroundColor: '#fff', fontSize: 12, padding: '15px 20px', borderRadius: 2, border: '1px solid #c7c7c7', outline: 'none', lineHeight: '18px', position: 'relative', color: '#999', margin: 10 }),
    
    markerColor:types.optional(types.string, '#000'),

    menuStyle: types.optional(types.string, 'side'),    
    menuTextColor:types.optional(types.string, '#fff'),
    prodPage: types.optional(types.string,'popup'),
    showCuisineFilter: types.optional(types.boolean,false),

    home: types.optional(home,{}),
    products: types.optional(products,{}),    
    cart: types.optional(cart,{}),
    customPages: types.optional(types.array(customPages),[])
}).actions(self => {
    function updatePrimaryColor(color) {
      if(color === '#44b4b8')
        color = '#000'

      self.primTxtColor = color;
    }
    return {updatePrimaryColor}
});

const review = types.model("review", {
    name:types.optional(types.string, ""),
    lang:types.optional(types.string, "en"),
    photo:types.optional(types.string, ""),
    description:types.optional(types.string, ""),
    relative_time:types.optional(types.string, ""),
    time: types.optional(types.number,0),
    rating: types.optional(types.number,0)
})


const blogCategories = types.model("blogCategories", {
    order:types.optional(types.number,0),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),  
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""),    
    keyName: types.optional(types.string,""),
    image: types.optional(types.string, '')
});

const blogArticles = types.model("blogArticles", {
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),    
    title_zh: types.optional(types.string, ""),    
    title_ko: types.optional(types.string, ""),    
    sub_title_en:types.optional(types.string, ""),
    sub_title_vi:types.optional(types.string, ""),
    sub_title_ja:types.optional(types.string, ""),
    sub_title_zh:types.optional(types.string, ""),
    sub_title_ko:types.optional(types.string, ""),
    slug:types.identifier,
    type:types.optional(types.string, "standard"),
    image:types.optional(types.string, ""),
    imageStyle:types.optional(types.string, 'fullWidth'),
    text_en: types.optional(types.string, ""),
    text_vi: types.optional(types.string, ""),
    text_ja: types.optional(types.string, ""),    
    text_zh: types.optional(types.string, ""),    
    text_ko: types.optional(types.string, ""),    
    categories: types.optional(types.array(types.string),[]),
    tags:types.optional(types.array(types.string),[]),
    date: types.optional(types.string, ''),
    createdAt: types.optional(types.string, '')
})

const instagram_feed_item = types.model("instagram_feed_item", {
    id: types.optional(types.string,""),
    code: types.optional(types.string,""),
    thumbnail: types.optional(types.string,""),
    shortcode: types.optional(types.string,""),
    image: types.optional(types.string,""),
    caption: types.optional(types.string,""),
    likes:types.optional(types.number,0),
    video_views:types.optional(types.number,0)
});

const location = types.model("location", {
    _id : types.optional(types.string,''),
    title : types.optional(types.string,''),
    description : types.optional(types.string,''),
    locationImage: types.array(types.optional(types.string,'')),
    phone : types.optional(types.string, ""),
    email : types.optional(types.string, ""),
    open:types.optional(types.boolean,true),
    defaultLocation:types.optional(types.boolean,false),
    storeHoursInfos:types.optional(types.array(openHours),[]),
    deliveryHoursInfos:types.optional(types.array(openHours),[]),
    closedDates:types.optional(types.array(types.string),[]),
    deliveryAreas:types.array(deliveryAreas),    
    duration: types.optional(types.number,0),
    distance: types.optional(types.number,0),
    disableDelivery:types.optional(types.boolean,false),
    address: address
}).actions(self => ({
    updateDuration(duration) {
        self.duration = parseInt(duration,10)
    },
    updateDistance(distance) {
        self.distance = parseFloat(distance)
    },
}))

const bank = types.model("bank", {
    account_name: types.optional(types.string,""),
    account_number: types.optional(types.string,""),
    bank_name: types.optional(types.string,""),
    bank_city: types.optional(types.string,""),
    bank_branch: types.optional(types.string,"")
});

const otherMarkets = types.model("otherMarkets", {
    name: types.optional(types.string,""),
    link: types.optional(types.string,""),
    image: types.optional(types.string,"")
});

export const Store = types.model("Store", {
  _id : types.optional(types.string,""),
  uuid : types.optional(types.string,""),
  marketplace: types.optional(types.string,""),
  title : types.optional(types.string,""),
  title_en: types.optional(types.string, ""),
  title_vi: types.optional(types.string, ""),
  title_ja: types.optional(types.string, ""),
  title_zh: types.optional(types.string, ""),    
  title_ko: types.optional(types.string, ""),
  description : types.optional(types.string,""),
  description_en: types.optional(types.string, ""),
  description_vi: types.optional(types.string, ""),
  description_ja: types.optional(types.string, ""),
  description_zh: types.optional(types.string, ""),    
  description_ko: types.optional(types.string, ""),
  featured: types.optional(types.boolean,false),
  story_en: types.optional(types.string, ""),
  story_vi: types.optional(types.string, ""),
  story_ja: types.optional(types.string, ""),
  story_zh: types.optional(types.string, ""),    
  story_ko: types.optional(types.string, ""),
  storyImages: types.optional(types.array(types.optional(types.string, '')),[]),
  categories: types.optional(types.array(categories),[]),
  tags: types.optional(types.array(tags), []),
  types: types.optional(types.array(types.string),[]),
  menuImages: types.optional(types.array(types.string),[]),
  rating: types.optional(types.number,0),
  noReviews: types.optional(types.number,0), 
  reviews: types.optional(types.array(review),[]),
  blogArticles: types.optional(types.array(blogArticles),[]),
  blogCategories: types.optional(types.array(blogCategories),[]),
  freeDelivery: types.optional(types.boolean,false),
  analytics: types.optional(types.string,""),
  priceBucket: types.optional(types.string,""),
  minimumOrderAmount: types.optional(types.number,0),
  logoImageUrl: types.optional(types.string,""),
  mapImageUrl: types.optional(types.string,""),
  heroImageUrl: types.optional(types.string,""),
  heroVideoUrl: types.optional(types.string,""),
  heroVideoLogo: types.optional(types.string,""),
  location: types.array(location),
  regionId: types.optional(types.number,0),
  notOrderableMessage: types.optional(types.string,""),
  etaRange: types.optional(etaRange, {}),  
  isOrderable: types.optional(types.boolean,true),
  isStoreMenuOpen: types.optional(types.boolean,true),
  isStoreVisible: types.optional(types.boolean,true),
  isOnMarketplace:types.optional(types.boolean,true),
  recomMarketplace:types.optional(types.boolean,false),
  storyMarketplace:types.optional(types.boolean,false),
  isFullSite: types.optional(types.boolean,false),
  languages: types.optional(types.array(types.string),["EN"]),
  notify: types.optional(types.string, '/audio/notify.mp3'),
  notify2: types.optional(types.string, '/audio/applause.mp3'),
  layout: types.optional(layout, {}),  
  closedEtaMessage: types.optional(types.string,""),  
  OutOfStockMessage: types.optional(types.string,"Sorry we are currently out of stock."),
  preOrderMessage: types.optional(types.string,"This product is currently out of stock but you can order for tomorrow."),
  preOrderMinHours: types.optional(types.number,24),
  canScheduleOrder: types.optional(types.boolean,true),
  scheduleDaysAhead: types.optional(types.number,14),
  limitOrderLocation: types.optional(types.boolean,true),
  open:types.optional(types.boolean,true),
  slug: types.identifier,  
  heroImage: types.optional(heroImage,{items:[]}),
  website : types.optional(types.string, ""),
  domains: types.optional(types.array(types.string),[]),
  phone : types.optional(types.string, ""),
  email : types.optional(types.string, ""),
  facebook : types.optional(types.string, ""),
  facebookId : types.optional(types.string, ""),
  facebookLogin: types.optional(types.string, ""),
  facebookPixel : types.optional(types.string, ""),
  instagram : types.optional(types.string, ""),  
  instagramToken : types.optional(types.string, ""),
  instagram_feed: types.optional(types.array(instagram_feed_item),[]),
  showInvoiceDetails: types.optional(types.boolean, true),
  paymentMethods: types.optional(types.array(paymentMethod), [
        { slug: 'cash', text: 'Cash', icon: '' },
        { slug: 'paypal', text: 'Paypal', icon: '' },
        { slug: 'bank', text:'Bank', icon: '' }
  ]),
  bank: types.optional(bank,{}),
  youtube : types.optional(types.string, ""),
  tripadvisor:types.optional(types.string, ""),
  tripadvisorPlugin:types.optional(types.string, ""),
  otherMarkets : types.optional(types.array(otherMarkets),[]), 
  blogSkip  : 0,
  blogLimit  : 10,
  blogMore  : true,
  loading: false
})
.actions(self => {
  function getPage(slug) {
        var page =  self.layout.customPages.toJSON().filter(function(page) {
            return (page.slug.toLowerCase() === slug.toLowerCase());
        })
        if(page.length > 0){
            return page[0];
        }
  }

  function getPost(slug) {
        var post =  self.blogArticles.filter(function(post) {
            return (post.slug.toLowerCase() === slug.toLowerCase());
        })
        if(post.length > 0){
            return post[0];
        }else{
            return self.loadPosts(slug).then(function(post) {
                return post;
            })
        }
  }
  function getLocationAddress(id){
    var location = self.location.filter(function(loc) {
      return (loc._id === id);
    });
    if(location.length > 0 ){
        return getLocation().address.label_address
    }else
        return null;
  }

  function getLocationTitle(id){
    var location = self.location.filter(function(loc) {
      return (loc._id === id);
    });
    if(location.length > 0 ){
        return location[0].title;
    }else
        return null;
  }
  
  function updateVideoURL(value){    
    self.heroVideoUrl = value;
  }
  // function removeStore(){
  //   detach(self)
  // }
  function getLocation(suburb=''){
    var loc;
    suburb = removeDiacritics(suburb);
    self.location.forEach(function (location) {
      var found = location.deliveryAreas.filter(function(area) {
        return (area.name.toLowerCase().indexOf(suburb.toLowerCase()) !== -1);
      });
      if(found.length > 0 && typeof location !== "undefined")
        loc = location;
    })
    if(!loc){
        loc = self.location.filter(function(location) {
            return (location.defaultLocation);
        });        
    }
    if(loc.length > 0)
        loc = loc[0]
    return loc;
  }

  function updateBlogArticles(posts){    
    self.blogArticles = posts;
    self.updateLoading(false);
    return self.blogArticles;
  }
  function addSingleBlogArticles(posts){    
    posts.forEach(function (post) {
        self.blogArticles.push(post);
    })
    self.updateLoading(false);
    return posts[0];
  }
  function addBlogArticles(posts){    
    posts.forEach(function (post) {
        self.blogArticles.push(post);
    })
    self.updateLoading(false);
    return self.blogArticles;
  }
  function updateBlogSkip(value){   
    self.blogSkip = value;
  }
  function updateBlogMore(status){
    self.blogMore = status;
  }
  function updateLoading(status){   
    self.loading = status;
  }
  function loadPosts(slug='',category='', clear, search=''){
    if(clear)
        self.updateBlogArticles([])

    if(self.blogMore && self.blogArticles.length > 0 && slug ==='')
        self.updateBlogSkip((self.blogArticles.length + self.blogSkip))

    var region  = "";

    if(slug ==='')
        self.updateLoading(true);

    
    setTimeout(function() {
        self.updateLoading(false);
    }, 500);

    var apiAddress = getParent(self, 2).shop.apiAddress;
    
    try{
        return axios.get(apiAddress + '/api/stores/blog?search=' + search + '&category=' + category + '&store=' + self.slug + '&region=' + region + '&domain='+self.slug + '&marketplace=' + self.marketplace + '&postSlug=' + slug + '&limit=' + self.blogLimit + '&skip=' + self.blogSkip + '&token=b3edbfa1-df84-11e8-8a08-4f825ac6f139').then( response => {
            if(!response.data || !response.data.blogArticles.length > 0){
                self.updateBlogMore(false);
                self.updateLoading(false);
            }

            if(response.data && typeof response.data.blogArticles !== "undefined"){
                if(response.data.blogArticles.length > 1 && clear === true){
                    return self.updateBlogArticles(response.data.blogArticles);
                }else if(response.data.blogArticles.length === 1 && slug !== ''){
                    self.addSingleBlogArticles(response.data.blogArticles);
                }else if(response.data.blogArticles.length > 0){
                    return self.addBlogArticles(response.data.blogArticles);                                    
                }
            }
        });
    }catch(error){
        console.log(error)
        return false;
    }
  }
  function updateInsta(feed){    
    self.instagram_feed = feed;
  }
  function updateDuration(value){    
    self.getLocation().updateDuration(value);
  }
  function updateDistance(value){    
    self.getLocation().updateDistance(value);
  }
  return {getPost,getPage,updateLoading,updateBlogArticles,updateBlogSkip,updateBlogMore,addBlogArticles,addSingleBlogArticles,loadPosts,updateVideoURL,updateInsta, updateDuration,updateDistance,getLocation,getLocationAddress, getLocationTitle}
})



export const StoreStore = types
    .model("StoreStore", {
        loading: true,
        stores: types.map(Store),
        budget: "",
        searchValue: "",
        search: types.optional(types.array(types.string),[]),   
        singleSearch: '',
        more: true,
        skip :0,
        limit:24,
        matching_item_limit: 48,
    })
    .views(self => ({
        get noSearchStores(){
          if(self.search.length > 0)
              return noSearchStores(Array.from(self.stores.values()), self.search,self.budget);
            
            return noSearchStores(Array.from(self.stores.values()), self.singleSearch,self.budget);
        },
        get shop() {
            return getParent(self)
        },
        get isLoading() {
            return self.loading
        },
        get getSearch() {
            return self.searchValue
        },
        get searchedAvailableStores() {
          if(self.search.length > 0)
            return searchStores(Array.from(self.stores.values()), self.search, self.budget, self.matching_item_limit,true);
          
          return searchStores(Array.from(self.stores.values()), self.singleSearch,self.budget, self.matching_item_limit,true);                        
        },
        get searchedFeaturedStores() {
          return Array.from(self.stores.values()).filter(function(str1) { return ( str1.recomMarketplace === true) });                     
        },
        get searchedStoryStores() {
          return Array.from(self.stores.values()).filter(function(str2) { return ( str2.storyMarketplace === true) });                     
        },
        get storeAvailableStores() {
          return storeStores(Array.from(self.stores.values()), self.searchValue)
        },
        get sortedAvailableStores() {
          return sortStores(Array.from(self.stores.values()))
        },
    }))
    .actions(self => {
        function deliveryCost(store){
          var cost = 0;
          if(typeof store === "undefined")
            return cost;

          store.location.forEach(function (location) {
            var areaCost = location.deliveryAreas.filter(function(area) {
              if(typeof self.shop.cart.address !== "undefined"){
                return (area.name.toLowerCase().indexOf(self.shop.cart.address.suburb.toLowerCase()) !== -1);
              }else{
                return false;
              }
            });
            if(areaCost.length > 0){
              cost = parseFloat(areaCost[0].toJSON().cost/100);
            }
          })
          return cost;
        }
        
        function addToSearch(value) {
            if(self.search.indexOf(value) > -1){
              self.search.remove(value)
            }else{
              self.search.push(value)
            }
        }
        function addBudget(value){
          if(self.budget === value){
            self.budget = ''
          }else{
            self.budget =value;
          }
        }
        function clearBudget() {
            self.budget = '';
        }
        function addSingleSearch(value){
          self.singleSearch = value;
        }
        function adjustSearch(value){
          self.search = value; 
        }
        function addSearch(value){
          adjustSearch(value)          
          self.singleSearch = ''
        }         
        function clearSearch() {
            self.singleSearch = ''
            self.search = []
        }

        function removeStore(store){
          detach(store)
        }

        function markLoading(loading) {
            self.loading = loading
        }
        function markMore(more) {
            self.more = more
        }
        function increaseItemLimit() {
            self.matching_item_limit += 24;
        }

        function loadScroll() {
          if(self.more){
            self.skip += self.limit
            self.loadStores(true);
          }
        }

        function getInstagramFeed(address){
            
          var nodes; var feed;
          if(self.shop.view && self.shop.view.selectedStore && self.shop.view.selectedStore.instagram_feed.length <=0){
            // axios.get('https://www.instagram.com/' + address.replace('https://www.instagram.com/','').replace('instagram.com/','').replace('https://','')).then( data => {
            try{
                return axios.get('https://api.instagram.com/v1/staff/self/media/recent?access_token=' + self.shop.view.selectedStore.instagramToken).then( data => {
                    feed = [];
                    var i = 0;
                    data.data.data.map(function (node) {
                        if(i < 18){
                            feed.push({
                                  id:node.id,
                                  code:node.caption.text,
                                  thumbnail:node.images.thumbnail.url,
                                  image:node.images.standard_resolution.url,
                                  shortcode: node.link,
                                  caption:node.caption.text,
                                  likes:node.likes.count,
                                  video_views:0
                                })
                            i++;
                        }
                        return true;
                    });
                    self.shop.view.selectedStore.updateInsta(feed);
                    return feed;
                  // }else if(typeof insta_json['entry_data']['LocationsPage'] !== "undefined" && insta_json['entry_data']['LocationsPage'].length > 0){
                  //   nodes =  insta_json['entry_data']['LocationsPage'][0]['graphql']['location']['edge_location_to_media']['edges'];
                  //   feed = [];
                  //       nodes.map(function (node) {
                  //         node = node.node;
                  //         var caption = "";
                  //         if(node.edge_media_to_caption.edges.length > 0)
                  //           caption = node.edge_media_to_caption.edges[0]['node'].text

                  //         feed.push({
                  //                     id:node.id,
                  //                     code:node.code,
                  //                     thumbnail:node.thumbnail_src,
                  //                     image:node.display_url,
                  //                     shortcode: node.shortcode,
                  //                     caption:caption,
                  //                     likes:node.edge_liked_by.count,
                  //                     video_views:node.video_views
                  //                   })
                  //         return true;
                  //       });
                  //       self.shop.view.selectedStore.updateInsta(feed);
                  //       return true;
                  // }else{
                  //   return false;
                  // }
                }).catch(function (error) {
                  // console.log(error);
                });
            }catch(error){
                return false;
                console.log(error);
            }
          }
        }
        
        function parse_youtube_meta(rawdata) {
          var data = parse_str(rawdata),
            streams = (data.url_encoded_fmt_stream_map + ',' + data.adaptive_fmts).split(','),
            result = {};
            streams.forEach(function (s,n) {
              var stream = parse_str(s + ''),
                  itag = stream.itag * 1,
                  itag_map = {
                      18: '360p',
                      22: '720p',
                      37: '1080p',
                      38: '3072p',
                      82: '360p3d',
                      83: '480p3d',
                      84: '720p3d',
                      85: '1080p3d',
                      133: '240pna',
                      134: '360pna',
                      135: '480pna',
                      136: '720pna',
                      137: '1080pna',
                      160: '144pna',
                      264: '1440pna',
                      139: "48kbps",
                      140: "128kbps",
                      141: "256kbps"
                  };
              if (itag_map[itag]) result[itag_map[itag]] = stream.url;
          });
          return result;
      }

      function parse_str(str) {
          return str.split('&').reduce(function(params, param) {
              var paramSplit = param.split('=').map(function(value) {
                  return decodeURIComponent(value.replace('+', ' '));
              });
              params[paramSplit[0]] = paramSplit[1];
              return params;
          }, {});
      }

      function loadyoutubeVideo(vid, store_slug, index = false){
        var streams;
        try{
            axios.get('https://query.yahooapis.com/v1/public/yql', {
                params: {
                  q: "select * from csv where url='https://www.youtube.com/get_video_info?video_id=" + vid + "'",
                  format: "json"
                }
            }).then( data => {
                  if (data.data.query.results) {
                      streams = parse_youtube_meta(data.data.query.results.row.col0);
                      var url = streams['480pna'];
                      // if(Math.round(navigator.connection.downlink) > 5)
                        // url = streams['720pna'];
                      
                      if(typeof url === "undefined")
                        url = streams['360pna']

                      if(typeof url === "undefined")
                        url = streams['360p']

                      if(index){
                        if(self.stores.get(store_slug))
                          self.stores.get(store_slug).heroImage.items[index].updateURL(url);
                      }else{
                        if(self.stores.get(store_slug))
                          self.stores.get(store_slug).updateVideoURL(url);
                      }

                  }
            });
        }catch(error){
            console.log(error)
        }
      }

      function contactForm(form){
        try{
            form['token'] = self.shop.staff.token;
            form['atoken'] = self.shop.token;
            form['store'] = self.shop.view.selectedStoreAddress;
            

            return axios.post(self.shop.apiAddress + '/api/stores/contact',form).then( response => {
                return response;
            });
        } catch (err) {
           console.error(" ", err)
        }
      }

      function updateStores(json,append = false) {
        // remove old stores
        if(!append)
            self.stores.forEach((store,i) => { /*detach(store);*/self.removeStore(store); });
        
        try {
          json.forEach((storeJson,i) => { 
            storeJson.heroImage.items.forEach((item,j) => {  
              if(item.url.indexOf("youtube.com/") > -1){
                var id = item.url.split("=")[1];
                loadyoutubeVideo(id, storeJson.slug,j)
              }
            })
            if(storeJson.heroVideoUrl && storeJson.heroVideoUrl.indexOf("youtube.com/") > -1){
              var id = storeJson.heroVideoUrl.split("=")[1];
              loadyoutubeVideo(id, storeJson.slug)
            }
            if(storeJson.layout.font !== "" && storeJson.layout.font !== "sans-serif" && typeof window !=="undefined"){

                var WebFont = require('webfontloader');
                
                WebFont.load({
                    google: {
                      families: [storeJson.layout.font]
                    }
              });
            }
            if(storeJson.paymentMethods && storeJson.paymentMethods.length === 0)
                storeJson.paymentMethods= [{
                                            slug: "cash",
                                            text: "Cash",
                                            icon: ""
                                            }]


            self.stores.put(storeJson)
            if(!self.shop.isMarketplace){
                if(self.shop.view)
                    self.shop.view.updateStoreAddress(storeJson.slug)
                
                var lang = self.shop.staff.lang;
                
                self.shop.updateIcon(storeJson.logoImageUrl)
                if(lang){
                     self.shop.updateTitle(storeJson['title_' + lang])
                    self.shop.updateDescription(storeJson['description_' + lang])
                }else{
                    self.shop.updateTitle(storeJson.title)
                    self.shop.updateDescription(storeJson.description)
                }
            }            
            if(storeJson.instagram && storeJson.instagram !=='' && !self.shop.isMarketplace)
              self.getInstagramFeed(storeJson.instagram)
          })
        } catch (e) {
          console.log(e);
        }
        if(self.shop.cart)
            self.shop.cart.validateCartEntries();

        markLoading(false);
            // self.checkOpenHours()
        }

        function loadFont(){
            if(self.shop.view.selectedStore && self.shop.view.selectedStore.layout.font !== "" && self.shop.view.selectedStore.layout.font !== "sans-serif" && typeof window !=="undefined"){

                var WebFont = require('webfontloader');
                WebFont.load({
                    google: {
                      families: [self.shop.view.selectedStore.layout.font]
                    }
              });
            }
        }

        function afterCreate(){
            setTimeout(function() {
                if(typeof window !=="undefined")
                    self.loadFont();
            }, 500);
        }

        

      // function checkOpenHours(){
      //     var date = new Date();
      //     var day = date.toLocaleString('en-US', {weekday: 'long'});
      //     var time = date.getHours() + ":" + date.getMinutes();
          
      //     if(date.getHours() < 10) time = "0" + time;

      //     var tomorrow = new Date();
      //     tomorrow.setDate(date.getDate()+1);
      //     tomorrow = tomorrow.toLocaleString('en-US', {weekday: 'long'});
      //     var twodays = new Date();
      //     twodays.setDate(date.getDate()+2);
      //     twodays = twodays.toLocaleString('en-US', {weekday: 'long'});

      //     self.stores.forEach(function (store) {

      //        if(store.logoImageUrl){
      //           try{
      //               var palette = Vibrant.from(store.logoImageUrl)
      //               if(palette){
      //                   palette.getPalette().then((swatches) => {
      //                       if(swatches['Vibrant']){
      //                           var color = swatches['Vibrant'].getHex();
      //                           store.layout.updatePrimaryColor(color);
      //                       }
      //                   });
      //               }
      //           }catch(e){

      //           }
      //       }


      //       var closedMsg = store.title + ' is closed.'          
      //       var today = store.deliveryHoursInfos.filter(function(dh) { return dh.day === day})[0];
      //       var future = store.deliveryHoursInfos.filter(function(dh) { return dh.day === tomorrow})[0];
      //       if(future.startTime === '0')
      //         var futuretwo = store.deliveryHoursInfos.filter(function(dh) { return dh.day === twodays})[0];
                        
      //       if(time  < today.startTime){
      //         self.stores.get(store.slug).open = false;
      //         closedMsg =  store.title + " is not open yet. Opens today at " + today.startTime + '.';
      //       }else if((time  < today.startTime) || (time > today.endTime && future.startTime === '0')){
      //         self.stores.get(store.slug).open = false;
      //         closedMsg = store.title + " is closed. Opens " + futuretwo.day + " at " + futuretwo.startTime + '.';
      //       }else if(time  < today.startTime || time > today.endTime){
      //         self.stores.get(store.slug).open = false;
      //         closedMsg = store.title + " is closed. Opens tomorrow at " + future.startTime + '.';
      //       }else{
      //         self.stores.get(store.slug).open = true;
      //       }
      //       if(!self.stores.get(store.slug).open && store.canScheduleOrder)
      //         closedMsg += ' Place future orders only'
            

      //       self.stores.get(store.slug).closedEtaMessage = closedMsg
            
      //     });
      // }

      const loadStores = function loadStores(append = false) {           
          try{
            
            if(self.shop.staff.type.indexOf('marketplace') > -1 || self.shop.staff.stores.length > 0){
                
                var region  = "";
                var search = "";
                var domain = "";
                var stores = [];
                var gps = JSON.stringify([self.shop.marketplace.location.latitude, self.shop.marketplace.location.longitude]);

                if(!self.shop.isMarketplace)
                    domain = self.shop.domain;
                
                // if(self.shop.staff.addresses.length >0 && self.shop.marketplace.slug){
                if(self.shop.marketplace.slug){
                  if(typeof self.shop.staff.address !== "undefined")
                    region = self.shop.staff.address.suburb;            

                  if(self.shop.staff.address && typeof self.shop.staff.address['gps'] !== "undefined")
                      gps = JSON.stringify(self.shop.staff.address['gps'].toJSON().toString())

                    // ?search=' + search + '&store=' + store_address + '&skip='+self.skip + '&limit='+ self.limit+ '&region=' + region + '&gps='+gps +'&domain='+domain + '&marketplace=' + self.shop.marketplace.slug + '&atoken=' + self.shop.token
                    var data = {}; 
                    data['stores'] = stores;
                    data['staff'] = self.shop.staff._id;
                    data['token'] = self.shop.staff.token;
                    data['atoken'] = self.shop.token;
                    data['marketplace'] = self.shop.marketplace.slug;
                    data['domain'] = domain;
                    data['gps'] = gps;
                    // data['sort'] = sort;
                    data['region'] = region;
                    // data['skip'] = skip;
                    // data['limit'] = limit;
                    data['search'] = search;

                    try{
                        return axios.put(self.shop.apiAddress + '/api/stores/staff/' + self.shop.staff._id,data).then( response => {
                          if(response.data && response.data.stores.length > 0){
                            self.updateStores(response.data.stores,append);
                          }else{
                            self.markMore(false)
                            self.markLoading(false);
                          }
                        });
                    }catch(error){
                        return false;
                        console.log(error)
                    }
                  }else{
                    markLoading(false);
                  //   setTimeout(function() {

                  //     if(typeof self.shop.staff.addresses !== "undefined" && self.shop.staff.addresses.length > 0)
                  //       gps = JSON.stringify(self.shop.staff.address['gps'].toJSON().toString())

                  //     if(typeof self.shop.staff.address !== "undefined")
                  //       region = self.shop.staff.address.suburb;

                  //     axios.get(self.shop.apiAddress + '/api/stores?search=' + search + '&store=' + self.shop.view.selectedStoreAddress + '&skip='+self.skip + '&limit='+ self.limit+ '&region=' + region + '&gps='+gps +'&domain='+self.shop.domain + '&marketplace=' + self.shop.marketplace.slug + '&atoken=' + self.shop.token).then( response => {
                  //       if(response.data && response.data.length > 0){
                  //         self.updateStores(response.data);
                  //       }else{
                  //           self.stores.forEach((store,i) => {   
                  //               // detach(store)
                  //               self.removeStore(store)
                  //           });
                  //           self.markMore(false)
                  //           self.markLoading(false);
                  //         // self.markLoading(false)
                  //         // if(self.shop.domain !=='' && response.data.length === 0)
                  //           // window.location.href="https://onlyngon.com"
                  //       }
                  //     }); 
                  //   }, 750);
                  }   
                  setTimeout(function() {
                    if(typeof window !=="undefined" && !self.shop.isMarketplace && window.location.href.indexOf('3007') === -1 &&  !self.shop.view.selectedStore)
                         self.shop.reloadPage();
                  }, 3000);
            }
          } catch (err) {
              console.error("Failed to load stores", err)
          }
      }
      return {
        afterCreate,
        removeStore, 
        markMore,
        deliveryCost,        
        increaseItemLimit,
        addBudget,
        clearBudget,
        addSingleSearch,
        addSearch,
        addToSearch,
        clearSearch,
        updateStores,
        markLoading,
        loadScroll,
        loadStores,
        loadFont,
        getInstagramFeed,
        contactForm
      }
    })

function searchStores(stores, value, budget, matching_item_limit, sort = false) {
    if(typeof value === "object" && value.length > 0){
      return stores.filter(function(store) {
        return value.some(function(val){
          return (
              (budget === "" || store.priceBucket === budget) &&
              (store.title.toLowerCase().indexOf(val.toLowerCase()) !== -1 || 
                            store.types.some(function(vl, i) { 
                              if (vl.toLowerCase().indexOf(val.toLowerCase()) > -1)return true;
                              return false;
                            }) ||
                            store.tags.find(x => x.name.toLowerCase().indexOf(val.toLowerCase()) !== -1))
          );
        });
      }).slice(0, matching_item_limit);
    }else{
      stores =  stores.filter(function(store) {
        if(store.types && store['types'].length > 0){
          return (
            (budget === "" || store.priceBucket === budget) &&
            (store.title.toLowerCase().indexOf(value.toLowerCase()) !== -1 || 
                        store.types.some(function(val, i) { if (val.toLowerCase().indexOf(value.toLowerCase()) > -1)return true;
                          return false;}) ||
                        store.tags.find(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1))
          );
        }else{
          return (
              (budget === "" || store.priceBucket === budget) &&
              (store.title.toLowerCase().indexOf(value.toLowerCase()) !== -1 || 
                            store.tags.find(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1))
          );
        }
      }).slice(0, matching_item_limit);
    }

    if(sort)
      return stores
      .sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1))
      .sort((a, b) => (a.location.distance > b.location.distance ? 1 : a.location.distance === b.location.distance ? 0 : -1))
      .sort((a, b) => (a.open < b.open ? 1 : a.open === b.open ? 0 : -1))

    return stores;
}
function noSearchStores(stores, value,budget) {
    if(typeof value === "object" && value.length > 0){
      return stores.filter(function(store) {
        return value.some(function(val){
          return (
              (budget === "" || store.priceBucket === budget) ||
              (store.title.toLowerCase().indexOf(val.toLowerCase()) !== -1 ||               
                            store.types.some(function(vl, i) { if (vl.toLowerCase().indexOf(val.toLowerCase()) > -1)return true;
                              return false;}) ||
                            store.tags.find(x => x.name.toLowerCase().indexOf(val.toLowerCase()) !== -1))
          );
        });
      }).length;
    }else{
      return stores.filter(function(store) {
          return (
              (budget === "" || store.priceBucket === budget) ||
              (store.title.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||               
                            store.types.some(function(val, i) { if (val.toLowerCase().indexOf(value.toLowerCase()) > -1)return true;
                              return false;}) ||
                            store.tags.find(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1))
          );
      }).length;
    }
}
function storeStores(stores, value) {
    return stores
    .filter(function(store) {
        return (store.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    });
        // .filter(b => b.viewable)
        // .filter(b => b.name.indexOf(search) > -1 )
}
function sortStores(stores) {
    return stores
        .filter(b => b.viewable)
        .sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1))
}
function removeDiacritics (str) {

  var defaultDiacriticsRemovalMap = [
    {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
    {'base':'AA','letters':/[\uA732]/g},
    {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
    {'base':'AO','letters':/[\uA734]/g},
    {'base':'AU','letters':/[\uA736]/g},
    {'base':'AV','letters':/[\uA738\uA73A]/g},
    {'base':'AY','letters':/[\uA73C]/g},
    {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
    {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
    {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
    {'base':'DZ','letters':/[\u01F1\u01C4]/g},
    {'base':'Dz','letters':/[\u01F2\u01C5]/g},
    {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
    {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
    {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
    {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
    {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
    {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
    {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
    {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
    {'base':'LJ','letters':/[\u01C7]/g},
    {'base':'Lj','letters':/[\u01C8]/g},
    {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
    {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
    {'base':'NJ','letters':/[\u01CA]/g},
    {'base':'Nj','letters':/[\u01CB]/g},
    {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
    {'base':'OI','letters':/[\u01A2]/g},
    {'base':'OO','letters':/[\uA74E]/g},
    {'base':'OU','letters':/[\u0222]/g},
    {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
    {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
    {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
    {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
    {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
    {'base':'TZ','letters':/[\uA728]/g},
    {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
    {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
    {'base':'VY','letters':/[\uA760]/g},
    {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
    {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
    {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
    {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
    {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
    {'base':'aa','letters':/[\uA733]/g},
    {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
    {'base':'ao','letters':/[\uA735]/g},
    {'base':'au','letters':/[\uA737]/g},
    {'base':'av','letters':/[\uA739\uA73B]/g},
    {'base':'ay','letters':/[\uA73D]/g},
    {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
    {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
    {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
    {'base':'dz','letters':/[\u01F3\u01C6]/g},
    {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
    {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
    {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
    {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
    {'base':'hv','letters':/[\u0195]/g},
    {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
    {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
    {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
    {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
    {'base':'lj','letters':/[\u01C9]/g},
    {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
    {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
    {'base':'nj','letters':/[\u01CC]/g},
    {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
    {'base':'oi','letters':/[\u01A3]/g},
    {'base':'ou','letters':/[\u0223]/g},
    {'base':'oo','letters':/[\uA74F]/g},
    {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
    {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
    {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
    {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
    {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
    {'base':'tz','letters':/[\uA729]/g},
    {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
    {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
    {'base':'vy','letters':/[\uA761]/g},
    {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
    {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
    {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
    {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
  ];

  for(var i=0; i<defaultDiacriticsRemovalMap.length; i++) {
    str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
  }

   str= str.replace(/à|ạ|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a");  
   str= str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e");  
   str= str.replace(/ì|í|ị|ỉ|ĩ/g,"i");  
   str= str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o");  
   str= str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u");  
   str= str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y");  
   str= str.replace(/đ/g,"d");  
   
  return str;

}