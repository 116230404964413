import React from 'react'

class StepTwo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          firstname: props.getStore().firstname,
          lastname: props.getStore().lastname,
          phone: props.getStore().phone,
          dob: props.getStore().dob
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as staff fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

      isValidated() {
        const staffInput = this._grabStaffInput(); // grab staff entered vals
        const validateNewInput = this._validateData(staffInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getStore().firstname !== staffInput.firstname || this.props.getStore().lastname !== staffInput.lastname || this.props.getStore().phone !== staffInput.phone || this.props.getStore().dob !== staffInput.dob) { // only update store of something changed
              this.props.updateStore({
                ...staffInput,
                savedToCloud: false // use this to notify step4 that some changes took place and prompt the staff to save again
              });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }        
            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(staffInput, validateNewInput, this._validationErrors(validateNewInput)));
        }
        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
          return;

        const staffInput = this._grabStaffInput(); // grab staff entered vals
        const validateNewInput = this._validateData(staffInput); // run the new input against the validator
        this.setState(Object.assign(staffInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {
        return  {
            firstnameVal:  (data.firstname.length >  2), // required: anything besides N/A
            lastnameVal:  (data.lastname.length >  2), // required: anything besides N/A
            phoneVal:  (data.phone.length > 7 && data.phone.length < 20), // required: anything besides N/A
            dobVal:  (data.dob.length > 4) 
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            firstnameValMsg: val.firstnameVal ? '' : 'A valid firstname is required',
            lastnameValMsg: val.lastnameVal ? '' : 'A valid last name is required',
            phoneValMsg: val.phoneVal ? '' : 'Enter a valid phone number',
            dobValMsg: val.dobVal ? '' : 'Enter a valid date'      
        }
        return errMsgs;
    }

    _grabStaffInput() {
        return {
            firstname: this.refs.firstname.value,
            lastname: this.refs.lastname.value,
            phone: this.refs.phone.value,
            dob: this.refs.dob.value          
        };
    }

    render() {

        var shop = this.props.shop;
        var lang = shop.staff.lang;

        // explicit class assigning based on validation
        let notValidClasses = {};

        if (typeof this.state.lastnameVal === 'undefined' || this.state.lastnameVal) {
          notValidClasses.lastnameCls = 'no-error col-md-8';
        }
        else {
           notValidClasses.lastnameCls = 'has-error col-md-8';
           notValidClasses.lastnameValGrpCls = 'val-err-tooltip';
        }

        if (typeof this.state.firstnameVal === 'undefined' || this.state.firstnameVal) {
            notValidClasses.firstnameCls = 'no-error col-md-8';
        }
        else {
           notValidClasses.firstnameCls = 'has-error col-md-8';
           notValidClasses.firstnameValGrpCls = 'val-err-tooltip';
        }

        if (typeof this.state.phoneVal === 'undefined' || this.state.phoneVal) {
            notValidClasses.phoneCls = 'no-error col-md-8';
        }
        else {
           notValidClasses.phoneCls = 'has-error col-md-8';
           notValidClasses.phoneValGrpCls = 'val-err-tooltip';
        }

        if (typeof this.state.dobVal === 'undefined' || this.state.dobVal) {
            notValidClasses.dobCls = 'no-error col-md-8';
        }
        else {
           notValidClasses.dobCls = 'has-error col-md-8';
           notValidClasses.dobValGrpCls = 'val-err-tooltip';
        }
        var input_style = {"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"24px","width":"100%","padding":"18px 2px","background":"rgb(255, 255, 255)",border:'none',borderBottom:"1px solid rgb(229, 229, 228)","outline":"none","color":"rgb(0, 0, 0)","height":"44px","boxSizing":"border-box","borderRadius":"0px", marginBottom:10};
        return (
            <div>
              <div>
                <h4 style={{fontWeight:300,fontSize:20, marginBottom:20}}>{shop.marketplace.layout.getTitle('account',lang)}</h4>
                <p className="" style={{fontSize:14}}>{shop.marketplace.layout.getTitle('why_create_account',lang)}</p>
                <div className="row">
                    <div className="col-md-6">
                      <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('firstname',lang)}</label>
                        <input
                            ref="firstname"
                            autoComplete="off"
                            name="firstname"
                            type="text"
                            placeholder={shop.marketplace.layout.getTitle('firstname',lang) ? shop.marketplace.layout.getTitle('firstname',lang) : 'Firstname'}
                            className="form-control"
                            style={input_style}
                            required
                            defaultValue={this.state.firstname}
                            onBlur={this.validationCheck} />
                        <div className={notValidClasses.firstnameValGrpCls}>{this.state.firstnameValMsg}</div>
                    </div>
                    <div className="col-md-6">
                      <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('lastname',lang)}</label>
                      <input
                            ref="lastname"
                            autoComplete="off"
                            type="text"
                            name="lastname"
                            placeholder={shop.marketplace.layout.getTitle('lastname',lang) ? shop.marketplace.layout.getTitle('lastname',lang) : 'Lastname'}
                            className="form-control"
                            style={input_style}
                            required
                            defaultValue={this.state.lastname}
                            onBlur={this.validationCheck} />
                      <div className={notValidClasses.lastnameValGrpCls}>{this.state.lastnameValMsg}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                      <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('phone',lang)} </label>
                      <input
                            ref="phone"
                            autoComplete="off"
                            type="tel"
                            name="phone"
                            placeholder={shop.marketplace.layout.getTitle('phone',lang)}
                            className="form-control"
                            style={input_style}
                            required
                            defaultValue={this.state.phone}
                            onBlur={this.validationCheck} />
                      <div className={notValidClasses.phoneValGrpCls}>{this.state.phoneValMsg}</div>
                    </div>
                    <div className="col-md-6">
                      <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{!shop.marketplace.layout.getTitle('dob',lang) ? 'Birthday' : shop.marketplace.layout.getTitle('dob',lang) } </label>
                      <input
                            ref="dob"
                            autoComplete="off"
                            type="date"
                            name="dob"
                            placeholder={shop.marketplace.layout.getTitle('dob',lang)}
                            className="form-control"
                            style={input_style}
                            required
                            defaultValue={this.state.dob}
                            onBlur={this.validationCheck} />
                      <div className={notValidClasses.dobValGrpCls}>{this.state.dobValMsg}</div>
                    </div>
                </div>
              </div>
            </div>
        )
    }
}

export default StepTwo