import { types, applySnapshot, getEnv } from "mobx-state-tree";
// import { v1 as uuid } from "uuid";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Cookies from "universal-cookie";

import { MarketplaceStore } from "./MarketplaceStore";
import { StoreStore } from "./StoreStore";
import { StaffStore } from "./StaffStore";
import { CartStore } from "./CartStore";
import { ProductStore } from "./ProductStore";
import { ViewStore } from "./ViewStore";

let shop = null;

const snackMessage = types
    .model("snackMessage", {
        title: types.optional(types.string, ""),
        message: types.optional(types.string, ""),
        link: types.optional(types.string, ""),
        position: types.optional(types.string, "tr"),
    })
    .actions((self) => ({
        clear() {
            self.title = "";
            self.message = "";
        },
    }));

const Store = types
    .model({
        marketplace: types.optional(MarketplaceStore, {}),
        staff: types.optional(StaffStore, {
            favourites: [],
        }),
        storeStore: types.optional(StoreStore, {
            stores: {},
        }),
        cart: types.optional(CartStore, {
            entries: [],
            discounts: [],
        }),
        productStore: types.optional(ProductStore, {
            products: {},
        }),
        view: types.optional(ViewStore, {}),
        light: false,
        snackMessage: types.optional(snackMessage, {}),
        icon: "https://static.thaodien.me/images/tasty_logo.png",
        title: types.optional(types.string, ""),
        description: types.optional(types.string, ""),
        href: types.optional(types.string, ""),
        domain: types.optional(types.string, ""),
        isMarketplace: false,
        token: types.optional(types.string, ""),
        imageDirectory: types.optional(types.string, "https://static.thaodien.me"),
        apiAddress: types.optional(types.string, "http://localhost:3001"),
        loading: false,
    })
    .views((self) => ({
        get fetch() {
            return getEnv(self).fetch;
        },
        get isLoading() {
            return (
                self.loading == true ||
                self.productStore.isLoading === true ||
                self.storeStore.isLoading === true ||
                self.cart.isLoading === true ||
                self.staff.isLoading === true ||
                self.marketplace.isLoading === true
            );
        },
        get products() {
            return self.productStore.products;
        },
        get stores() {
            return self.storeStore.stores;
        },
        get selectedStoreAddress() {
            return self.view.selectedStoreAddress;
        },
        get sortedAvailableProducts() {
            return self.productStore.sortedAvailableProducts;
        },
    }))
    .actions((self) => ({
        markLoading(loading) {
            self.loading = loading;
        },
        updateIsMarketplace(value) {
            self.isMarketplace = value;
        },
        alert(
            title = "",
            message = "",
            link = "",
            position = "tr",
            timeout = 2000
        ) {
            var uid = uuidv4();
            self.snackMessage = {
                uid: uid,
                title: title,
                message: message,
                link: link,
                position: position,
            };

            setTimeout(function() {
                self.snackMessage.clear();
            }, timeout);
        },
        clearAlert() {
            self.snackMessage.clear();
        },
        updateTitle(title) {
            self.title = title;
        },
        updateDescription(description) {
            self.description = description;
        },
        updateIcon(icon) {
            self.icon = icon;
        },
        updateToken(token) {
            self.token = token;
            const cookies = new Cookies();
            cookies.set("access_token", token, { path: "/" });
        },
        updateApiAddress(address) {
            self.apiAddress = address;
        },
        updateDomain(domain) {
            self.domain = domain;
        },
        setLocalStorage(label, value) {
            if (typeof window !== "undefined") {
                window.localStorage.setItem(label, value);
            } else {
                return false;
            }
        },
        getLocalStorage(label) {
            if (typeof window !== "undefined") {
                return window.localStorage.getItem(label);
            } else {
                return false;
            }
        },
        removeLocalStorage(label) {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(label);
            } else {
                return false;
            }
        },
        setLocationValue(label, value) {
            if (typeof window !== "undefined") {
                window.location[label] = value;
            } else {
                return false;
            }
        },
        getLocationValue(label) {
            if (typeof window !== "undefined") {
                return window.location[label];
            } else {
                return "";
            }
        },
        getWindowValue(label) {
            if (typeof window !== "undefined") {
                return window[label];
            } else {
                return "";
            }
        },
        setWindowValue(label, value) {
            if (typeof window !== "undefined") {
                window[label] = value;
            } else {
                return false;
            }
        },
        updateSiteDomain() {},
        reloadPage() {
            // if(typeof window !=="undefined")
            // window.location.reload();
        },
        afterCreate() {
            // Update api if in browser
            if (typeof window !== "undefined" && window.location.port !== "3007")
                self.apiAddress = "https://api.thaodien.me";
        },
    }));

export function initStore(snapshot = null, shopObj = {}) {
    // if (!typeof window !=="undefined") {
    if (shopObj.shop) shop = Store.create(shopObj.shop);

    if (
        shopObj.featuredProducts &&
        typeof shopObj.featuredProducts !== "undefined" &&
        shopObj.featuredProducts.length > 0
    )
        shop.productStore.updateProducts(shopObj.featuredProducts);

    if (shopObj.marketplace && typeof shopObj.marketplace.slug !== "undefined") {
        shop.marketplace.updateMarketplace(shopObj.marketplace);
    }

    // if(shopObj.shop && shopObj.shop.lang !== "")
    // shop.staff.setLang(shopObj.shop.lang);

    if (shopObj.stores && shopObj.stores.length > 0) {
        shop.storeStore.updateStores(shopObj.stores);
    } else {
        shop.storeStore.markLoading(false);
    }

    if (shopObj.product && shopObj.product.length > 0) {
        shop.productStore.updateProducts(shopObj.product);
    } else {
        shop.productStore.markLoading(false);
    }

    // }
    if (shop === null) {
        shop = Store.create();
    }
    if (snapshot) {
        applySnapshot(shop, snapshot);
    }

    if (typeof window !== "undefined" && window.location.port !== "3007")
        shop.updateApiAddress("https://api.thaodien.me");

    if (typeof window !== "undefined" && window.location.port === "3007")
        window.shop = shop;

    return shop;
}

// function initializeStore(shopObj, snapshot = null) {
//   if (shopObj && shopObj.!shop.isMarketplace && snapshot === null && typeof shopObj.shop != "undefined") {
//     // console.log(shopObj.shop)
//     shop = Store.create(shopObj.shop)
//     if(shopObj.marketplace && typeof shopObj.marketplace.slug !== "undefined")
//         shop.marketplace.updateMarketplace(shopObj.marketplace);

//     if(shopObj.stores && shopObj.stores.length > 0)
//         shop.storeStore.updateStores(shopObj.stores);

//     if(shopObj.product && shopObj.product.length > 0)
//         shop.productStore.updateProducts(shopObj.product);
//   }

//   if (shop === null)
//     shop = Store.create()

//   if (snapshot)
//     applySnapshot(shop, snapshot)
//     // console.log('applySnapshot',Date.now())

//   if(typeof window !=="undefined")
//     window.shop = shop;

//   return shop
// }

function getDomain(href) {
    var domain;
    var imageDirectory = "https://static.thaodien.me";
    var lang = "en";
    var title = "";
    var region = "";
    var apiAddress = "http://localhost:3001";
    if (typeof window !== "undefined" && window.location.port !== "3007")
        apiAddress = "https://api.thaodien.me";

    if (window.location.host.indexOf("192.168.") > -1)
        apiAddress = "http://" + window.location.host.replace("3007", "3001");

    var main_domain = [
        "www.tastysaigon.com",
        "tastysaigon.com",
        "www.thaodien.me",
        "www.sweets.vn",
    ];
    var region_sub_domains = ["thao-dien", "melbourne"];

    var main_domain_filter = main_domain.filter((s) => href.includes(s));

    if (main_domain_filter.length > 0) {
        main_domain = main_domain_filter[0];
        // self.updateIsMarketplace(true);
    }

    domain = href.split("." + main_domain[href.indexOf(main_domain)])[0];

    if (
        href.indexOf(main_domain) === -1 &&
        href.indexOf("localhost") === -1 &&
        href.indexOf("192.168.") === -1 &&
        href.indexOf("127.0.0.1") === -1
    ) {
        domain = href.split(":")[0];
        if (
            domain.indexOf("en.") === 0 ||
            domain.indexOf("vi.") === 0 ||
            domain.indexOf("ja.") === 0 ||
            domain.indexOf("ko.") === 0 ||
            domain.indexOf("zh.") === 0
        ) {
            lang = domain.substring(domain.indexOf(".") - 2, domain.indexOf("."));
            domain = domain
                .replace("en.", "")
                .replace("vi.", "")
                .replace("ja.", "")
                .replace("ko.", "")
                .replace("zh.", "");
        }
    } else if (
        href.indexOf(main_domain) >= 0 &&
        href.indexOf("www." + main_domain) === -1 &&
        href.indexOf("thao-dien") === -1 &&
        href.indexOf("melbourne") === -1
    ) {
        domain = href.split(":")[0];

        if (
            (domain.indexOf("en") === 0 ||
                domain.indexOf("vi") === 0 ||
                domain.indexOf("ja") === 0 ||
                domain.indexOf("ko") === 0 ||
                domain.indexOf("zh") === 0) &&
            href.split(".").length <= 3
        ) {
            lang = domain.substring(domain.indexOf(".") - 2, domain.indexOf("."));
        } else {
            var domain = domain
                .replace("en.", "")
                .replace("vi.", "")
                .replace("ja.", "")
                .replace("ko.", "")
                .replace("zh.", "");
            title = domain;
        }
    } else if (region_sub_domains.indexOf(domain) > -1) {
        domain.split("-").forEach(function(string, index) {
            string = string.charAt(0).toUpperCase() + string.slice(1);
            region += string + " ";
        });
    }

    if (href.indexOf("file:///") > -1) {
        domain = "cheesecake-ngon";
        title = "Cheescake Ngon";
        imageDirectory = "file:///android_asset/www";
    }
    return {
        href: href,
        domain: domain,
        region: region,
        title: title,
        imageDirectory: imageDirectory,
        apiAddress: apiAddress,
    };
}
// import { types, applySnapshot, getEnv } from 'mobx-state-tree'
// import uuid from 'uuid'
// import axios from 'axios';
// import Cookies from 'universal-cookie';

// import { MarketplaceStore } from "./MarketplaceStore"
// import { StoreStore } from "./StoreStore"
// import { StaffStore } from "./StaffStore"
// import { CartStore } from "./CartStore"
// import { ProductStore } from "./ProductStore"
// import { ViewStore } from "./ViewStore"

// let shop = null;
// let timer;

// const snackMessage = types.model("snackMessage", {
//     title:types.optional(types.string,''),
//     message: types.optional(types.string,''),
//     link: types.optional(types.string,''),
//     position: types.optional(types.string,'tr')
// }).actions(self => ({
//     clear(){
//         self.title = "";
//         self.message = "";
//     }
// }));

// const Store = types
//   .model({
//     marketplace: types.optional(MarketplaceStore, {}),
//     staff: types.optional(StaffStore, {
//           favourites: []
//     }),
//     storeStore: types.optional(StoreStore, {
//         stores: {}
//     }),
//     cart: types.optional(CartStore, {
//         entries: [],
//         discounts: []
//     }),
//     productStore: types.optional(ProductStore, {
//         products: {}
//     }),
//     view: types.optional(ViewStore, {}),
//     lastUpdate: types.Date,
//     light: false,
//     snackMessage: types.optional(snackMessage, {}),
//     icon: '/images/tasty_logo.png',
//     title:types.optional(types.string,''),
//     description:types.optional(types.string,''),
//     href: types.optional(types.string,''),
//     domain :types.optional(types.string,''),
//     token: types.optional(types.string,''),
//     imageDirectory:types.optional(types.string,'https://static.thaodien.me'),
//     apiAddress:types.optional(types.string,'https://api.thaodien.me'),
//     loading:true,
//     isServer:types.optional(types.boolean,false),
//     loads: types.optional(types.array(types.optional(types.string, '')),[])
//   })
//   .views(self => ({
//       get fetch() {
//           return getEnv(self).fetch
//       },
//       get isLoading() {
//           return (self.loading == true || self.productStore.isLoading === true || self.storeStore.isLoading === true  || self.cart.isLoading === true || self.staff.isLoading === true || self.marketplace.isLoading === true)
//       },
//       get products() {
//           return self.productStore.products
//       },
//       get stores() {
//           return self.storeStore.stores
//       },
//       get selectedStoreAddress() {
//           return self.view.selectedStoreAddress
//       },
//       get sortedAvailableProducts() {
//           return self.productStore.sortedAvailableProducts
//       }
//   }))
//   .actions(self => ({
//     start () {
//       timer = setInterval(() => {
//         // mobx-state-tree doesn't allow anonymous callbacks changing data
//         // pass off to another action instead
//         self.update()
//       }, 1000)
//     },
//     update () {
//       self.lastUpdate = Date.now()
//       self.light = true
//     },
//     stop () {
//       clearInterval(timer)
//     },

//     markLoading(loading) {
//         self.loading = loading
//     },
//     alert(title = '',message = '',link = "",position = "tr", timeout=2000) {
//         var uid = uuidv4();
//         self.snackMessage = {
//             uid: uid,
//             title: title,
//             message: message,
//             link: link,
//             position: position
//         };

//         setTimeout(function() {
//             self.snackMessage.clear();
//         }, timeout);
//     },
//     clearAlert(){
//         self.snackMessage.clear();
//     },
//     updateTitle(title){
//         self.title = title;
//     },
//     updateDescription(description){
//         self.description = description;
//     },
//     updateIcon(icon){
//         self.icon = icon;
//     },
//     updateToken(token){
//         self.token = token;
//         const cookies = new Cookies();
//         cookies.set(('access_token'), token, { path: '/'});
//     },
//     updateApiAddress(address){
//         self.apiAddress = address;
//     },
//     updateDomain(domain){
//         self.domain = domain;
//     },
//     setLocalStorage(label,value){
//         if(typeof window !=="undefined"){
//             window.localStorage.setItem(label,value);
//         }else{
//             return false;
//         }
//     },
//     getLocalStorage(label){
//         if(typeof window !=="undefined"){
//             return window.localStorage.getItem(label);
//         }else{
//             return false;
//         }
//     },
//     removeLocalStorage(label){
//         if(typeof window !=="undefined"){
//             window.localStorage.removeItem(label);
//         }else{
//             return false;
//         }
//     },
//     setLocationValue(label,value){
//         if(typeof window !=="undefined"){
//             window.location[label] = value;
//         }else{
//             return false;
//         }
//     },
//     getLocationValue(label){
//         if(typeof window !=="undefined"){
//             return window.location[label];
//         }else{
//             return '';
//         }
//     },
//     getWindowValue(label){
//         if(typeof window !=="undefined"){
//             return window[label];
//         }else{
//             return false;
//         }
//     },
//     setWindowValue(label,value){
//         if(typeof window !=="undefined"){
//             window[label] = value;
//         }else{
//             return false;
//         }
//     },
//     updateSiteDomain(){

//     },
//     reloadPage(){
//         if(typeof window !=="undefined")
//             location.reload();
//     },
//     afterCreate() {
//         var date = new Date();
//         var platform = 'Server';
//         if(typeof window !=="undefined")platform = 'Client';
//         self.loads.push(platform + ' - ' + date)
//         // self.updateApiAddress('https://api.thaodien.me');
//         // self.updateSiteDomain();
//     }
// }))

// export function initializeStore (isServer, domain, snapshot = null) {
//     var apiAddress = 'https://api.thaodien.me';
//     isServer = !typeof window !=="undefined";
//     if (isServer) {
//         var createObject = getDomain(domain);
//         createObject['apiAddress'] = apiAddress;
//         createObject['lastUpdate'] = Date.now();
//         axios.get(apiAddress + '/api/staff-auth/accesstoken?domain=' + createObject.domain + '&marketplace=').then( response => {
//             if(response.data && response.data.token && response.data.token !== "")
//                 createObject['token'] = response.data.token;
//             shop = Store.create(createObject)
//         });
//     }
//   if (shop === null) {
//     shop = Store.create({lastUpdate: Date.now(), href:domain})
//   }
//   if (snapshot) {
//     console.log(isServer,snapshot, snapshot.lastUpdate)
//     applySnapshot(shop, snapshot)
//   }

//   if(!isServer)
//     window.shop = shop;

//   return shop
// }

// function getDomain(href){
//     var domain;
//     var imageDirectory = "https://static.thaodien.me";
//     var lang = "en";
//     var title = "";
//     var region = "";
//     var main_domain = ['tastysaigon.com','tastyd2.com','sweets.vn'];
//     var region_sub_domains = ['thao-dien','melbourne'];

//     var main_domain_filter = main_domain.filter(s => href.includes(s));

//     if(main_domain_filter.length > 0)
//         main_domain= main_domain_filter[0];

//     domain = href.split('.'+main_domain[href.indexOf(main_domain)])[0];

//     if(href.indexOf(main_domain) === -1 && href.indexOf("localhost") === -1 && href.indexOf("192.168.") === -1 && href.indexOf("127.0.0.1") === -1){
//         domain = href.split(":")[0];
//         if(domain.indexOf('en.') === 0 || domain.indexOf('vi.') === 0 || domain.indexOf('ja.') === 0 || domain.indexOf('ko.') === 0 || domain.indexOf('zh.') === 0){
//             lang = domain.substring(domain.indexOf('.')-2,domain.indexOf('.'));
//             domain = domain.replace('en.','').replace('vi.','').replace('ja.','').replace('ko.','').replace('zh.','')
//         }
//     }else if(href.indexOf(main_domain) >= 0 && href.indexOf("www." + main_domain) === -1 && href.indexOf("thao-dien") === -1 && href.indexOf("melbourne") === -1 ){
//             domain = href.split(":")[0];

//         if((domain.indexOf('en') === 0 || domain.indexOf('vi') === 0 || domain.indexOf('ja') === 0 || domain.indexOf('ko') === 0 || domain.indexOf('zh') === 0) && href.split('.').length <= 3){
//             lang = domain.substring(domain.indexOf('.')-2,domain.indexOf('.'))
//         }else{
//             var domain = domain.replace('en.','').replace('vi.','').replace('ja.','').replace('ko.','').replace('zh.','');
//             title = domain;
//         }
//     }else if(region_sub_domains.indexOf(domain) > -1){
//         domain.split('-').forEach(function(string,index) {
//             string = string.charAt(0).toUpperCase() + string.slice(1);
//             region += string + ' ';
//         });
//     }

//     if(href.indexOf('file:///') > -1){
//         domain = "cheesecake-ngon";
//         title = "Cheescake Ngon";
//         imageDirectory = 'file:///android_asset/www';
//     }

//     return {href:href,domain:domain,region:region,title:title,imageDirectory:imageDirectory}
// }

// // export function initializeStore (isServer, snapshot = null) {
// //   console.log('initializeStore')
// //   if (isServer) {
// //     const shop = store.create(
// //         {},
// //         {
// //             alert: m => console.log(m)
// //         }
// //     )
// //   }
// //   console.log(shop)
// //   if (shop === null) {
// //     var address = 'https://api.thaodien.me';
// //     if(href.indexOf('3007') === -1){
// //       address = 'https://api.thaodien.me'
// //     }else if(href.indexOf('localhost')=== -1){
// //       address = self.getLocationValue('protocol') + '//' + self.getLocationValue('hostname') + ':3001';
// //     }
// //     const fetcher = url => window.fetch(url).then(response => response.json())
// //     const shop = store.create(
// //         {apiAddress:address},
// //         {
// //             fetch: fetcher,
// //             alert: m => console.log(m)
// //         }
// //     )
// //   }
// //   if (snapshot) {
// //     applySnapshot(Shop, snapshot)
// //   }
// //   return shop
// // }