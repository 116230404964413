/*global google*/
import React from "react";
import { inject, observer } from "mobx-react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { v4 as uuidv4 } from "uuid";

class NewOption extends React.Component {
  constructor(props) {
    super(props);

    var option = {
      uuid: uuidv4(),
      slug: "",
      sku: "",
      order: 1,
      price: 0,
      wholesale_price: 0,
      productImages: {
        pItems: [],
      },
      quantity: [],
    };

    this.props.languages.map((lang, i) => {
      option["title_" + lang.toLowerCase()] = "";
    });

    this.state = {
      option: option,
    };
  }
  updateOptionDetails(field, value) {
    var option = this.state.option;
    option[field] = value;
    this.setState({ option: option });
    console.log(this.state.option);
  }

  createOption() {
    this.props.createOption(this.state.option, this.props.customization_uuid);
  }

  render() {
    var shop = this.props.shop;
    var option = this.state.option;

    var languages = this.props.languages;
    var customization_uuid = this.props.customization_uuid;

    var fontSize = this.props.font;

    return (
      <Modal
        isOpen={true}
        toggle={this.props.close}
        style={this.props.style}
        className={this.props.className}
      >
        <ModalHeader toggle={this.props.close}>
          <span
            style={{
              fontWeight: 300,
              fontSize: 18,
              position: "relative",
              top: -10,
            }}
          >
            {"New Option"}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="product_details" style={{ fontSize: 10 }}>
            <div className="row">
              <div className="col-md-3 prod_label_grey">
                <label className="product_details">Option Title</label>
              </div>
              <div className="col-md-3 prod_label">
                <Tabs>
                  <TabList>
                    {languages.map((lang, i) => (
                      <Tab key={i}>{lang}</Tab>
                    ))}
                  </TabList>
                  {languages.map((lang, i) => (
                    <TabPanel key={i}>
                      <input
                        type="text"
                        defaultValue={option["title_" + lang.toLowerCase()]}
                        onBlur={(e) => {
                          this.updateOptionDetails(
                            "title_" + lang.toLowerCase(),
                            e.target.value,
                            "text"
                          );
                        }}
                        placeholder={lang + " Option Title"}
                        className="product_details"
                      />
                      <br />
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
              <div className="col-md-3 prod_label_grey">
                <label className="product_details">Option Slug</label>
              </div>
              <div className="col-md-3 prod_label">
                <input
                  type="text"
                  defaultValue={option.slug}
                  placeholder="Option Slug"
                  className="product_details"
                  onBlur={(e) => {
                    this.updateOptionDetails("slug", e.target.value, "text");
                  }}
                />
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 prod_label_grey">
                <label className="product_details">Option Sku</label>
              </div>
              <div className="col-md-3 prod_label">
                <input
                  type="text"
                  defaultValue={option.sku}
                  placeholder="Option Sku"
                  className="product_details"
                  onBlur={(e) => {
                    this.updateOptionDetails("sku", e.target.value, "text");
                  }}
                />
                <br />
              </div>
              <div className="col-md-3 prod_label_grey">
                <label className="product_details">Option Order</label>
              </div>
              <div className="col-md-3 prod_label">
                <select
                  type="number"
                  defaultValue={option.order}
                  placeholder="Option Order"
                  className="product_details"
                  onChange={(e) => {
                    this.updateOptionDetails("order", e.target.value, "number");
                  }}
                >
                  {[...Array(this.props.noOptions + 1)].map((x, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 prod_label_grey">
                <label className="product_details">Option Retail Price</label>
              </div>
              <div className="col-md-3 prod_label">
                <input
                  type="number"
                  defaultValue={option.price / 100 ? option.price / 100 : 0}
                  placeholder="Option Retail Price"
                  className="product_details"
                  onBlur={(e) => {
                    this.updateOptionDetails("price", e.target.value, "number");
                  }}
                />
                <br />
              </div>
              <div className="col-md-3 prod_label_grey">
                <label className="product_details">
                  Option Wholesale Price
                </label>
              </div>
              <div className="col-md-3 prod_label">
                <input
                  type="number"
                  defaultValue={
                    option.wholesale_price / 100
                      ? option.wholesale_price / 100
                      : 0
                  }
                  placeholder="Option Wholesale Price"
                  className="product_details"
                  onBlur={(e) => {
                    this.updateOptionDetails(
                      "wholesale_price",
                      e.target.value,
                      "number"
                    );
                  }}
                />
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "right" }}>
                <button
                  onClick={(e) => {
                    this.createOption();
                  }}
                  style={{
                    backgroundColor: "#000",
                    border: "none",
                    color: "#fff",
                    margin: 5,
                    width: 150,
                    height: 25,
                    borderRadius: 5,
                  }}
                >
                  Create New Option
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default NewOption;
