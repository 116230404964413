import React from "react";

import printJS from "print-js";
import moment from "moment";
import axios from "axios";
import InlineCss from "react-inline-css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

import ChangeDeliveryTime from "./orders/ChangeDeliveryTime";
import ChangeDeliveryAddress from "./orders/ChangeDeliveryAddress";

import LaLaDeliveryAddress from "./orders/LaLaDeliveryAddress";

import ChangeDeliveryLink from "./orders/ChangeDeliveryLink";
import OrderPhoto from "./orders/OrderPhoto";

import SendMessage from "./orders/SendMessage";
import DetailedOrder from "./orders/DetailedOrder";

import NewOrder from "./orders/NewOrder";
import OpenCarts from "./orders/OpenCarts";

import "../css/orders.css";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    var showSide = false;
    var currentView = "active";
    if (window.screen.width < 800) showSide = false;
    var showSearch = true;
    var style = "table";
    if (this.props.currentView) currentView = this.props.currentView;
    if (this.props.style) style = this.props.style;
    if (typeof this.props.showSearch !== "undefined")
      showSearch = this.props.showSearch;
    if (typeof this.props.showSide !== "undefined")
      showSide = this.props.showSide;

    var startDate = this.getHashValue("startDate");
    if (startDate) {
      startDate = moment(startDate);
    } else {
      startDate = moment();
    }

    var endDate = this.getHashValue("endDate");
    if (endDate) {
      endDate = moment(endDate);
    } else {
      endDate = moment(new Date()).add(7, "days");
    }

    var search = this.getHashValue("search");
    if (!search) search = "";

    var selectedStore = this.getHashValue("store");

    if (!selectedStore) selectedStore = "";

    var status = this.getHashValue("status");
    if (status) {
      status = status;
    } else {
      status = "active";
    }

    var paymentMethod = this.getHashValue("paymentMethod");
    if (paymentMethod) {
      paymentMethod = paymentMethod;
    } else {
      paymentMethod = "all";
    }

    this.state = {
      orders: [],
      pendingMessages: [],
      itemCounter: [],
      subItemCounter: [],
      newOrder: false,
      openCarts: false,
      loading: true,
      total: 0,
      selectedStore: selectedStore,
      startDate: startDate,
      endDate: endDate,
      sort: "next",
      search: search,
      style: style,
      showSide: showSide,
      currentView: currentView,
      showSearch: showSearch,
      orderStatus: status,
      paymentMethod: paymentMethod,
      format: "list",
      fontSize: 10,
      limit: 25,
    };
  }
  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  }
  countItems(orders) {
    var _this = this;
    var itemCounter = [];
    var subItemCounter = [];
    orders.map((ord, i) => {
      ord.entries.map((entr, j) => {
        if (entr.size) {
          entr.entryOptions.map((option, index, array) => {
            var ind = subItemCounter.findIndex(
              (prod, k) => prod.slug === option.slug
            );
            if (option.size) {
              if (subItemCounter.length > 0 && ind > -1) {
                subItemCounter[ind]["quantity"] =
                  parseInt(subItemCounter[ind]["quantity"]) +
                  parseInt(entr.quantity);
                subItemCounter[ind]["total"] =
                  parseInt(subItemCounter[ind]["total"]) + parseInt(entr.total);
              } else {
                subItemCounter.push({
                  title: option.title,
                  parent: entr.title,
                  slug: option.slug,
                  quantity: parseInt(entr.quantity),
                  price: parseInt(option.price),
                  total: parseInt(entr.total),
                });
              }
            }
          });
        }
        //
        var indx = itemCounter.findIndex((prod, k) => prod.slug === entr.slug);
        if (itemCounter.length > 0 && indx > -1) {
          itemCounter[indx]["quantity"] =
            parseInt(itemCounter[indx]["quantity"]) + parseInt(entr.quantity);
          itemCounter[indx]["total"] =
            parseInt(itemCounter[indx]["total"]) + parseInt(entr.total);
        } else {
          itemCounter.push({
            title: entr.title,
            slug: entr.slug,
            quantity: parseInt(entr.quantity),
            price: parseInt(entr.price),
            total: parseInt(entr.total),
          });
        }
      });
    });
    this.setState({ itemCounter: itemCounter, subItemCounter: subItemCounter });
  }

  compareUUIDs(a, b) {
    var idsA = a
      .map(function (x) {
        return x.uuid;
      })
      .sort();
    var idsB = b
      .map(function (x) {
        return x.uuid;
      })
      .sort();
    return idsA.join(",") === idsB.join(",");
  }

  componentWillMount() {
    var _this = this;
    if (!this.props.orders) {
      this.props.shop.staff
        .loadOrders(
          this.state.selectedStore,
          this.state.startDate,
          this.state.endDate,
          this.state.sort,
          this.state.limit,
          this.state.search,
          this.state.orderStatus,
          this.state.paymentMethod
        )
        .then((response) => {
          _this.setState({
            orders: response.orders,
            stores: response.stores,
            total: response.total,
          });
          _this.countItems(response.orders);
        });
    } else {
      this.setState({ orders: this.props.orders });
    }
    if (this.props.stores) this.setState({ stores: this.props.stores });

    this.interval = setInterval(() => {
      _this.setState({ pendingMessages: [] });
      var pendingMessages = [];
      _this.props.shop.storeStore.searchedAvailableStores.map((str, i) => {
        _this.props.shop.staff.checkMessages(str._id).then((data) => {
          if (data.messages > 0) {
            pendingMessages.push({
              store: str.slug,
              no: data.messages,
              color: data.color,
              logo: data.logo,
            });
            _this.setState({ pendingMessages: pendingMessages });
          }
        });
      });
    }, 120000);
    this.interval = setInterval(() => {
      // if (
      //   _this.showSendMessage === false &&
      //   _this.showCamera === false &&
      //   _this.showChangeAddress === false &&
      //   _this.showLaLaAddress === false
      // ) {
      _this.props.shop.staff
        .loadOrders(
          _this.state.selectedStore,
          _this.state.startDate,
          _this.state.endDate,
          _this.state.sort,
          _this.state.limit,
          _this.state.search,
          _this.state.orderStatus,
          _this.state.paymentMethod
        )
        .then((response) => {
          var pending_orders = response.orders.find(
            (o) => o.status === "Order Pending"
          );
          if (
            typeof pending_orders !== "undefined" &&
            pending_orders.length > 0
          )
            _this.notify();

          // if(!_this.compareUUIDs(_this.state.orders,response.orders) && typeof pending_orders !== "undefined" && pending_orders.length > 0)_this.notify();
          _this.setState({
            orders: response.orders,
            stores: response.stores,
            total: response.total,
          });
          _this.countItems(response.orders);
        });
      // }
    }, 20000);
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  updateDates(startDate, endDate) {
    if (endDate === null) endDate = startDate;
    var _this = this;
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        startDate,
        endDate,
        this.state.sort,
        this.state.limit,
        this.state.search,
        this.state.orderStatus,
        this.state.paymentMethod
      )
      .then((response) => {
        window.location.hash =
          "search=" +
          _this.state.search +
          "&status=" +
          _this.state.orderStatus +
          "&paymentMethod=" +
          _this.state.paymentMethod +
          "&startDate=" +
          startDate.format("YYYY-MM-DD").toString() +
          "&endDate=" +
          endDate.format("YYYY-MM-DD").toString();
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          startDate: startDate,
          endDate: endDate,
        });
        _this.countItems(response.orders);
      });
  }
  updateSelectedStore(event) {
    var _this = this;
    var store = event.target.value;
    window.location.hash = "store=" + store;
    this.props.shop.staff
      .loadOrders(
        store,
        this.state.startDate,
        this.state.endDate,
        this.state.sort,
        this.state.limit,
        this.state.search,
        this.state.orderStatus,
        this.state.paymentMethod
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          selectedStore: store,
        });
        _this.countItems(response.orders);
      });
  }
  updateSort(event) {
    var _this = this;
    var sort = event.target.value;
    if (sort === "next")
      this.setState({ startDate: moment().format("YYYY-MM-DD").toString() });
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        this.state.startDate,
        this.state.endDate,
        sort,
        this.state.limit,
        this.state.search,
        this.state.orderStatus,
        this.state.paymentMethod
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          sort: sort,
        });
        _this.countItems(response.orders);
      });
  }
  updateOrdersStatus(event) {
    var _this = this;
    var orderStatus = event.target.value;
    window.location.hash =
      "search=" +
      _this.state.search +
      "&status=" +
      orderStatus +
      "&paymentMethod=" +
      this.state.paymentMethod +
      "&startDate=" +
      _this.state.startDate.format("YYYY-MM-DD").toString() +
      "&endDate=" +
      _this.state.endDate.format("YYYY-MM-DD").toString();
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        this.state.startDate,
        this.state.endDate,
        this.state.sort,
        this.state.limit,
        this.state.search,
        orderStatus,
        this.state.paymentMethod
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          orderStatus: orderStatus,
        });
        _this.countItems(response.orders);
      });
  }
  updateOrdersPaymentMethod(event) {
    var _this = this;
    var paymentMethod = event.target.value;
    window.location.hash =
      "search=" +
      _this.state.search +
      "&status=" +
      _this.state.orderStatus +
      "&paymentMethod=" +
      paymentMethod +
      "&startDate=" +
      _this.state.startDate.format("YYYY-MM-DD").toString() +
      "&endDate=" +
      _this.state.endDate.format("YYYY-MM-DD").toString();
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        this.state.startDate,
        this.state.endDate,
        this.state.sort,
        this.state.limit,
        this.state.search,
        this.state.orderStatus,
        paymentMethod
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          paymentMethod: paymentMethod,
        });
        _this.countItems(response.orders);
      });
  }
  updateLimit(event) {
    var _this = this;
    var limit = event.target.value;
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        this.state.startDate,
        this.state.endDate,
        this.state.sort,
        limit,
        this.state.search,
        this.state.orderStatus,
        this.state.paymentMethod
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          limit: limit,
        });
        _this.countItems(response.orders);
      });
  }
  searchCustomer(event) {
    var _this = this;
    var search = event.target.value;
    window.location.hash =
      "search=" +
      search +
      "&status=" +
      _this.state.orderStatus +
      "&paymentMethod=" +
      this.state.paymentMethod +
      "&startDate=" +
      _this.state.startDate.format("YYYY-MM-DD").toString() +
      "&endDate=" +
      _this.state.endDate.format("YYYY-MM-DD").toString();
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        this.state.startDate,
        this.state.endDate,
        this.state.sort,
        this.state.limit,
        search
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          search: search,
        });
      });
  }
  loadOrders() {
    var _this = this;
    this.props.shop.staff
      .loadOrders(
        this.state.selectedStore,
        this.state.startDate,
        this.state.endDate,
        this.state.sort,
        this.state.limit,
        this.state.search,
        this.state.orderStatus,
        this.state.paymentMethod
      )
      .then((response) => {
        _this.setState({
          orders: response.orders,
          stores: response.stores,
          total: response.total,
        });
      });
  }

  siteLogin(order) {
    var _this = this;
    this.props.shop.staff.userLogin(order.user).then((token) => {
      _this.setState({
        customerSiteLogin: false,
        userSite: order.website,
        userToken: token,
        userId: order.user,
      });
      if (token)
        window.open(
          order.website + "#tasty_token_login=" + token + "=" + order.user,
          "_blank"
        );
    });
  }

  notify() {
    var file = "/audio/notify.mp3";
    if (this.props.shop.marketplace.notify)
      file = this.props.shop.marketplace.notify;

    if (this.props.shop.view.selectedStore)
      file = this.props.view.selectedStore.notify;

    console.log("notify", this.props.shop.imageDirectory + file);
    var snd = new Audio(this.props.shop.imageDirectory + file);
    snd.play();
  }

  notify2() {
    var file = "/audio/applause.mp3";
    if (this.props.shop.marketplace.notify2)
      file = this.props.shop.marketplace.notify2;

    if (this.props.shop.view.selectedStore)
      file = this.props.view.selectedStore.notify2;

    var snd = new Audio(this.props.shop.imageDirectory + file);
    snd.play();
  }

  openNewOrder() {
    this.setState({ newOrder: true });
  }

  closeNewOrder() {
    this.setState({ newOrder: false });
  }

  openOpenCarts() {
    this.setState({ openCarts: true });
  }

  closeOpenCarts() {
    this.setState({ openCarts: false });
  }

  render() {
    var shop = this.props.shop;
    var today = new Date();
    var fontSize = this.state.fontSize;
    var latestOrder;
    if (
      this.state.orders &&
      this.state.orders.length > 0 &&
      this.state.sort === "latest"
    ) {
      latestOrder = this.state.orders[this.state.orders.length - 1];
    } else if (this.state.orders && this.state.orders.length > 0) {
      latestOrder = this.state.orders[0];
    }

    var input_style = {
      borderRadius: 5,
      transition: "all 400ms ease",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "24px",
      background: "rgb(255, 255, 255)",
      border: "1px solid rgba(200, 200, 200,0.5)",
      outline: "none",
      color: "rgba(0, 0, 0,0.5)",
      height: 35,
      boxSizing: "border-box",
      display: "inline-block",
      padding: 5,
      maxWidth: 170,
    };

    if (this.state.pendingMessages.length > 0) {
      this.state.pendingMessages.map((val, index, array) => {
        var words = val.store.replace("-", " ").split(" ");

        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        // this.notify();
        toast(
          <div>
            <img
              src={shop.imageDirectory + val.logo}
              style={{ width: 30, marginRight: 5 }}
            />
            {words.join(" ") +
              " has " +
              val.no +
              " pending sms messages. Open TastySMS App & connect!"}
          </div>,
          {
            toastId: val.store,
            autoClose: 15000,
            type: toast.TYPE.ERROR,
            style: { backgroundColor: val.color },
          }
        );
      });
    }
    return (
      <InlineCss
        stylesheet={`
            .CalendarDay__selected_span {
              background: #82e0aa; //background
              color: white; //text
              border: 1px solid $light-red; //default styles include a border
            }

            // Will edit selected date or the endpoints of a range of dates
            .CalendarDay__selected {
              background: $dark-red;
              color: white;
            }

            // Will edit when hovered over. _span style also has this property
            .CalendarDay__selected:hover {
              background: orange;
              color: white;
            }

            .DateInput_input{
              font-size: 14px;
              color: rgba(0, 0, 0,0.5);
              height:25px;
              padding:0px;
              width:70px;
              border-radius:5px;
            }

            .DateInput {
              font-size: 16px;
              font-weight: normal;
              line-height: normal;
              padding: 6px;
              width: 80px;
              height:30px;
              border-radius:5px;
            }
            .DateRangePickerInput__withBorder{
              border-radius: 5px;
              transition: all 400ms ease 0s;
              font-weight: 300;
              font-size: 14px;
              line-height: 24px;
              background: rgb(255, 255, 255);
              border: 1px solid rgba(200, 200, 200, 0.5);
              outline: none;

              height: 35px;
              box-sizing: border-box;
              position:relative;
              top:-2px;
              display: inline-block;
            }

            .DateRangePickerInput_arrow_svg{
              width: 10px;
              margin-top:5px;
              height: 10px;
            }

            // Will edit when the second date (end date) in a range of dates
            // is not yet selected. Edits the dates between your mouse and said date
            .CalendarDay__hovered_span:hover,
            .CalendarDay__hovered_span {
              background: brown;
            }

            .table thead th{
              border:none;
            }

            `}
      >
        <ToastContainer />
        {this.state.newOrder ? (
          <div className="row">
            {/* <NewOrder close={this.closeNewOrder.bind(this)} /> */}
          </div>
        ) : (
          <div
            className="row"
            style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
          >
            <div
              className={
                this.state.showSide
                  ? "col-sm-12 col-lg-9"
                  : "col-sm-12 col-lg-12"
              }
            >
              <div className="row" style={{ marginBottom: 70 }}>
                <div
                  className="col-sm-3 col-md-2 col-lg-2"
                  style={{ marginTop: 50 }}
                >
                  <span
                    style={{
                      fontSize: 24,
                      fontWeight: "bold",
                      marginRight: 30,
                      marginTop: 10,
                      marginLeft: 30,
                    }}
                  >
                    Orders
                  </span>
                </div>
                <div
                  className="col-sm-8 col-md-9 col-lg-9"
                  style={{ marginTop: 50 }}
                >
                  <span
                    style={{
                      display: this.state.showSearch ? "inline-block" : "none",
                    }}
                  >
                    {shop.stores.size > 1 && (
                      <select
                        style={input_style}
                        value={this.state.selectedStore}
                        onChange={this.updateSelectedStore.bind(this)}
                      >
                        <option value="">All Stores</option>
                        {shop.storeStore.searchedAvailableStores.map(
                          (store, i) => (
                            <option key={i} value={store.slug}>
                              {store.title}
                            </option>
                          )
                        )}
                      </select>
                    )}
                    &nbsp;
                    <select
                      style={input_style}
                      value={this.state.orderStatus}
                      onChange={this.updateOrdersStatus.bind(this)}
                    >
                      <option value="active">Active</option>
                      <option value="future">Future</option>
                      <option value="all">All</option>
                      <option
                        value="Order Cancelled"
                        style={{ background: "#f44336", color: "#fff" }}
                      >
                        Cancelled
                      </option>
                      <option
                        value="Order Pending"
                        style={{ background: "#E91E63", color: "#fff" }}
                      >
                        Pending
                      </option>
                      <option
                        value="Order Confirmed"
                        style={{ background: "#4CAF50", color: "#fff" }}
                      >
                        Confirmed
                      </option>
                      <option
                        value="Order Prepared"
                        style={{ background: "#009688", color: "#fff" }}
                      >
                        Prepared
                      </option>
                      <option
                        value="Driver Booked"
                        style={{ background: "#FF9800", color: "#fff" }}
                      >
                        Driver Booked
                      </option>
                      <option
                        value="Order in Transit"
                        style={{ background: "#795548", color: "#fff" }}
                      >
                        In Transit
                      </option>
                      <option
                        value="Order Complete"
                        style={{ background: "#2196F3", color: "#fff" }}
                      >
                        Complete
                      </option>
                      <option
                        value="discounted"
                        style={{ background: "#FF9800", color: "#000" }}
                      >
                        Discounted
                      </option>
                    </select>
                    &nbsp;
                    <select
                      style={input_style}
                      value={this.state.paymentMethod}
                      onChange={this.updateOrdersPaymentMethod.bind(this)}
                    >
                      <option value="all">All</option>
                      <option value="cash">Cash</option>
                      <option value="bank">Bank</option>
                      <option value="paypal">Paypal</option>
                      <option value="creditcard">CreditCard</option>
                    </select>
                    &nbsp;
                    <DateRangePicker
                      isOutsideRange={() => false}
                      minimumNights={0}
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) =>
                        this.updateDates(startDate, endDate)
                      } // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      } // PropTypes.func.isRequired,
                    />
                    &nbsp;
                    <input
                      ref="customer"
                      type="text"
                      name="customer"
                      placeholder="Search Customer"
                      style={input_style}
                      defaultValue={this.state.customer}
                      onChange={this.searchCustomer.bind(this)}
                    />
                    &nbsp;
                    <select
                      style={input_style}
                      value={this.state.sort}
                      onChange={this.updateSort.bind(this)}
                    >
                      <option value="next">Next Up</option>
                      <option value="latest">Latest</option>
                      <option value="oldest">Oldest</option>
                      <option value="largest">Largest</option>
                      <option value="lowest">Lowest</option>
                      <option value="date_ordered">Date Ordered</option>
                    </select>
                    &nbsp;
                    <select
                      style={input_style}
                      value={this.state.limit}
                      onChange={this.updateLimit.bind(this)}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value="all">All</option>
                    </select>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                  </span>
                </div>
                <div className="col-sm-1" style={{ textAlign: "left" }}>
                  <div
                    style={{
                      color: "#9490D1",
                      fontSize: 10,
                      marginTop: 60,
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={this.openNewOrder.bind(this)}
                  >
                    + New Order
                  </div>
                </div>
                <div className="col-sm-1" style={{ textAlign: "left" }}>
                  <div
                    style={{
                      color: "#9490D1",
                      fontSize: 10,
                      marginTop: 60,
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={this.openOpenCarts.bind(this)}
                  >
                    Open Carts
                  </div>
                </div>
              </div>
              <a
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  marginTop: 2,
                  left: window.screen.width < 800 ? "95%" : "80%",
                  top: 0,
                }}
                onClick={(e) => {
                  this.setState({ showSearch: !this.state.showSearch });
                }}
              >
                <i
                  className={
                    this.state.showSearch
                      ? "fas fa-caret-up"
                      : "fas fa-caret-down"
                  }
                  style={{
                    color: "rgba(200, 200, 200,1)",
                    fontSize: fontSize + 10,
                  }}
                ></i>
              </a>

              {this.state.openCarts && (
                <div>
                  <OpenCarts
                    lang_date={"en-US"}
                    fontSize={fontSize}
                    open={this.state.openCarts}
                    close={this.closeOpenCarts.bind(this)}
                  />
                </div>
              )}
              <div className="row">
                <div className="col-sm-6" style={{ marginBottom: 50 }}>
                  <span
                    style={{
                      color: "#9490D1",
                      fontSize: 14,
                      marginRight: 20,
                      cursor: "pointer",
                      textTransform: "uppercase",
                      marginLeft: 30,
                    }}
                  >
                    {this.state.orders.length}{" "}
                    {this.state.orderStatus
                      .replace("%20", " ")
                      .replace("Order ", "")}{" "}
                    Orders{" "}
                    <span style={{ color: "#fff" }}>
                      {this.state.total > 0 &&
                        " - " +
                          (this.state.total / 100).toLocaleString() +
                          " " +
                          this.props.shop.marketplace.currency}
                    </span>
                  </span>
                  {/*
                      <span style={{color:this.state.currentView === 'active' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'active'})}}>Active Now</span>
                      <span style={{color:this.state.currentView === 'cancelled' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'cancelled'})}}>Cancelled</span>
                      <span style={{color:this.state.currentView === 'map' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'map'})}}>Map View</span>
                      */}
                </div>
                <div
                  className="col-sm-6"
                  style={{ marginBottom: 50, textAlign: "right" }}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.setState({ style: "grid" });
                    }}
                  >
                    <i
                      className="fal fa-th"
                      aria-hidden="true"
                      style={{
                        color:
                          this.state.style === "grid"
                            ? "#9490D1"
                            : "rgba(200, 200, 200,0.5)",
                        fontSize: 14,
                        padding: 2,
                      }}
                    ></i>
                  </a>
                  &nbsp;
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.setState({ style: "table" });
                    }}
                  >
                    <i
                      className="fal fa-th-list"
                      aria-hidden="true"
                      style={{
                        color:
                          this.state.style === "table"
                            ? "#9490D1"
                            : "rgba(200, 200, 200,0.5)",
                        fontSize: 14,
                        padding: 2,
                      }}
                    ></i>
                  </a>
                  <br />
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.setState({ fontSize: fontSize + 1 });
                    }}
                  >
                    <i
                      className="fal fa-plus"
                      aria-hidden="true"
                      style={{
                        color: "rgba(200, 200, 200,0.5)",
                        fontSize: 12,
                        padding: 2,
                      }}
                    ></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.setState({ fontSize: fontSize - 1 });
                    }}
                  >
                    <i
                      className="fal fa-minus"
                      aria-hidden="true"
                      style={{
                        color: "rgba(200, 200, 200,0.5)",
                        fontSize: 12,
                        padding: 2,
                      }}
                    ></i>
                  </a>
                  &nbsp;
                </div>
              </div>
              <div
                className="table-responsive"
                style={{ minHeight: 500, overflowY: "hidden" }}
              >
                <table
                  className={
                    this.state.style === "table" ? "table" : "orders_table"
                  }
                  style={{
                    fontSize: fontSize,
                    color: "#666",
                    width: "100%",
                    transition: "0.4s",
                  }}
                  border="0"
                >
                  {this.state.style === "table" && (
                    <thead>
                      <tr
                        style={{
                          margin: 10,
                          color: "rgba(0, 0, 0,0.5)",
                          fontSize: 12,
                          fontWeight: 200,
                        }}
                      >
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          &nbsp;
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Order Id
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Time
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Customer
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Order
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                          }}
                          colSpan="2"
                        >
                          Payment
                        </th>
                        <th
                          scope="col"
                          style={{
                            margin: 10,
                            color: "rgba(0, 0, 0,0.5)",
                            fontSize: 12,
                            fontWeight: 200,
                            textAlign: "center",
                          }}
                        >
                          Status
                        </th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                  )}
                  {this.state.style === "table" ? (
                    <tbody>
                      {this.state.orders.map((order, i) => (
                        <OrderItem
                          i={i}
                          order={order}
                          notify={this.notify.bind(this)}
                          notify2={this.notify2.bind(this)}
                          siteLogin={this.siteLogin.bind(this)}
                          fontSize={this.state.fontSize}
                          style={this.state.style}
                          shop={this.props.shop}
                          key={i}
                          loadOrders={this.loadOrders.bind(this)}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="11">
                          <div className="row">
                            {this.state.orders.map((order, i) => (
                              <OrderItem
                                i={i}
                                order={order}
                                notify={this.notify.bind(this)}
                                notify2={this.notify2.bind(this)}
                                siteLogin={this.siteLogin.bind(this)}
                                fontSize={this.state.fontSize}
                                style={this.state.style}
                                shop={this.props.shop}
                                key={i}
                                loadOrders={this.loadOrders.bind(this)}
                              />
                            ))}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <div
              className="d-sm-none d-md-block col-md-3"
              style={{
                background: "rgb(249, 248, 255)",
                position: "absolute",
                height: "100%",
                width: this.state.showSide ? "100%" : "0%",
                right: 0,
                transition: "0.4s",
                overflow: "hidden",
                padding: this.state.showSide ? 10 : 0,
                paddingLeft: this.state.showSide ? 40 : 0,
              }}
            >
              <p>&nbsp;</p>
              <div>
                {latestOrder &&
                  today.toDateString() ===
                    new Date(latestOrder.deliveryDateTime).toDateString() &&
                  latestOrder.status !== "Order Complete" &&
                  latestOrder.status !== "Order Cancelled" && (
                    <div style={{ textAlign: "right", width: "100%" }}>
                      <span style={{ fontSize: 20, whiteSpace: "nowrap" }}>
                        Next Order
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(50, 50, 50,0.5)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: 22,
                          fontWeight: "bold",
                          paddingLeft: 10,
                        }}
                      >
                        #{latestOrder.uuid.substr(0, 6)}
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(50, 50, 50,0.5)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: 22,
                          fontWeight: "bold",
                          paddingLeft: 10,
                        }}
                      >
                        {latestOrder.name}
                      </span>
                      <p>&nbsp;</p>
                      <div style={{ display: "inline-block" }}>
                        <CountDown
                          color={"#9490D1"}
                          size={parseInt(window.innerWidth / 30)}
                          timeTillDate={new Date(latestOrder.deliveryDateTime)}
                          timeFormat="MM DD YYYY, h:mm a"
                        />
                      </div>
                    </div>
                  )}
              </div>
              <p>&nbsp;</p>
              <span style={{ color: "#9490D1", fontSize: 16 }}>Overview</span>
              <p>&nbsp;</p>
              <span style={{ color: "#717173", fontSize: 16 }}># Orders</span>
              <p>&nbsp;</p>
              <span
                style={{
                  color: "#333",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginTop: 20,
                }}
              >
                {this.state.orders.length}
              </span>{" "}
              <p>&nbsp;</p>
              <div>
                <span style={{ color: "#717173", fontSize: 16 }}>
                  Products Counter
                </span>
                {this.state.itemCounter.length > 0 &&
                  this.state.subItemCounter.length > 0 && (
                    <table style={{ fontSize: 10 }}>
                      {this.state.itemCounter.map((value, index, array) => (
                        <React.Fragment>
                          <tr key={index}>
                            <td
                              style={{ textAlign: "left", fontWeight: "bold" }}
                            >
                              {value.title}
                            </td>
                            <td>{value.quantity}</td>
                            <td style={{ textAlign: "center" }}>{"x"}</td>
                            <td style={{ textAlign: "right" }}>
                              {value.price > 0 &&
                                (value.price / 100 / 1000).toLocaleString() +
                                  "k"}
                            </td>
                            <td style={{ textAlign: "center" }}>{"="}</td>
                            <td style={{ textAlign: "right" }}>
                              {(value.total / 100 / 1000).toLocaleString()}k
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={"6"}>
                              <table style={{ fontSize: 10 }}>
                                {this.state.subItemCounter
                                  .filter(
                                    (val, index, array) =>
                                      val.parent === value.title
                                  )
                                  .sort((a, b) => a.quantity < b.quantity)
                                  .map((value, index, array) => (
                                    <tr key={index}>
                                      <td style={{ textAlign: "left" }}>
                                        {value.title}
                                      </td>
                                      <td>{value.quantity}</td>
                                      <td style={{ textAlign: "center" }}>
                                        {"x"}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {(
                                          value.price /
                                          100 /
                                          1000
                                        ).toLocaleString()}
                                        k
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {"="}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {(
                                          value.total /
                                          100 /
                                          1000
                                        ).toLocaleString()}
                                        k
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </table>
                  )}
              </div>
              <br />
              <span style={{ color: "#717173", fontSize: 16 }}>Total</span>
              <br />
              <span
                style={{
                  color: "#333",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginTop: 20,
                }}
              >
                {(this.state.total / 100).toLocaleString()}{" "}
                {this.props.shop.marketplace.currency}
              </span>
              <p>&nbsp;</p>
              <span style={{ color: "#717173", fontSize: 16 }}>Paid Total</span>
              <br />
              <span
                style={{
                  color: "#333",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginTop: 20,
                }}
              >
                {this.state.orders.length > 0 && (
                  <span>
                    {parseInt(
                      this.state.orders
                        .map(function (ord) {
                          if (ord.payment_status === "Paid") {
                            return ord.total;
                          } else {
                            return 0;
                          }
                        })
                        .reduce(function (a, b) {
                          return a + b;
                        }) / 100
                    ).toLocaleString()}{" "}
                    {this.props.shop.marketplace.currency}
                  </span>
                )}
              </span>
              <p>&nbsp;</p>
              <span style={{ color: "#717173", fontSize: 16 }}>Paid Today</span>
              <br />
              <span
                style={{
                  color: "#333",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginTop: 20,
                }}
              >
                {this.state.orders.length > 0 && (
                  <span>
                    {parseInt(
                      this.state.orders
                        .map(function (ord) {
                          var today = new Date().setHours(0, 0, 0, 0);
                          var tsDay = new Date(ord.payment_datetime).setHours(
                            0,
                            0,
                            0,
                            0
                          );
                          if (
                            ord.payment_status === "Paid" &&
                            today === tsDay
                          ) {
                            return ord.total;
                          } else {
                            return 0;
                          }
                        })
                        .reduce(function (a, b) {
                          return a + b;
                        }) / 100
                    ).toLocaleString()}{" "}
                    {this.props.shop.marketplace.currency}
                  </span>
                )}
              </span>
            </div>
            {window.screen.width > 800 && (
              <a
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 10,
                  top: 5,
                }}
                onClick={(e) => {
                  this.setState({ showSide: !this.state.showSide });
                }}
              >
                <i
                  className={
                    this.state.showSide
                      ? "fas fa-caret-right"
                      : "far fa-caret-left"
                  }
                  style={{ color: "#9490D1", fontSize: fontSize + 4 }}
                ></i>
              </a>
            )}
          </div>
        )}
      </InlineCss>
    );
  }
}

class OrderItem extends React.Component {
  constructor(props) {
    super(props);

    var order = this.props.order;

    var _this = this;
    if (order.photos) {
      order.photos.map(function (photo) {
        var msg = {};
        msg = {
          uuid: photo.uuid,
          createdAt: photo.timestamp,
          direction: "sent",
          image: _this.props.shop.imageDirectory + photo.image,
          message: photo.caption,
        };
        order.messages.push(msg);
      });
    }

    this.state = {
      order: order,
      deliveryModal: false,
      showMore: false,
      showSendMessage: false,
      showCamera: false,
      showChangeAddress: false,
      showLaLaAddress: false,
      changeName: false,
      changeInstructions: false,
      changePhone: false,
      changeTaxCode: false,
      changeDeliveryAmt: false,
      changeDiscountAmt: false,
      showChangeDeliveryLink: false,
      showMenu: false,
      orderBooked: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.order !== this.state.order) {
      var order = nextProps.order;

      var _this = this;
      if (order.photos) {
        order.photos.map(function (photo) {
          var msg = {};
          msg = {
            uuid: photo.uuid,
            createdAt: photo.timestamp,
            direction: "sent",
            image: _this.props.shop.imageDirectory + photo.image,
            message: photo.caption,
          };
          order.messages.push(msg);
        });
      }

      this.setState({ order: order });
    }
  }

  updateOrderStatus(status) {
    // var status = event.target.value;
    var order = this.state.order;
    order.status = status;
    this.setState({ order: order });
    this.props.shop.staff.updateOrderStatus(order.uuid, status);

    var _this = this;
    setTimeout(function () {
      _this.props.loadOrders();
    }, 2000);
  }
  updatePaymentStatus(status) {
    // var status = event.target.value;
    var order = this.state.order;
    order.payment_status = status;
    this.setState({ order: order });
    this.props.shop.staff.updatePaymentStatus(order.uuid, status);
    var _this = this;
    setTimeout(function () {
      _this.props.loadOrders();
    }, 100);
  }
  updatePaymentMethod(method) {
    // var method = event.target.value;
    var _this = this;
    var order = this.state.order;
    order.payment_method = method;
    _this.props.shop.staff
      .updateOrderPaymentMethod(this.props.order.uuid, method)
      .then((response) => {
        order.payment_link = response;
        _this.setState({ order: order });
        _this.props.loadOrders();
      });
  }

  updateOrderName(event) {
    if (event.key === "Enter" || typeof event.key === "undefined") {
      var name = event.target.value;
      var _this = this;
      var order = this.state.order;
      order.name = name;
      _this.props.shop.staff
        .updateOrderName(this.props.order.uuid, name)
        .then((response) => {
          _this.setState({ order: order, changeName: false });
          _this.props.loadOrders();
        });
    }
  }

  updateOrderInstructions(instructions, entry_id) {
    // if (event.key === "Enter" || typeof event.key === "undefined") {
    //   var instructions = event.target.value;
    var _this = this;
    var order = this.state.order;
    order.instructions = instructions;
    _this.props.shop.staff
      .updateOrderInstructions(this.props.order.uuid, entry_id, instructions)
      .then((response) => {
        _this.setState({ order: order, changeInstructions: false });
        _this.props.loadOrders();
      });
    // }
  }

  updateOrderPhone(event) {
    if (event.key === "Enter" || typeof event.key === "undefined") {
      var phone = event.target.value;
      var _this = this;
      var order = this.state.order;
      order.phone = phone;
      _this.props.shop.staff
        .updateOrderPhone(this.props.order.uuid, phone)
        .then((response) => {
          _this.setState({ order: order, changePhone: false });
          _this.props.loadOrders();
        });
    }
  }

  updateOrderTaxCode(event) {
    if (event.key === "Enter" || typeof event.key === "undefined") {
      var taxCode = event.target.value;
      var _this = this;
      var order = this.state.order;
      order.taxCode = taxCode;
      _this.props.shop.staff
        .updateOrderTaxCode(this.props.order.uuid, taxCode)
        .then((response) => {
          _this.setState({ order: order, changePhone: false });
          _this.props.loadOrders();
        });
    }
  }

  updateDeliveryAmt(event) {
    if (event.key === "Enter" || typeof event.key === "undefined") {
      var deliveryAmt = event.target.value;
      var _this = this;
      var order = this.state.order;
      order.deliveryAmt = deliveryAmt;
      _this.props.shop.staff
        .updateDeliveryAmt(this.props.order.uuid, deliveryAmt)
        .then((response) => {
          _this.setState({ order: order, changeDeliveryAmt: false });
          _this.props.loadOrders();
        });
    }
  }

  updateDiscountAmount(event) {
    if (event.key === "Enter" || typeof event.key === "undefined") {
      var discountAmount = event.target.value;
      var _this = this;
      var order = this.state.order;
      order.discountAmount = discountAmount;
      _this.props.shop.staff
        .updateDiscountAmount(this.props.order.uuid, discountAmount)
        .then((response) => {
          _this.setState({ order: order, changeDiscountAmt: false });
          _this.props.loadOrders();
        });
    }
  }
  updateOrderType(type) {
    // var method = event.target.value;
    var _this = this;
    var order = this.state.order;
    order.order_type = type;
    _this.props.shop.staff
      .updateOrderType(this.props.order.uuid, type)
      .then((response) => {
        _this.setState({ order: order });
        _this.props.loadOrders();
      });
  }

  updateDeliveryType(type) {
    // var method = event.target.value;
    var _this = this;
    var order = this.state.order;
    order.delivery_type = type;
    _this.props.shop.staff
      .updateDeliveryType(this.props.order.uuid, type)
      .then((response) => {
        _this.setState({ order: order });
        _this.props.loadOrders();
      });
  }

  showMore() {
    this.setState({ showMore: !this.state.showMore });
  }
  calculateDistance(customer_gps, driver_gps) {
    var lat1 = customer_gps[0];
    var lon1 = customer_gps[1];
    var lat2 = driver_gps[0];
    var lon2 = driver_gps[1];
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  printDocument(url) {
    axios({
      url: url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      setTimeout(function () {
        var pdfFile = new Blob([response.data], {
          type: "application/pdf",
        });
        var pdfUrl = URL.createObjectURL(pdfFile);
        printJS(pdfUrl);
      }, 100);
    });
  }

  showSendMessage() {
    this.setState({ showSendMessage: !this.state.showSendMessage });
  }
  sendMessage(message) {
    var _this = this;
    _this.props.shop.staff
      .sendMessage(this.props.order.uuid, message)
      .then((response) => {
        _this.props.shop.alert("Message Sent", "", "info", "br");
        _this.setState({ showSendMessage: false });
        _this.props.loadOrders();
      });
  }
  showCamera() {
    this.setState({ showCamera: !this.state.showCamera });
  }

  savePhoto(image, caption) {
    var _this = this;
    _this.props.shop.staff
      .savePhoto(
        this.props.order.uuid,
        image,
        caption,
        this.props.order.store_slug
      )
      .then((response) => {
        _this.props.shop.alert("Photo Saved", "", "info", "br");
        _this.setState({ showCamera: false });
        _this.props.loadOrders();
      });
  }

  showChangeAddress() {
    this.setState({ showChangeAddress: !this.state.showChangeAddress });
  }
  showLaLaAddress() {
    this.setState({ showLaLaAddress: !this.state.showLaLaAddress });
  }

  showChangeDeliveryLink() {
    this.setState({
      showChangeDeliveryLink: !this.state.showChangeDeliveryLink,
    });
  }
  setDeliveryTime(deliveryDateTime) {
    this.props.shop.staff.updateOrderDate(
      this.props.order.uuid,
      deliveryDateTime
    );
    var _this = this;
    setTimeout(function () {
      _this.props.loadOrders();
    }, 100);
  }
  setDeliveryLink(link) {
    var order = this.state.order;
    this.props.shop.staff.updateDeliveryLink(order.uuid, link);
    order.delivery_link = link;
    this.setState({ order: order });
  }

  updateAddress(address) {
    var order = this.state.order;
    order.address = address;
    var _this = this;
    var delivery_cost = order.deliveryAmt;

    order.store[0].location.forEach(function (location) {
      var areaCost = location.deliveryAreas.filter(function (area) {
        if (typeof address !== "undefined") {
          return (
            area.name.toLowerCase().indexOf(address.suburb.toLowerCase()) !==
              -1 ||
            area.name.toLowerCase().indexOf(address.ward.toLowerCase()) !== -1
          );
        } else {
          return false;
        }
      });
      if (areaCost.length > 0) {
        delivery_cost = parseFloat(areaCost[0].cost / 100);
      }
    });

    _this.props.shop.staff
      .updateOrderAddress(this.props.order.uuid, address, delivery_cost)
      .then((response) => {
        _this.setState({
          order: order,
          changeName: false,
          changePhone: false,
          changeInstructions: false,
        });
        _this.props.loadOrders();
      });
  }

  deletePhoto(uuid, photo_uuid) {
    var _this = this;
    if (window.confirm("Are you sure you wish to delete this photo?")) {
      this.props.shop.staff.deletePhoto(uuid, photo_uuid).then((response) => {
        _this.props.loadOrders();
      });
    }
  }

  sendOrderEmail(uuid) {
    if (
      window.confirm("Are you sure you want to email the customer this order?")
    ) {
      this.props.shop.staff.sendOrderEmail(uuid);
    }
  }
  updateEntryStatus(id, status) {
    var order = this.state.order;
    // order.findIndex(.payment_status = status;
    // this.setState({order:order});
    this.props.shop.staff.updateEntryStatus(order.uuid, id, status);
    var _this = this;
    setTimeout(function () {
      _this.props.loadOrders();
    }, 100);
  }

  lalaQuote(uuid) {
    if (uuid) this.props.shop.staff.lalaQuote(uuid);
  }

  lalaBook(uuid, name, address) {
    var _this = this;
    if (
      uuid &&
      window.confirm(
        "Are you sure you want to book a lala for " +
          name +
          " to " +
          address +
          " ?"
      )
    ) {
      this.setState({ orderBooked: true });
      setTimeout(() => {
        if (_this.props.shop.staff.lalaBook(uuid)) {
          // this.updateOrderStatus("Driver Booked");
        }
      }, 500);
    }
  }

  render() {
    var order = this.state.order;

    if (typeof order.address === "undefined") {
      order["address"] = {
        gps: [0, 0],
        selected: false,
        apt: "",
        delivery_instructions: "",
        label_address: "",
        form_address: "",
        street: "",
        ward: "",
        suburb: "",
        post_code: "",
        city: "",
        region: "",
        country: "",
        country_code: "",
      };
    }

    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var layout = shop.marketplace.layout;

    var fontSize = this.props.fontSize;

    var lang_date = "en-US";
    if (lang === "vi") lang_date = "vi-VN";
    if (lang === "ja") lang_date = "ja-JP";
    if (lang === "zh") lang_date = "zh-CN";
    if (lang === "ko") lang_date = "ko-KR";

    var order_color = "#E91E63";
    if (order.status === "Order Cancelled") {
      order_color = "#f44336";
    } else if (order.status === "Order in Transit") {
      order_color = "#795548";
    } else if (order.status === "Order Prepared") {
      order_color = "#009688";
    } else if (order.status === "Driver Booked") {
      order_color = "#FF9800";
    } else if (order.status === "Order Confirmed") {
      order_color = "#4CAF50";
    } else if (order.status === "Order Complete") {
      order_color = "#2196F3";
    }

    if (this.props.style === "table") {
      return (
        <React.Fragment>
          <tr style={{ marginTop: 30, marginBottom: 30 }}>
            <td>
              <input type={"checkbox"} style={{ margin: 10 }} />
            </td>
            <td>
              <img
                src={shop.imageDirectory + order.store[0].logoImageUrl}
                style={{ minWidth: 45, maxWidth: 45 }}
              />
            </td>
            <td>
              <div
                style={{
                  color: "#fff",
                  color: "rgb(50, 50, 50)",
                  borderRadius: 5,
                  padding: 2,
                  marginBottom: 4,
                  fontSize: fontSize + 2,
                  fontWeight: 200,
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText("#" + order.uuid.substr(0, 6));
                  this.props.shop.alert("Text Copied", "", "info", "br");
                }}
              >
                #{order.uuid.substr(0, 6)}
              </div>
              <div style={{ display: "inline-block", marginRight: 10 }}>
                <i
                  className="fas fa-external-link-alt"
                  style={{
                    marginLeft: 1,
                    fontSize: 10,
                    cursor: "pointer",
                    color: "rgb(100, 100, 100)",
                    display: "inline-block",
                    top: 0,
                    position: "relative",
                  }}
                  onClick={(e) => {
                    window.open(
                      order.website + "/order/" + order.uuid,
                      "_blank"
                    );
                  }}
                ></i>
                <i
                  className="fas fa-paper-plane"
                  style={{
                    marginLeft: 2,
                    fontSize: 10,
                    cursor: "pointer",
                    color: "rgb(100, 100, 100)",
                    display: "inline-block",
                    top: 0,
                    position: "relative",
                  }}
                  onClick={(e) => {
                    this.sendOrderEmail(order.uuid);
                  }}
                ></i>
              </div>
              <SelectOrderType
                order_type={order.order_type}
                orderTypes={shop.marketplace.layout.home.orderTypes}
                updateOrderType={this.updateOrderType.bind(this)}
                fontSize={fontSize}
              />
              {order.order_type === "delivery" && order.store.length > 0 && (
                <span>
                  <SelectDeliveryType
                    delivery_type={order.delivery_type}
                    deliveryTypes={order.store[0].deliveryTypes}
                    updateDeliveryType={this.updateDeliveryType.bind(this)}
                    showChangeDeliveryLink={this.showChangeDeliveryLink.bind(
                      this
                    )}
                    fontSize={fontSize}
                  />
                  {this.state.showChangeDeliveryLink && (
                    <ChangeDeliveryLink
                      delivery_link={order.delivery_link}
                      open={true}
                      showChangeDeliveryLink={this.showChangeDeliveryLink.bind(
                        this
                      )}
                      setDeliveryLink={this.setDeliveryLink.bind(this)}
                      shop={shop}
                    />
                  )}
                </span>
              )}
            </td>
            <td>
              <SelectDateTime
                status={order.status}
                deliveryDateTime={order.deliveryDateTime}
                deliveryModal={this.state.deliveryModal}
                shop={shop}
                uuid={order.uuid}
                fontSize={this.props.fontSize}
                setDeliveryTime={this.setDeliveryTime.bind(this)}
              />
            </td>
            <td>
              {this.state.changeName ? (
                <input
                  style={{
                    fontSize: fontSize + 4,
                    borderRadius: 5,
                    color: "#fff",
                    color: "rgb(75, 75, 75)",
                    border: "2px solid rgb(10, 10, 10)",
                    padding: 2,
                    paddingLeft: 5,
                    minWidth: 80,
                    border: "solid 0px",
                  }}
                  defaultValue={order.name}
                  onBlur={this.updateOrderName.bind(this)}
                  onKeyDown={this.updateOrderName.bind(this)}
                />
              ) : (
                <span>
                  <span>
                    <div
                      style={{
                        fontSize: fontSize,
                        borderRadius: 5,
                        color: "#fff",
                        color: "rgb(50, 50, 50)",
                        padding: 2,
                        paddingLeft: 5,
                        minWidth: 80,
                        border: "solid 0px",
                        fontSize: fontSize + 4,
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        navigator.clipboard.writeText(order.name);
                        this.props.shop.alert("Text Copied", "", "info", "br");
                      }}
                    >
                      {order.name}
                    </div>
                    <i
                      className="fal fa-edit"
                      style={{
                        marginLeft: 5,
                        fontSize: 12,
                        cursor: "pointer",
                        color: "rgb(50, 50, 50)",
                        display: "inline-block",
                        top: -2,
                        position: "relative",
                      }}
                      onClick={(e) => {
                        this.setState({ changeName: true });
                      }}
                    ></i>
                    <i
                      className="fal fa-user"
                      style={{
                        marginLeft: 5,
                        fontSize: 12,
                        cursor: "pointer",
                        color: "rgb(50, 50, 50)",
                        display: "inline-block",
                        top: -2,
                        position: "relative",
                      }}
                      onClick={(e) => {
                        shop.view.openCustomersPage(order.email);
                      }}
                    ></i>
                    <br />
                  </span>
                  {this.state.changePhone ? (
                    <input
                      style={{
                        fontSize: fontSize + 4,
                        borderRadius: 5,
                        color: "#fff",
                        color: "rgb(75, 75, 75)",
                        border: "2px solid rgb(10, 10, 10)",
                        padding: 2,
                        paddingLeft: 5,
                        minWidth: 80,
                        border: "solid 0px",
                      }}
                      defaultValue={order.phone}
                      onBlur={this.updateOrderPhone.bind(this)}
                      onKeyDown={this.updateOrderPhone.bind(this)}
                    />
                  ) : (
                    <span>
                      <a
                        onClick={(e) => {
                          navigator.clipboard.writeText(order.phone);
                          this.props.shop.alert(
                            "Text Copied",
                            "",
                            "info",
                            "br"
                          );
                        }}
                        target="_blank"
                        style={{
                          cursor: "pointer",
                          fontSize: fontSize + 4,
                          paddingLeft: 5,
                        }}
                      >
                        {order.phone}
                      </a>
                      <i
                        className="fal fa-edit"
                        style={{
                          marginLeft: 18,
                          fontSize: 12,
                          cursor: "pointer",
                          color: "rgb(50, 50, 50)",
                          display: "inline-block",
                          top: -2,
                          position: "relative",
                        }}
                        onClick={(e) => {
                          this.setState({ changePhone: true });
                        }}
                      ></i>
                    </span>
                  )}
                  <br />
                  {this.state.changeTaxCode ? (
                    <input
                      style={{
                        fontSize: fontSize + 4,
                        borderRadius: 5,
                        color: "#fff",
                        color: "rgb(75, 75, 75)",
                        border: "2px solid rgb(10, 10, 10)",
                        padding: 2,
                        paddingLeft: 5,
                        minWidth: 80,
                        border: "solid 0px",
                      }}
                      defaultValue={order.taxCode}
                      onBlur={this.updateOrderTaxCode.bind(this)}
                      onKeyDown={this.updateOrderTaxCode.bind(this)}
                    />
                  ) : (
                    <span>
                      <a
                        onClick={(e) => {
                          navigator.clipboard.writeText(order.taxCode);
                          this.props.shop.alert(
                            "Text Copied",
                            "",
                            "info",
                            "br"
                          );
                        }}
                        target="_blank"
                        style={{
                          cursor: "pointer",
                          fontSize: fontSize + 4,
                          paddingLeft: 5,
                        }}
                      >
                        {order.taxCode}
                      </a>
                      <i
                        className="fal fa-edit"
                        style={{
                          marginLeft: 18,
                          fontSize: 12,
                          cursor: "pointer",
                          color: "rgb(50, 50, 50)",
                          display: "inline-block",
                          top: -2,
                          position: "relative",
                        }}
                        onClick={(e) => {
                          this.setState({ changeTaxCode: true });
                        }}
                      ></i>
                    </span>
                  )}
                </span>
              )}
              {order.messages &&
                order.messages.map((message, i) => (
                  <div key={i}>
                    {/* {i > order.messages.length - 3 && ( */}
                    <div className="messagesBox">
                      <span style={{ fontSize: fontSize }}>
                        {message.sendSMS && (
                          <i className="fas fa-sms" style={{ margin: 2 }}></i>
                        )}
                        {message.sendEmail && (
                          <i
                            className="fas fa-envelope"
                            style={{ margin: 2 }}
                          ></i>
                        )}
                        <i
                          className="fas fa-sticky-note"
                          style={{ margin: 0 }}
                        ></i>
                        {/*&nbsp;&nbsp;
                      {new Date(message.createdAt).toLocaleDateString(lang_date, { weekday:"short",day: "numeric", month: "short" })}
                      &nbsp;&nbsp;{new Date(message.createdAt).toLocaleTimeString(lang_date, {})}
                      {message.subject !=="" && <b>&nbsp;&nbsp;{message.subject}</b> */}
                        &nbsp;{message.message}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                ))}
            </td>
            <td>
              {order.entries.map((entry, i) => (
                <div
                  key={i}
                  style={{
                    width: 220,
                    color: entry.status === "Ready" ? "#999" : "#333",
                    border:
                      entry.status === "Ready"
                        ? "0px solid rgba(215, 215, 215,0.7)"
                        : "0px solid rgba(200, 200, 200,0.5)",
                    borderRadius: 5,
                    padding: 4,
                    margin: 0,
                    fontSize: fontSize,
                    textDecoration:
                      entry.status === "Ready" ? "line-through" : "",
                  }}
                >
                  {entry.quantity}&nbsp;x&nbsp;{entry.title} &nbsp;&nbsp;
                  <br />
                  {entry.entryOptions && entry.entryOptions.length > 0 && (
                    <span>
                      {entry.entryOptions.map((option, i) => (
                        <span
                          key={i}
                          style={{ color: "#666", fontSize: fontSize - 1 }}
                        >
                          {option.title},&nbsp;
                        </span>
                      ))}
                    </span>
                  )}
                  &nbsp;&nbsp;{" "}
                  <EntryStatus
                    id={entry._id}
                    fontSize={fontSize}
                    updateEntryStatus={this.updateEntryStatus.bind(this)}
                    status={entry.status}
                  />
                  <br />
                  {entry.instructions && (
                    <span style={{ display: "inline-block" }}>
                      {this.state.changeInstructions ? (
                        <input
                          style={{
                            fontSize: fontSize,
                            borderRadius: 5,
                            color: "#fff",
                            color: "red",
                            border: "2px solid rgb(10, 10, 10)",
                            padding: 2,
                            paddingLeft: 5,
                            width: "100%",
                            minWidth: 210,
                            border: "solid 0px",
                          }}
                          defaultValue={entry.instructions}
                          onBlur={(event) => {
                            if (
                              event.key === "Enter" ||
                              typeof event.key === "undefined"
                            ) {
                              var instructions = event.target.value;
                              this.updateOrderInstructions(
                                instructions,
                                entry._id
                              );
                            }
                            this.setState({ changeInstructions: false });
                          }}
                          onKeyDown={(event) => {
                            if (
                              event.key === "Enter" ||
                              typeof event.key === "undefined"
                            ) {
                              var instructions = event.target.value;
                              this.updateOrderInstructions(
                                instructions,
                                entry._id
                              );
                            }
                          }}
                          // onBlur={this.updateOrderInstructions.bind(this)}
                          // onKeyDown={this.updateOrderInstructions.bind(this)}
                        />
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <div
                            className="instructionsBox"
                            style={{ display: "inline-block" }}
                          >
                            <span
                              style={{
                                fontSize: fontSize - 2,
                                fontStyle: "normal",
                              }}
                            >
                              {entry.instructions}
                            </span>
                          </div>
                          <i
                            className="fal fa-edit"
                            style={{
                              marginLeft: 5,
                              fontSize: 12,
                              cursor: "pointer",
                              color: "rgb(50, 50, 50)",
                              display: "inline-block",
                              bottom: 0,
                              right: -5,
                              position: "absolute",
                            }}
                            onClick={(e) => {
                              this.setState({ changeInstructions: true });
                            }}
                          ></i>
                        </span>
                      )}
                    </span>
                  )}
                </div>
              ))}
              <div
                style={{
                  fontSize: 8,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  color: "#fff",
                  padding: 2,
                  borderRadius: 5,
                  paddingLeft: 5,
                }}
              >
                <b>Ordered on:</b>&nbsp;&nbsp;
                {new Date(order.timestamp).toLocaleTimeString(lang_date, {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                &nbsp;
                {new Date(order.timestamp).toLocaleDateString(lang_date, {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                })}
              </div>
            </td>
            <td style={{ maxWidth: "25%" }}>
              {order.order_type === "delivery" && (
                <div>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        order.address.apt + " " + order.address.label_address
                      );
                      this.props.shop.alert("Text Copied", "", "info", "br");
                    }}
                  >
                    {/*order.address.apt && <span><span style={{color:'#fff',backgroundColor:'rgb(110, 110, 110)',borderRadius:5,padding:2,marginBottom:4,fontSize:fontSize}}>{order.address && order.address.apt}</span><br /></span>*/}
                    <div
                      className="addressBox"
                      style={{ display: "inline-block", cursor: "pointer" }}
                    >
                      {order.address &&
                      order.address.label_address.indexOf(
                        order.address.street
                      ) === -1 ? (
                        <span
                          style={{
                            color: "rgb(150, 150, 150)",
                            fontSize: fontSize,
                          }}
                        >
                          {order.address &&
                            order.address.apt &&
                            order.address.apt + " | "}
                          {order.address && order.address.street}
                        </span>
                      ) : (
                        <span style={{ fontSize: fontSize }}>
                          {order.address &&
                            order.address.apt &&
                            order.address.apt + " | "}
                          {order.address && order.address.label_address}
                        </span>
                      )}
                    </div>
                    <i
                      className="fal fa-edit"
                      style={{
                        marginLeft: 10,
                        fontSize: 12,
                        cursor: "pointer",
                        color: "rgb(50, 50, 50)",
                        display: "inline-block",
                        top: -10,
                        position: "relative",
                      }}
                      onClick={(e) => {
                        this.showChangeAddress();
                      }}
                    ></i>
                    <br />
                    {order.address && order.address.ward && (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor:
                            order.address && order.address.ward
                              ? stringToColour(
                                  order.address.ward
                                    .split("")
                                    .reverse()
                                    .join("")
                                    .replace(" ", "")
                                )
                              : "rgb(0, 0, 0)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: fontSize,
                          whiteSpace: "normal",
                          display: "inline-block",
                        }}
                      >
                        {order.address && order.address.ward}
                      </span>
                    )}
                    {order.address && order.address.suburb && (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor:
                            order.address && order.address.suburb
                              ? stringToColour(
                                  order.address.suburb
                                    .split("")
                                    .reverse()
                                    .join("")
                                    .replace(" ", "")
                                )
                              : "rgb(0, 0, 0)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: fontSize,
                          whiteSpace: "normal",
                          display: "inline-block",
                        }}
                      >
                        {order.address && order.address.suburb}
                      </span>
                    )}
                  </a>
                  {this.state.showChangeAddress && (
                    <ChangeDeliveryAddress
                      address={order.address}
                      open={this.state.showChangeAddress}
                      updateAddress={this.updateAddress.bind(this)}
                      showChangeAddress={this.showChangeAddress.bind(this)}
                      gps={order.address && order.address.gps}
                      shop={shop}
                    />
                  )}
                  {this.state.showLaLaAddress && (
                    <LaLaDeliveryAddress
                      address={order.address}
                      open={this.state.showLaLaAddress}
                      name={order.name}
                      uuid={order.uuid}
                      updateAddress={this.updateAddress.bind(this)}
                      showLaLaAddress={this.showLaLaAddress.bind(this)}
                      gps={order.address && order.address.gps}
                      shop={shop}
                      lalaBook={this.lalaBook.bind(this)}
                    />
                  )}
                </div>
              )}
              {order.address && order.address.delivery_instructions && (
                <span
                  style={{
                    color: "#fff",
                    backgroundColor: "rgb(110, 110, 110)",
                    borderRadius: 5,
                    padding: 2,
                    margin: 2,
                    fontSize: fontSize - 1,
                    whiteSpace: "normal",
                    display: "inline-block",
                  }}
                >
                  {order.address && order.address.delivery_instructions}
                </span>
              )}
            </td>
            <td style={{ minWidth: 95, textAlign: "right" }}>
              {(order.order_type === "delivery" || order.deliveryAmt > 0) && (
                <span>
                  <i
                    className="fas fa-truck"
                    style={{ color: "rgb(150, 150, 150)", fontSize: fontSize }}
                  />
                  &nbsp;
                  {this.state.changeDeliveryAmt ? (
                    <input
                      style={{
                        fontSize: fontSize,
                        borderRadius: 5,
                        color: "#fff",
                        backgroundColor: "rgb(125, 125, 125)",
                        border: "2px solid rgb(10, 10, 10)",
                        padding: 2,
                        paddingLeft: 5,
                        width: 60,
                        border: "solid 0px",
                      }}
                      defaultValue={order.deliveryAmt / 100}
                      onBlur={this.updateDeliveryAmt.bind(this)}
                      type="number"
                      onKeyDown={this.updateDeliveryAmt.bind(this)}
                    />
                  ) : (
                    <span
                      style={{
                        color: "#fff",
                        backgroundColor: "rgb(150, 150, 150)",
                        borderRadius: 5,
                        padding: 4,
                        margin: 2,
                        fontSize: fontSize,
                      }}
                      onClick={(e) => {
                        this.setState({ changeDeliveryAmt: true });
                      }}
                    >
                      {(order.deliveryAmt / 100).toLocaleString()}
                      {shop.marketplace.currency}
                    </span>
                  )}
                  <br />
                </span>
              )}
              {this.state.changeDiscountAmt ? (
                <div
                  style={{ margin: 0, fontSize: fontSize - 1, marginTop: 4 }}
                >
                  <input
                    style={{
                      fontSize: fontSize,
                      borderRadius: 5,
                      color: "#fff",
                      backgroundColor: "#FF9800",
                      border: "2px solid rgb(10, 10, 10)",
                      padding: 2,
                      paddingLeft: 5,
                      width: 60,
                      border: "solid 0px",
                    }}
                    defaultValue={order.discountAmount / 100}
                    onBlur={this.updateDiscountAmount.bind(this)}
                    type="number"
                    onKeyDown={this.updateDiscountAmount.bind(this)}
                  />
                </div>
              ) : (
                <div
                  style={{
                    color: "#fff",
                    backgroundColor: "#FF9800",
                    borderRadius: 5,
                    padding: 4,
                    margin: 0,
                    fontSize: fontSize - 1,
                    marginTop: 4,
                    float: "right",
                    width: order.discountAmount ? "auto" : 60,
                  }}
                  onClick={(e) => {
                    this.setState({ changeDiscountAmt: true });
                  }}
                >
                  -{(order.discountAmount / 100).toLocaleString()}
                  {shop.marketplace.currency}
                  <i
                    className="fal fa-tag"
                    style={{ color: "#fff", marginLeft: 4 }}
                  ></i>
                </div>
              )}
              <div style={{ position: "relative", top: 5, float: "right" }}>
                <span
                  style={{
                    color: "#fff",
                    backgroundColor: "rgb(50, 50, 50)",
                    borderRadius: 5,
                    padding: 4,
                    margin: 2,
                    fontSize: fontSize,
                  }}
                >
                  {(order.total / 100).toLocaleString()}
                  {shop.marketplace.currency}
                </span>
              </div>
            </td>
            <td style={{ textAlign: "center" }}>
              <SelectPayment
                payment_method={order.payment_method}
                methods={order.store[0].paymentMethods}
                updatePaymentMethod={this.updatePaymentMethod.bind(this)}
                payment_link={order.payment_link}
                fontSize={fontSize}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <PaymentStatus
                payment_status={order.payment_status}
                updatePaymentStatus={this.updatePaymentStatus.bind(this)}
                fontSize={fontSize}
              />
            </td>
            <td style={{ textAlign: "center", width: 95 }}>
              <span style={{ width: 95, display: "inline-block" }}>
                <SelectStatus
                  notify={this.props.notify}
                  notify2={this.props.notify2}
                  uuid={order.uuid}
                  name={order.name}
                  delivery_id={order.delivery_driver}
                  token={order.store_id}
                  store_slug={order.store_slug}
                  status={order.status}
                  payment_status={order.payment_status}
                  updateOrderStatus={this.updateOrderStatus.bind(this)}
                  shop={shop}
                  fontSize={fontSize}
                />
              </span>
            </td>
            <td style={{ textAlign: "center", position: "relative" }}>
              {/*
              <a onClick={(e)=>{e.preventDefault();this.printDocument(shop.apiAddress + '/api/orders/receipt.pdf?token=' + order.store_id + '&domain=' + order.store_slug  + '&uuid=' + order.uuid);this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer',display:'inline-block'}}>
                <i className="fas fa-print" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
              </a>
              <a onClick={(e)=>{e.preventDefault();this.showSendMessage();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer',display:'inline-block'}}>
                <i className="fas fa-comments-alt" aria-hidden="true" style={{color: '#007aff',fontSize:20, padding:5}}></i>
              </a>
            */}
              <a
                onClick={(e) => {
                  this.setState({ showMenu: !this.state.showMenu });
                }}
              >
                <i
                  className="fas fa-ellipsis-v"
                  style={{
                    cursor: "pointer",
                    fontSize: fontSize + 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                ></i>
              </a>
              {this.state.showSendMessage && (
                <SendMessage
                  uuid={order.uuid}
                  phone={order.phone}
                  email={order.email}
                  open={true}
                  showSendMessage={this.showSendMessage.bind(this)}
                  sendMessage={this.sendMessage.bind(this)}
                  staff={shop.staff._id}
                  customer_id={order.customer}
                  customer={order.name}
                  store_title={order.store_title}
                  store_phone={order.store_phone}
                  name={order.name}
                  delivery_link={order.delivery_link}
                  shop={shop}
                  messages={order.messages}
                  order={order}
                  loadOrders={this.props.loadOrders.bind(this)}
                  messageTemplates={order.store[0].messageTemplates}
                  imageDirectory={shop.imageDirectory}
                  showCamera={this.showCamera.bind(this)}
                />
              )}
              {this.state.showCamera && (
                <OrderPhoto
                  shop={shop}
                  showCamera={this.showCamera.bind(this)}
                  open={this.state.showCamera}
                  savePhoto={this.savePhoto.bind(this)}
                />
              )}
              {this.state.showMenu && (
                <div
                  className="row"
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    width: 80,
                    backgroundColor: "#fff",
                    border: "#efefef 1px solid",
                    left: -65,
                    top: 4,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      href={"tel:" + order.phone}
                      onClick={(e) => {
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-phone"
                        aria-hidden="true"
                        style={{
                          color: "rgb(0, 200, 83)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      href={"mailto:" + order.email}
                      onClick={(e) => {
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-envelope"
                        aria-hidden="true"
                        style={{
                          color: "rgb(0, 136, 254)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.showSendMessage();
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-comments-alt"
                        aria-hidden="true"
                        style={{ color: "#007aff", fontSize: 20, padding: 5 }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.showCamera();
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-camera"
                        aria-hidden="true"
                        style={{
                          color: "rgb(30, 30, 30)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    {/*<a href={shop.apiAddress + '/api/orders/receipt.pdf?token=' + order.store_id + '&domain=' + order.store_slug  + '&uuid=' + order.uuid} target="_blank">*/}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.printDocument(
                          shop.apiAddress +
                            "/api/orders/receipt.pdf?token=" +
                            order.store_id +
                            "&domain=" +
                            order.store_slug +
                            "&uuid=" +
                            order.uuid +
                            "&time=" +
                            Date.now()
                        );
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-print"
                        aria-hidden="true"
                        style={{
                          color: "rgb(253, 126, 20)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    {/*<a href={shop.apiAddress + '/api/orders/receipt.pdf?token=' + order.store_id + '&domain=' + order.store_slug  + '&uuid=' + order.uuid} target="_blank">*/}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={this.props.siteLogin.bind(this, order)}
                    >
                      <i
                        className="fal fa-sign-in-alt"
                        aria-hidden="true"
                        style={{
                          color: "rgb(253, 126, 20)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    {/*<a href={shop.apiAddress + '/api/orders/receipt.pdf?token=' + order.store_id + '&domain=' + order.store_slug  + '&uuid=' + order.uuid} target="_blank">*/}
                    {order.status !== "Driver Booked" &&
                      this.state.orderBooked === false && (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            this.showLaLaAddress();
                          }}
                        >
                          <img
                            src={shop.imageDirectory + "/images/lalamove.png"}
                            width={20}
                          />
                        </a>
                      )}
                  </div>

                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        this.setState({ showMenu: !this.state.showMenu });
                        shop.staff.openOrderCart(order.uuid);
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-shopping-cart"
                        aria-hidden="true"
                        style={{
                          color: "rgb(0, 200, 83)",
                          fontSize: 18,
                          padding: 5,
                          paddingTop: 0,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        this.setState({ showMenu: !this.state.showMenu });
                        shop.staff.printOrderCart(order);
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-print"
                        aria-hidden="true"
                        style={{
                          color: "#007AFE",
                          fontSize: 18,
                          padding: 5,
                          paddingTop: 0,
                        }}
                      ></i>
                    </a>
                  </div>

                  {order.payment_link && order.payment_link != "" && (
                    <div
                      style={{ textAlign: "center", width: "49%", padding: 4 }}
                    >
                      <a
                        href={order.payment_link}
                        onClick={(e) => {
                          this.setState({ showMenu: !this.state.showMenu });
                        }}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="fas fa-link"
                          aria-hidden="true"
                          style={{
                            color: "rgb(0, 136, 254)",
                            fontSize: 20,
                            padding: 5,
                          }}
                        ></i>
                      </a>
                    </div>
                  )}
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        window
                          .open(
                            shop.apiAddress +
                              "/api/orders/receipt.pdf?token=" +
                              order.store_id +
                              "&domain=" +
                              order.store_slug +
                              "&uuid=" +
                              order.uuid +
                              "&time=" +
                              Date.now(),
                            "_blank"
                          )
                          .focus();
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-print"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: 18,
                          padding: 5,
                          paddingTop: 0,
                        }}
                      ></i>
                    </a>
                  </div>
                  {order.taxCode && order.taxCode !== "" && (
                    <div
                      style={{ textAlign: "center", width: "49%", padding: 4 }}
                    >
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          shop.staff.downloadRedInvoice(order);
                          this.setState({ showMenu: !this.state.showMenu });
                        }}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="fas fa-file-invoice"
                          aria-hidden="true"
                          style={{
                            color: "red",
                            fontSize: 18,
                            padding: 5,
                            paddingTop: 0,
                          }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              )}
            </td>
            <td>
              {!this.state.showMore ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="far fa-caret-up"
                    style={{ color: "rgb(50, 50, 50)", fontSize: fontSize + 5 }}
                  ></i>
                </a>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="fas fa-caret-down"
                    style={{ color: "rgb(50, 50, 50)", fontSize: fontSize + 5 }}
                  ></i>
                </a>
              )}
            </td>
          </tr>
          {this.state.showMore && (
            <DetailedOrder
              order={order}
              layout={layout}
              shop={shop}
              showMore={this.showMore.bind(this)}
              deletePhoto={this.deletePhoto.bind(this)}
              lang={lang}
              fontSize={fontSize}
              lang_date={lang_date}
              loadOrders={this.props.loadOrders.bind(this)}
              sendMessage={this.sendMessage.bind(this)}
              showCamera={this.showCamera.bind(this)}
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div
          className="col-sm-4 col-md-2"
          style={{
            padding: 10,
            boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.5)",
            border: "1px solid " + order_color,
            background: "#fff",
            borderRadius: 2,
            marginLeft: 5,
          }}
        >
          <div className="row" style={{ margin: 0 }}>
            <div style={{ padding: 0, width: "20%", margin: 2 }}>
              <div
                style={{
                  color: "#fff",
                  backgroundColor: order_color,
                  borderRadius: 5,
                  padding: 2,
                  marginBottom: 4,
                  fontSize: fontSize + 2,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText("#" + order.uuid.substr(0, 6));
                  this.props.shop.alert("Text Copied", "", "info", "br");
                }}
              >
                #{order.uuid.substr(0, 6)}
              </div>
              <SelectOrderType
                order_type={order.order_type}
                orderTypes={shop.marketplace.layout.home.orderTypes}
                updateOrderType={this.updateOrderType.bind(this)}
                fontSize={fontSize}
              />
              {order.order_type === "delivery" && order.store.length > 0 && (
                <span>
                  <SelectDeliveryType
                    delivery_type={order.delivery_type}
                    deliveryTypes={order.store[0].deliveryTypes}
                    updateDeliveryType={this.updateDeliveryType.bind(this)}
                    showChangeDeliveryLink={this.showChangeDeliveryLink.bind(
                      this
                    )}
                    fontSize={fontSize}
                  />
                  {this.state.showChangeDeliveryLink && (
                    <ChangeDeliveryLink
                      delivery_link={order.delivery_link}
                      open={true}
                      showChangeDeliveryLink={this.showChangeDeliveryLink.bind(
                        this
                      )}
                      setDeliveryLink={this.setDeliveryLink.bind(this)}
                      shop={shop}
                    />
                  )}
                </span>
              )}
            </div>
            <div style={{ padding: 0, width: "20%", margin: 2 }}>
              <SelectDateTime
                status={order.status}
                deliveryDateTime={order.deliveryDateTime}
                deliveryModal={this.state.deliveryModal}
                shop={shop}
                uuid={order.uuid}
                fontSize={this.props.fontSize}
                setDeliveryTime={this.setDeliveryTime.bind(this)}
              />
            </div>
            <div style={{ textAlign: "center", width: 95, margin: 2 }}>
              <span style={{ width: 95, display: "inline-block" }}>
                <SelectStatus
                  uuid={order.uuid}
                  name={order.name}
                  delivery_id={order.delivery_driver}
                  token={order.store_id}
                  store_slug={order.store_slug}
                  status={order.status}
                  payment_status={order.payment_status}
                  updateOrderStatus={this.updateOrderStatus.bind(this)}
                  shop={shop}
                  fontSize={fontSize}
                />
              </span>
            </div>
            <div style={{ padding: 0, width: "60%", margin: 2 }}>
              {this.state.changeName ? (
                <input
                  style={{
                    fontSize: fontSize,
                    borderRadius: 5,
                    color: "#fff",
                    backgroundColor: "rgb(75, 75, 75)",
                    border: "2px solid rgb(10, 10, 10)",
                    padding: 2,
                    paddingLeft: 5,
                    minWidth: 80,
                    border: "solid 0px",
                  }}
                  defaultValue={order.name}
                  onBlur={this.updateOrderName.bind(this)}
                  onKeyDown={this.updateOrderName.bind(this)}
                />
              ) : (
                <div
                  style={{
                    fontSize: fontSize,
                    borderRadius: 5,
                    color: "#fff",
                    backgroundColor: "rgb(50, 50, 50)",
                    padding: 2,
                    paddingLeft: 5,
                    minWidth: 80,
                    border: "solid 0px",
                  }}
                  onClick={(e) => {
                    this.setState({ changeName: true });
                  }}
                >
                  {order.name}
                </div>
              )}
            </div>
            <div className="col-sm-12" style={{ padding: 0, margin: 2 }}>
              {order.entries.map((entry, i) => (
                <div
                  key={i}
                  style={{
                    width: 220,
                    color: entry.status === "Ready" ? "#333" : "#999",
                    backgroundColor:
                      entry.status === "Ready"
                        ? "rgb(255, 255, 255)"
                        : "rgb(255, 255, 255)",
                    borderRadius: 5,
                    padding: 4,
                    margin: 1,
                    fontSize: fontSize,
                  }}
                >
                  {entry.entryOptions.length > 0 &&
                  entry.entryOptions.length > 0 &&
                  entry.entryOptions[0].image ? (
                    <img
                      alt={entry.entryOptions[0].title}
                      src={shop.imageDirectory + entry.entryOptions[0].image}
                      title={entry.entryOptions[0].title}
                      style={{ maxHeight: 35, maxWidth: 35 }}
                    />
                  ) : (
                    <img
                      alt={entry.title}
                      src={shop.imageDirectory + entry.productImage}
                      title={entry.title}
                      style={{ maxHeight: 35, maxWidth: 35 }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <span
                    style={{
                      textDecoration:
                        entry.status === "Ready" ? "line-through" : "",
                    }}
                  >
                    {entry.quantity}&nbsp;x&nbsp;{entry.title} &nbsp;&nbsp;
                    <br />
                    {entry.entryOptions && entry.entryOptions.length > 0 && (
                      <span>{entry.entryOptions[0].title}</span>
                    )}
                  </span>
                  <EntryStatus
                    id={entry._id}
                    updateEntryStatus={this.updateEntryStatus.bind(this)}
                    status={entry.status}
                  />
                  <br />
                  {entry.instructions && (
                    <div className="instructionsBox">
                      <span
                        style={{ fontSize: fontSize + 2, fontStyle: "normal" }}
                      >
                        {entry.instructions}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="col-sm-12" style={{ padding: 0, margin: 2 }}>
              {order.order_type === "delivery" && (
                <div>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      this.showChangeAddress();
                    }}
                  >
                    {/*order.address.apt && <span><span style={{color:'#fff',backgroundColor:'rgb(110, 110, 110)',borderRadius:5,padding:2,marginBottom:4,fontSize:fontSize}}>{order.address && order.address.apt}</span><br /></span>*/}
                    <div
                      className="addressBox"
                      style={{ display: "inline-block" }}
                    >
                      {order.address && order.address.street !== "" ? (
                        <span
                          style={{
                            backgroundColor: "rgb(150, 150, 150)",
                            fontSize: fontSize,
                          }}
                        >
                          <span>
                            {order.address &&
                              order.address.apt &&
                              order.address.apt + " | "}
                          </span>
                          {order.address && order.address.street}
                        </span>
                      ) : (
                        <span style={{ fontSize: fontSize }}>
                          {order.address &&
                            order.address.apt &&
                            order.address.apt + " | "}
                          {order.address && order.address.label_address}
                        </span>
                      )}
                    </div>
                    {order.address && order.address.ward && (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "rgb(0, 0, 0)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: fontSize - 1,
                          whiteSpace: "normal",
                          display: "inline-block",
                        }}
                      >
                        {order.address && order.address.ward}
                      </span>
                    )}
                    {order.address && order.address.suburb && (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "rgb(0, 0, 0)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: fontSize - 1,
                          whiteSpace: "normal",
                          display: "inline-block",
                        }}
                      >
                        {order.address && order.address.suburb}
                      </span>
                    )}
                    {order.address && order.address.delivery_instructions && (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "rgb(110, 110, 110)",
                          borderRadius: 5,
                          padding: 2,
                          margin: 2,
                          fontSize: fontSize - 1,
                          whiteSpace: "normal",
                          display: "inline-block",
                        }}
                      >
                        {order.address && order.address.delivery_instructions}
                      </span>
                    )}
                  </a>
                  {this.state.showChangeAddress && (
                    <ChangeDeliveryAddress
                      address={order.address}
                      open={true}
                      updateAddress={this.updateAddress.bind(this)}
                      showChangeAddress={this.showChangeAddress.bind(this)}
                      gps={order.address && order.address.gps}
                      shop={shop}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="col-sm-3" style={{ minWidth: 95 }}>
              {order.order_type === "delivery" && order.deliveryAmt > 0 && (
                <span>
                  <i
                    className="fas fa-truck"
                    style={{ color: "rgb(150, 150, 150)", fontSize: fontSize }}
                  />
                  &nbsp;
                  {this.state.changeDeliveryAmt ? (
                    <input
                      style={{
                        fontSize: fontSize,
                        borderRadius: 5,
                        color: "#fff",
                        backgroundColor: "rgb(125, 125, 125)",
                        border: "2px solid rgb(10, 10, 10)",
                        padding: 2,
                        paddingLeft: 5,
                        width: 60,
                        border: "solid 0px",
                      }}
                      defaultValue={order.deliveryAmt / 100}
                      onBlur={this.updateDeliveryAmt.bind(this)}
                      type="number"
                      onKeyDown={this.updateDeliveryAmt.bind(this)}
                    />
                  ) : (
                    <span
                      style={{
                        color: "#fff",
                        backgroundColor: "rgb(150, 150, 150)",
                        borderRadius: 5,
                        padding: 4,
                        margin: 2,
                        fontSize: fontSize,
                      }}
                      onClick={(e) => {
                        this.setState({ changeDeliveryAmt: true });
                      }}
                    >
                      {(order.deliveryAmt / 100).toLocaleString()}
                      {shop.marketplace.currency}
                    </span>
                  )}
                  <br />
                </span>
              )}
              <span style={{ position: "relative", top: 5 }}>
                <i
                  className="fas fa-equals"
                  style={{ color: "rgb(50, 50, 50)", fontSize: fontSize }}
                />
                &nbsp;
                <span
                  style={{
                    color: "#fff",
                    backgroundColor: "rgb(50, 50, 50)",
                    borderRadius: 5,
                    padding: 4,
                    margin: 2,
                    fontSize: fontSize,
                  }}
                >
                  {(order.total / 100).toLocaleString()}
                  {shop.marketplace.currency}
                </span>
              </span>
            </div>
            <div className="col-sm-3" style={{ textAlign: "center" }}>
              <SelectPayment
                payment_method={order.payment_method}
                methods={order.store[0].paymentMethods}
                updatePaymentMethod={this.updatePaymentMethod.bind(this)}
                payment_link={order.payment_link}
                fontSize={fontSize}
              />
            </div>
            <div className="col-sm-3" style={{ textAlign: "center" }}>
              <PaymentStatus
                payment_status={order.payment_status}
                updatePaymentStatus={this.updatePaymentStatus.bind(this)}
                fontSize={fontSize}
              />
            </div>
            <div className="col-sm-12">
              {order.messages &&
                order.messages.map((message, i) => (
                  <div key={i}>
                    {/* {i > order.messages.length - 3 && ( */}
                    <div className="messagesBox">
                      <span style={{ fontSize: fontSize }}>
                        {message.sendSMS && (
                          <i className="fas fa-sms" style={{ margin: 2 }}></i>
                        )}
                        {message.sendEmail && (
                          <i
                            className="fas fa-envelope"
                            style={{ margin: 2 }}
                          ></i>
                        )}
                        <i
                          className="fas fa-sticky-note"
                          style={{ margin: 2 }}
                        ></i>
                        &nbsp;&nbsp;
                        {new Date(message.createdAt).toLocaleDateString(
                          lang_date,
                          {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                          }
                        )}
                        &nbsp;&nbsp;
                        {new Date(message.createdAt).toLocaleTimeString(
                          lang_date,
                          {}
                        )}
                        {message.subject !== "" && (
                          <b>&nbsp;&nbsp;{message.subject}</b>
                        )}
                        &nbsp;&nbsp;{message.message}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                ))}
            </div>
            <div
              className="col-sm-12"
              style={{ textAlign: "center", position: "relative" }}
            >
              <div style={{ textAlign: "center", width: "49%", padding: 4 }}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.printDocument(
                      shop.apiAddress +
                        "/api/orders/receipt.pdf?token=" +
                        order.store_id +
                        "&domain=" +
                        order.store_slug +
                        "&uuid=" +
                        order.uuid +
                        "&time=" +
                        Date.now()
                    );
                    this.setState({ showMenu: !this.state.showMenu });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fas fa-print"
                    aria-hidden="true"
                    style={{
                      color: "rgb(253, 126, 20)",
                      fontSize: 20,
                      padding: 5,
                    }}
                  ></i>
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.showSendMessage();
                    this.setState({ showMenu: !this.state.showMenu });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fas fa-comments-alt"
                    aria-hidden="true"
                    style={{
                      color: "rgb(253, 126, 20)",
                      fontSize: 20,
                      padding: 5,
                    }}
                  ></i>
                </a>
              </div>
              <span
                onClick={(e) => {
                  this.setState({ showMenu: !this.state.showMenu });
                }}
              >
                <i
                  className="fas fa-ellipsis-v"
                  style={{
                    cursor: "pointer",
                    fontSize: fontSize + 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                ></i>
              </span>
              {this.state.showSendMessage && (
                <SendMessage
                  uuid={order.uuid}
                  phone={order.phone}
                  email={order.email}
                  open={true}
                  showSendMessage={this.showSendMessage.bind(this)}
                  sendMessage={this.sendMessage.bind(this)}
                  staff={shop.staff._id}
                  customer_id={order.customer}
                  customer={order.name}
                  store_title={order.store_title}
                  store_phone={order.store_phone}
                  name={order.name}
                  delivery_link={order.delivery_link}
                  shop={shop}
                  loadOrders={this.props.loadOrders.bind(this)}
                  messageTemplates={order.store[0].messageTemplates}
                />
              )}
              {this.state.showCamera && (
                <OrderPhoto
                  shop={shop}
                  showCamera={this.showCamera.bind(this)}
                  open={this.state.showCamera}
                  savePhoto={this.savePhoto.bind(this)}
                />
              )}
              {this.state.showMenu && (
                <div
                  className="row"
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    width: 80,
                    backgroundColor: "#fff",
                    border: "#efefef 1px solid",
                    left: -65,
                    top: 4,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      href={"tel:" + order.phone}
                      onClick={(e) => {
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-phone"
                        aria-hidden="true"
                        style={{
                          color: "rgb(0, 200, 83)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      href={"mailto:" + order.email}
                      onClick={(e) => {
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-envelope"
                        aria-hidden="true"
                        style={{
                          color: "rgb(0, 136, 254)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.showSendMessage();
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-comments-alt"
                        aria-hidden="true"
                        style={{
                          color: "rgb(253, 126, 20)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.showCamera();
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-camera"
                        aria-hidden="true"
                        style={{
                          color: "rgb(30, 30, 30)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "49%", padding: 4 }}
                  >
                    {/*<a href={shop.apiAddress + '/api/orders/receipt.pdf?token=' + order.store_id + '&domain=' + order.store_slug  + '&uuid=' + order.uuid} target="_blank">*/}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.printDocument(
                          shop.apiAddress +
                            "/api/orders/receipt.pdf?token=" +
                            order.store_id +
                            "&domain=" +
                            order.store_slug +
                            "&uuid=" +
                            order.uuid
                        );
                        this.setState({ showMenu: !this.state.showMenu });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fas fa-print"
                        aria-hidden="true"
                        style={{
                          color: "rgb(253, 126, 20)",
                          fontSize: 20,
                          padding: 5,
                        }}
                      ></i>
                    </a>
                  </div>
                  {order.payment_link && order.payment_link != "" && (
                    <div
                      style={{ textAlign: "center", width: "49%", padding: 4 }}
                    >
                      <a
                        href={order.payment_link}
                        onClick={(e) => {
                          this.setState({ showMenu: !this.state.showMenu });
                        }}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="fas fa-link"
                          aria-hidden="true"
                          style={{
                            color: "rgb(0, 136, 254)",
                            fontSize: 20,
                            padding: 5,
                          }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-sm-12">
              {!this.state.showMore ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="down-arrow"
                    style={{
                      borderColor: "rgb(50, 50, 50)",
                      fontSize: fontSize,
                    }}
                  ></i>
                </a>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <i
                    className="up-arrow"
                    style={{
                      borderColor: "rgb(50, 50, 50)",
                      fontSize: fontSize,
                    }}
                  ></i>
                </a>
              )}
            </div>
            {this.state.showMore && (
              <DetailedOrder
                order={order}
                layout={layout}
                shop={shop}
                showMore={this.showMore.bind(this)}
                deletePhoto={this.deletePhoto.bind(this)}
                lang={lang}
                lang_date={lang_date}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

class CountDown extends React.Component {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.timeTillDate !== this.state.timeTillDate) {
      this.setState({ order: nextProps.timeTillDate });
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props;
      const now = new Date();

      var eventTime = moment(timeTillDate, "DD-MM-YYYY HH:mm:ss").unix();
      var currentTime = moment().unix();
      var diffTime = eventTime - currentTime;
      var countdown = moment.duration(diffTime * 1000, "milliseconds");

      const days = countdown.days();
      const hours = countdown.hours();
      const minutes = countdown.minutes();
      const seconds = countdown.seconds();

      this.setState({ days, hours, minutes, seconds });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    const daysRadius = mapNumber(days, 30, 0, 0, 360);
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

    // if(!seconds) {
    //   return null;
    // }

    return (
      <InlineCss
        stylesheet={
          `
        .countdown-wrapper {
            float:left;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .countdown-item {
            color: ` +
          this.props.color +
          ` ;
            font-size: ` +
          Math.round(this.props.size / 2 / 1.25) +
          `px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: ` +
          Math.round(this.props.size / 2 / 0.833) +
          `px;
            margin: ` +
          5 +
          `px;
            padding-top: ` +
          Math.round(this.props.size / 2 / 1.25) +
          `px;
            position: relative;
            width: ` +
          this.props.size +
          `px;
            height: ` +
          this.props.size +
          `px;
        }

        .countdown-item span {
            color:  ` +
          this.props.color +
          ` ;
            font-size: ` +
          Math.round(this.props.size / 2 / 3.571) +
          `px;
            font-weight: 600;
            position: relative;
            top:-` +
          Math.round(this.props.size / 2 / 1.666) +
          `px;
            text-transform: uppercase;
        }

        .countdown-svg {
            position: absolute;
            top: 0;
            left: 0;
            width: ` +
          this.props.size +
          `px;
            height: ` +
          this.props.size +
          `px;
        }
      `
        }
      >
        <div>
          <div className="countdown-wrapper">
            {hours > 0 && hours && (
              <div className="countdown-item">
                <SVGCircle
                  size={Math.round(this.props.size / 2) - 2}
                  radius={hoursRadius}
                  color={this.props.color}
                />
                {hours}
                <span>hrs</span>
              </div>
            )}
            {minutes > 0 && minutes && (
              <div className="countdown-item">
                <SVGCircle
                  size={Math.round(this.props.size / 2) - 2}
                  radius={minutesRadius}
                  color={this.props.color}
                />
                {minutes}
                <span>mins</span>
              </div>
            )}
            {seconds > 0 && seconds && (
              <div className="d-none d-lg-block">
                <div className="countdown-item">
                  <SVGCircle
                    size={Math.round(this.props.size / 2) - 2}
                    radius={secondsRadius}
                    color={this.props.color}
                  />
                  {seconds}
                  <span>secs</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </InlineCss>
    );
  }
}

const SVGCircle = ({ size, radius, color }) => (
  <svg className="countdown-svg">
    <path
      fill="none"
      stroke={color}
      strokeWidth={Math.round(size / 12.5)}
      d={describeArc(size + 2, size, size, 0, radius)}
    />
  </svg>
);

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (
    ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  );
}

class SelectDateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deliveryDateTime: this.props.deliveryDateTime };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deliveryDateTime !== this.state.deliveryDateTime) {
      this.setState({ deliveryDateTime: nextProps.deliveryDateTime });
    }
  }

  showChangeDate() {
    this.setState({ showChangeDate: !this.state.showChangeDate });
  }

  setDeliveryTime(deliveryDateTime) {
    this.props.setDeliveryTime(deliveryDateTime);
    this.setState({ deliveryDateTime: deliveryDateTime });
  }

  render() {
    var shop = this.props.shop;
    var lang = shop.staff.lang;

    var fontSize = this.props.fontSize;

    var lang_date = "en-US";
    if (lang === "vi") lang_date = "vi-VN";
    if (lang === "ja") lang_date = "ja-JP";
    if (lang === "zh") lang_date = "zh-CN";
    if (lang === "ko") lang_date = "ko-KR";

    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    var bgColor = "rgb(100, 100, 100)";
    var timeSize = fontSize;
    if (
      today.toDateString() ===
        new Date(this.state.deliveryDateTime).toDateString() &&
      this.props.status !== "Order Complete" &&
      this.props.status !== "Order Cancelled"
    ) {
      bgColor = "#fce8b2";
      bgColor = "rgb(0, 200, 83)"; //rgb(255, 187, 40)';
      if (this.props.status === "Order Cancelled") bgColor = "rgb(255, 68, 68)";
      timeSize = fontSize + 1;

      if (today > new Date(this.state.deliveryDateTime))
        bgColor = "rgb(0, 200, 83)";
      // timeSize = 'calc(7px + 0.9vw)';
    } else if (
      tomorrow.toDateString() ===
      new Date(this.state.deliveryDateTime).toDateString()
    ) {
      bgColor = "#b7e1cd";
    } else if (
      tomorrow.toDateString() <
      new Date(this.state.deliveryDateTime).toDateString()
    ) {
      bgColor = "rgb(0, 136, 254)";
    }

    var span_style = {
      fontSize: timeSize,
      fontWeight: "normal",
      display: "inline-block",
      color: "#000",
      borderRadius: 5,
      margin: 0,
      padding: 4,
    };

    return (
      <div style={{ textAlign: "center", width: 65 }}>
        {this.state.showChangeDate && (
          <ChangeDeliveryTime
            deliveryDateTime={this.state.deliveryDateTime}
            open={true}
            showChangeDate={this.showChangeDate.bind(this)}
            setDeliveryTime={this.setDeliveryTime.bind(this)}
            shop={shop}
          />
        )}
        <a
          onClick={(e) => {
            e.preventDefault();
            this.showChangeDate();
          }}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "inline-block" }}>
            <span style={span_style}>
              {new Date(this.state.deliveryDateTime).toLocaleTimeString(
                lang_date,
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </span>
          </div>
          <div style={{ display: "inline-block" }}>
            <span
              style={{
                fontSize: fontSize - 2 + 0.5,
                fontWeight: "normal",
                display: "inline-block",
                backgroundColor: bgColor,
                color: "#fff",
                borderRadius: 5,
                margin: 2,
                padding: 2,
              }}
            >
              {new Date(this.state.deliveryDateTime).toLocaleDateString(
                lang_date,
                {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                }
              )}
            </span>
          </div>
        </a>
      </div>
    );
  }
}

// Status - Order Pending, Order Confirmed, Order Prepared, Driver Booked, Order in Transit, Order Completed, Order Cancelled.

//  Order Pending,
//  Order Confirmed,
//  Order Ready (order prepared),
//  Order in transit,
//  Driver Booked,
//  Order Complete,
//  Order Cancelled
class SelectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showStatus: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showStatus !== this.state.showStatus) {
      this.setState({ showStatus: nextProps.showStatus });
    }
  }

  updateStatus(event) {
    var status = event.target.value;
    if (status === "Order Complete") this.props.notify2();
    if (status === "Order Cancelled") {
      if (
        window.confirm(
          "Are you sure you want to cancel " + event.target.name + "'s order?"
        )
      ) {
        this.props.updateOrderStatus(status);
      }
    } else {
      this.props.updateOrderStatus(status);
    }
  }
  showStatus() {
    this.setState({ showStatus: !this.state.showStatus });
  }

  render() {
    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var fontSize = this.props.fontSize;

    return (
      <span style={{ position: "relative", top: 2 }}>
        {/*<a onClick={this.showStatus.bind(this)}>
                  <span style={{color:'#fff',background: this.props.status === 'Order Cancelled' ? '#f44336' : this.props.status === 'Order Confirmed'? '#4CAF50' : this.props.status === 'Order Prepared' ? '#009688' : this.props.status === 'Order in Transit' ? '#795548' : this.props.status === 'Driver Booked' ? '#FF9800' : (this.props.status === 'Order Complete') ? '#2196F3' : '#E91E63',borderRadius:5,padding:5,paddingLeft:10,paddingRight:10, width:79,height:21,fontSize:fontSize,marginTop:4}}>{this.props.status.replace('Order ','')}</span>
                </a>
                {this.state.showStatus &&
                */}
        <select
          value={this.props.status}
          name={this.props.name}
          onChange={this.updateStatus.bind(this)}
          style={{
            border: "0px solid #efefef",
            position: "relative",
            right: 0,
            top: -5,
            height: 21,
            textIndent: "0.01px",
            fontSize: 10,
            marginBottom: 24,
            background:
              this.props.status === "Order Cancelled"
                ? "#f44336"
                : this.props.status === "Order Confirmed"
                ? "#4CAF50"
                : this.props.status === "Order Prepared"
                ? "#009688"
                : this.props.status === "Order in Transit"
                ? "#795548"
                : this.props.status === "Driver Booked"
                ? "#FF9800"
                : this.props.status === "Order Complete"
                ? "#2196F3"
                : "#E91E63",
            color: "#fff",
            borderRadius: 5,
            outline: "none",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <option
            value="Order Cancelled"
            style={{ background: "#f44336", color: "#fff" }}
          >
            Cancelled
          </option>
          <option
            value="Order Pending"
            style={{ background: "#E91E63", color: "#fff" }}
          >
            Pending
          </option>
          <option
            value="Order Confirmed"
            style={{ background: "#4CAF50", color: "#fff" }}
          >
            Confirmed
          </option>
          <option
            value="Order Prepared"
            style={{ background: "#009688", color: "#fff" }}
          >
            Prepared
          </option>
          <option
            value="Driver Booked"
            style={{ background: "#FF9800", color: "#fff" }}
          >
            Driver Booked
          </option>
          <option
            value="Order in Transit"
            style={{ background: "#795548", color: "#fff" }}
          >
            In Transit
          </option>
          <option
            value="Order Complete"
            style={{ background: "#2196F3", color: "#fff" }}
          >
            Complete
          </option>
        </select>
      </span>
    );
  }
}

class PaymentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { payment_status: this.props.payment_status };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.payment_status !== this.state.payment_status) {
      this.setState({ payment_status: nextProps.payment_status });
    }
  }

  updateStatus(status) {
    this.setState({ payment_status: status });
    this.props.updatePaymentStatus(status);
  }

  render() {
    var fontSize = this.props.fontSize;

    return (
      <div style={{ position: "relative", display: "inline-block", top: -2 }}>
        {this.state.payment_status === "Not Paid" ? (
          <a
            onClick={(e) => {
              if (window.confirm("Mark as 'Paid'?")) this.updateStatus("Paid");
            }}
          >
            <i
              className="fas fa-dollar-sign"
              style={{
                fontSize: fontSize + 6,
                verticalAlign: "middle",
                WebkitTextFillColor: "white",
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor: "rgb(255, 68, 68)",
              }}
            ></i>
          </a>
        ) : (
          <a
            onClick={(e) => {
              if (window.confirm("Mark as 'Not Paid'?"))
                this.updateStatus("Not Paid");
            }}
          >
            <i
              className="fas fa-dollar-sign"
              style={{
                fontSize: fontSize + 6,
                verticalAlign: "middle",
                color: "rgb(0, 200, 83)",
              }}
            ></i>
          </a>
        )}
      </div>
    );
  }
}

class EntryStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: this.props.status };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.payment_status !== this.state.status) {
      this.setState({ status: nextProps.status });
    }
  }

  updateStatus(status) {
    this.setState({ status: status });
    this.props.updateEntryStatus(this.props.id, status);
  }

  render() {
    var fontSize = this.props.fontSize;

    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          top: -2,
          float: "right",
          marginRight: 5,
        }}
      >
        {this.state.status === "Ready" ? (
          <a
            onClick={(e) => {
              this.updateStatus("Not Ready");
            }}
          >
            <i
              className="far fa-check"
              style={{
                fontSize: fontSize + 2,
                verticalAlign: "middle",
                color: "rgb(0, 200, 83)",
              }}
            ></i>
          </a>
        ) : (
          <a
            onClick={(e) => {
              this.updateStatus("Ready");
            }}
          >
            <i
              className="far fa-check"
              style={{
                fontSize: fontSize + 2,
                verticalAlign: "middle",
                WebkitTextFillColor: "white",
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor: "rgb(200, 200, 200)",
              }}
            ></i>
          </a>
        )}
      </div>
    );
  }
}

class SelectPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false, payment_method: this.props.payment_method };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.payment_method !== this.state.payment_method) {
      this.setState({ payment_method: nextProps.payment_method });
    }
  }

  updatePaymentMethod(method) {
    this.setState({ payment_method: method });
    this.props.updatePaymentMethod(method);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  render() {
    var fontSize = this.props.fontSize;

    return (
      <div style={{ position: "relative", display: "inline-block", top: -2 }}>
        <span onClick={this.showMenu}>
          {this.props.payment_method === "paypal" ||
          this.props.payment_method === "creditcard" ? (
            <i
              className="fab fa-paypal"
              style={{
                fontSize: fontSize + 6,
                verticalAlign: "middle",
                color: "rgb(0, 155, 225)",
              }}
            ></i>
          ) : this.props.payment_method === "bank" ? (
            <i
              className="far fa-university"
              style={{
                fontSize: fontSize + 6,
                verticalAlign: "middle",
                color: "rgb(0, 155, 225)",
              }}
            ></i>
          ) : (
            <i
              className="fal fa-money-bill"
              style={{
                fontSize: fontSize + 6,
                verticalAlign: "middle",
                color: "rgb(0, 155, 225)",
              }}
            ></i>
          )}
        </span>

        {this.state.showMenu ? (
          <div
            className="payment_method"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 5,
              background: "#fff",
              borderRadius: 1,
              border: "0px solid #efefef",
            }}
            ref={(element) => {
              this.dropdownMenu = element;
            }}
          >
            <ul style={{ listStyleType: "none" }}>
              {this.props.methods.map((type, i) => {
                if (type.slug === "paypal" || type.slug === "creditcard") {
                  return (
                    <li
                      key={i}
                      value={type.slug}
                      onClick={(e) => {
                        e.preventDefault();
                        this.updatePaymentMethod("paypal");
                      }}
                      className="fab fa-paypal"
                      style={{
                        fontSize: fontSize + 6,
                        verticalAlign: "middle",
                        color: "rgb(0, 155, 225)",
                        textAlign: "center",
                      }}
                    ></li>
                  );
                } else if (type.slug === "bank") {
                  return (
                    <li
                      key={i}
                      value={type.slug}
                      onClick={(e) => {
                        e.preventDefault();
                        this.updatePaymentMethod("bank");
                      }}
                      className="far fa-university"
                      style={{
                        fontSize: fontSize + 6,
                        verticalAlign: "middle",
                        color: "rgb(0, 155, 225)",
                        textAlign: "center",
                      }}
                    ></li>
                  );
                } else {
                  return (
                    <li
                      key={i}
                      value={type.slug}
                      onClick={(e) => {
                        e.preventDefault();
                        this.updatePaymentMethod("cash");
                      }}
                      className="fal fa-money-bill"
                      style={{
                        fontSize: fontSize + 6,
                        verticalAlign: "middle",
                        color: "rgb(0, 155, 225)",
                        textAlign: "center",
                      }}
                    ></li>
                  );
                }
              })}
            </ul>
          </div>
        ) : null}
        {(this.props.payment_method === "paypal" ||
          this.props.payment_method === "creditcard") &&
          this.props.payment_link &&
          this.props.payment_link != "" && (
            <a
              href={this.props.payment_link}
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <br />
              <i
                className="fas fa-link"
                aria-hidden="true"
                style={{
                  color: "rgb(0, 136, 254)",
                  fontSize: fontSize + 6,
                  padding: 5,
                }}
              ></i>
            </a>
          )}
      </div>
    );
  }
}

class SelectDeliveryType extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false, delivery_type: this.props.delivery_type };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delivery_type !== this.state.delivery_type) {
      this.setState({ delivery_type: nextProps.delivery_type });
    }
  }

  updateDeliveryType(method) {
    this.setState({ delivery_type: method });
    this.props.updateDeliveryType(method);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  render() {
    var deliveryTypes = this.props.deliveryTypes;
    var delivery_type = this.props.delivery_type;
    var icon;
    var text;

    var fontSize = this.props.fontSize;

    if (deliveryTypes)
      icon =
        deliveryTypes[
          deliveryTypes.findIndex((type) => type.text === delivery_type)
        ];
    if (icon) {
      text = icon.text;
      icon = icon.icon;
    }
    return (
      <div style={{ position: "relative", display: "inline-block", margin: 2 }}>
        <a>
          <span onClick={this.showMenu}>
            {icon && icon.indexOf("fa") > -1 ? (
              <i
                className={icon}
                style={{
                  fontSize: fontSize + 6,
                  verticalAlign: "middle",
                  color: "rgb(255, 128, 66)",
                  margin: 2,
                }}
              ></i>
            ) : (
              <img src={icon} style={{ maxHeight: 20 }} alt={text} />
            )}
          </span>
          {this.state.showMenu ? (
            <div
              className="delivery_type"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 5,
                background: "#fff",
                borderRadius: 1,
                border: "0px solid #efefef",
              }}
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul
                style={{
                  listStyleType: "none",
                  border: "#efefef 1px solid",
                  padding: 2,
                }}
              >
                {this.props.deliveryTypes.map((type, i) => (
                  <li
                    key={i}
                    value={type.text}
                    onClick={(e) => {
                      e.preventDefault();
                      this.updateDeliveryType(type.text);
                    }}
                    className={type.icon.indexOf("fa") > -1 ? type.icon : ""}
                    style={{
                      fontSize: fontSize + 6,
                      verticalAlign: "middle",
                      color: "rgb(255, 128, 66)",
                      textAlign: "center",
                    }}
                  >
                    {(type.icon.indexOf("jpg") > -1 ||
                      type.icon.indexOf("png") > -1) && (
                      <img
                        src={type.icon}
                        style={{ maxHeight: 20 }}
                        alt={type.text}
                      />
                    )}
                  </li>
                ))}
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.showChangeDeliveryLink();
                  }}
                  className="fas fa-link"
                  style={{
                    fontSize: fontSize + 6,
                    verticalAlign: "middle",
                    color: "rgb(0, 155, 225)",
                    textAlign: "center",
                  }}
                ></li>
              </ul>
            </div>
          ) : null}
        </a>
      </div>
    );
  }
}
class SelectOrderType extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false, order_type: this.props.order_type };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.order_type !== this.state.order_type) {
      this.setState({ order_type: nextProps.order_type });
    }
  }

  updateOrderType(method) {
    this.setState({ order_type: method });
    this.props.updateOrderType(method);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  render() {
    var fontSize = this.props.fontSize;

    return (
      <div style={{ position: "relative", display: "inline-block", margin: 2 }}>
        <a>
          <span onClick={this.showMenu}>
            {this.props.order_type === "eatin" ? (
              <i
                className="fas fa-utensils"
                style={{
                  fontSize: fontSize + 6,
                  verticalAlign: "middle",
                  color: "rgb(0, 155, 225)",
                }}
              ></i>
            ) : this.props.order_type === "pickup" ? (
              <i
                className="fas fa-box"
                style={{
                  fontSize: fontSize + 6,
                  verticalAlign: "middle",
                  color: "rgb(0, 155, 225)",
                }}
              ></i>
            ) : (
              <i
                className="fas fa-truck"
                style={{
                  fontSize: fontSize + 6,
                  verticalAlign: "middle",
                  color: "rgb(0, 155, 225)",
                }}
              ></i>
            )}
          </span>

          {this.state.showMenu ? (
            <div
              className="order_type"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 5,
                background: "#fff",
                borderRadius: 1,
                border: "0px solid #efefef",
              }}
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul style={{ listStyleType: "none" }}>
                {this.props.orderTypes.map((type, i) => {
                  if (type.text === "eatin") {
                    return (
                      <li
                        key={i}
                        value={type.text}
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateOrderType("eatin");
                        }}
                        className="fas fa-utensils"
                        style={{
                          fontSize: fontSize + 6,
                          verticalAlign: "middle",
                          color: "rgb(0, 155, 225)",
                          textAlign: "center",
                          marginBottom: 4,
                        }}
                      ></li>
                    );
                  } else if (type.text === "pickup") {
                    return (
                      <li
                        key={i}
                        value={type.text}
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateOrderType("pickup");
                        }}
                        className="fas fa-box"
                        style={{
                          fontSize: fontSize + 6,
                          verticalAlign: "middle",
                          color: "rgb(0, 155, 225)",
                          textAlign: "center",
                          marginBottom: 4,
                        }}
                      ></li>
                    );
                  } else {
                    return (
                      <li
                        key={i}
                        value={type.text}
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateOrderType("delivery");
                        }}
                        className="fas fa-truck"
                        style={{
                          fontSize: fontSize + 6,
                          verticalAlign: "middle",
                          color: "rgb(0, 155, 225)",
                          textAlign: "center",
                          marginBottom: 4,
                        }}
                      ></li>
                    );
                  }
                })}
              </ul>
            </div>
          ) : null}
        </a>
      </div>
    );
  }
}
const mdColors = [
  "#F44336",
  // "#FFEBEE",
  // "#FFCDD2",
  "#EF9A9A",
  "#E57373",
  "#EF5350",
  "#F44336",
  "#E53935",
  "#D32F2F",
  "#C62828",
  "#B71C1C",
  // "#FF8A80",
  // "#FF5252",
  // "#FF1744",
  "#D50000",
  "#E91E63",
  "#FCE4EC",
  "#F8BBD0",
  "#F48FB1",
  "#F06292",
  "#EC407A",
  "#E91E63",
  "#D81B60",
  "#C2185B",
  "#AD1457",
  "#880E4F",
  // "#FF80AB",
  // "#FF4081",
  "#F50057",
  "#C51162",
  "#9C27B0",
  // "#F3E5F5",
  "#E1BEE7",
  "#CE93D8",
  "#BA68C8",
  "#AB47BC",
  "#9C27B0",
  "#8E24AA",
  "#7B1FA2",
  "#6A1B9A",
  "#4A148C",
  "#EA80FC",
  "#E040FB",
  "#D500F9",
  "#AA00FF",
  "#673AB7",
  "#EDE7F6",
  "#D1C4E9",
  "#B39DDB",
  "#9575CD",
  "#7E57C2",
  "#673AB7",
  "#5E35B1",
  "#512DA8",
  "#4527A0",
  "#311B92",
  "#B388FF",
  "#7C4DFF",
  "#651FFF",
  "#6200EA",
  "#3F51B5",
  "#E8EAF6",
  "#C5CAE9",
  "#9FA8DA",
  "#7986CB",
  "#5C6BC0",
  "#3F51B5",
  "#3949AB",
  "#303F9F",
  "#283593",
  "#1A237E",
  "#8C9EFF",
  "#536DFE",
  "#3D5AFE",
  "#304FFE",
  "#2196F3",
  "#E3F2FD",
  "#BBDEFB",
  "#90CAF9",
  "#64B5F6",
  "#42A5F5",
  "#2196F3",
  "#1E88E5",
  "#1976D2",
  "#1565C0",
  "#0D47A1",
  "#82B1FF",
  "#448AFF",
  "#2979FF",
  "#2962FF",
  "#03A9F4",
  "#E1F5FE",
  "#B3E5FC",
  "#81D4FA",
  "#4FC3F7",
  "#29B6F6",
  "#03A9F4",
  "#039BE5",
  "#0288D1",
  "#0277BD",
  "#01579B",
  "#80D8FF",
  "#40C4FF",
  "#00B0FF",
  "#0091EA",
  "#00BCD4",
  "#E0F7FA",
  "#B2EBF2",
  "#80DEEA",
  "#4DD0E1",
  "#26C6DA",
  "#00BCD4",
  "#00ACC1",
  "#0097A7",
  "#00838F",
  "#006064",
  "#84FFFF",
  "#18FFFF",
  "#00E5FF",
  "#00B8D4",
  "#009688",
  "#E0F2F1",
  "#B2DFDB",
  "#80CBC4",
  "#4DB6AC",
  "#26A69A",
  "#009688",
  "#00897B",
  "#00796B",
  "#00695C",
  "#004D40",
  "#A7FFEB",
  "#64FFDA",
  "#1DE9B6",
  "#00BFA5",
  "#4CAF50",
  "#E8F5E9",
  "#C8E6C9",
  "#A5D6A7",
  "#81C784",
  "#66BB6A",
  "#4CAF50",
  "#43A047",
  "#388E3C",
  "#2E7D32",
  "#1B5E20",
  "#B9F6CA",
  "#69F0AE",
  "#00E676",
  "#00C853",
  "#8BC34A",
  "#F1F8E9",
  "#DCEDC8",
  "#C5E1A5",
  "#AED581",
  "#9CCC65",
  "#8BC34A",
  "#7CB342",
  "#689F38",
  "#558B2F",
  "#33691E",
  "#CCFF90",
  "#B2FF59",
  "#76FF03",
  "#64DD17",
  "#CDDC39",
  "#F9FBE7",
  "#F0F4C3",
  "#E6EE9C",
  "#DCE775",
  "#D4E157",
  "#CDDC39",
  "#C0CA33",
  "#AFB42B",
  "#9E9D24",
  "#827717",
  "#F4FF81",
  "#EEFF41",
  "#C6FF00",
  "#AEEA00",
  // "#FFEB3B",
  // "#FFFDE7",
  // "#FFF9C4",
  // "#FFF59D",
  // "#FFF176",
  // "#FFEE58",
  // "#FFEB3B",
  "#FDD835",
  "#FBC02D",
  "#F9A825",
  "#F57F17",
  // "#FFFF8D",
  // "#FFFF00",
  // "#FFEA00",
  // "#FFD600",
  // "#FFC107",
  // "#FFF8E1",
  // "#FFECB3",
  // "#FFE082",
  // "#FFD54F",
  // "#FFCA28",
  // "#FFC107",
  // "#FFB300",
  // "#FFA000",
  // "#FF8F00",
  // "#FF6F00",
  // "#FFE57F",
  // "#FFD740",
  // "#FFC400",
  // "#FFAB00",
  // "#FF9800",
  // "#FFF3E0",
  // "#FFE0B2",
  // "#FFCC80",
  // "#FFB74D",
  // "#FFA726",
  // "#FF9800",
  "#FB8C00",
  "#F57C00",
  "#EF6C00",
  "#E65100",
  // "#FFD180",
  // "#FFAB40",
  // "#FF9100",
  // "#FF6D00",
  // "#FF5722",
  "#FBE9E7",
  // "#FFCCBC",
  // "#FFAB91",
  // "#FF8A65",
  // "#FF7043",
  // "#FF5722",
  "#F4511E",
  "#E64A19",
  "#D84315",
  "#BF360C",
  // "#FF9E80",
  // "#FF6E40",
  // "#FF3D00",
  "#DD2C00",
  "#795548",
  "#EFEBE9",
  "#D7CCC8",
  "#BCAAA4",
  "#A1887F",
  "#8D6E63",
  "#795548",
  "#6D4C41",
  "#5D4037",
  "#4E342E",
  "#3E2723",
  "#9E9E9E",
  "#FAFAFA",
  "#F5F5F5",
  "#EEEEEE",
  "#E0E0E0",
  "#BDBDBD",
  "#9E9E9E",
  "#757575",
  "#616161",
  "#424242",
  "#212121",
  "#607D8B",
  "#ECEFF1",
  "#CFD8DC",
  "#B0BEC5",
  "#90A4AE",
  "#78909C",
  "#607D8B",
  "#546E7A",
  "#455A64",
  "#37474F",
  "#263238",
  "#000000",
  // "#FFFFFF",
];

var colorMD = [
  "#0ff1ce",
  "#696969",
  "#bada55",
  "#7fe5f0",
  "#ff0000",
  "#ff80ed ",
  "#407294",
  "#ffffff",
  "#cbcba9",
  "#420420",
  "#133337",
  "#065535",
  "#c0c0c0",
  "#000000 ",
  "#5ac18e ",
  "#666666 ",
  "#dcedc1 ",
  "#f7347a ",
  "#576675 ",
  "#ffc0cb ",
  "#ffe4e1 ",
  "#008080 ",
  "#696966 ",
  "#e6e6fa ",
  "#ffd700 ",
  "#ffa500 ",
  "#8a2be2 ",
  "#00ffff ",
  "#ff7373 ",
  "#40e0d0 ",
  "#0000ff ",
  "#f0f8ff ",
  "#c6e2ff ",
  "#d3ffce ",
  "#b0e0e6 ",
  "#faebd7",
  "#fa8072 ",
  "#003366 ",
  "#ffb6c1 ",
  "#ffff00 ",
  "#800000 ",
  "#7fffd4 ",
  "#800080 ",
  "#f08080 ",
  "#c39797 ",
  "#eeeeee ",
  "#fff68f",
  "#cccccc ",
  "#00ff00 ",
  "#ffc3a0 ",
  "#333333 ",
  "#20b2aa ",
  "#ac25e2 ",
  "#66cdaa ",
  "#ff6666 ",
  "#ffdab9 ",
  "#ff00ff ",
  "#ff7f50 ",
  "#4ca3dd ",
  "#c0d6e4 ",
  "#468499 ",
  "#008000 ",
  "#afeeee ",
  "#047806 ",
  "#cbbeb5 ",
  "#f6546a ",
  "#660066",
  "#00ced1",
  "#b6fcd5",
  "#daa520",
  "#990000",
  "#0e2f44",
  "#b4eeb4",
  "#808080",
  "#8b0000",
  "#f5f5f5",
  "#088da5",
  "#6897bb",
  "#101010",
  "#000080",
  "#ffff66",
  "#f5f5dc",
  "#0a75ad",
  "#81d8d0",
];

var stringToColour = function (str) {
  if (str === "2tcirtsiD") return "#1021BC";
  if (!isNaN(str[1])) {
    var num = str[1] + str[0];
    return mdColors[Math.round(mdColors.length / parseInt(num))];
  } else if (!isNaN(str[0])) {
    var num = str[0];
    return mdColors[Math.round(mdColors.length / parseInt(num))];
  } else {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }
};

function convertLetterToNumber(str) {
  if (!isNaN(str)) return str;

  var out = 0,
    len = str.length;
  for (var pos = 0; pos < len; pos++) {
    out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
  }
  return out;
}
function convertUnicode(input) {
  return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
}
// function htmlDecode(input){
//   var e = document.createElement('div');
//   e.innerHTML = input;
//   return e.childNodes[0].nodeValue;
// }

export default Orders;
