/*global google*/
import { types, getParent, detach } from "mobx-state-tree";
import axios from "axios";
// import { v4 as uuidv4 } from "uuid";
import { v4 as uuidv4 } from "uuid";

const address = types
    .model("address", {
        uuid: types.optional(types.string, uuidv4()),
        label_address: types.optional(types.string, ""),
        form_address: types.optional(types.string, ""),
        street: types.optional(types.string, ""),
        ward: types.optional(types.string, ""),
        suburb: types.optional(types.string, ""),
        post_code: types.optional(types.string, ""),
        city: types.optional(types.string, ""),
        region: types.optional(types.string, ""),
        country: types.optional(types.string, ""),
        country_code: types.optional(types.string, ""),
        gps: types.optional(types.array(types.number), []),
        apt: types.optional(types.string, ""),
        delivery_instructions: types.optional(types.string, ""),
        selected: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        updateLocation(gps) {
            self.gps = gps;
        },
        updateItem(item, value) {
            self[item] = value;
        },
    }));

const emptyStaff = {
    loading: false,
    _id: "0",
    ip: "",
    type: "store_user",
    wholesale: [],
    firstname: "",
    lastname: "",
    permissions: [],
    qualifications: [],
    workAvailability: [],
    email: "",
    address: {},
    stores: [],
    facebook: "",
    facebookId: "",
    googleId: "",
    imageUrl: "",
    phone: "",
    dob: "",
    landing_page: "",
    marketplace: "",
    token: "",
    lang: "en",
};

const permission = types.model("permission", {
    area: types.optional(types.string, ""),
    access: types.optional(types.string, ""),
    grantedBy: types.optional(types.string, ""),
});

const qualification = types.model("qualification", {
    qualification: types.optional(types.string, ""),
    level: types.optional(types.number, 0),
    grantedBy: types.optional(types.string, ""),
});

const workAvailability = types.model("workAvailability", {
    day: types.optional(types.string, ""),
    startTime: types.optional(types.string, ""),
    endTime: types.optional(types.string, ""),
    availability: types.optional(types.boolean, true),
});

const store = types.model("store", {
    _id: types.optional(types.string, ""),
    slug: types.optional(types.string, ""),
    title: types.optional(types.string, ""),
    location: types.optional(types.string, ""),
});

export const StaffStore = types
    .model("StaffStore", {
        loading: false,
        _id: "0",
        ip: "",
        type: "store_user",
        wholesale: types.optional(types.array(types.string), []),
        firstname: "",
        lastname: "",
        permissions: types.optional(types.array(permission), []),
        qualifications: types.optional(types.array(qualification), []),
        workAvailability: types.optional(types.array(workAvailability), []),
        stores: types.optional(types.array(store), []),
        email: "",
        address: types.optional(address, {}),
        facebook: "",
        facebookId: "",
        googleId: "",
        imageUrl: "",
        phone: "",
        dob: "",
        landing_page: "",
        marketplace: "",
        token: "",
        lang: "en",
    })
    .views((self) => ({
        get shop() {
            return getParent(self);
        },
        get isLoading() {
            return self.loading;
        },
    }))
    .actions((self) => ({
        updateOrderStatus(id, status) {
            var data = {};
            data["uuid"] = id;
            data["status"] = status;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/order_status/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderDate(id, deliveryDateTime) {
            var data = {};
            data["uuid"] = id;
            data["deliveryDateTime"] = deliveryDateTime;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/order_datetime/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderPaymentMethod(id, payment_method) {
            var data = {};
            data["uuid"] = id;
            data["payment_method"] = payment_method;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/payment_method/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return response.data.payment_link;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateDeliveryType(id, delivery_type) {
            var data = {};
            data["uuid"] = id;
            data["delivery_type"] = delivery_type;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/delivery_type/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderType(id, order_type) {
            var data = {};
            data["uuid"] = id;
            data["order_type"] = order_type;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/order_type/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderName(id, name) {
            var data = {};
            data["uuid"] = id;
            data["name"] = name;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/name/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderInstructions(id, entry_id, instructions) {
            var data = {};
            data["uuid"] = id;
            data["entry_id"] = entry_id;
            data["instructions"] = instructions;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            console.log("data", data);
            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/instructions/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderPhone(id, phone) {
            var data = {};
            data["uuid"] = id;
            data["phone"] = phone;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/phone/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderTaxCode(id, taxCode) {
            var data = {};
            data["uuid"] = id;
            data["taxCode"] = taxCode;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/taxCode/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderField(id, field, value) {
            var data = {};
            data["uuid"] = id;
            data["field"] = field;
            data["value"] = value;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/field/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateDeliveryAmt(id, deliveryAmt) {
            var data = {};
            data["uuid"] = id;
            data["deliveryAmt"] = deliveryAmt;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/deliveryAmt/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateDiscountAmount(id, discountAmount) {
            var data = {};
            data["uuid"] = id;
            data["discountAmount"] = discountAmount;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/discountAmt/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        sendOrderEmail(uuid) {
            var data = {};
            data["uuid"] = uuid;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/sendEmail/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        sendMessage(id, message) {
            var data = {};
            data["uuid"] = id;
            data["message"] = message;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/message/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        saveCustomerPhoto(uuid, image, caption, store_slug) {
            var data = {};
            data["uuid"] = uuid;
            data["photo"] = image;
            data["caption"] = caption;
            data["store_slug"] = store_slug;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/photo/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        savePhoto(uuid, image, caption, store_slug) {
            var data = {};
            data["uuid"] = uuid;
            data["photo"] = image;
            data["caption"] = caption;
            data["store_slug"] = store_slug;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/orders/photo/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        deleteMessage(uuid, message_uuid) {
            var data = {};
            data["uuid"] = uuid;
            data["message_uuid"] = message_uuid;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            return axios
                .delete(self.shop.apiAddress + "/api/orders/message/" + self._id, {
                    data: data,
                })
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        deletePhoto(uuid, photo_uuid) {
            var data = {};
            data["uuid"] = uuid;
            data["photo_uuid"] = photo_uuid;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            return axios
                .delete(self.shop.apiAddress + "/api/orders/photo/" + self._id, {
                    data: data,
                })
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateOrderAddress(id, address, delivery_cost) {
            var data = {};
            data["uuid"] = id;
            data["address"] = address;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            data["delivery_cost"] = delivery_cost;

            return axios
                .put(self.shop.apiAddress + "/api/orders/address/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateDeliveryLink(id, delivery_link) {
            var data = {};
            data["uuid"] = id;
            data["delivery_link"] = delivery_link;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/delivery_link/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updatePaymentStatus(id, payment_status) {
            var data = {};
            data["uuid"] = id;
            data["payment_status"] = payment_status;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/payment_status/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return response.data.payment_link;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateEntryStatus(uuid, id, status) {
            var data = {};
            data["uuid"] = uuid;
            data["entry_id"] = id;
            data["status"] = status;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/orders/entry_status/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return response.data.payment_link;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateStaffName(id, name) {
            var data = {};
            data["uuid"] = id;
            data["firstname"] = name.firstname;
            data["lastname"] = name.lastname;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/staff/name/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateStaffProfile(staff) {
            console.log(staff);
            var data = {};
            data["uuid"] = staff._id;
            data["firstname"] = staff.firstname;
            data["lastname"] = staff.lastname;
            data["email"] = staff.email;
            data["phone"] = staff.phone;
            data["facebook"] = staff.facebook;
            data["startArea"] = staff.startArea;

            data["address"] = staff.address;

            data["dob"] = staff.dob;
            data["description"] = staff.description;

            if (staff.password !== "") data["password"] = staff.password;

            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/staff/profile/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateStaffPermissions(id, permissions) {
            console.log(permissions);
            var data = {};
            data["uuid"] = id;
            data["permissions"] = permissions;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/staff/permissions/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateStaffQualifications(id, qualifications) {
            console.log(qualifications);
            var data = {};
            data["uuid"] = id;
            data["qualifications"] = qualifications;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/staff/qualifications/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateStaffAvailabilities(id, availabilities) {
            console.log(availabilities);
            var data = {};
            data["uuid"] = id;
            data["availabilities"] = availabilities;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(
                    self.shop.apiAddress + "/api/staff/availabilities/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        newStaff(staff) {
            console.log(staff);
            var data = {};
            data["uuid"] = staff._id;
            data["firstname"] = staff.firstname;
            data["lastname"] = staff.lastname;
            data["email"] = staff.email;
            data["phone"] = staff.phone;
            data["facebook"] = staff.facebook;
            data["startArea"] = staff.startArea;

            data["address"] = staff.address;

            data["dob"] = staff.dob;
            data["description"] = staff.description;

            if (staff.password !== "") data["password"] = staff.password;

            data["token"] = self.token;
            data["atoken"] = self.shop.token;

            return axios
                .put(self.shop.apiAddress + "/api/staff/new/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        console.log(response);
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateStaff(staffDetails) {
            if (typeof staffDetails !== "undefined") {
                Object.keys(staffDetails).forEach(function(key) {
                    if (staffDetails[key] !== null) self[key] = staffDetails[key];
                    if (key === "landing_page")
                        self.shop.view.openPage(staffDetails[key]);

                    if (key === "marketplace" && self[key] !== "")
                        self.shop.marketplace.loadMarketplace(self[key]);
                });
                self.shop.storeStore.loadStores();
                self.markLoading(false);
                return true;
            } else {
                self.markLoading(false);
                return false;
            }
        },
        checkMessages(storeId) {
            var Buffer = require("buffer/").Buffer;
            var access_token = Buffer.from(storeId).toString("base64");

            try {
                return axios
                    .post(self.shop.apiAddress + "/api/staff/retrieve_no_sms", {
                        access_token: access_token,
                    })
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        loadOrders(
            store,
            startDate,
            endDate,
            sort,
            limit,
            search,
            orderStatus,
            paymentMethod
        ) {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["store"] = store;
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["store"] = store;
            data["startDate"] = startDate;
            data["endDate"] = endDate;
            data["website"] = self.shop.getLocationValue("origin");
            data["sort"] = sort;
            data["order_status"] = orderStatus;
            data["payment_method"] = paymentMethod;
            data["limit"] = limit;
            data["search"] = search;
            try {
                return axios
                    .put(
                        self.shop.apiAddress + "/api/orders/staff_orders/" + self._id,
                        data
                    )
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        lalaQuote(uuid) {
            var data = {};
            data["uuid"] = uuid;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            return axios
                .put(self.shop.apiAddress + "/api/orders/lala_quote/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        lalaBook(uuid) {
            var data = {};
            data["uuid"] = uuid;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            return axios
                .put(self.shop.apiAddress + "/api/orders/lala_book/" + self._id, data)
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        openOrderCart(uuid) {
            var data = {};
            data["uuid"] = uuid;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            return axios
                .put(
                    self.shop.apiAddress + "/api/staff/openOrderCart/" + self._id,
                    data
                )
                .then((response) => {
                    console.log(response.data);
                    var success = response.data.success;
                    if (success) {
                        window.open(
                            response.data.cart.website +
                            "#tasty_token_cart=" +
                            response.data.token,
                            "_blank"
                        );
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        printOrderCart(order) {
            if (order)
                return axios
                    .get(
                        self.shop.apiAddress +
                        "/api/orders/print?token=" +
                        order.store_id +
                        "&domain=" +
                        order.store_slug +
                        "&uuid=" +
                        order.uuid +
                        "&time=" +
                        Date.now()
                    )
                    .then((response) => {
                        return true;
                    });
            else return false;
        },
        downloadRedInvoice(order) {
            if (order) {
                // return axios
                //   .get(

                //   )
                //   .then((response) => {
                // console.log(response);
                const tempLink = document.createElement("a");
                tempLink.href =
                    self.shop.apiAddress +
                    "/api/orders/invoice?token=" +
                    order.store_id +
                    "&domain=" +
                    order.store_slug +
                    "&uuid=" +
                    order.uuid +
                    "&time=" +
                    Date.now(); //'http://localhost:2000/users/admin-panel/csv'
                tempLink.click();
                // return true;
                // });
            } else return false;
        },
        loadStaff(store, sort, limit, search, verified) {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["store"] = store;
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["store"] = store;
            data["website"] = self.shop.getLocationValue("origin");
            data["sort"] = sort;
            data["verified"] = verified;
            data["limit"] = limit;
            data["search"] = search;
            try {
                return axios
                    .put(self.shop.apiAddress + "/api/staff/list/" + self._id, data)
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        // Load all store details
        loadStores(store, sort, limit, search, verified) {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["store"] = store;
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["store"] = store;
            data["website"] = self.shop.getLocationValue("origin");
            data["sort"] = sort;
            data["verified"] = verified;
            data["limit"] = limit;
            data["search"] = search;
            try {
                return axios
                    .put(self.shop.apiAddress + "/api/staff/store_list/" + self._id, data)
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },

        // Load Customer details
        loadCustomers(store, sort, limit, search) {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["store"] = store;
            // data['startDate'] = startDate;
            // data['endDate'] = endDate;
            data["website"] = self.shop.getLocationValue("origin");
            data["sort"] = sort;
            data["limit"] = limit;
            data["search"] = search;
            try {
                return axios
                    .put(self.shop.apiAddress + "/api/staff/customers/" + self._id, data)
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        // Load Product details
        loadProducts(store, sort, limit, search) {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["store"] = store;
            // data['startDate'] = startDate;
            // data['endDate'] = endDate;
            data["website"] = self.shop.getLocationValue("origin");
            data["sort"] = sort;
            data["limit"] = limit;
            data["search"] = search;
            try {
                return axios
                    .put(self.shop.apiAddress + "/api/staff/products/" + self._id, data)
                    .then((response) => {
                        if (response.data.success) {
                            return response.data;
                        } else {
                            return false;
                        }
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        loadDash() {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["website"] = self.shop.getLocationValue("origin");
            try {
                return axios
                    .post(self.shop.apiAddress + "/api/staff/dash", data)
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        // Load Product details
        getProduct(product_uuid, store = "") {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            data["store"] = store;
            // data['startDate'] = startDate;
            // data['endDate'] = endDate;
            data["website"] = self.shop.getLocationValue("origin");
            data["product_uuid"] = product_uuid;
            try {
                return axios
                    .put(self.shop.apiAddress + "/api/staff/product/" + self._id, data)
                    .then((response) => {
                        if (response.data.success) {
                            return response.data;
                        } else return false;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        deleteProdImg(product_uuid, image_type, image_id, image_url, store_slug) {
            var data = {};
            data["product_uuid"] = product_uuid;
            data["image_type"] = image_type;
            data["image_id"] = image_id;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            data["image_url"] = image_url;
            data["store_slug"] = store_slug;

            return axios
                .delete(self.shop.apiAddress + "/api/staff/product_image/" + self._id, {
                    data: data,
                })
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        deleteProdOptionImg(
            product_uuid,
            image_type,
            cust_uuid,
            opt_uuid,
            image_id,
            image_url,
            store_slug
        ) {
            var data = {};
            data["product_uuid"] = product_uuid;
            data["image_type"] = image_type;
            data["cust_uuid"] = cust_uuid;
            data["opt_uuid"] = opt_uuid;
            data["image_id"] = image_id;
            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            data["image_url"] = image_url;
            data["store_slug"] = store_slug;
            return axios
                .delete(self.shop.apiAddress + "/api/staff/product_image/" + self._id, {
                    data: data,
                })
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return true;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        updateAvailability(uuid, availability) {
            var data = {};

            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            // var product_details = JSON.parse(JSON.stringify(self.shop.products.get(slug).toJSON()));

            // delete product_details.store;// = undefined;
            // delete product_details.select_quantity;// = undefined;
            // Sort customizations and options by order;

            data["uuid"] = uuid;
            data["availability"] = availability;

            return axios
                .put(
                    self.shop.apiAddress + "/api/staff/product_availability/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return response.data.product;
                    } else {
                        console.log(response);
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        saveProductDetails(product_details) {
            var data = {};

            data["token"] = self.token;
            data["atoken"] = self.shop.token;
            // var product_details = JSON.parse(JSON.stringify(self.shop.products.get(slug).toJSON()));

            // delete product_details.store;// = undefined;
            // delete product_details.select_quantity;// = undefined;
            // Sort customizations and options by order;
            product_details.customizations = product_details.customizations.sort(
                function(cust1, cust2) {
                    return cust1.order - cust2.order;
                }
            );

            product_details.customizations.map(function(custom, j) {
                product_details.customizations[j].options = custom.options.sort(
                    function(opt1, opt2) {
                        return opt1.order - opt2.order;
                    }
                );
            });
            data["product_details"] = product_details;

            return axios
                .put(
                    self.shop.apiAddress + "/api/staff/product_update/" + self._id,
                    data
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return response.data.product;
                    } else {
                        console.log(response);
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        uploadProductImage(
            uuid,
            image,
            image_type,
            image_name,
            image_title,
            cust_uuid,
            opt_uuid
        ) {
            var data = new FormData();
            data.set("uuid", uuid);
            data.set("image_type", image_type);
            data.set("type", image.type);
            image_name = image_name + "." + image.name.split(".")[1];
            data.set("image_name", image_name);
            data.set("image_title", image_title);
            data.append("image", image);
            data.set("token", self.token);
            data.set("atoken", self.shop.token);
            data.set("cust_uuid", cust_uuid);
            data.set("opt_uuid", opt_uuid);

            // console.log(uuid,image, image_type)

            return axios
                .post(
                    self.shop.apiAddress + "/api/staff/product_image/" + self._id,
                    data, { headers: { "Content-Type": "multipart/form-data" } }
                )
                .then((response) => {
                    var success = response.data.success;
                    if (success) {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return response.data.product_data;
                    } else {
                        self.shop.alert(response.data.message, "", "info", "br");
                        return false;
                    }
                });
        },
        // Load Cart Details
        loadCarts() {
            if (self.domain === "") self.domain = self.shop.domain;

            var data = {};
            data["staff"] = self.shop.staff._id;
            data["token"] = self.shop.staff.token;
            data["atoken"] = self.shop.token;
            data["marketplace"] = self.shop.marketplace.slug;
            // data['store'] = store;
            // data['startDate'] = startDate;
            // data['endDate'] = endDate;
            data["website"] = self.shop.getLocationValue("origin");
            // data['sort'] = sort;
            // data['limit'] = limit;
            // data['search'] = search;
            try {
                return axios
                    .put(self.shop.apiAddress + "/api/staff/carts/" + self._id, data)
                    .then((response) => {
                        return response.data;
                    });
            } catch (err) {
                console.error(" ", err);
            }
        },
        markLoading(loading) {
            self.loading = loading;
        },
        arrayUnique(array) {
            var a = array.concat();
            for (var i = 0; i < a.length; ++i) {
                for (var j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j]) a.splice(j--, 1);
                }
            }

            return a;
        },

        setLang(lang) {
            self.lang = lang;
            var address = self.getLocationValue("href");
            if (address.indexOf(lang + ".") === -1) {
                var sub = address.substring(
                    address.indexOf("//") + 2,
                    address.indexOf(".")
                );
                if (sub.length === 2) {
                    address = address.replace(sub, lang);
                } else {
                    address = address.replace("//", "//" + lang + ".");
                }
                address = address.replace("www.", "");
                self.setLocationValue("href", address);
            }
        },
        getLang(lang) {
            var address = self.getLocationValue("href");
            if (address.indexOf(lang + ".") === -1) {
                var sub = address.substring(
                    address.indexOf("//") + 2,
                    address.indexOf(".")
                );
                if (sub.length === 2) {
                    address = address.replace(sub, lang);
                } else {
                    address = address.replace("//", "//" + lang + ".");
                }
                return address.replace("www.", "");
            }
        },
        updateLocation(location, label, save = true) {
            var street_number = "";
            var street = "";
            var ward = "";
            var suburb = "";
            var city = "";
            var country = "";
            var post_code = "";
            var country_code = "";
            var no_components = location.address_components.length;
            location.address_components.forEach(function(component, i) {
                i += 1;
                component.types.forEach(function(type) {
                    if (type === "route" || type === "establishment") {
                        street = component.long_name;
                    }
                    if (type === "street_number") {
                        street_number = component.long_name;
                    }
                    if (type === "sublocality_level_1") {
                        ward = component.long_name;
                    }
                    if (type === "administrative_area_level_2")
                        suburb = removeDiacritics(
                            component.long_name
                            .replace("Quận", "District")
                            .replace("quận", "District")
                        );

                    if (type === "postal_code") {
                        post_code = component.long_name;
                    }
                });
                // Load Suburb
                if (
                    i === no_components - 2 &&
                    removeDiacritics(component.long_name).indexOf("Ho Chi Minh") === -1
                ) {
                    suburb = removeDiacritics(
                        component.long_name
                        .replace("Quận", "District")
                        .replace("quận", "District")
                    );
                    // Load City
                } else if (i === no_components - 1) {
                    city = component.long_name;
                    //  Load Country
                } else if (i === no_components) {
                    country = component.long_name;
                    country_code = component.short_name;
                }
            });

            suburb = suburb.replace("district", "District");
            if (suburb.charAt(0) == "Q" && suburb.charAt(1) == " ")
                suburb = suburb.replace("Q ", "");

            if (
                suburb.indexOf("District") === -1 &&
                suburb.indexOf("Ho Chi Minh") === -1
            )
                suburb += " District";

            if (suburb === "An Phu District") suburb = "An Phu";

            if (location.formatted_address.indexOf("Thảo Điền") > -1)
                ward = "Thao Dien";

            var gps = [];

            if (typeof location.geometry !== "undefined")
                gps = [
                    location.geometry.location.lat(),
                    location.geometry.location.lng(),
                ];

            if (typeof location.location !== "undefined")
                gps = [location.location.lat, location.location.lng];
            var add = {
                uuid: uuidv4(),
                address_complete: label,
                label_address: label,
                form_address: label,
                street: street_number + " " + street,
                ward: ward,
                suburb: removeDiacritics(suburb),
                post_code: post_code,
                city: city,
                region: city,
                country: country,
                country_code: country_code,
                gps: gps,
            };

            return add;
        },
        signOut() {
            self.shop.view.updateLoginError("");
            self.removeLocalStorage("staff");

            emptyStaff.marketplace = self.marketplace;
            emptyStaff.lang = self.lang;

            var page = self.shop.view.page;
            if (page === "product" || page === "account") page = "products";

            self.shop.view.openPage("", false);
            self.shop.view.openPage(page, false);

            if (self.shop.cart.entries.length > 0) self.shop.productStore.getCart();

            return self.updateStaff(emptyStaff);
        },
        staffLogin(params) {
            if (params) {
                try {
                    if (params.email !== "" && params.password !== "") {
                        params["atoken"] = self.shop.token;
                        return axios
                            .post(self.shop.apiAddress + "/api/staff-auth/login", params)
                            .then((response) => {
                                var staff = response.data;
                                if (staff.success) {
                                    return self.processLogin(staff.staff);
                                } else {
                                    self.shop.view.updateLoginError(staff.message);
                                    return false;
                                }
                            });
                    } else {
                        self.shop.view.updateLoginError("Enter a valid email and password");
                    }
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        processLogin(staff) {
            try {
                if (staff) self.updateStaff(staff);
            } catch (err) {
                self.removeLocalStorage("staff");
                self.reloadPage();
            }

            self.setLocalStorage(
                "staff",
                JSON.stringify({
                    token: staff.token,
                    _id: staff._id,
                    ip: self.toJSON().ip,
                    timestamp: new Date().getTime(),
                })
            );
            self.shop.view.updateLoginError("");
            self.shop.view.closeSignup();
            self.shop.view.hideDropdown();

            // Check opened page needs to refresh wholesale pricing.
            var page = self.shop.view.page;
            // if(staff.wholesale.length > 0 && staff.wholesale.indexOf(self.shop.domain) > -1){
            if (page === "product") page = "products";

            self.shop.view.openPage("", false);
            self.shop.view.openPage(page, false);

            if (self.shop.cart.entries.length > 0) self.shop.productStore.getCart();

            self.shop.cart.updateName(self.firstname + " " + self.lastname);
            self.shop.cart.updatePhone(self.phone);
            // }

            return true;
        },
        savePassword(pssData) {
            if (pssData) {
                try {
                    pssData["token"] = self.token;
                    pssData["atoken"] = self.shop.token;
                    pssData["staffId"] = self._id;

                    if (pssData.password !== "") {
                        return axios
                            .put(self.shop.apiAddress + "/api/staff/" + self._id, pssData)
                            .then((response) => {
                                var success = response.data.success;
                                if (success) {
                                    self.shop.alert(response.data.message, "", "info", "br");
                                    return true;
                                } else {
                                    return false;
                                }
                            });
                    }
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        resetPassword(email) {
            if (email) {
                try {
                    var pssData = {};
                    pssData["email"] = email;
                    pssData["token"] = self.token;
                    pssData["atoken"] = self.shop.token;
                    if (pssData.email !== "") {
                        return axios
                            .put(
                                self.shop.apiAddress + "/api/staff-auth/resetPassword",
                                pssData
                            )
                            .then((response) => {
                                var success = response.data.success;
                                if (success) {
                                    self.shop.view.updateLoginError(response.data.message);
                                    self.shop.alert(response.data.message, "", "info", "br");
                                    return true;
                                } else {
                                    self.shop.view.updateLoginError(response.data.message);
                                    return false;
                                }
                            });
                    }
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        resetPasswordValue(password) {
            if (password) {
                try {
                    var pssData = {};
                    pssData["password"] = password;
                    pssData["token"] = self.token;
                    pssData["atoken"] = self.shop.token;
                    pssData["pwkey"] = self.shop.getLocationValue("hash").split("=")[1];
                    if (pssData.password !== "") {
                        return axios
                            .post(
                                self.shop.apiAddress + "/api/staff-auth/resetPassword",
                                pssData
                            )
                            .then((response) => {
                                var success = response.data.success;
                                if (success) {
                                    self.shop.view.updateLoginError(response.data.message);
                                    self.shop.alert(response.data.message, "", "info", "br");
                                    return true;
                                } else {
                                    self.shop.view.updateLoginError(response.data.message);
                                    return false;
                                }
                            });
                    }
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        saveStaff(staffData) {
            if (staffData) {
                try {
                    staffData["token"] = self.token;
                    staffData["atoken"] = self.shop.token;
                    staffData["staffId"] = self._id;

                    if (staffData.firstname !== "") {
                        return axios
                            .put(self.shop.apiAddress + "/api/staff/" + self._id, staffData)
                            .then((response) => {
                                var success = response.data.success;
                                if (success) {
                                    self.shop.alert("Staff Updated", "", "info", "br");
                                    return true;
                                } else {
                                    return false;
                                }
                            });
                    }
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },

        userLogin(uuid) {
            if (uuid) {
                try {
                    var params = {};
                    params["uuid"] = uuid;
                    params["token"] = self.token;
                    if (params.uuid !== "") {
                        params["atoken"] = self.shop.token;
                        return axios
                            .post(self.shop.apiAddress + "/api/staff/userLogin", params)
                            .then((response) => {
                                var user = response.data;
                                if (user.success) {
                                    console.log(user);

                                    user = user.user;

                                    window.localStorage.setItem(
                                        "user",
                                        JSON.stringify({
                                            token: user.token,
                                            _id: user._id,
                                            ip: self.toJSON().ip,
                                            region: self.address.suburb,
                                            timestamp: new Date().getTime(),
                                        })
                                    );

                                    // console.log(JSON.stringify({token:user.token, _id:user._id,ip:self.toJSON().ip,region:self.address.suburb, timestamp: new Date().getTime()}));
                                    return user.token;
                                    // return JSON.stringify({token:user.token, _id:user._id,ip:self.toJSON().ip,region:self.address.suburb, timestamp: new Date().getTime()});
                                    // alert('Loading ' + user.lastSite)
                                    // setTimeout(function() {
                                    //    window.open('http://localhost:3000',"_self")
                                    //    if(!user.lastSite){
                                    //        console.log(window.localStorage.getItem('user'));
                                    //        window.open(user.signupSite,"_self")
                                    //    }else{
                                    //        window.open(user.lastSite,"_self")
                                    //    }
                                    // }, 1000);

                                    // wind
                                } else {
                                    console.log(user);
                                }
                            });
                    } else {
                        console.log(params);
                    }
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },

        setLocalStorage(label, value) {
            if (typeof window !== "undefined") {
                window.localStorage.setItem(label, value);
            } else {
                return false;
            }
        },
        getLocalStorage(label) {
            if (typeof window !== "undefined") {
                return window.localStorage.getItem(label);
            } else {
                return false;
            }
        },
        removeLocalStorage(label) {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(label);
            } else {
                return false;
            }
        },
        setLocationValue(label, value) {
            if (typeof window !== "undefined") {
                window.location[label] = value;
            } else {
                return false;
            }
        },
        getLocationValue(label) {
            if (typeof window !== "undefined") {
                return window.location[label];
            } else {
                return "";
            }
        },
        reloadPage() {
            if (typeof window !== "undefined") window.location.reload();
        },

        checkEmail(params) {
            if (params) {
                try {
                    params["atoken"] = self.shop.token;
                    return axios
                        .post(self.shop.apiAddress + "/api/staff-auth/email", params)
                        .then((response) => {
                            var staff = response.data;
                            if (staff.exists) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        checkFacebook(params) {
            if (params) {
                try {
                    params["atoken"] = self.shop.token;
                    return axios
                        .post(self.shop.apiAddress + "/api/staff-auth/facebook", params)
                        .then((response) => {
                            var staff = response.data;
                            if (staff.success) {
                                return self.processLogin(staff.staff);
                            } else {
                                self.shop.view.updateLoginError(staff.message);
                                return false;
                            }
                        });
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        checkGoogle(params) {
            if (params) {
                try {
                    params["atoken"] = self.shop.token;
                    return axios
                        .post(self.shop.apiAddress + "/api/staff-auth/google", params)
                        .then((response) => {
                            var staff = response.data;
                            if (staff.success) {
                                return self.processLogin(staff.staff);
                            } else {
                                self.shop.view.updateLoginError(staff.message);
                                return false;
                            }
                        });
                } catch (err) {
                    console.error("Login Failed ", err);
                }
            }
        },
        createStaff(staff) {
            staff["marketplace"] = self.marketplace;
            staff["atoken"] = self.shop.token;
            try {
                axios
                    .post(self.shop.apiAddress + "/api/staff", staff)
                    .then((response) => {
                        if (response.data.success) {
                            var stff = staff;
                            stff["marketplace"] = self.marketplace;
                            stff["token"] = response.data.token;
                            stff["ip"] = response.data.ip;
                            stff["_id"] = response.data._id;
                            self.updateStaff(stff);
                            self.setLocalStorage(
                                "staff",
                                JSON.stringify({
                                    token: stff.token,
                                    _id: stff._id,
                                    region: self.shop.marketplace.address.suburb,
                                    timestamp: new Date().getTime(),
                                })
                            );
                            self.shop.productStore.getCart();

                            var page = self.shop.view.page;

                            self.shop.view.openPage("", false);
                            self.shop.view.openPage(page, false);

                            self.shop.alert("Staff created", "", "info", "br");
                        } else {
                            self.shop.alert(response.data.message, "", "error", "br");
                        }
                    });
            } catch (err) {
                console.error("Create Staff Failed ", err);
            }
        },
        fetchStaffDetails(stff_id, token) {
            var config = {
                headers: { Authorization: token },
            };
            if (stff_id && stff_id !== 0) {
                var domain = "";
                if (self.shop.domain !== "" && self.shop.view.selectedStore)
                    domain = self.shop.view.selectedStore.slug;
                fetch(
                        self.shop.apiAddress +
                        "/api/staff/" +
                        stff_id +
                        "?domain=" +
                        domain +
                        "&atoken=" +
                        self.shop.token +
                        "&token=" +
                        token,
                        config
                    )
                    .then(function(response) {
                        try {
                            if (!response.body || !response.ok || response.status >= 400) {
                                self.removeLocalStorage("staff");
                                self.reloadPage();
                                // throw new Error("Bad response from server");
                            } else {
                                return response.text().then(function(text) {
                                    return text ? JSON.parse(text) : {};
                                });
                                // return response.json();
                            }
                        } catch (err) {
                            self.removeLocalStorage("staff");
                            self.reloadPage();
                        }
                    })
                    .then(function(staff) {
                        try {
                            if (staff) self.updateStaff(staff);
                        } catch (err) {
                            self.removeLocalStorage("staff");
                            self.reloadPage();
                        }
                    });
            }
        },
        setLocalStorage(label, value) {
            if (typeof window !== "undefined") {
                window.localStorage.setItem(label, value);
            } else {
                return false;
            }
        },
        getLocalStorage(label) {
            if (typeof window !== "undefined") {
                return window.localStorage.getItem(label);
            } else {
                return false;
            }
        },
        removeLocalStorage(label) {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(label);
            } else {
                return false;
            }
        },
        setLocationValue(label, value) {
            if (typeof window !== "undefined") {
                window.location[label] = value;
            } else {
                return false;
            }
        },
        getLocationValue(label) {
            if (typeof window !== "undefined") {
                return window.location[label];
            } else {
                return "";
            }
        },
        afterCreate() {
            var stff = {};
            if (
                self.getLocalStorage("staff") &&
                self.getLocalStorage("staff") !== null &&
                self.getLocalStorage("staff") !== ""
            ) {
                stff = JSON.parse(self.getLocalStorage("staff"));
                // if(stff.timestamp && (Math.floor((new Date().getTime().toString()-stff.timestamp)/1000/60/60/24)) > 20){
                //     self.removeLocalStorage("staff");
                //     self.reloadPage();
                // }
            }
            if (
                self.getLocalStorage("staff") &&
                self.getLocalStorage("staff") !== null &&
                self.getLocalStorage("staff") !== "" &&
                typeof JSON.parse(self.getLocalStorage("staff")).token !== "undefined"
            ) {
                try {
                    stff = JSON.parse(self.getLocalStorage("staff"));
                    if (typeof stff.token !== "undefined") {
                        setTimeout(function() {
                            self.fetchStaffDetails(stff._id, stff.token);
                        }, 500);
                    }
                } catch (err) {
                    console.error(err);
                }
            } else if (
                self.getLocalStorage("staff") &&
                self.getLocalStorage("staff") !== null &&
                self.getLocalStorage("staff") !== "" &&
                self.getLocalStorage("staff").length > 3 &&
                self.getLocalStorage("staff").indexOf("ip") > -1 &&
                self.getLocalStorage("staff").length > 24
            ) {
                try {
                    stff = JSON.parse(self.getLocalStorage("staff"));
                    self.updateStaff(stff);
                } catch (err) {
                    self.removeLocalStorage("staff");
                    self.reloadPage();
                    console.error(err);
                }
            } else if (self.shop.token) {
                try {
                    fetch(
                            self.shop.apiAddress + "/api/staff/ip?atoken=" + self.shop.token
                        )
                        .then(function(response) {
                            if (response.status >= 400) {
                                console.log("Bad response from server");
                                // throw new Error("Bad response from server");
                            }
                            return response.json();
                        })
                        .then(function(staff) {
                            try {
                                if (staff.success) self.updateStaff(staff);
                            } catch (err) {
                                self.removeLocalStorage("staff");
                                self.reloadPage();
                            }
                        });
                } catch (err) {
                    console.error(err);
                }
            }
        },
        afterAttach() {
            self.markLoading(false);
        },
        clear() {
            self.staff.clear();
        },
    }));

function removeDiacritics(str) {
    var defaultDiacriticsRemovalMap = [{
            base: "A",
            letters: /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g,
        },
        { base: "AA", letters: /[\uA732]/g },
        { base: "AE", letters: /[\u00C6\u01FC\u01E2]/g },
        { base: "AO", letters: /[\uA734]/g },
        { base: "AU", letters: /[\uA736]/g },
        { base: "AV", letters: /[\uA738\uA73A]/g },
        { base: "AY", letters: /[\uA73C]/g },
        {
            base: "B",
            letters: /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g,
        },
        {
            base: "C",
            letters: /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g,
        },
        {
            base: "D",
            letters: /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g,
        },
        { base: "DZ", letters: /[\u01F1\u01C4]/g },
        { base: "Dz", letters: /[\u01F2\u01C5]/g },
        {
            base: "E",
            letters: /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g,
        },
        { base: "F", letters: /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
        {
            base: "G",
            letters: /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g,
        },
        {
            base: "H",
            letters: /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g,
        },
        {
            base: "I",
            letters: /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g,
        },
        { base: "J", letters: /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
        {
            base: "K",
            letters: /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g,
        },
        {
            base: "L",
            letters: /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g,
        },
        { base: "LJ", letters: /[\u01C7]/g },
        { base: "Lj", letters: /[\u01C8]/g },
        {
            base: "M",
            letters: /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g,
        },
        {
            base: "N",
            letters: /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g,
        },
        { base: "NJ", letters: /[\u01CA]/g },
        { base: "Nj", letters: /[\u01CB]/g },
        {
            base: "O",
            letters: /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g,
        },
        { base: "OI", letters: /[\u01A2]/g },
        { base: "OO", letters: /[\uA74E]/g },
        { base: "OU", letters: /[\u0222]/g },
        {
            base: "P",
            letters: /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g,
        },
        { base: "Q", letters: /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
        {
            base: "R",
            letters: /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g,
        },
        {
            base: "S",
            letters: /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g,
        },
        {
            base: "T",
            letters: /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g,
        },
        { base: "TZ", letters: /[\uA728]/g },
        {
            base: "U",
            letters: /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g,
        },
        {
            base: "V",
            letters: /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g,
        },
        { base: "VY", letters: /[\uA760]/g },
        {
            base: "W",
            letters: /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g,
        },
        { base: "X", letters: /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
        {
            base: "Y",
            letters: /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g,
        },
        {
            base: "Z",
            letters: /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g,
        },
        {
            base: "a",
            letters: /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
        },
        { base: "aa", letters: /[\uA733]/g },
        { base: "ae", letters: /[\u00E6\u01FD\u01E3]/g },
        { base: "ao", letters: /[\uA735]/g },
        { base: "au", letters: /[\uA737]/g },
        { base: "av", letters: /[\uA739\uA73B]/g },
        { base: "ay", letters: /[\uA73D]/g },
        {
            base: "b",
            letters: /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g,
        },
        {
            base: "c",
            letters: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g,
        },
        {
            base: "d",
            letters: /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g,
        },
        { base: "dz", letters: /[\u01F3\u01C6]/g },
        {
            base: "e",
            letters: /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
        },
        { base: "f", letters: /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
        {
            base: "g",
            letters: /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g,
        },
        {
            base: "h",
            letters: /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g,
        },
        { base: "hv", letters: /[\u0195]/g },
        {
            base: "i",
            letters: /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
        },
        { base: "j", letters: /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
        {
            base: "k",
            letters: /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g,
        },
        {
            base: "l",
            letters: /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g,
        },
        { base: "lj", letters: /[\u01C9]/g },
        {
            base: "m",
            letters: /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g,
        },
        {
            base: "n",
            letters: /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g,
        },
        { base: "nj", letters: /[\u01CC]/g },
        {
            base: "o",
            letters: /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
        },
        { base: "oi", letters: /[\u01A3]/g },
        { base: "ou", letters: /[\u0223]/g },
        { base: "oo", letters: /[\uA74F]/g },
        {
            base: "p",
            letters: /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g,
        },
        { base: "q", letters: /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
        {
            base: "r",
            letters: /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g,
        },
        {
            base: "s",
            letters: /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g,
        },
        {
            base: "t",
            letters: /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g,
        },
        { base: "tz", letters: /[\uA729]/g },
        {
            base: "u",
            letters: /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g,
        },
        {
            base: "v",
            letters: /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g,
        },
        { base: "vy", letters: /[\uA761]/g },
        {
            base: "w",
            letters: /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g,
        },
        { base: "x", letters: /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
        {
            base: "y",
            letters: /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g,
        },
        {
            base: "z",
            letters: /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g,
        },
    ];

    for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
        str = str.replace(
            defaultDiacriticsRemovalMap[i].letters,
            defaultDiacriticsRemovalMap[i].base
        );
    }

    str = str.replace(/à|ạ|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");

    return str;
}