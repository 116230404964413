import React from "react"
import { observer, inject } from "mobx-react"
import { Button, Table} from 'reactstrap'; //, Collapse, Card, CardBody } from 'reactstrap';
// import Transition from 'react-transition-group/Transition';
// import TransitionGroup from 'react-addons-transition-group';
import '../css/cart.css'


@inject("shop","prods")
@observer
class Cart extends React.Component {

    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { collapse: false };
    }

    toggle() {
      this.setState({ collapse: !this.state.collapse });
    }

    render() {
      var shop = this.props.shop; 
      var cart = shop.cart
      var view = shop.view
      var lang = shop.staff.lang;
      const uniqueStores = [...new Set(cart.entries.map(item => shop.stores.get(item.store_slug)))];

      var layout = shop.marketplace.layout;

      var cart_header_text = layout.getTitle('yourcart',lang);
      
      if(!shop.isMarketplace ){
        var store = shop.view.selectedStore
        if(store)
          layout = store.layout;
      }

      return (  
        <div> 
          {(view.cartDisplayStatus > 0 || view.currentUrl === "/cart") &&
            <section className="">                
              <div className="mini-cart" style={{position:'absolute',right:0, top:35  , zIndex:5, backgroundColor:'white',color:'#000', border:'#efefef 1px solid', fontSize:14, width:360, maxWidth:(shop.getWindowValue('innerWidth')-50), maxHeight:(shop.getWindowValue('innerHeight')), overflowY:'auto'}} >
                {cart.entries.length > 0 ? (
                  <div>
                    <Table style={{maxWidth:'100%', border:'none'}}>
                      <thead>
                        <tr style={{border:'none'}}>
                          <th colSpan={5} style={{textAlign:"left",fontStyle:"normal",fontWeight:"550",fontSize:"1rem",lineHeight:"1.41667em",letterSpacing:".05em",color:"#000", border:"none", marginLeft:5,marginTop:5}}>{cart_header_text}</th>
                          <th style={{border:'none',textAlign:'right'}}>
                            <a className='topboxclose'     
                                  onClick={(e) => { shop.view.cartPopup() }}                          
                                  style={{position:'relative', left:-5, top:-2, color:"#000"}}><span style={{fontSize:10}}></span>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{border:'none'}}>
                          <td colSpan="6" style={{border:'none',margin:0}}>
                            <div className="product-options" style={{textAlign:'left', width:'100%'}}>
                              {uniqueStores.length > 0 &&
                                <div>
                                  {uniqueStores.map((store, k) =>
                                    <div key={k}>
                                      {store &&
                                        <a onClick={() => shop.view.openStorePage(store.slug)}>
                                          <div style={{display:'inline-block',width:30,height:30,borderRadius: 22.5, backgroundPosition:'center center',backgroundColor:'#fff', backgroundImage:'url(' + this.props.shop.imageDirectory + store.logoImageUrl + ')',backgroundSize:'cover',position:'relative',top:0,left:0,border:'1px solid #fff'}}></div>
                                          <span style={{fontStyle:"normal",fontWeight:"600",fontSize:"0.8rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000",position:'relative',top:-10,marginLeft:10}}>{store.title}</span>
                                        </a>     
                                      }
                                      {cart.entries.toJSON().length > 0 &&
                                        <div style={{width:'100%'}}>
                                          <Table style={{width:'100%'}}>
                                            <tbody>
                                              {cart.entries.filter(x => x.product.store.slug === store.slug).map((entry, i) =>
                                                <CartEntry layout={layout} key={entry.product.slug + i} store={store} shop={shop} prods={this.props.prods}  entry={entry} />
                                              )}
                                            </tbody>
                                          </Table> 
                                        </div>
                                      }
                                      {shop.cart.deliveryCost(store) > 0 &&
                                        <table style={{width:'100%',fontStyle:"normal",fontWeight:"300",fontSize:"0.6rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000"}} border='0'>
                                          <tbody>
                                            <tr>
                                              <th className="text-right" colSpan="3" style={{width:'50%'}}>
                                                {shop.marketplace.layout.getTitle('delivery_costs',lang)}
                                              </th>
                                              <th className="text-right" colSpan="2">
                                                {parseFloat(shop.cart.deliveryCost(store)).toLocaleString()}
                                                &nbsp;{shop.marketplace.currency}
                                              </th>
                                            </tr>
                                            <tr>
                                              <th className="text-right" colSpan="3" style={{width:'50%'}}>
                                                {shop.marketplace.layout.getTitle('subtotal',lang)}
                                              </th>
                                              <th className="text-right" colSpan="2">
                                                {parseFloat(cart.storeSubtotal(store)).toLocaleString()}
                                                &nbsp;{shop.marketplace.currency}
                                              </th>
                                            </tr>
                                          </tbody>                                        
                                        </table>
                                      }
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                      {/*
                      <tr style={{border:'none',margin:0}}>
                        <th className="text-left" colSpan="6" style={{textAlign:"center",fontStyle:"normal",fontWeight:"700",fontSize:"0.6rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000", border:"none",width:'100%', backgroundColor:'#EFEFEF', marginTop:-10}}>
                          <div>
                            <div onClick={this.toggle} style={{ marginBottom: '1rem', textAlign:"left",fontStyle:"normal",fontWeight:"500",fontSize:"0.70rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000", border:"none", height:20,padding:12, paddingLeft:3}}>
                              <a>
                                PROMO CODE
                                <span style={{float:'right',fontSize:"0.8rem"}}>
                                  {this.state.collapse ?
                                    <span>-</span>
                                  :
                                    <span>+</span>
                                  }
                                </span></a></div>
                            <Collapse isOpen={this.state.collapse}>
                                    <input className="" name="promo_code" placeholder="Enter Promo Code" ref="promo_code" style={{"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"18px","width":220,height:30,"padding":"14px 2px","background":"rgb(255, 255, 255)",border:"1px solid rgb(229, 229, 228)","outline":"none","color":"rgb(0, 0, 0)","boxSizing":"border-box","borderRadius":"0px", marginBottom:0}} type="text" value="" />
                                    &nbsp;&nbsp;&nbsp;
                                    <Button style={{color:"#fff",backgroundColor:layout.primTxtColor,marginTop:-3, padding:2, width:100, fontSize:13, height:29, borderRadius: 0,border: 'none'}} onClick={() => shop.view.openPage('checkout',false)}>Apply</Button>
                            </Collapse>
                          </div>
                        </th>
                      </tr>
                    */}
                      {cart.hasDiscount && 
                        <tr style={{fontStyle:"normal",fontWeight:"300",fontSize:"0.7rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000"}}>
                          <th>&nbsp;</th>
                          <th className="text-right" colSpan="3">
                            {shop.marketplace.layout.getTitle('discounts',lang)}
                          </th>
                          <th className="text-right" colSpan="2">
                            {parseFloat(cart.discount).toLocaleString()}
                            &nbsp;{shop.marketplace.currency}
                          </th>
                        </tr>
                      }
                      <tr style={{fontStyle:"normal",fontWeight:"300",fontSize:"0.9rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000"}}>
                        <th>&nbsp;</th>
                        <th className="text-right" colSpan="3">{shop.marketplace.layout.getTitle('total',lang)}</th>
                        <th className="text-right" colSpan="2">
                          {(parseFloat(cart.total/100) + parseFloat(cart.orderDeliveryCost(uniqueStores))).toLocaleString()}
                          &nbsp;{shop.marketplace.currency}
                        </th>
                      </tr>
                        {/*
                        <tr style={{fontStyle:"normal",fontWeight:"300",fontSize:"0.9rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000"}}>
                          <th>&nbsp;</th>
                          <th className="text-right" colSpan="3">{shop.marketplace.layout.getTitle('total',lang)}</th>
                          <th className="text-right" colSpan="2">
                            {(cart.total/100).toLocaleString()}
                            &nbsp;{shop.marketplace.currency}
                          </th>
                        </tr>
                      */}
                        <tr>
                          <th colSpan="7" style={{borderTop:'none',textAlign:'center'}}>
                            <div style={{position:'relative', margin:5}}>
                              {uniqueStores.length > 0 &&
                                <div>
                                  {uniqueStores.map((store, k) =>
                                    <div key={k}>
                                      {!cart.storeReachMin(store.slug) &&
                                        <a onClick={() => shop.view.openStorePage(store.slug)}>
                                          <span style={{display:'inline-block',color:layout.primTxtColor, fontSize:12, "fontWeight":300,position:'relative'}}>{store.title} {shop.marketplace.layout.getTitle('min_not_reached',lang)} - {(store.minimumOrderAmount).toLocaleString()}&nbsp;{shop.marketplace.currency} </span>
                                        </a>
                                      }
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="7" style={{borderTop:'none',textAlign:'center'}}>
                            <div style={{position:'relative'}}>
                              <a href={"/checkout"} onClick={(e) =>{e.preventDefault(); shop.view.openPage('checkout',false)}}>
                                <Button style={{color:"#fff",backgroundColor:layout.primTxtColor,margin:5, padding:15, width:"100%", fontSize:13, height:50, borderRadius: 0,border: 'none'}} disabled={!cart.canOpenCheckout}>{shop.marketplace.layout.getTitle('checkout',lang)}</Button>
                              </a>
                            </div>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                ) : (
                  <div>
                  </div>
                )}
              </div>
            
            </section>    
        }
        </div>
      )
    }  
}

export default Cart

class CartEntry extends React.Component {

    constructor(props) {
      super(props);
    
      this.state = {};
    }
    render() {
        var entry = this.props.entry
        var shop = this.props.shop
        var layout = this.props.layout

        // var extras = entry.entryOptions.toJSON().reduce(function(sum, e){return sum + e.price; }, 0)
        var lang = shop.staff.lang;
  
        var product = entry.product;
  
        var price = product.price;
        var sale  = product.sale;

        return(
          <tr>
            <td style={{padding:'0.1rem'}}>
              <div className="mini-cart-product">
                <div className="mini-cart-image" style={{width: 120}}>
                  {(entry.entryOptions.toJSON().length > 0 && entry.entryOptions.toJSON().length > 0 && entry.entryOptions.toJSON()[0].image) ?
                    <img alt={entry.entryOptions.toJSON()[0].title} src={this.props.shop.imageDirectory + entry.entryOptions.toJSON()[0].image} title={entry.entryOptions.toJSON()[0].title} style={{maxHeight: 150,maxWidth: '100%'}} />
                  :
                    <img alt={product['title_' + lang]} src={this.props.shop.imageDirectory + product.imageUrl} title={product['title_' + lang]} style={{maxHeight: 150,maxWidth: '100%'}} />
                  }
                </div>
              </div>
            </td>
            <td style={{paddingTop:0,paddingLeft:'0.6rem', width:'100%'}}>
              <div className="mini-cart-product">
                <div className="mini-cart-info" style={{position:'relative'}}>
                  <div className="mini-cart-name">
                    <a onClick={(event) => { this.props.prods.selectProduct(product);  shop.view.openProductPage(product)}} title={"Go to Product: " + product['title_' + lang]}><span className="product-name" style={{fontStyle:"normal",fontWeight:"600",fontSize:"0.8rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000",overflowWrap: "break-all"}}>{product['title_' + lang]}</span></a>
                    {entry.preOrder &&
                      <span style={{color:layout.primTxtColor,fontSize:12}}><br />Pre Order {entry.product.preOrderMinHours} {entry.product.preOrderMinHours === 1? <span>hour</span> : <span>hours</span>} ahead</span>
                    }
                  </div>
                  {/*
                  <div className="mini-product-desc">
                      <span className="product-desc" style={{fontStyle:"normal",fontWeight:"300",fontSize:"0.8rem",lineHeight:"1.41667em",letterSpacing:".05em",color:"#000",textTransform:"capitalize"}}>{product['itemDescription_' + lang].substring(0,100)}</span>
                  </div>
                */}
                  {entry.entryOptions.toJSON().length > 0 ?
                    <div>
                      <div className="mini-cart-pricing">
                        <span className="mini-price" style={{fontStyle:"normal",fontWeight:"700",fontSize:"0.75rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000",float:"right"}}>
                          {sale > 0 ?
                            <span>
                              <s>{(price/100).toLocaleString()}{shop.marketplace.currency}</s><br />
                              {(sale/100).toLocaleString()}{shop.marketplace.currency}
                            </span>
                          :
                            <span>
                              {entry.size === false && 
                                <span>{(price/100).toLocaleString()}{shop.marketplace.currency}</span>
                              }
                            </span>
                          }
                          </span>
                        <br />
                      </div>
                      <div className="mini-cart-attributes">
                        <span className="mini-price" style={{fontStyle:"normal",fontWeight:"700",fontSize:"0.7rem",lineHeight:"1.41667em",letterSpacing:".05em",color:"#000"}}>
                          Options <br />
                        </span>
                        <span style={{fontStyle:"normal",fontWeight:"300",fontSize:"0.9rem",lineHeight:"1.54545em",letterSpacing:".05em",float:"none","width":"100%"}}>
                        {entry.entryOptions.toJSON().map((option,i)=>
                            <div key={i} className="attribute" data-attribute={option['title_' + lang]} style={{display:'flex', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',width:130}}>
                                <span className="label" style={{display:'inline-block',fontStyle:"normal",fontWeight:"300",fontSize:"0.7rem",lineHeight:"1.54545em",letterSpacing:".05em",float:"left"}}>
                                  <span>{option.title}</span>
                                  {(option.size === true) ?
                                    <span className="value" style={{display:'inline-block',fontStyle:"normal",fontWeight:"600",fontSize:"0.7rem",lineHeight:"1.54545em",letterSpacing:".05em",float:"right",position:"absolute",right:0}}>{(( option.price + entry.price)).toLocaleString()}</span>
                                  : (option.price > 0) ? 
                                    <span className="value" style={{display:'inline-block',fontStyle:"normal",fontWeight:"600",fontSize:"0.7rem",lineHeight:"1.54545em",letterSpacing:".05em",float:"right",position:"absolute",right:0}}>+{option.price.toLocaleString()}</span>
                                  :
                                    <span className="value" style={{display:'inline-block',fontStyle:"normal",fontWeight:"600",fontSize:"0.7rem",lineHeight:"1.54545em",letterSpacing:".05em",float:"right",position:"absolute",right:0}}></span>
                                  }
                                </span>
                            </div>
                        )}
                        </span>
                      </div>
                    </div>
                    :
                    <div>
                      <div className="mini-cart-pricing">
                        <span className="mini-price" style={{fontStyle:"normal",fontWeight:"700",fontSize:"0.75rem",lineHeight:"1.41667em",letterSpacing:".05em",textTransform:"uppercase",color:"#000",float:"right"}}>
                            {sale > 0 ?
                                <span>
                                  <s>{(price/100).toLocaleString()}{shop.marketplace.currency}</s><br />
                                  {(sale/100).toLocaleString()}{shop.marketplace.currency}
                                </span>
                              :
                                <span>
                                  {(price/100).toLocaleString()}{shop.marketplace.currency}
                                </span>
                              }
                          </span>
                        </div>
                      </div>
                    }
                  <div className="mini-cart-qty" style={{marginTop:5}}>
                    {product.available?
                      <div>
                        <span className="label" style={{fontStyle:"normal",fontWeight:"300",fontSize:"0.7rem",lineHeight:"1.41667em",letterSpacing:".05em",color:"#000",textTransform:"capitalize"}}>{shop.marketplace.layout.getTitle('quantity',lang)}:</span> 
                        <span className="value">
                          <select style={{borderRadius:0, appearance:'none',WebkitAppearance: 'none', height:30, width:30, textAlignLast:'center', padding:5, fontStyle:"normal",fontWeight:"300",fontSize:"0.8rem",lineHeight:"1.41667em",letterSpacing:".05em",color:"#000",backgroundColor:'#fff',border:'rgb(224, 224, 224) 1px solid', marginLeft:2}} value={entry.quantity} onChange={(e) => { entry.setQuantity(e.target.value); shop.cart.updateTotal(true); }}>
                            {(function (rows, i, len) {
                              while (++i <= len) {
                                rows.push(<option key={i} value={parseInt(i,10)}>{parseInt(i,10)}</option>)
                              }
                              return rows;
                            })([], 0, (product.allow_oversell || product.allow_preorder || (product.quantity.quantity > 50) ? 50 :product.quantity.quantity))}
                          </select>
                        </span>
                      </div>
                    :
                      <div>
                        <span style={{fontSize:10, color:layout.primTxtColor, fontWeight:600}}>
                          {shop.staff.token !== "" ?
                            <a onClick={(e) => {e.preventDefault();shop.view.openAccountPage('addresses')}}>
                              {shop.marketplace.layout.getTitle('not_available',lang)}
                            </a> 
                          :
                            <a onClick={shop.view.toggleLocation}>
                             {shop.marketplace.layout.getTitle('not_available',lang)}
                            </a>
                          }
                        </span>
                      </div>
                    }                     
                  </div>
                  <span className="mini-cart-price" style={{display:'block',fontStyle: 'normal', "fontWeight": 700, textShadow: 'none', fontSize: '0.9rem', lineHeight: '1.30769em', letterSpacing: '.05em', textTransform: 'uppercase', color: '#000', float: 'right',marginTop: 14}}>{(entry.total/100).toLocaleString()}{shop.marketplace.currency}</span>
                  <p>&nbsp;</p>
                  {entry.instructions &&
                    <div>
                      <span className="instructions" style={{fontStyle:"normal",fontWeight:"700",fontSize:"0.7rem",lineHeight:"1.41667em",letterSpacing:".05em",color:"#000"}}>
                        {shop.marketplace.layout.getTitle('instructions',lang)}
                      </span><br />
                      <span style={{fontSize:"0.7rem",fontWeight:300}}>
                        {entry.instructions}
                      </span>
                    </div>
                  }
                  <a className="mini-cart-remove boxclose" style={{display:'block',fontStyle: 'normal', "fontWeight": 800, textShadow: 'none', fontSize: '0.6rem', lineHeight: '1.30769em', letterSpacing: '.05em', textTransform: 'uppercase', color: '#000', float: 'right',marginTop: 14, textAlign:'right'}} onClick={(e) => { shop.cart.removeProduct(entry) }}>
                    {shop.marketplace.layout.getTitle('remove',lang)}
                  </a>
                </div>
              </div>
            </td>
          </tr>
         
        )
    }
}