/*global google*/
import React from "react";
import { inject, observer } from "mobx-react"
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


export default @inject("shop","prods") @observer class OpenCarts extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {carts:[],
                    style:'table'
                    };
    // deliveryDateTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12:false}
  }


  componentWillMount() {
    var _this = this;
    if(!this.props.carts){
      this.props.shop.staff.loadCarts().then( response => {
        _this.setState({carts:response.carts})
      });
    }else{ 
      this.setState({carts:this.props.carts})
    }
    if(this.props.stores)this.setState({stores:this.props.stores})
    setInterval(function() {
      _this.props.shop.staff.loadCarts().then( response => {
        _this.setState({carts:response.carts})
      });
    }, 60000);
  }

  addCart(cart){
      if(cart.token)
        window.open(cart.website + '#tasty_token_cart=' + cart.token,"_blank")
    
  }

  render () {
    var shop = this.props.shop;
    var lang = shop.staff.lang;
    var layout = shop.marketplace.layout;
    var fontSize = this.props.fontSize
    return (
      <Modal isOpen={this.props.open} toggle={this.props.close} style={{minWidth:1100}} className={this.props.className}>
              <ModalHeader toggle={this.props.close}>
                  <span style={{fontWeight:300,fontSize:28, position:'relative',top:-10}}>Open Carts</span>
              </ModalHeader>
              <ModalBody>
              <div className="row" style={{width:"100%",textAlign:'center',minHeight: 450, margin:0, position:'relative', background:'#fff', borderRadius:4}}>

                <div className="col-md-12" style={{minHeight: 500,overflowY: 'hidden'}}>
                  <div className="row">
                    <div className="col-md-1">&nbsp;</div>
                    <div className="col-md-1">&nbsp;</div>
                    <div className="col-md-1">Delivery Date</div>
                    <div className="col-md-2">Name</div>
                    <div className="col-md-2">Email</div>
                    <div className="col-md-1">Phone</div>
                    <div className="col-md-1">Total</div>
                    <div className="col-md-1">Type</div>
                    <div className="col-md-1">Medivod</div>
                    <div className="col-md-1">&nbsp;</div>
                  </div>
                  <div className="row">
                    {this.state.carts.map((cart,i) =>
                      <div className="col-md-12">
                        <Accordion  allowZeroExpanded={true} style={{width:'100%'}}>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>                                            
                                <div className="row" style={{fontSize:10}}>
                                  <div className="col-md-1">&nbsp;</div>
                                  <div className="col-sm-1">
                                    {cart.entries.length > 0 &&
                                    <img src={shop.storeStore.stores.get(cart.entries[0].store_slug) && this.props.shop.imageDirectory + shop.storeStore.stores.get(cart.entries[0].store_slug).logoImageUrl} style={{maxWidth:'100%',maxHeight:30, marginRight:5}} className="logo" alt={cart.entries[0].store_title} />
                                    }
                                  </div>
                                  <div className="col-sm-1">
                                    {new Date(cart.deliveryDateTime).toLocaleTimeString(this.props.lang_date, {hour: '2-digit', minute:'2-digit'})}
                                    <br />
                                    {new Date(cart.deliveryDateTime).toLocaleDateString(this.props.lang_date, { weekday:"short",day: "numeric", month: "short" })}
                                  </div>
                                  <div className="col-sm-2">
                                    {cart.firstname + ' ' + cart.lastname}
                                  </div>
                                  <div className="col-sm-2">
                                    {cart.email}
                                  </div>
                                  <div className="col-sm-1">
                                    {cart.phone}
                                  </div>
                                  <div className="col-sm-1">
                                    {cart.total}
                                  </div>
                                  <div className="col-sm-1">
                                    {cart.order_type}
                                  </div>
                                  <div className="col-sm-1"> 
                                    {cart.payment_method}
                                  </div>
                                  <div className="col-sm-1">
                                    <a style={{cursor: 'pointer'}} onClick={this.addCart.bind(this,cart)}><i className="fas fa-shopping-cart" style={{color:'#4CAF50',cursor:'pointer'}}></i></a>
                                  </div>    
                                </div>                                
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                          {cart.entries.map((entry, i) =>
                            <div key={i} style={{width:220,color:entry.status === 'Ready' ? '#999'  : '#333',border:entry.status === 'Ready' ? '0px solid rgba(215, 215, 215,0.7)'  : '0px solid rgba(200, 200, 200,0.5)' ,borderRadius:5,padding:4,margin:0,fontSize:fontSize,textDecoration: entry.status === "Ready" ? 'line-through' : ""}}>
                              {entry.quantity}&nbsp;x&nbsp;{entry.title} &nbsp;&nbsp;<br />
                              {entry.entryOptions && entry.entryOptions.length > 0 &&
                                <span>
                                {entry.entryOptions.map((option, i) =>
                                    <span key={i} style={{color:'#666',fontSize:fontSize-1}}>{option.title},&nbsp;</span>
                                )}
                                </span>
                              }
                              &nbsp;&nbsp;{entry.status}
                              <br />
                               {entry.instructions &&
                                <div className="instructionsBox">
                                  <span style={{fontSize:fontSize-2,fontStyle:'normal'}}>{entry.instructions}</span>
                                </div>
                              }                          
                            </div>                        
                          )}
                        </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>                            
                    )}
                  </div>
                </div>                              
              </div>
        </ModalBody>
      </Modal>      
      );
    }
  }

