  import React from "react"

import moment from 'moment';
import axios from 'axios';
import InlineCss from "react-inline-css";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";

// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';

// import './main.scss' // webpack must be configured to do this


class Schedules extends React.Component {
  constructor (props) {
    super(props);
    var showSide = true;
    var currentView = 'active';
    if(window.screen.width < 800) showSide = false;
    var showSearch = true;
    var style = 'full'
    if(this.props.currentView)currentView=this.props.currentView;
    if(this.props.style)style=this.props.style;
    if(typeof this.props.showSearch !== "undefined")showSearch=this.props.showSearch;
    if(typeof this.props.showSide !== "undefined")showSide=this.props.showSide;

    var startDate = this.getHashValue("startDate");
    if(startDate){
      startDate = moment(startDate);
    }else{
      startDate = moment().subtract(10,'years')
    }

    var endDate = this.getHashValue("endDate");
    if(endDate){
      endDate = moment(endDate);
    }else{
      endDate = moment(new Date()).add(3,'days')
    }

    var search = this.getHashValue("search");
    if(search){
      search = search;
    }else{
      search = ''
    }


    this.state = {
      staff:[],
      loading:true,
      selectedStore:'',
      startDate:startDate,
      endDate: endDate,
      sort:'next',
      search:search,
      style:style,
      showSide:showSide,
      currentView:currentView,
      showSearch:showSearch,
      verified:'all',
      format:'list',
      fontSize:10,
      limit:25
    };
  }
  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key+'=([^&]*)'));
    return matches ? matches[1] : null;
  }

  componentWillMount() {
    var _this = this;
    if(!this.props.staff){
      this.props.shop.staff.loadStaff(this.state.selectedStore,this.state.startDate,this.state.endDate,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
        _this.setState({staff:response.staff,stores:response.stores})
      });
    }else{ 
      this.setState({staff:this.props.staff})
    }
    if(this.props.stores)this.setState({stores:this.props.stores})
    setInterval(function() {
      _this.props.shop.staff.loadStaff(_this.state.selectedStore,_this.state.startDate,_this.state.endDate,_this.state.sort,_this.state.limit,_this.state.search,_this.state.verified).then( response => {
        _this.setState({staff:response.staff,stores:response.stores})
      });
    }, 10000);
  }
  updateDates(startDate,endDate){
    if(endDate === null)endDate = startDate;
    var _this = this;
    this.props.shop.staff.loadStaff(this.state.selectedStore,startDate,endDate,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      console.log(startDate.toString())
      window.location.hash = "search=" + _this.state.search + "&verified=" + _this.state.verified + "&startDate=" +startDate.format('YYYY-MM-DD').toString()+ "&endDate=" +  endDate.format('YYYY-MM-DD').toString();
      _this.setState({staff:response.staff,stores:response.stores,startDate:startDate,endDate:endDate})
    });
  }
  updateSelectedStore(event){
    var _this = this;
    var store = event.target.value;
    this.props.shop.staff.loadStaff(store,this.state.startDate,this.state.endDate,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      _this.setState({staff:response.staff,stores:response.stores,selectedStore:store})
    });
  }
  updateSort(event){
    var _this = this;
    var sort = event.target.value;
    if(sort === "next") this.setState({startDate:moment().format('YYYY-MM-DD').toString()})
    this.props.shop.staff.loadStaff(this.state.selectedStore,this.state.startDate,this.state.endDate,sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      _this.setState({staff:response.staff,stores:response.stores,sort:sort})
    });
  }
  updateStaffStatus(event){
    var _this = this;
    var verified = event.target.value;
    window.location.hash = "search=" + _this.state.search + "&verified=" + verified + "&startDate=" +_this.state.startDate.format('YYYY-MM-DD').toString()+ "&endDate=" +  _this.state.endDate.format('YYYY-MM-DD').toString();
    this.props.shop.staff.loadStaff(this.state.selectedStore,this.state.startDate,this.state.endDate,this.state.sort,this.state.limit,this.state.search,verified).then( response => {
      _this.setState({staff:response.staff,stores:response.stores,verified:verified})
    });
  }
  updateLimit(event){
    var _this = this;
    var limit = event.target.value;
    this.props.shop.staff.loadStaff(this.state.selectedStore,this.state.startDate,this.state.endDate,this.state.sort,limit,this.state.search,this.state.verified).then( response => {
      _this.setState({staff:response.staff,stores:response.stores,limit:limit})      
    });
  }
  searchStaff(event){
    var _this = this;
    var search = event.target.value;    
    window.location.hash = "search=" + search + "&verified=" + _this.state.verified + "&startDate=" +_this.state.startDate.format('YYYY-MM-DD').toString()+ "&endDate=" +  _this.state.endDate.format('YYYY-MM-DD').toString();
    this.props.shop.staff.loadStaff(this.state.selectedStore,this.state.startDate,this.state.endDate,this.state.sort,this.state.limit,search).then( response => {
      _this.setState({staff:response.staff,stores:response.stores,search:search})
    });
  }
  loadStaff(){
    var _this = this;
    this.props.shop.staff.loadStaff(this.state.selectedStore,this.state.startDate,this.state.endDate,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      _this.setState({staff:response.staff,stores:response.stores})
    });
  }
  handleDateClick(arg){ // bind with an arrow function
    alert(arg.dateStr)
  }

  render (){

  var shop = this.props.shop;
  var today = new Date();
  var fontSize= this.state.fontSize;
  var latestStaff;
  if(this.state.staff && this.state.staff.length > 0 && this.state.sort === 'latest'){latestStaff = this.state.staff[this.state.staff.length - 1];}else if(this.state.staff && this.state.staff.length > 0){latestStaff = this.state.staff[0];}

  var input_style = {borderRadius: 5,"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"24px","background":"rgb(255, 255, 255)",border:"1px solid rgba(200, 200, 200,0.5)","outline":"none","color":"rgba(0, 0, 0,0.5)","height":35,"boxSizing":"border-box", display:'inline-block', padding:5, maxWidth:170};

    return (<InlineCss stylesheet={`
            .CalendarDay__selected_span {
              background: #82e0aa; //background
              color: white; //text
              border: 1px solid $light-red; //default styles include a border
            }

            // Will edit selected date or the endpoints of a range of dates
            .CalendarDay__selected {
              background: $dark-red;
              color: white;
            }

            // Will edit when hovered over. _span style also has this property
            .CalendarDay__selected:hover {
              background: orange;
              color: white;
            }

            .DateInput_input{
              font-size: 14px;
              color: rgba(0, 0, 0,0.5);
              height:25px;
              padding:0px;
              width:70px;
              border-radius:5px;
            }

            .DateInput {
              font-size: 16px;
              font-weight: normal;
              line-height: normal;
              padding: 6px;
              width: 80px;
              height:30px;
              border-radius:5px;
            }
            .DateRangePickerInput__withBstaff{
              border-radius: 5px;
              transition: all 400ms ease 0s;
              font-weight: 300;
              font-size: 14px;
              line-height: 24px;
              background: rgb(255, 255, 255);
              border: 1px solid rgba(200, 200, 200, 0.5);
              outline: none;
              
              height: 35px;
              box-sizing: border-box;
              position:relative;
              top:-2px;
              display: inline-block;
            }

            .DateRangePickerInput_arrow_svg{
              width: 10px;
              margin-top:5px;
              height: 10px;
            }

            // Will edit when the second date (end date) in a range of dates
            // is not yet selected. Edits the dates between your mouse and said date
            .CalendarDay__hovered_span:hover,
            .CalendarDay__hovered_span {
              background: brown;
            }

            `}>
            <div className="row" style={{marginLeft:0,marginRight:0,padding:0}}>
              <div className={this.state.showSide ? "col-sm-12 col-lg-10" : "col-sm-12 col-lg-12"}>
                <div className="row" style={{marginBottom:70}}>
                  <div className="col-sm-3 col-md-2 col-lg-2" style={{marginTop:50}}>
                    <span style={{fontSize:24,fontWeight:'bold',marginRight:30,marginTop:10,marginLeft:30}}>
                      Staff
                    </span>
                  </div>
                  <div className="col-sm-8 col-md-9 col-lg-9" style={{marginTop:50}}>
                    <span style={{display:this.state.showSearch ? 'inline-block' : 'none'}}>
                      {shop.stores.size > 1 &&
                        <select style={input_style} value={this.state.selectedStore} onChange={this.updateSelectedStore.bind(this)}>
                            <option value=''>All Stores</option>
                              {shop.storeStore.searchedAvailableStores.map((store,i) => 
                                <option key={i} value={store.slug}>{store.title}</option>
                              )}                      
                         </select>
                      }
                        &nbsp;
                        <select name="verified" style={input_style} value={this.state.verified} onChange={this.updateStaffStatus.bind(this)}>
                             <option value='all'>All</option>
                              <option value='true'>Verified</option>
                              <option value="false" style={{background:'rgb(255, 68, 68)',color:'#fff'}}>Not Verified</option>
                         </select>
                         &nbsp;
                        <DateRangePicker
                          isOutsideRange={() => false}
                          minimumNights={0}
                          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) => this.updateDates(startDate,endDate)} // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        />
                        &nbsp;
                        <input
                          ref="staff"
                          type="text"
                          name="staff"
                          placeholder="Search Staff"
                          style={input_style}
                          defaultValue={this.state.search}

                          onChange={this.searchStaff.bind(this)} />
                        &nbsp;
                        
                        <select style={input_style} value={this.state.sort} onChange={this.updateSort.bind(this)}>
                            <option value='latest'>Latest</option>
                            <option value='oldest'>Oldest</option>
                         </select>
                        &nbsp;
                        <select style={input_style} value={this.state.limit} onChange={this.updateLimit.bind(this)}>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='250'>250</option>
                            <option value='500'>500</option>
                            <option value='all'>All</option>
                         </select> 
                         &nbsp;&nbsp;
                         &nbsp;&nbsp;                          
                        </span>           
                    </div>
                    <div className="col-sm-1" style={{textAlign:'left'}}>
                        <div style={{color:'#9490D1',fontSize:10,marginTop:60,cursor: 'pointer',fontWeight:'bold'}}>+ New Staff</div>
                    </div>
                  </div>                                    
                  <a style={{cursor: 'pointer',position:'absolute',marginTop:2,left:window.screen.width < 800 ? '95%': '80%' ,top:0 }} onClick={(e)=>{this.setState({showSearch:!this.state.showSearch})}}>
                    <i className={this.state.showSearch ? "fas fa-caret-up" : "fas fa-caret-down"} style={{color:'rgba(200, 200, 200,1)',fontSize:fontSize+10}}></i>
                  </a>  
                  <div className="row">
                    <div className="col-sm-6" style={{marginBottom:50}}>
                      <span style={{color:'#9490D1',fontSize:14,marginRight:20,cursor: 'pointer',textTransform:'uppercase',marginLeft:30}}>{this.state.verified} Staff</span>
                      {/*
                      <span style={{color:this.state.currentView === 'active' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'active'})}}>Active Now</span>
                      <span style={{color:this.state.currentView === 'cancelled' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'cancelled'})}}>Cancelled</span>
                      <span style={{color:this.state.currentView === 'map' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'map'})}}>Map View</span>
                      */}
                    </div>
                    <div className="col-sm-6" style={{marginBottom:50,textAlign:'right'}}>
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({style:'grid'})}}>
                          <i className="fal fa-th" aria-hidden="true" style={{color: this.state.style === 'grid' ? '#9490D1' : 'rgba(200, 200, 200,0.5)',fontSize:14, padding:2}}></i>
                        </a>
                        &nbsp;
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({style:'table'})}}>
                          <i className="fal fa-th-list" aria-hidden="true" style={{color: this.state.style === 'table' ? '#9490D1' : 'rgba(200, 200, 200,0.5)',fontSize:14, padding:2}}></i>
                        </a>
                      <br />
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({fontSize:fontSize + 1})}}>
                          <i className="fal fa-plus" aria-hidden="true" style={{color: 'rgba(200, 200, 200,0.5)',fontSize:12, padding:2}}></i>
                        </a>
                        &nbsp;&nbsp;
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({fontSize:fontSize -1})}}>
                          <i className="fal fa-minus" aria-hidden="true" style={{color: 'rgba(200, 200, 200,0.5)',fontSize:12, padding:2}}></i>
                        </a>  
                        &nbsp; 
                    </div>
                  </div>
                  <div className="table-responsive" style={{minHeight: 500,overflowY: 'hidden'}}>
                        {this.state.style === 'table' ?
                          <FullCalendar ref={this.calendarRef} dateClick={this.handleDateClick} plugins={[ dayGridPlugin, interactionPlugin ]} />
                        :                   
                          <FullCalendar ref={this.calendarRef} dateClick={this.handleDateClick} plugins={[ dayGridPlugin, interactionPlugin ]} />
                        }
                  </div>
              </div>
              {window.screen.width > 800 &&
                <a style={{cursor: 'pointer',position:'absolute',right:10,top:5}} onClick={(e)=>{this.setState({showSide:!this.state.showSide})}}>
                  <i className={this.state.showSide ? "fas fa-caret-right" : "far fa-caret-left"} style={{color:'#9490D1',fontSize:fontSize+4}}></i>
                </a>
              }
            </div>
          </InlineCss>);
  }
}



export default Schedules;