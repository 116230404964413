import React from "react";
import { observer, inject } from "mobx-react";
import "../css/animate.css";
import { Badge } from "reactstrap";
import Headroom from "./react-headroom";
import MiniCart from "./MiniCart";
import AccountModal from "./AccountModal";
import SetLocation from "./SetLocation";
import InlineCss from "react-inline-css";
import classnames from "classnames";

import { activePath } from "../utils/activePath";

// import {Link} from '../routes'
// import Link from 'next/link'
// import ActiveLink from './ActiveLink'
//import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import $ from "jquery";

import "../css/navbar.css";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default
@inject("shop", "prods")
@observer
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    var menuTextColor = "#fff";
    if (this.props.shop.view.selectedStore)
      menuTextColor = this.props.shop.view.selectedStore.layout.menuTextColor;

    if (this.props.shop.view.page === "home") {
      this.state = {
        isOpen: false,
        topNav: true,
        menuColor: menuTextColor,
        menuSize: 13,
        logoWidth: 125,
        menuBGColor: "transparent",
      };
    } else {
      this.state = {
        isOpen: false,
        topNav: true,
        menuColor: "#000",
        menuSize: 13,
        menuBGColor: "#fbfbfb",
        logoWidth: 250,
      };
    }
  }
  changeLang(lang) {
    this.props.shop.staff.setLang(lang);
  }
  signOut() {
    var _this = this;
    this.props.shop.staff.signOut();
    // window.location.reload()
  }

  resize = () => this.forceUpdate();

  componentDidMount() {
    var shop = this.props.shop;

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.onScroll, false);
      window.addEventListener("resize", this.resize);

      window.scrollTo(0, 0);

      $(".contact_link").click(function () {
        if (shop.getLocationValue("pathname") === "/") {
          $("html, body").animate(
            {
              scrollTop: $("#locations").offset().top,
            },
            2000
          );
        } else {
          shop.setLocationValue("href", "/#locations");
        }
      });
      if (
        shop.getLocationValue("hash") !== "" &&
        shop.getLocationValue("hash") !== "#cc" &&
        shop.getLocationValue("hash").indexOf("#search") === -1 &&
        shop.getLocationValue("hash").indexOf("#category") === -1 &&
        shop.getLocationValue("hash").indexOf("=") === -1
      ) {
        if ($(shop.getLocationValue("hash")).length > 0) {
          $("html, body").animate(
            {
              scrollTop: $(shop.getLocationValue("hash")).offset().top,
            },
            2000
          );
        }
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.onScroll, false);
      window.removeEventListener("resize", this.resize);
    }
  }

  onScroll = () => {
    if (this.props.shop.view.page === "home" && typeof window !== "undefined") {
      var menuTextColor = this.props.shop.stores.get(
        Object.keys(this.props.shop.stores.toJSON())[0]
      ).layout.menuTextColor;
      if (window.scrollY >= 650) {
        this.setState({
          menuColor: "#000",
          menuSize: 13,
          menuBGColor: "#fbfbfb",
          logoWidth: 50,
        });
      } else {
        this.setState({
          menuColor: menuTextColor,
          menuSize: 13,
          menuBGColor: "transparent",
          logoWidth: 125,
        });
      }
    }
  };

  loadNewLang(event) {
    if (typeof window !== "undefined")
      window.location.href =
        "https://" +
        event.target.value +
        "." +
        this.props.shop.view.selectedStore.website.replace("www.", "");
  }

  loadLanguages() {
    var store = this.props.shop.view.selectedStore;

    if (!store.languages) {
      return <div></div>;
    } else {
      var en, vi, ja, zh, ko;
      store.languages.map(function (lng, i) {
        if (lng === "EN")
          en = (
            <option
              data-content='<span className="flag-icon flag-icon-us"></span> English'
              value="en"
            >
              English
            </option>
          );
        if (lng === "VN")
          vi = (
            <option
              data-content='<span className="flag-icon flag-icon-vi"></span> Vietnamese'
              value="vi"
            >
              Tiếng Việt
            </option>
          );
        if (lng === "JP")
          ja = (
            <option
              data-content='<span className="flag-icon flag-icon-ja"></span> Japanese'
              value="ja"
            >
              日本人
            </option>
          );
        if (lng === "CN")
          zh = (
            <option
              data-content='<span className="flag-icon flag-icon-cn"></span> Chinese'
              value="zh"
            >
              中文
            </option>
          );
        if (lng === "KO")
          ko = (
            <option
              data-content='<span className="flag-icon flag-icon-ko"></span> Korean'
              value="ko"
            >
              한국어
            </option>
          );
      });

      if (!en && !vi && !ja && !zh && !ko) return <div></div>;

      return (
        <select
          className="selectpicker"
          data-width="fit"
          style={{
            borderColor: "#ccc",
            marginTop: 13,
            borderRadius: 2,
            background: "transparent",
          }}
          value={this.props.shop.staff.lang}
          onChange={this.loadNewLang.bind(this)}
        >
          {en}
          {vi}
          {ja}
          {zh}
          {ko}
        </select>
      );
    }
  }

  render() {
    var logoIMG = "";
    var shop = this.props.shop;
    var store = shop.view.selectedStore;

    var lang = shop.staff.lang;

    var marketplace_layout = shop.marketplace.layout;
    var layout = marketplace_layout;
    var menu_layout = layout.menu;
    var home = layout.home;

    var txtSecBGColor = menu_layout.txtSecBGColor;

    if (!shop.isMarketplace && store) {
      logoIMG = store.logoImageUrl;
      layout = store.layout;
      home = layout.home;
      txtSecBGColor = layout.primTxtColor;
      var _this = this;
      // setTimeout(function(){
      //   _this.setState({topNav:false})
      // }, 10000);
    }

    var navigation_style = {
      menu_list_style: {
        display: "block",
        outline: "none",
        color: menu_layout.txtColor,
      },
      staffTopMenu: {
        color: menu_layout.txtColor,
        borderRadius: 0,
        margin: 10,
        width: 130,
      },
      staffTopDisplay: { marginTop: 13, fontSize: 16, textAlign: "right" },
      subMenu: {
        fontSize: 14,
        fontWeight: 300,
        textAlign: "left",
        padding: 12,
        paddingLeft: 20,
        color: menu_layout.txtColor,
        background: menu_layout.bgColor,
      },
      dropDownValues: { padding: 0, border: "1px solid #efefef" },
      cartBadge: {
        backgroundColor: txtSecBGColor,
        color: menu_layout.txtSecColor,
        float: "right",
        position: "relative",
        left: -1,
        zIndex: 2,
        top: -5,
        fontSize: 12,
      },
      staffIcon: { fontSize: 28, color: menu_layout.txtColor },
    };

    var menuColor = "#000";
    var menuSize = "13px";
    var menBGColor = this.state.menuBGColor;
    var logoWidth = this.state.logoWidth;

    if (
      store &&
      store.layout.home.showHeaderVideo === true &&
      store.heroVideoUrl !== "" &&
      shop.getWindowValue("innerWidth") > 800 &&
      shop.view.page === "home"
    ) {
      menuColor = this.state.menuColor;
      menuSize = this.state.menuSize + "px";
      logoWidth = this.state.logoWidth;
      menBGColor = this.state.menuBGColor;
    }

    var divStyle = {};

    if (shop.view.page === "home")
      divStyle = { position: "absolute", right: 0, zIndex: 2, width: "100%" };

    return (
      <InlineCss
        stylesheet={
          `
        .custom-navbar {
            border: none;
            margin-bottom: 0;
            padding-top: 2px;
            background-color:` +
          menBGColor +
          `!important;
            z-index:1;
          }
          .navbar-collapse{
            background-color:#fff;
          }

          .custom-navbar .navbar-brand {
            color:` +
          menuColor +
          `;
            font-weight: normal;
            font-size: 20px;
          }

          .custom-navbar .navbar-nav {
               display: table;
               float: left;
               margin-left: 10px;
          }

          .custom-navbar .nav-item{
               text-align:left;
               position: relative;
               display: inline-block;
          }

          .custom-navbar .nav-link{
               display: inline-block;
               font-size: ` +
          menuSize +
          `;
               font-weight: normal;
               color:` +
          menuColor +
          `;
               letter-spacing: 1px;
               -webkit-transition: all ease-in-out 0.4s;
               transition: all ease-in-out 0.4s;
               padding: 0;
               margin: 15px;
          }

          .custom-navbar .nav-link:hover,
          .custom-navbar .nav-link:focus {
            background-color: transparent;
            color:` +
          menuColor +
          `;
          }
          .custom-navbar{
            color:` +
          menuColor +
          `;
          }
          .custom-navbar .nav-link:after {
            content: "";
            position: absolute;
            display: block;
            width: 0px;
            height: 2px;
            margin: auto;
            background: transparent;
            transition: width .3s ease, background-color .3s ease;
          }

          .custom-navbar .nav-link:hover:after,
          .custom-navbar .nav li.active > a:after {
            background-color: ` +
          layout.primTxtColor +
          ` !important;
            color: #fff;
            width: 70%;
          }

          .custom-navbar .nav li.active > a {
            background-color: transparent;
            color:` +
          menuColor +
          `;
          }

          .custom-navbar .navbar-toggler {
            border: none;
            padding-top: 12px;
          }

          .custom-navbar .navbar-toggler {
            background-color: transparent;
          }

          .custom-navbar .navbar-toggler .navbar-toggler-icon {
            border-color: #000000 !important;
            border-color: transparent;
          }

          @media(min-width:768px) {
              .custom-navbar {
                border-bottom: 0;
                background: 0 0;
              }
              .custom-navbar.top-nav-collapse {
                background: #fff;
                padding: 10px 0;
              }

          }


          @media (max-width: 768px) {

            h1 {
              font-size: 30px;
              line-height: normal;
            }

            h2  {font-size: 30px;}

            .custom-navbar {
              background-color: #fff;
              padding-top: 0px;
              padding-bottom: 5px;
            }

            .custom-navbar .nav {
              padding-bottom: 10px;
            }

            .custom-navbar .nav li a {
              display: inline-block;
              margin-bottom: 5px;
            }
            .custom-navbar .nav-item{
               text-align:left;
               position: relative;
               display: block;
            }

          }
        `
        }
      >
        {typeof window !== "undefined" ? (
          <div style={divStyle}>
            <Headroom
              style={{
                width: "100%",
                textAlign: "center",
                zIndex: 3,
                color: "#000 !important",
                height: 60,
                backgroundColor: menBGColor,
              }}
            >
              <div style={{ backgroundColor: menBGColor }}>
                {home["topBarMSG_" + lang] !== "" && this.state.topNav && (
                  <div style={JSON.parse(home.topBarMSGCSS)}>
                    {home["topBarMSG_" + lang]}
                    <a>
                      <div
                        onClick={(e) => {
                          this.setState({ topNav: false });
                        }}
                        style={{
                          color: "#fff",
                          position: "absolute",
                          right: 6,
                          top: 0,
                          fontWeight: 500,
                        }}
                      >
                        x
                      </div>
                    </a>
                  </div>
                )}
                {home["topBarMSG_" + lang] !== "" && this.state.topNav && (
                  <div style={{ height: 20 }}>&nbsp;</div>
                )}
                {/*store && store.layout && store.layout.showLanguages &&
                    <div style={{float:'right', position:'absolute', right:15, top:2, fontSize:10, color:"#999", zIndex:2}}>
                      {store.languages && store.languages.map((lng, i) =>
                        <span key={i}>
                        {lng === "EN" &&
                          <span><a href={shop.staff.getLang('en') ? shop.staff.getLang('en')  : "https://en." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'en' ? layout.primTxtColor : '#666' }}>EN</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "VN" &&
                          <span><a href={shop.staff.getLang('vi') ? shop.staff.getLang('vi')  : "https://vi." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'vi' ? layout.primTxtColor : '#666' }}>VN</a>{store.languages.length - 1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "JP" &&
                          <span><a href={shop.staff.getLang('ja') ? shop.staff.getLang('ja')  : "https://ja." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'ja' ? layout.primTxtColor : '#666' }}>JP</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "CN" &&
                          <span><a href={shop.staff.getLang('zh') ? shop.staff.getLang('zh')  : "https://zh." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'zh' ? layout.primTxtColor : '#666' }}>CN</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "KO" &&
                          <span><a href={shop.staff.getLang('ko') ? shop.staff.getLang('ko')  : "https://ko." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'ko' ? layout.primTxtColor : '#666' }}>KO</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        </span>
                      )}
                    </div>
                  */}
                {window.innerWidth > 800 ? (
                  <div>
                    {store &&
                      store.layout.home.showHeaderVideo === true &&
                      store.heroVideoUrl !== "" &&
                      window.innerWidth > 800 &&
                      shop.view.page === "home" && (
                        <a
                          href={"/orders"}
                          onClick={(e) => {
                            e.preventDefault();
                            shop.view.openPage("orders", false);
                          }}
                          style={{
                            fontSize: 16,
                            top: 5,
                            left: 10,
                            position: "absolute",
                            zIndex: 2,
                          }}
                        >
                          <img
                            src={this.props.shop.imageDirectory + logoIMG}
                            alt={shop.view.title}
                            style={{
                              maxWidth: logoWidth,
                              maxHeight: logoWidth,
                              marginRight: 10,
                              borderRadius: logoWidth / 2,
                            }}
                          />
                        </a>
                      )}
                    <Navbar className="custom-navbar">
                      {(store && store.layout.home.showHeaderVideo !== true) ||
                      (store && store.heroVideoUrl === "") ||
                      window.innerWidth < 800 ||
                      shop.view.page !== "home" ? (
                        <NavbarBrand
                          href={"/orders"}
                          onClick={(e) => {
                            e.preventDefault();
                            shop.view.openPage("orders", false);
                          }}
                          style={{
                            fontSize: 16,
                            top: -4,
                            position: "absolute",
                          }}
                        >
                          <img
                            src={this.props.shop.imageDirectory + logoIMG}
                            alt={shop.view.title}
                            style={{
                              maxHeight: 60,
                              maxWidth: 105,
                              marginRight: 10,
                              padding: 5,
                              borderRadius: 60 / 2,
                            }}
                          />
                        </NavbarBrand>
                      ) : (
                        <NavbarBrand style={{ position: "absolute" }}>
                          &nbsp;
                        </NavbarBrand>
                      )}
                      <Nav style={{ display: "flex" }} className="ml-auto">
                        <NavItem
                          className={shop.view.page === "home" ? "active" : ""}
                        >
                          <NavLink
                            href={"/orders"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("orders", false);
                            }}
                          >
                            {marketplace_layout.getTitle("home", lang)}
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={
                            shop.view.page === "products" ? "active" : ""
                          }
                        >
                          <NavLink
                            href={"/products"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("products", false);
                              this.props.prods.clearSearch(
                                this.props.shop.marketplace.slug,
                                this.props.shop.domain,
                                this.props.shop.staff._id
                              );
                            }}
                          >
                            {marketplace_layout.getTitle("products", lang)}
                          </NavLink>
                        </NavItem>
                        {store && store.layout.home.showBlog && (
                          <NavItem
                            className={
                              shop.view.page === "blog" ? "active" : ""
                            }
                          >
                            <NavLink
                              href={"/blog"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("blog", false);
                              }}
                            >
                              {marketplace_layout.getTitle("blog", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {layout.customPages &&
                          layout.customPages
                            .sort(function (obj1, obj2) {
                              return obj1.menu_order - obj2.menu_order;
                            })
                            .map((page, i) => (
                              <NavItem
                                key={i}
                                className={
                                  shop.view.page === page.slug ? "active" : ""
                                }
                              >
                                {page.on_menu && (
                                  <NavLink
                                    href={"/" + page.slug}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      shop.view.openPage(page.slug, false);
                                    }}
                                  >
                                    {page["title_" + lang]}
                                  </NavLink>
                                )}
                              </NavItem>
                            ))}
                        <NavItem
                          className={
                            shop.view.page === "contact"
                              ? "active contact_link"
                              : "contact_link"
                          }
                        >
                          <NavLink>
                            {marketplace_layout.getTitle("contact", lang)}
                          </NavLink>
                        </NavItem>
                        {shop.cart.entries > 0 && (
                          <NavItem
                            className={
                              shop.view.page === "checkout" ? "active" : ""
                            }
                          >
                            <NavLink
                              href={"/checkout"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("checkout", false);
                              }}
                            >
                              {marketplace_layout.getTitle("checkout", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token !== "" && (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                              {marketplace_layout.getTitle("account", lang)}
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                href={"/account"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("orders");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "orders",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("profile");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "profile",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("addresses");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "addresses",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("favourites");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "favourites",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(e) => {
                                  this.signOut();
                                }}
                              >
                                {marketplace_layout.getTitle("signout", lang)}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                        {shop.staff.token !== "" && (
                          <NavItem>
                            <NavLink
                              onClick={(e) => {
                                this.signOut();
                              }}
                            >
                              {marketplace_layout.getTitle("signout", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token === "" && (
                          <NavItem>
                            <NavLink onClick={shop.view.toggleLogin}>
                              {marketplace_layout.getTitle("login", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {(!typeof window !== "undefined" ||
                          window.location === window.parent.location) &&
                          shop.staff.token === "" &&
                          !shop.marketplace.disableSignup && (
                            <NavItem>
                              <NavLink onClick={shop.view.toggleSignup}>
                                {marketplace_layout.getTitle("register", lang)}
                              </NavLink>
                            </NavItem>
                          )}
                        {store &&
                          store.layout &&
                          store.layout.showLanguages && (
                            <NavItem>{this.loadLanguages()}</NavItem>
                          )}
                        <NavItem style={{ paddingTop: 19 }}>
                          {/*<div onClick={(e) => shop.view.cartPopup()}  onMouseOver={(e) => shop.view.cartOpen()}>*/}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.cartPopup();
                            }}
                          >
                            <Badge
                              color="default"
                              style={navigation_style.cartBadge}
                              pill
                            >
                              {shop.cart.cartQty}
                            </Badge>
                            <img
                              src={
                                shop.imageDirectory +
                                "/images/fontawesome/shopping-bag-light.svg"
                              }
                              alt=""
                              style={{
                                width: 50,
                                paddingRight: 20,
                                float: "right",
                                position: "relative",
                                left: 30,
                                top: 0,
                                color: menuColor,
                              }}
                            />
                          </a>
                        </NavItem>
                      </Nav>
                    </Navbar>
                    <MiniCart />
                  </div>
                ) : (
                  <Navbar className="custom-navbar" color="faded" light>
                    <NavbarBrand
                      href={"/orders"}
                      onClick={(e) => {
                        e.preventDefault();
                        shop.view.openPage("orders", false);
                      }}
                      style={{
                        fontSize: 16,
                        top: 5,
                        position: "absolute",
                        transform: "translate(-50%,0)",
                        left: "50%",
                      }}
                    >
                      <img
                        src={this.props.shop.imageDirectory + logoIMG}
                        alt={shop.view.title}
                        style={{
                          maxHeight: 35,
                          maxWidth: 105,
                          marginRight: 10,
                          borderRadius: 35 / 2,
                        }}
                      />
                    </NavbarBrand>
                    <NavbarToggler
                      onClick={(e) => {
                        e.preventDefault();
                        shop.view.toggleMenu();
                      }}
                    />
                    <Collapse isOpen={shop.view.menuOpen} navbar>
                      <Nav className="ml-auto" navbar>
                        <NavItem>
                          <NavLink
                            href={"/orders"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("orders", false);
                            }}
                          >
                            {marketplace_layout.getTitle("home", lang)}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href={"/products"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("products", false);
                            }}
                          >
                            {marketplace_layout.getTitle("products", lang)}
                          </NavLink>
                        </NavItem>
                        {store && store.layout.home.showBlog && (
                          <NavItem
                            className={
                              shop.view.page === "blog" ? "active" : ""
                            }
                          >
                            <NavLink
                              href={"/blog"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("blog", false);
                              }}
                            >
                              {marketplace_layout.getTitle("blog", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {layout.customPages &&
                          layout.customPages
                            .sort(function (obj1, obj2) {
                              return obj1.menu_order - obj2.menu_order;
                            })
                            .map((page, i) => (
                              <NavItem
                                key={i}
                                className={
                                  shop.view.page === page.slug ? "active" : ""
                                }
                              >
                                {page.on_menu && (
                                  <NavLink
                                    href={"/page/" + page.slug}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      shop.view.openPage(page.slug, false);
                                    }}
                                  >
                                    {page["title_" + lang]}
                                  </NavLink>
                                )}
                              </NavItem>
                            ))}
                        <NavItem>
                          <NavLink
                            href={"/home#locations"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("contact", false);
                            }}
                          >
                            {marketplace_layout.getTitle("contact", lang)}
                          </NavLink>
                        </NavItem>
                        {shop.cart.entries.length > 0 && (
                          <NavItem
                            className={
                              shop.view.page === "checkout" ? "active" : ""
                            }
                          >
                            <NavLink
                              href={"/checkout"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("checkout", false);
                              }}
                            >
                              {marketplace_layout.getTitle("checkout", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token !== "" && (
                          <NavItem>
                            <NavLink
                              href={"/favourites"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("favourites", false);
                              }}
                            >
                              {marketplace_layout.getTitle("favourites", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token !== "" ? (
                          <div>
                            <NavItem>
                              <NavLink
                                href={"/account"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("orders");
                                }}
                              >
                                {marketplace_layout.getTitle("account", lang)}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                onClick={(e) => {
                                  this.signOut();
                                }}
                              >
                                {marketplace_layout.getTitle("signout", lang)}
                              </NavLink>
                            </NavItem>
                          </div>
                        ) : (
                          <div>
                            <NavItem>
                              <NavLink onClick={shop.view.toggleLogin}>
                                {marketplace_layout.getTitle("login", lang)}
                              </NavLink>
                            </NavItem>
                            {(!typeof window !== "undefined" ||
                              window.location === window.parent.location) && (
                              <NavItem>
                                {!shop.marketplace.disableSignup && (
                                  <NavLink onClick={shop.view.toggleSignup}>
                                    {marketplace_layout.getTitle(
                                      "register",
                                      lang
                                    )}
                                  </NavLink>
                                )}
                              </NavItem>
                            )}
                          </div>
                        )}
                        {store && store.layout && store.layout.showLanguages && (
                          <NavItem>
                            <div style={{ margin: 13 }}>
                              {this.loadLanguages()}
                            </div>
                          </NavItem>
                        )}
                      </Nav>
                    </Collapse>
                  </Navbar>
                )}
              </div>
              <div style={{ position: "absolute", zIndex: 1000 }}>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                {shop.view.loginModal && (
                  <AccountModal className="accountModal" />
                )}
                <SetLocation />
              </div>
            </Headroom>
          </div>
        ) : (
          <div style={divStyle}>
            <Headroom
              style={{
                width: "100%",
                textAlign: "center",
                zIndex: 3,
                color: "#000 !important",
                height: 60,
                backgroundColor: menBGColor,
              }}
            >
              <div style={{ backgroundColor: menBGColor }}>
                {home["topBarMSG_" + lang] !== "" && this.state.topNav && (
                  <div style={JSON.parse(home.topBarMSGCSS)}>
                    {home["topBarMSG_" + lang]}
                    <div
                      onClick={(e) => {
                        this.setState({ topNav: false });
                      }}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 6,
                        top: 0,
                        fontWeight: 500,
                      }}
                    >
                      x
                    </div>
                  </div>
                )}
                {home["topBarMSG_" + lang] !== "" && this.state.topNav && (
                  <div style={{ height: 20 }}>&nbsp;</div>
                )}
                {/*store && store.layout && store.layout.showLanguages && shop.staff &&
                    <div style={{float:'right', position:'absolute', right:15, top:2, fontSize:10, color:"#999", zIndex:2}}>
                      {store.languages && store.languages.map((lng, i) =>
                        <span key={i}>
                        {lng === "EN" &&
                          <span><a href={shop.staff.getLang('en') ? shop.staff.getLang('en')  : "https://en." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'en' ? layout.primTxtColor : '#666' }}>EN</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "VN" &&
                          <span><a href={shop.staff.getLang('vi') ? shop.staff.getLang('vi')  : "https://vi." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'vi' ? layout.primTxtColor : '#666' }}>VN</a>{store.languages.length - 1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "JP" &&
                          <span><a href={shop.staff.getLang('ja') ? shop.staff.getLang('ja')  : "https://ja." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'ja' ? layout.primTxtColor : '#666' }}>JP</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "CN" &&
                          <span><a href={shop.staff.getLang('zh') ? shop.staff.getLang('zh')  : "https://zh." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'zh' ? layout.primTxtColor : '#666' }}>CN</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        {lng === "KO" &&
                          <span><a href={shop.staff.getLang('ko') ? shop.staff.getLang('ko')  : "https://ko." + shop.view.selectedStore.website.replace("www.","")} style={{color: lang === 'ko' ? layout.primTxtColor : '#666' }}>KO</a>{store.languages.length -1 > i && <span>&nbsp;|&nbsp;</span>}</span>
                        }
                        </span>
                      )}
                    </div>
                  */}
                {shop.getWindowValue("innerWidth") > 800 ? (
                  <div>
                    {store &&
                      store.layout.home.showHeaderVideo === true &&
                      store.heroVideoUrl !== "" &&
                      shop.getWindowValue("innerWidth") > 800 &&
                      shop.view.page === "home" && (
                        <NavLink
                          href={"/orders"}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span
                            style={{
                              fontSize: 16,
                              top: 5,
                              left: 10,
                              position: "absolute",
                              zIndex: 2,
                            }}
                          >
                            <img
                              src={this.props.shop.imageDirectory + logoIMG}
                              alt={shop.view.title}
                              style={{
                                maxWidth: logoWidth,
                                maxHeight: logoWidth,
                                marginRight: 10,
                                borderRadius: logoWidth / 2,
                              }}
                            />
                          </span>
                        </NavLink>
                      )}

                    {/*
                      &nbsp;
                      <NavLink route='/'>
                        <a>Home</a>
                      </NavLink>
                      &nbsp;
                      <NavLink route='/about'>
                        <a>About</a>
                      </NavLink>
                      &nbsp;
                      <NavLink route='/about2'>
                        <a>About2</a>
                      </NavLink>
                      &nbsp;
                      <NavLink route='/products' >
                        <a>{marketplace_layout.getTitle('products',lang)}</a>
                      </NavLink>
                      &nbsp;
                      <NavLink route='/blog'>
                        <a>{marketplace_layout.getTitle('blog',lang)}</a>
                      </NavLink>
                      &nbsp;
                      <NavLink href={{ pathname: '/', query: { name:'post', id: 'Gift-voucher' } }} passHref  scroll={false} as={'/post/Gift-voucher'} >
                        <a>post</a>
                      </NavLink>
                    */}

                    <Navbar className="custom-navbar">
                      {(store && store.layout.home.showHeaderVideo !== true) ||
                      (store && store.heroVideoUrl === "") ||
                      shop.getWindowValue("innerWidth") < 800 ||
                      shop.view.page !== "home" ? (
                        <NavbarBrand
                          href={"/orders"}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          style={{
                            fontSize: 16,
                            top: -4,
                            position: "absolute",
                          }}
                        >
                          <NavLink href="/">
                            <img
                              src={this.props.shop.imageDirectory + logoIMG}
                              alt={shop.title}
                              style={{
                                maxHeight: 60,
                                maxWidth: 105,
                                marginRight: 10,
                                padding: 5,
                                borderRadius: 60 / 2,
                              }}
                            />
                          </NavLink>
                        </NavbarBrand>
                      ) : (
                        <NavbarBrand style={{ position: "absolute" }}>
                          &nbsp;
                        </NavbarBrand>
                      )}
                      <Nav style={{ display: "flex" }} className="ml-auto">
                        <NavLink
                          href="/"
                          className={classnames("nav-item nav-link", {
                            active: activePath(
                              shop.getLocationValue("pathname"),
                              "/",
                              { strict: true }
                            ),
                          })}
                        >
                          {marketplace_layout.getTitle("home", lang)}
                        </NavLink>
                        {/*
                            <a href={'/'} onClick={(event) => { event.preventDefault(); shop.view.openPage('orders',false);Router.pushRoute('/',{},{shallow:true});}}  className={classnames("nav-item nav-link", { active: activePath(shop.getLocationValue('pathname'), "/", { strict: true }) })}>{marketplace_layout.getTitle('home',lang)}</a>
                            <a href={'/about2'} onClick={(event) => { event.preventDefault(); shop.view.openPage('about2',false); Router.pushRoute('/about2',{},{shallow:true});}}  className={classnames("nav-item nav-link", { active: activePath(shop.getLocationValue('pathname'), "/", { strict: true }) })}>about2</a>
                          */}
                        <NavLink
                          href={"/products"}
                          onClick={(event) => {
                            event.preventDefault();
                            shop.view.openPage("products", false);
                          }}
                          className={classnames("nav-item nav-link", {
                            active: activePath(
                              shop.getLocationValue("pathname"),
                              "/",
                              { strict: true }
                            ),
                          })}
                        >
                          {marketplace_layout.getTitle("products", lang)}
                        </NavLink>
                        <NavLink
                          href={"/blog"}
                          onClick={(event) => {
                            event.preventDefault();
                            shop.view.openPage("blog", false);
                          }}
                          className={classnames("nav-item nav-link", {
                            active: activePath(
                              shop.getLocationValue("pathname"),
                              "/",
                              { strict: true }
                            ),
                          })}
                        >
                          {marketplace_layout.getTitle("blog", lang)}
                        </NavLink>
                        {layout.customPages &&
                          layout.customPages
                            .sort(function (obj1, obj2) {
                              return obj1.menu_order - obj2.menu_order;
                            })
                            .map((page, i) => (
                              <NavLink
                                href={"/page/" + page.slug}
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openPage(page.slug, false);
                                }}
                                key={i}
                                className={classnames("nav-item nav-link", {
                                  active: activePath(
                                    shop.getLocationValue("pathname"),
                                    "/",
                                    { strict: true }
                                  ),
                                })}
                              >
                                {page["title_" + lang]}
                              </NavLink>
                            ))}
                        <NavLink
                          href={"/checkout"}
                          onClick={(event) => {
                            event.preventDefault();
                            shop.view.openPage("checkout", false);
                          }}
                          className={classnames("nav-item nav-link", {
                            active: activePath(
                              shop.getLocationValue("pathname"),
                              "/",
                              { strict: true }
                            ),
                          })}
                        >
                          {marketplace_layout.getTitle("checkout", lang)}
                        </NavLink>
                        {/*
                                <NavItem key={i} className={shop.view.page === page.slug ? 'active' : '' }>
                                  {page.on_menu &&
                                    <NavLink href={"/" + page.slug} onClick={(e) => {e.preventDefault();shop.view.openPage(page.slug,false)}}>
                                      {page['title_' + lang]}
                                    </NavLink>
                                  }
                                </NavItem>
                              */}
                        {/*
                              <NavItem className={shop.view.page === 'home' ? 'active' : '' }>
                                <NavLink href={"/orders"} onClick={(e) => {e.preventDefault();shop.view.openPage('orders',false)}}>{marketplace_layout.getTitle('home',lang)}</NavLink>
                              </NavItem>
                              <NavItem className={shop.view.page === 'products' ? 'active' : '' }>
                                <NavLink href={"/products"} onClick={(e) => {e.preventDefault();shop.view.openPage('products',false)}}>
                                  {marketplace_layout.getTitle('products',lang)}
                                </NavLink>
                              </NavItem>
                              {store && store.layout.home.showBlog &&
                                <NavItem className={shop.view.page === 'blog' ? 'active' : '' }>
                                  <NavLink href={"/blog"} onClick={(e) => {e.preventDefault();shop.view.openPage('blog',false)}}>{marketplace_layout.getTitle('blog',lang)}</NavLink>
                                </NavItem>
                              }
                              {layout.customPages && layout.customPages.sort(function(obj1, obj2) {return obj1.menu_order - obj2.menu_order;}).map((page, i) =>
                                <NavItem key={i} className={shop.view.page === page.slug ? 'active' : '' }>
                                  {page.on_menu &&
                                    <NavLink href={"/" + page.slug} onClick={(e) => {e.preventDefault();shop.view.openPage(page.slug,false)}}>
                                      {page['title_' + lang]}
                                    </NavLink>
                                  }
                                </NavItem>
                              )}
                              <NavItem className={shop.view.page === 'contact' ? 'active contact_link' : 'contact_link' }>
                                <NavLink>{marketplace_layout.getTitle('contact',lang)}</NavLink>
                              </NavItem>
                              {shop.cart.entries > 0 &&
                                <NavItem className={shop.view.page === 'checkout' ? 'active' : '' }>
                                  <NavLink href={"/checkout"} onClick={(e) => {e.preventDefault();shop.view.openPage('checkout',false)}}>{marketplace_layout.getTitle('checkout',lang)}</NavLink>
                                </NavItem>
                              }
                              */}

                        {shop.staff.token !== "" && (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                              {marketplace_layout.getTitle("account", lang)}
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                href={"/account"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("orders");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "orders",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("profile");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "profile",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("addresses");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "addresses",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openAccountPage("favourites");
                                }}
                              >
                                {shop.marketplace.layout.getTitle(
                                  "favourites",
                                  lang
                                )}
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(e) => {
                                  this.signOut();
                                }}
                              >
                                {marketplace_layout.getTitle("signout", lang)}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                        {shop.staff.token !== "" && (
                          <NavItem>
                            <NavLink
                              onClick={(e) => {
                                this.signOut();
                              }}
                            >
                              {marketplace_layout.getTitle("signout", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token === "" && (
                          <NavItem>
                            <NavLink onClick={shop.view.toggleLogin}>
                              {marketplace_layout.getTitle("login", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token === "" &&
                          !shop.marketplace.disableSignup &&
                          (!typeof window !== "undefined" ||
                            window.location === window.parent.location) && (
                            <NavItem>
                              <NavLink onClick={shop.view.toggleSignup}>
                                {marketplace_layout.getTitle("register", lang)}
                              </NavLink>
                            </NavItem>
                          )}
                        {store &&
                          store.layout &&
                          store.layout.showLanguages && (
                            <NavItem>{this.loadLanguages()}</NavItem>
                          )}
                        <NavItem style={{ paddingTop: 19 }}>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.cartPopup();
                            }}
                          >
                            <Badge
                              color="default"
                              style={navigation_style.cartBadge}
                              pill
                            >
                              {shop.cart.cartQty}
                            </Badge>
                            <img
                              src={
                                this.props.shop.imageDirectory +
                                "/images/fontawesome/shopping-bag-light.svg"
                              }
                              alt=""
                              style={{
                                width: 50,
                                paddingRight: 20,
                                float: "right",
                                position: "relative",
                                left: 30,
                                top: 0,
                                color: menuColor,
                              }}
                            />
                          </div>
                        </NavItem>
                      </Nav>
                    </Navbar>
                    <MiniCart />
                  </div>
                ) : (
                  <Navbar className="custom-navbar" color="faded" light>
                    <NavbarBrand
                      href={"/orders"}
                      onClick={(e) => {
                        e.preventDefault();
                        shop.view.openPage("orders", false);
                      }}
                      style={{
                        fontSize: 16,
                        top: 5,
                        position: "absolute",
                        transform: "translate(-50%,0)",
                        left: "50%",
                      }}
                    >
                      <img
                        src={this.props.shop.imageDirectory + logoIMG}
                        alt={shop.title}
                        style={{
                          maxHeight: 35,
                          maxWidth: 105,
                          marginRight: 10,
                          borderRadius: 35 / 2,
                        }}
                      />
                    </NavbarBrand>
                    <NavbarToggler
                      onClick={(e) => {
                        e.preventDefault();
                        shop.view.toggleMenu();
                      }}
                    />
                    <Collapse isOpen={shop.view.menuOpen} navbar>
                      <Nav className="ml-auto" navbar>
                        <NavItem>
                          <NavLink
                            href={"/orders"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("orders", false);
                            }}
                          >
                            {marketplace_layout.getTitle("home", lang)}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href={"/products"}
                            onClick={(e) => {
                              e.preventDefault();
                              shop.view.openPage("products", false);
                            }}
                          >
                            {marketplace_layout.getTitle("products", lang)}
                          </NavLink>
                        </NavItem>
                        {store && store.layout.home.showBlog && (
                          <NavItem
                            className={
                              shop.view.page === "blog" ? "active" : ""
                            }
                          >
                            <NavLink
                              href={"/blog"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("blog", false);
                              }}
                            >
                              {marketplace_layout.getTitle("blog", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {layout.customPages &&
                          layout.customPages
                            .sort(function (obj1, obj2) {
                              return obj1.menu_order - obj2.menu_order;
                            })
                            .map((page, i) => (
                              <NavItem
                                key={i}
                                className={
                                  shop.view.page === page.slug ? "active" : ""
                                }
                              >
                                {page.on_menu && (
                                  <NavLink
                                    href={"/page/" + page.slug}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      shop.view.openPage(page.slug, false);
                                    }}
                                  >
                                    {page["title_" + lang]}
                                  </NavLink>
                                )}
                              </NavItem>
                            ))}
                        <NavItem>
                          <NavLink
                            href={"/home#locations"}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {marketplace_layout.getTitle("contact", lang)}
                          </NavLink>
                        </NavItem>
                        {shop.cart.entries.length > 0 && (
                          <NavItem
                            className={
                              shop.view.page === "checkout" ? "active" : ""
                            }
                          >
                            <NavLink
                              href={"/checkout"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("checkout", false);
                              }}
                            >
                              {marketplace_layout.getTitle("checkout", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token !== "" && (
                          <NavItem>
                            <NavLink
                              href={"/favourites"}
                              onClick={(e) => {
                                e.preventDefault();
                                shop.view.openPage("favourites", false);
                              }}
                            >
                              {marketplace_layout.getTitle("favourites", lang)}
                            </NavLink>
                          </NavItem>
                        )}
                        {shop.staff.token !== "" ? (
                          <div>
                            <NavItem>
                              <NavLink
                                href={"/account"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  shop.view.openPage("account", false);
                                }}
                              >
                                {marketplace_layout.getTitle("account", lang)}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                onClick={(e) => {
                                  this.signOut();
                                }}
                              >
                                {marketplace_layout.getTitle("signout", lang)}
                              </NavLink>
                            </NavItem>
                          </div>
                        ) : (
                          <div>
                            <NavItem>
                              <NavLink onClick={shop.view.toggleLogin}>
                                {marketplace_layout.getTitle("login", lang)}
                              </NavLink>
                            </NavItem>
                            {(!typeof window !== "undefined" ||
                              window.location === window.parent.location) && (
                              <NavItem>
                                {!shop.marketplace.disableSignup && (
                                  <NavLink onClick={shop.view.toggleSignup}>
                                    {marketplace_layout.getTitle(
                                      "register",
                                      lang
                                    )}
                                  </NavLink>
                                )}
                              </NavItem>
                            )}
                          </div>
                        )}
                        {store &&
                          store.layout &&
                          store.layout.showLanguages && (
                            <NavItem>{this.loadLanguages()}</NavItem>
                          )}
                      </Nav>
                    </Collapse>
                  </Navbar>
                )}
              </div>
              <div style={{ position: "absolute", zIndex: 1000 }}>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                {shop.view.loginModal && (
                  <AccountModal className="accountModal" />
                )}
                <SetLocation />
              </div>
            </Headroom>
          </div>
        )}
      </InlineCss>
    );
  }
}
