  import React from "react"

import moment from 'moment';
import InlineCss from "react-inline-css";

import ChangeDeliveryTime from "./store/ChangeDeliveryTime";
import ChangeDeliveryAddress from "./store/ChangeDeliveryAddress";
import ChangeDeliveryLink from "./store/ChangeDeliveryLink";
import StorePhoto from "./store/StorePhoto";

import SendMessage from "./store/SendMessage";
import DetailedStore from "./store/DetailedStore";
import NewStore from "./store/NewStore";
import "../css/store.css"


class Stores extends React.Component {
  constructor (props) {
    super(props);
    var showSide = false;
    var currentView = 'active';
    if(window.screen.width < 800) showSide = false;
    var showSearch = true;
    var style = 'table'
    if(this.props.currentView)currentView=this.props.currentView;
    if(this.props.style)style=this.props.style;
    if(typeof this.props.showSearch !== "undefined")showSearch=this.props.showSearch;
    if(typeof this.props.showSide !== "undefined")showSide=this.props.showSide;

   
    var search = this.getHashValue("search");
    if(search){
      search = search;
    }else{
      search = ''
    }


    this.state = {
      stores:[],
      store:'',
      loading:true,
      selectedStore:'',
      sort:'next',
      search:search,
      style:style,
      showSide:showSide,
      currentView:currentView,
      showSearch:showSearch,
      verified:'all',
      format:'list',
      newStore:false,
      fontSize:10,
      limit:25
    };
  }
  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key+'=([^&]*)'));
    return matches ? matches[1] : null;
  }

  componentWillMount() {
    var _this = this;
    if(!this.props.store){
      this.props.shop.staff.loadStores(this.state.selectedStore,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
        _this.setState({stores:response.stores})
      });
    }else{ 
      this.setState({store:this.props.store})
    }
    if(this.props.stores)this.setState({stores:this.props.stores})
    setInterval(function() {
      _this.props.shop.staff.loadStores(_this.state.selectedStore,_this.state.sort,_this.state.limit,_this.state.search,_this.state.verified).then( response => {
        _this.setState({stores:response.stores})
      });
    }, 60000);
  }
  
  updateSelectedStore(event){
    var _this = this;
    var store = event.target.value;
    this.props.shop.staff.loadStores(store,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      _this.setState({tores:response.stores})
    });
  }
  updateSort(event){
    var _this = this;
    var sort = event.target.value;    
    this.props.shop.staff.loadStores(this.state.selectedStore,sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      _this.setState({stores:response.stores})
    });
  }
  updateStoreStatus(event){
    var _this = this;
    var verified = event.target.value;
    window.location.hash = "search=" + _this.state.search + "&verified=" + verified;
    this.props.shop.staff.loadStores(this.state.selectedStore,this.state.sort,this.state.limit,this.state.search,verified).then( response => {
      _this.setState({stores:response.stores,verified:verified})
    });
  }
  updateLimit(event){
    var _this = this;
    var limit = event.target.value;
    this.props.shop.staff.loadStores(this.state.selectedStore,this.state.sort,limit,this.state.search,this.state.verified).then( response => {
      _this.setState({stores:response.stores,limit:limit})      
    });
  }
  searchStore(event){
    var _this = this;
    var search = event.target.value;    
    window.location.hash = "search=" + search + "&verified=" + _this.state.verified;
    this.props.shop.staff.loadStores(this.state.selectedStore,this.state.sort,this.state.limit,search).then( response => {
      _this.setState({stores:response.stores,search:search})
    });
  }
  updateSearchStore(search){
    var _this = this;
    window.location.hash = "search=" + search + "&verified=" + _this.state.verified;
    this.props.shop.staff.loadStores(this.state.selectedStore,this.state.sort,this.state.limit,search).then( response => {
      _this.setState({stores:response.stores,search:search})
    });
  }
  loadStores(){
    var _this = this;
    this.props.shop.staff.loadStores(this.state.selectedStore,this.state.sort,this.state.limit,this.state.search,this.state.verified).then( response => {
      _this.setState({stores:response.stores})
    });
  }
  toggleNewStore(){
    this.setState({newStore:!this.state.newStore})
    console.log(this.state.newStore)
  }

  render (){

  var shop = this.props.shop;
  var today = new Date();
  var fontSize= this.state.fontSize;
  var latestStore;
  if(this.state.stores && this.state.stores.length > 0 && this.state.sort === 'latest'){latestStore = this.state.stores[this.state.stores.length - 1];}else if(this.state.stores && this.state.stores.length > 0){latestStore = this.state.stores[0];}

  var input_style = {borderRadius: 5,"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"24px","background":"rgb(255, 255, 255)",border:"1px solid rgba(200, 200, 200,0.5)","outline":"none","color":"rgba(0, 0, 0,0.5)","height":35,"boxSizing":"border-box", display:'inline-block', padding:5, maxWidth:170};


    return (<InlineCss stylesheet={`
            .CalendarDay__selected_span {
              background: #82e0aa; //background
              color: white; //text
              border: 1px solid $light-red; //default styles include a border
            }

            // Will edit selected date or the endpoints of a range of dates
            .CalendarDay__selected {
              background: $dark-red;
              color: white;
            }

            // Will edit when hovered over. _span style also has this property
            .CalendarDay__selected:hover {
              background: orange;
              color: white;
            }

            // Will edit when the second date (end date) in a range of dates
            // is not yet selected. Edits the dates between your mouse and said date
            .CalendarDay__hovered_span:hover,
            .CalendarDay__hovered_span {
              background: brown;
            }

            .table thead th{
              border:none;
            }

            `}>
            <div className="row" style={{marginLeft:0,marginRight:0,padding:0}}>
              {this.state.newStore &&
                <NewStore toggleNewStore={this.toggleNewStore.bind(this)} updateSearchStore={this.updateSearchStore.bind(this)} stores={this.state.stores} shop={this.props.shop} />
              }
              <div className={this.state.showSide ? "col-sm-12 col-lg-10" : "col-sm-12 col-lg-12"}>
                <div className="row" style={{marginBottom:70}}>
                  <div className="col-sm-3 col-md-2 col-lg-2" style={{marginTop:50}}>
                    <span style={{fontSize:24,fontWeight:'bold',marginRight:30,marginTop:10,marginLeft:30}}>
                      Store
                    </span>
                  </div>
                  <div className="col-sm-8 col-md-9 col-lg-9" style={{marginTop:50}}>
                    <span style={{display:this.state.showSearch ? 'inline-block' : 'none'}}>
                      {shop.stores.size > 1 &&
                        <select style={input_style} value={this.state.selectedStore} onChange={this.updateSelectedStore.bind(this)}>
                            <option value=''>All Stores</option>
                              {shop.storeStore.searchedAvailableStores.map((store,i) => 
                                <option key={i} value={store.slug}>{store.title}</option>
                              )}                      
                         </select>
                      }
                        &nbsp;
                        <select name="verified" style={input_style} value={this.state.verified} onChange={this.updateStoreStatus.bind(this)}>
                             <option value='all'>All</option>
                              <option value='true'>Verified</option>
                              <option value="false" style={{background:'rgb(255, 68, 68)',color:'#fff'}}>Not Verified</option>
                         </select>
                         &nbsp;                        
                        <input
                          ref="store"
                          type="text"
                          name="store"
                          placeholder="Search Store"
                          style={input_style}
                          defaultValue={this.state.search}

                          onChange={this.searchStore.bind(this)} />
                        &nbsp;
                        
                        <select style={input_style} value={this.state.sort} onChange={this.updateSort.bind(this)}>
                            <option value='latest'>Latest</option>
                            <option value='oldest'>Oldest</option>
                         </select>
                        &nbsp;
                        <select style={input_style} value={this.state.limit} onChange={this.updateLimit.bind(this)}>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='250'>250</option>
                            <option value='500'>500</option>
                            <option value='all'>All</option>
                         </select> 
                         &nbsp;&nbsp;
                         &nbsp;&nbsp;                          
                        </span>           
                    </div>
                    <div className="col-sm-1" style={{textAlign:'left'}}>
                        <div style={{color:'#9490D1',fontSize:10,marginTop:60,cursor: 'pointer',fontWeight:'bold'}} onClick={this.toggleNewStore.bind(this)}>+ New Store</div>
                    </div>
                  </div>                                    
                  <a style={{cursor: 'pointer',position:'absolute',marginTop:2,left:window.screen.width < 800 ? '95%': '80%' ,top:0 }} onClick={(e)=>{this.setState({showSearch:!this.state.showSearch})}}>
                    <i className={this.state.showSearch ? "fas fa-caret-up" : "fas fa-caret-down"} style={{color:'rgba(200, 200, 200,1)',fontSize:fontSize+10}}></i>
                  </a>  
                  <div className="row">
                    <div className="col-sm-6" style={{marginBottom:50}}>
                      <span style={{color:'#9490D1',fontSize:14,marginRight:20,cursor: 'pointer',textTransform:'uppercase',marginLeft:30}}>{this.state.verified} Store</span>
                      {/*
                      <span style={{color:this.state.currentView === 'active' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'active'})}}>Active Now</span>
                      <span style={{color:this.state.currentView === 'cancelled' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'cancelled'})}}>Cancelled</span>
                      <span style={{color:this.state.currentView === 'map' ? '#9490D1' : 'rgba(0, 0, 0,0.5)',fontSize:14,marginRight:20,cursor: 'pointer'}} onClick={(e)=>{this.setState({currentView:'map'})}}>Map View</span>
                      */}
                    </div>
                    <div className="col-sm-6" style={{marginBottom:50,textAlign:'right'}}>
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({style:'grid'})}}>
                          <i className="fal fa-th" aria-hidden="true" style={{color: this.state.style === 'grid' ? '#9490D1' : 'rgba(200, 200, 200,0.5)',fontSize:14, padding:2}}></i>
                        </a>
                        &nbsp;
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({style:'table'})}}>
                          <i className="fal fa-th-list" aria-hidden="true" style={{color: this.state.style === 'table' ? '#9490D1' : 'rgba(200, 200, 200,0.5)',fontSize:14, padding:2}}></i>
                        </a>
                      <br />
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({fontSize:fontSize + 1})}}>
                          <i className="fal fa-plus" aria-hidden="true" style={{color: 'rgba(200, 200, 200,0.5)',fontSize:12, padding:2}}></i>
                        </a>
                        &nbsp;&nbsp;
                        <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({fontSize:fontSize -1})}}>
                          <i className="fal fa-minus" aria-hidden="true" style={{color: 'rgba(200, 200, 200,0.5)',fontSize:12, padding:2}}></i>
                        </a>  
                        &nbsp; 
                    </div>
                  </div>
                  <div className="table-responsive" style={{minHeight: 500,overflowY: 'hidden'}}>
                    <table className={this.state.style === "table" ? "table" : "store_table"} style={{fontSize:fontSize,color:'#666', width:'100%',transition: '0.4s'}} border="0">
                        {this.state.style === 'table' &&
                          <thead>
                            <tr style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>#</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>&nbsp;</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>Store Id</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>Name</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>Store</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>Position</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>Phone</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200}}>Last Login</th>
                              <th scope="col" style={{margin:10,color:'rgba(0, 0, 0,0.5)',fontSize:12,fontWeight:200, textAlign:'right'}}>
                                Permissions
                              </th>
                              <th scope="col">&nbsp;</th>
                              <th scope="col">&nbsp;</th>
                              <th scope="col">&nbsp;</th>
                            </tr>
                          </thead>
                        }
                        {this.state.style === 'table' ?
                          <tbody>
                          {this.state.stores && this.state.stores.map((store,i) =>
                            <StoreItem i={i} total={this.state.stores.length} store={store} fontSize={this.state.fontSize} style={this.state.style} shop={this.props.shop} key={i} loadStores={this.loadStores.bind(this)} />
                          )}
                          </tbody>
                        :                   
                          <tbody>
                            <tr>
                              <td colSpan="11">
                                <div className="row">
                                  {this.state.stores.map((store,i) =>
                                    <StoreItem i={i} total={this.state.stores.length}  store={store} fontSize={this.state.fontSize} style={this.state.style} shop={this.props.shop} key={i} loadStores={this.loadStores.bind(this)} stores={this.state.stores} />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        }
                      
                    </table>
                  </div>
              </div>
              {window.screen.width > 800 &&
                <a style={{cursor: 'pointer',position:'absolute',right:10,top:5}} onClick={(e)=>{this.setState({showSide:!this.state.showSide})}}>
                  <i className={this.state.showSide ? "fas fa-caret-right" : "far fa-caret-left"} style={{color:'#9490D1',fontSize:fontSize+4}}></i>
                </a>
              }
            </div>
          </InlineCss>);
  }
}

class StoreItem extends React.Component {
    constructor(props) {
      super(props);

      var store = this.props.store;

      var _this = this;

      this.state = {store:store,deliveryModal:false,showMore:false,showSendMessage:false,showCamera:false,showChangeAddress:false,changeName:false,changeDeliveryAmt:false,showChangeDeliveryLink:false,showMenu:false}
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.store !== this.state.store) {
        var store = nextProps.store;

        var _this = this;        
        this.setState({ store: store });
      }
    }
        
    updateStoreStatus(verified){
      // var verified = event.target.value;
      var store = this.state.store;
      store.verified = verified;      
      this.setState({store:store});
      this.props.shop.staff.updateStoreStatus(store.uuid, verified)

      var _this = this;
      setTimeout(function() {
        _this.props.loadStores();
      }, 100);
    }
    
    updateStoreFirstname(event){      
      if (event.key === 'Enter' || typeof event.key === "undefined") {
        var firstname = event.target.value;
        var _this = this;
        var store = this.state.store;
        store.firstname = firstname;      
        _this.props.shop.staff.updateStoreName(this.props.store.uuid,{firstname:store.firstname,lastname:store.lastname}).then( response => {
          _this.setState({store:store,changeName:false});
          _this.props.loadStores();      
        })
      }
    }

    updateStoreLastname(event){      
      if (event.key === 'Enter' || typeof event.key === "undefined") {
        var lastname = event.target.value;
        var _this = this;
        var store = this.state.store;
        store.lastname = lastname;      
        _this.props.shop.staff.updateStoreName(this.props.store.uuid,{firstname:store.firstname,lastname:store.lastname}).then( response => {
          _this.setState({store:store,changeName:false});
          _this.props.loadStores();      
        })
      }
    }

    updateStoreType(type){      
      // var method = event.target.value;
      var _this = this;
      var store = this.state.store;
      store.store_type = type;      
      _this.props.shop.staff.updateStoreType(this.props.store.uuid,type).then( response => {
        _this.setState({store:store});
        _this.props.loadStores();
      })
    }
    
    showMore(){
      this.setState({showMore:!this.state.showMore})
    }


    showSendMessage(){
      this.setState({showSendMessage:!this.state.showSendMessage});
    }
    sendMessage(message){
      var _this = this;
      _this.props.shop.staff.sendMessage(this.props.store.uuid,message).then( response => {
        _this.props.shop.alert('Message Sent','', 'info', 'br')
        _this.setState({showSendMessage:false});
        _this.props.loadStores();
      })
    }
    showCamera(){
      this.setState({showCamera:!this.state.showCamera});
    }

    savePhoto (image,caption) {
      var _this = this;
      _this.props.shop.staff.savePhoto(this.props.store.uuid,image,caption,this.props.store.store_slug).then( response => {
        _this.props.shop.alert('Message Sent','', 'info', 'br')
        _this.setState({showCamera:false});
        _this.props.loadStores();
      })
    }
    
    showChangeAddress(){
      this.setState({showChangeAddress:!this.state.showChangeAddress});
    }

    updateAddress(address){
      var store = this.state.store;
      store.address = address;  
      var _this = this;
      var delivery_cost = store.deliveryAmt;

      store.store[0].location.forEach(function (location) {
        var areaCost = location.deliveryAreas.filter(function(area) {
          if(typeof address !== "undefined"){
            return (area.name.toLowerCase().indexOf(address.suburb.toLowerCase()) !== -1);
          }else{
            return false;
          }
        });
        if(areaCost.length > 0){
          delivery_cost = parseFloat(areaCost[0].cost/100);
        }
      })


      _this.props.shop.staff.updateStoreAddress(this.props.store.uuid,address,delivery_cost).then( response => {
        _this.setState({store:store,changeName:false});
        _this.props.loadStores();
      })
    }

    deletePhoto(uuid,photo_uuid){
      var _this = this;
      if (window.confirm('Are you sure you wish to delete this photo?')){
        this.props.shop.staff.deletePhoto(uuid,photo_uuid).then( response => {
          _this.props.loadStores();
        })
      }
    }

    render(){
      var store = this.state.store;

      var shop = this.props.shop;
      var lang = shop.staff.lang;
      var layout = shop.marketplace.layout;

      var fontSize = this.props.fontSize;

      var lang_date = 'en-US';
      if(lang === 'vi')
        lang_date = 'vi-VN'
      if(lang === 'ja')
        lang_date = 'ja-JP'
      if(lang === 'zh')
        lang_date = 'zh-CN'
      if(lang === 'ko')
        lang_date = 'ko-KR'

      var store_color = 'rgb(255, 128, 66)';
      if(store.verified === false)
        store_color = 'rgb(255, 68, 68)';

    if(this.props.style === 'table'){
      return (
      <React.Fragment>
          <tr style={{marginTop:30,marginBottom:30}}>
            <td>
              <input type={'checkbox'} style={{margin:10}} />
            </td>
            <td>
              {store.imageUrl && store.imageUrl !== "" ?
                <img src={store.imageUrl.indexOf("http") > -1 ? store.imageUrl : shop.imageDirectory + store.imageUrl} alt={store.name} style={{ width: 50, height:50, borderRadius:50, border: '5px white solid' }} />
                : store.length > 0 ?
                <img src={shop.imageDirectory + store.store[0].logoImageUrl} style={{width:45}} />
              :
                <span>
                  &nbsp;
                </span>
              }
            </td>
            <td>
              <div style={{color:'#fff',color:'rgb(50, 50, 50)',borderRadius:5,padding:2,marginBottom:4,fontSize:fontSize+2,fontWeight:200}}>#{store.uuid.substr(0, 6)}</div>              
            </td>
            <td>
              {this.state.changeName ?
                <span>
                  <input style={{fontSize:fontSize, borderRadius:5, color:'#fff',color:'rgb(75, 75, 75)',border:'2px solid rgb(10, 10, 10)', padding:2, paddingLeft:5, minWidth:80, border:'solid 0px'}} defaultValue={store.firstname} onBlur={this.updateStoreLastname.bind(this)} onKeyDown={this.updateStoreLastname.bind(this)} />
                  &nbsp;
                  <input style={{fontSize:fontSize, borderRadius:5, color:'#fff',color:'rgb(75, 75, 75)',border:'2px solid rgb(10, 10, 10)', padding:2, paddingLeft:5, minWidth:80, border:'solid 0px'}} defaultValue={store.lastname} onBlur={this.updateStoreFirstname.bind(this)} onKeyDown={this.updateStoreFirstname.bind(this)} />
                </span>
              :
                <div style={{fontSize:fontSize, borderRadius:5, color:'#fff',color:'rgb(50, 50, 50)', padding:2, paddingLeft:5, minWidth:80, border:'solid 0px'}} onClick={(e)=>{this.setState({changeName:true})}}>{store.firstname}&nbsp;{store.lastname}</div>
              }
              {store.messages && store.messages.map((message, i) =>
                <div key={i}>
                {(i > store.messages.length - 3) && 
                  <div className="messagesBox">
                    <span style={{fontSize:fontSize}}>
                      {message.sendSMS && <i className="fas fa-sms" style={{margin:2}}></i>}
                      {message.sendEmail && <i className="fas fa-envelope" style={{margin:2}}></i>}
                      <i className="fas fa-sticky-note" style={{margin:2}}></i> 
                      &nbsp;&nbsp;
                      {new Date(message.createdAt).toLocaleDateString(lang_date, { weekday:"short",day: "numeric", month: "short" })}
                      &nbsp;&nbsp;{new Date(message.createdAt).toLocaleTimeString(lang_date, {})}
                      {message.subject !=="" && <b>&nbsp;&nbsp;{message.subject}</b> }
                      &nbsp;&nbsp;{message.message}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                }
                </div>
              )}
            </td>
            <td>
              <span>
                {store.title}
              </span>              
            </td>
            <td>
              {store.email}
            </td>
            <td>
              {store.phone}
            </td>
            <td>
              {new Date(store.lastLogin).toLocaleDateString(lang_date, { weekday:"short",day: "numeric", month: "short" })}
            </td>
            <td style={{minWidth:95,textAlign:'right'}}>
                
            </td>
            <td style={{textAlign:'center',width:95}}>
              <span style={{width:95, display:'inline-block'}}>
                {store.verified ?
                  <b>Verified</b>
                  :
                  <b>Not Verified</b>
                }
              </span>
            </td> 
            <td style={{textAlign:'center',position:'relative'}}>
              {/*
              <a onClick={(e)=>{e.preventDefault();this.printDocument(shop.apiAddress + '/api/store/receipt.pdf?token=' + store.store_id + '&domain=' + store.store_slug  + '&uuid=' + store.uuid);this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer',display:'inline-block'}}>
                <i className="fas fa-print" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
              </a>
              <a onClick={(e)=>{e.preventDefault();this.showSendMessage();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer',display:'inline-block'}}>
                <i className="fas fa-comments-alt" aria-hidden="true" style={{color: '#007aff',fontSize:20, padding:5}}></i>
              </a>
            */}
              <a onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}}>
                <i className="fas fa-ellipsis-v" style={{cursor: 'pointer',fontSize:fontSize+2,paddingLeft:5,paddingRight:5}}></i>
              </a>
              {this.state.showSendMessage &&
                <SendMessage uuid={store.uuid} phone={store.phone} email={store.email} open={true} showSendMessage={this.showSendMessage.bind(this)} sendMessage={this.sendMessage.bind(this)} store={shop.staff._id} store_id={store.store} store={store.name} store_title={store.store_title} store_phone={store.store_phone} name={store.name} delivery_link={store.delivery_link} shop={shop} messages={store.messages} store={store} loadStores={this.props.loadStores.bind(this)} messageTemplates={store.store[0].messageTemplates} imageDirectory={shop.imageDirectory} showCamera={this.showCamera.bind(this)} />
              }
              {this.state.showCamera &&
                <StorePhoto shop={shop} showCamera={this.showCamera.bind(this)} open={this.state.showCamera} savePhoto={this.savePhoto.bind(this)} />
              }
              {this.state.showMenu &&
                <div className="row" style={{position:'absolute',zIndex:2, width:80, backgroundColor:'#fff',border:'#efefef 1px solid',left:-65, top:4,borderRadius:5}}>
                  <div  style={{textAlign:'center',width:'49%',padding:4}}>
                    <a href={'tel:' + store.phone} onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}} target="_blank" style={{cursor: 'pointer'}}>
                      <i className="fas fa-phone" aria-hidden="true" style={{color: 'rgb(0, 200, 83)',fontSize:20, padding:5}}></i>
                    </a>
                  </div>
                  <div  style={{textAlign:'center',width:'49%',padding:4}}>
                    <a href={'mailto:' + store.email} onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}} target="_blank" style={{cursor: 'pointer'}}>
                      <i className="fas fa-envelope" aria-hidden="true" style={{color: 'rgb(0, 136, 254)',fontSize:20, padding:5}}></i>
                    </a>
                  </div>
                  <div  style={{textAlign:'center',width:'49%',padding:4}}>
                    <a onClick={(e)=>{e.preventDefault();this.showSendMessage();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                      <i className="fas fa-comments-alt" aria-hidden="true" style={{color: '#007aff',fontSize:20, padding:5}}></i>
                    </a>                          
                  </div>
                  <div  style={{textAlign:'center',width:'49%',padding:4}}>
                    <a onClick={(e)=>{e.preventDefault();this.showCamera();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                      <i className="fas fa-camera" aria-hidden="true" style={{color: 'rgb(30, 30, 30)',fontSize:20, padding:5}}></i>
                    </a>                          
                  </div>
                  <div  style={{textAlign:'center',width:'49%',padding:4}}>
                    {/*<a href={shop.apiAddress + '/api/store/receipt.pdf?token=' + store.store_id + '&domain=' + store.store_slug  + '&uuid=' + store.uuid} target="_blank">*/}
                    <a onClick={(e)=>{e.preventDefault();this.printDocument(shop.apiAddress + '/api/store/receipt.pdf?token=' + store.store_id + '&domain=' + store.store_slug  + '&uuid=' + store.uuid);this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                      <i className="fas fa-print" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
                    
                    </a>
                  </div>   
                  {store.payment_link && store.payment_link != "" &&
                    <div  style={{textAlign:'center',width:'49%',padding:4}}>
                      <a href={store.payment_link} onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}} target="_blank" style={{cursor: 'pointer'}}>
                        <i className="fas fa-link" aria-hidden="true" style={{color: 'rgb(0, 136, 254)',fontSize:20, padding:5}}></i>
                      </a>
                    </div>
                  }
                </div>
              }
            </td>
            <td>
              {!this.state.showMore ?
                <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({showMore:!this.state.showMore})}}>
                  <i className="far fa-caret-up" style={{color:'rgb(50, 50, 50)',fontSize:fontSize+5}}></i></a>
              :
                <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({showMore:!this.state.showMore})}}>
                  <i className="fas fa-caret-down" style={{color:'rgb(50, 50, 50)',fontSize:fontSize+5}}></i></a>
              }
            </td>
          </tr>     
          {(this.state.showMore === true || this.props.total === 1) &&
            <DetailedStore store={store} layout={layout} shop={shop} showMore={this.showMore.bind(this)} deletePhoto={this.deletePhoto.bind(this)} lang={lang} fontSize={fontSize} lang_date={lang_date} sendMessage={this.sendMessage.bind(this)} showCamera={this.showCamera.bind(this)} stores={this.props.shop.isMarketplace && this.props.stores && this.props.stores} />  
          }                
        </React.Fragment>    
      );
    }else{
      return (
          <div className="col-sm-4 col-md-2" style={{padding:10,boxShadow:'0 0 1in -0.25in rgba(0, 0, 0, 0.5)', border:'1px solid ' + store_color,background:'#fff',borderRadius:2,marginLeft:5}}>
            <div className="row" style={{margin:0}}>
              <div style={{padding:0,width:'20%', margin:2}}>
                <div style={{color:'#fff',backgroundColor:store_color,borderRadius:5,padding:2,marginBottom:4,fontSize:fontSize+2,fontWeight:'bold'}}>#{store.uuid.substr(0, 6)}</div>
                {store.store_type === "delivery" && store.length > 0 &&
                  <span>
                    
                  </span>
                }
              </div>
              <div style={{padding:0,width:'20%', margin:2}}>
                
              </div>
              <div style={{textAlign:'center',width:95, margin:2}}>
                <span style={{width:95, display:'inline-block'}}>
                
                </span>
              </div> 
              <div style={{padding:0,width:'60%', margin:2}}>
                {this.state.changeName ?
                  <input style={{fontSize:fontSize, borderRadius:5, color:'#fff',backgroundColor:'rgb(75, 75, 75)',border:'2px solid rgb(10, 10, 10)', padding:2, paddingLeft:5, minWidth:80, border:'solid 0px'}} defaultValue={store.name} onBlur={this.updateStoreName.bind(this)} onKeyDown={this.updateStoreName.bind(this)} />
                :
                  <div style={{fontSize:fontSize, borderRadius:5, color:'#fff',backgroundColor:'rgb(50, 50, 50)', padding:2, paddingLeft:5, minWidth:80, border:'solid 0px'}} onClick={(e)=>{this.setState({changeName:true})}}>{store.name}</div>
                }
              </div>              
              <div className="col-sm-12" style={{padding:0,margin:2}}>
                  
              </div>
              <div className="col-sm-12" style={{padding:0,margin:2}}>
                {store.store_type === "delivery" &&
                  <div>
                    <a onClick={(e)=>{e.preventDefault();this.showChangeAddress()}}>
                      {/*store.address.apt && <span><span style={{color:'#fff',backgroundColor:'rgb(110, 110, 110)',borderRadius:5,padding:2,marginBottom:4,fontSize:fontSize}}>{store.address.apt}</span><br /></span>*/} 
                    <div className="addressBox" style={{display:'inline-block'}}>
                      {store.address.street ?
                        <span style={{backgroundColor:'rgb(150, 150, 150)',fontSize:fontSize}}>
                         <span>{store.address.apt && store.address.apt + ' | '}</span>
                         {store.address.street }                                  
                        </span>
                      :
                          <span style={{fontSize:fontSize}}>
                              {store.address.apt && store.address.apt + ' | '}{store.address.label_address}
                          </span>
                      }
                    </div>
                    {store.address.ward && <span style={{color:'#fff',backgroundColor:'rgb(0, 0, 0)',borderRadius:5,padding:2,margin:2,fontSize:fontSize-1,whiteSpace: 'normal',display:'inline-block'}}>{store.address.ward}</span>}
                    {store.address.suburb && <span style={{color:'#fff',backgroundColor:'rgb(0, 0, 0)',borderRadius:5,padding:2,margin:2,fontSize:fontSize-1,whiteSpace: 'normal',display:'inline-block'}}>{store.address.suburb}</span>}
                    {store.address.delivery_instructions &&<span style={{color:'#fff',backgroundColor:'rgb(110, 110, 110)',borderRadius:5,padding:2,margin:2,fontSize:fontSize-1,whiteSpace: 'normal',display:'inline-block'}}>{store.address.delivery_instructions}</span>}
                    </a>
                    {this.state.showChangeAddress &&
                      <ChangeDeliveryAddress address={store.address} open={true} updateAddress={this.updateAddress.bind(this)} showChangeAddress={this.showChangeAddress.bind(this)} gps={store.address.gps} shop={shop} />
                    }
                  </div>
                }
              </div>
              <div className="col-sm-3" style={{minWidth:95}}>
              {store.store_type === 'delivery' && store.deliveryAmt > 0 &&
                <span>
                  <i className="fas fa-truck" style={{color:'rgb(150, 150, 150)',fontSize:fontSize}} />&nbsp;
                  {this.state.changeDeliveryAmt ?
                    <input style={{fontSize:fontSize, borderRadius:5, color:'#fff',backgroundColor:'rgb(125, 125, 125)',border:'2px solid rgb(10, 10, 10)', padding:2, paddingLeft:5, width:60, border:'solid 0px'}} defaultValue={store.deliveryAmt/100} onBlur={this.updateDeliveryAmt.bind(this)} type="number" onKeyDown={this.updateDeliveryAmt.bind(this)} />
                  :
                    <span style={{color:'#fff',backgroundColor:'rgb(150, 150, 150)',borderRadius:5,padding:4,margin:2,fontSize:fontSize}} onClick={(e)=>{this.setState({changeDeliveryAmt:true})}}>{(store.deliveryAmt/100).toLocaleString()}{shop.marketplace.currency}</span>
                  }
                  <br />
                </span>
              }
                <span style={{position:'relative',top:5}}>
                <i className="fas fa-equals" style={{color:'rgb(50, 50, 50)',fontSize:fontSize}} />&nbsp;
                <span style={{color:'#fff',backgroundColor:'rgb(50, 50, 50)',borderRadius:5,padding:4,margin:2,fontSize:fontSize}}>{(store.total/100).toLocaleString()}{shop.marketplace.currency}</span>
                </span>
              </div>
              <div className="col-sm-3" style={{textAlign:'center'}}>                 
                
              </div>
              <div className="col-sm-3" style={{textAlign:'center'}}>
                
              </div>              
              <div className="col-sm-12">
                {store.messages && store.messages.map((message, i) =>
                  <div key={i}>
                  {(i > store.messages.length - 3) && 
                    <div className="messagesBox">
                      <span style={{fontSize:fontSize}}>
                        {message.sendSMS && <i className="fas fa-sms" style={{margin:2}}></i>}
                        {message.sendEmail && <i className="fas fa-envelope" style={{margin:2}}></i>}
                        <i className="fas fa-sticky-note" style={{margin:2}}></i> 
                        &nbsp;&nbsp;
                        {new Date(message.createdAt).toLocaleDateString(lang_date, { weekday:"short",day: "numeric", month: "short" })}&nbsp;&nbsp;{new Date(message.createdAt).toLocaleTimeString(lang_date, {})}
                        {message.subject !=="" && <b>&nbsp;&nbsp;{message.subject}</b> }
                        &nbsp;&nbsp;{message.message}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                  }
                  </div>
                )}
              </div>
              <div className="col-sm-12" style={{textAlign:'center',position:'relative'}}>
                <div  style={{textAlign:'center',width:'49%',padding:4}}>
                  <a onClick={(e)=>{e.preventDefault();this.printDocument(shop.apiAddress + '/api/store/receipt.pdf?token=' + store.store_id + '&domain=' + store.store_slug  + '&uuid=' + store.uuid);this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                    <i className="fas fa-print" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
                  </a>
                  <a onClick={(e)=>{e.preventDefault();this.showSendMessage();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                    <i className="fas fa-comments-alt" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
                  </a>
                </div>  
                <span onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}}>
                  <i className="fas fa-ellipsis-v" style={{cursor: 'pointer',fontSize:fontSize+2,paddingLeft:5,paddingRight:5}}></i>
                </span>
                {this.state.showSendMessage &&
                  <SendMessage uuid={store.uuid} phone={store.phone} email={store.email} open={true} showSendMessage={this.showSendMessage.bind(this)} sendMessage={this.sendMessage.bind(this)} store={shop.staff._id} store_id={store.store} store={store.name} store_title={store.store_title} store_phone={store.store_phone} name={store.name} delivery_link={store.delivery_link} shop={shop} loadStores={this.props.loadStores.bind(this)} messageTemplates={store.store[0].messageTemplates} />
                }
                {this.state.showCamera &&
                  <StorePhoto shop={shop} showCamera={this.showCamera.bind(this)} open={this.state.showCamera} savePhoto={this.savePhoto.bind(this)} />
                }
                {this.state.showMenu &&
                  <div className="row" style={{position:'absolute',zIndex:2, width:80, backgroundColor:'#fff',border:'#efefef 1px solid',left:-65, top:4,borderRadius:5}}>
                    <div  style={{textAlign:'center',width:'49%',padding:4}}>
                      <a href={'tel:' + store.phone} onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}} target="_blank" style={{cursor: 'pointer'}}>
                        <i className="fas fa-phone" aria-hidden="true" style={{color: 'rgb(0, 200, 83)',fontSize:20, padding:5}}></i>
                      </a>
                    </div>
                    <div  style={{textAlign:'center',width:'49%',padding:4}}>
                      <a href={'mailto:' + store.email} onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}} target="_blank" style={{cursor: 'pointer'}}>
                        <i className="fas fa-envelope" aria-hidden="true" style={{color: 'rgb(0, 136, 254)',fontSize:20, padding:5}}></i>
                      </a>
                    </div>
                    <div  style={{textAlign:'center',width:'49%',padding:4}}>
                      <a onClick={(e)=>{e.preventDefault();this.showSendMessage();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                        <i className="fas fa-comments-alt" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
                      </a>                          
                    </div>
                    <div  style={{textAlign:'center',width:'49%',padding:4}}>
                      <a onClick={(e)=>{e.preventDefault();this.showCamera();this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                        <i className="fas fa-camera" aria-hidden="true" style={{color: 'rgb(30, 30, 30)',fontSize:20, padding:5}}></i>
                      </a>                          
                    </div>
                    <div  style={{textAlign:'center',width:'49%',padding:4}}>
                      {/*<a href={shop.apiAddress + '/api/store/receipt.pdf?token=' + store.store_id + '&domain=' + store.store_slug  + '&uuid=' + store.uuid} target="_blank">*/}
                      <a onClick={(e)=>{e.preventDefault();this.printDocument(shop.apiAddress + '/api/store/receipt.pdf?token=' + store.store_id + '&domain=' + store.store_slug  + '&uuid=' + store.uuid);this.setState({showMenu:!this.state.showMenu});}} style={{cursor: 'pointer'}}>
                        <i className="fas fa-print" aria-hidden="true" style={{color: 'rgb(253, 126, 20)',fontSize:20, padding:5}}></i>
                      
                      </a>
                    </div>   
                    {store.payment_link && store.payment_link != "" &&
                      <div  style={{textAlign:'center',width:'49%',padding:4}}>
                        <a href={store.payment_link} onClick={(e)=>{this.setState({showMenu:!this.state.showMenu})}} target="_blank" style={{cursor: 'pointer'}}>
                          <i className="fas fa-link" aria-hidden="true" style={{color: 'rgb(0, 136, 254)',fontSize:20, padding:5}}></i>
                        </a>
                      </div>
                    }
                  </div>
                }
              </div>
              <div className="col-sm-12">
                {!this.state.showMore ?
                  <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({showMore:!this.state.showMore})}}>
                    <i className="down-arrow" style={{borderColor:'rgb(50, 50, 50)',fontSize:fontSize}}></i></a>
                :
                  <a style={{cursor: 'pointer'}} onClick={(e)=>{this.setState({showMore:!this.state.showMore})}}>
                    <i className="up-arrow" style={{borderColor:'rgb(50, 50, 50)',fontSize:fontSize}}></i></a>
                }
              </div>   
            
            {(this.state.showMore === true || this.props.total === 1) &&
              <DetailedStore store={store} layout={layout} shop={shop} showMore={this.showMore.bind(this)} deletePhoto={this.deletePhoto.bind(this)} lang={lang} lang_date={lang_date} />  
            }   
          </div>             
        </div>     
      );  
    }
  }    
}

function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

  function convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g,function(a,b) {
      var charcode = parseInt(b,16);
      return String.fromCharCode(charcode);
    });
  }
  // function htmlDecode(input){
  //   var e = document.createElement('div');
  //   e.innerHTML = input;
  //   return e.childNodes[0].nodeValue;
  // }


export default Stores;