import { types, getParent,detach} from "mobx-state-tree"
import axios from 'axios';


var nutriInfo = {
    "caloricInfo": {
        "lowerRange": 0,
        "higherRange": 0,
        "displayType": "MULTIPLE",
        "displayFormat": ""
    },
    // "displayString": ""
}
 
var calInfo = {
    "lowerRange": 0,
    "higherRange": 0,
    "displayType": "MULTIPLE",
    "displayFormat": ""
}
 
const caloricInfo = types.model("caloricInfo", {
    lowerRange: types.optional(types.number, 0),
    higherRange: types.optional(types.number, 0),
    displayType: types.string,
    displayFormat: types.optional(types.string, "")
})
 
const nutritionalInfo = types.model("nutritionalInfo", {
    caloricInfo: types.optional(caloricInfo, calInfo),
    displayString: types.string
})

const items = types.model("items", {
    link: types.optional(types.string,''),
    url: types.optional(types.string,''),
    type: types.optional(types.string,'image'),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),
    title_zh: types.optional(types.string, ""),    
    title_ko: types.optional(types.string, ""),
    subtitle_en: types.optional(types.string, ""),
    subtitle_vi: types.optional(types.string, ""),
    subtitle_ja: types.optional(types.string, ""),
    subtitle_zh: types.optional(types.string, ""),    
    subtitle_ko: types.optional(types.string, ""),
    style: types.optional(types.string, ""),
    showLogo: types.optional(types.boolean,false),
    mute: types.optional(types.boolean,true),
    width: types.optional(types.number,0),
    height: types.optional(types.number,0),
    delay: types.optional(types.number,3500),
    alt:types.optional(types.string,''),
})
.actions(self => {
  function updateURL(value,uuid){    
    self.url = value
  }
  return {
    updateURL
  }
});

const heroImage = types.model("heroImage", {
    height:types.optional(types.string,"full"),
    items: types.optional(types.array(items),[])
});

const pItems = types.model("pItems", {
    uuid: types.optional(types.string, ""),
    url: types.string,
    width: types.optional(types.number,0),
    height: types.optional(types.number,0),
    alt:types.optional(types.string,"")
});

const productImages = types.model("productImages", {
    pItems: types.optional(types.array(pItems),[])
});
 
const tags = types.model("tags", {
    uuid: types.identifier,
    name: types.string,
    keyName: types.optional(types.string, "")
});
 
const categories = types.model("categories", {
    uuid: types.identifier,    
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""),        
    keyName: types.string,
    image:types.optional(types.string,"")
});
 

// export const quantity = types.model("quantity", {
//     quantity:types.optional(types.number,0),
//     location: types.optional(types.string, "")
// })

export const quantity = types.model("quantity", {
    quantity:types.optional(types.number,0),
    quantity_retail:types.optional(types.number,0),
    quantity_wholesale:types.optional(types.number,0),
    location: types.optional(types.string, "")
})


const options = types.model("options", {
    uuid: types.string,
    slug: types.optional(types.string, ""),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""),    
    price: types.optional(types.number, 0),
    wholesale_price: types.optional(types.number, 0),
    quantity:types.optional(types.array(quantity), []),
    allow_oversell: types.optional(types.boolean,false),
    productImages: types.optional(productImages,{items:[]}),
    // tags: types.optional(types.array(tags), [{ uuid: "", name: "", keyName: "" }]),
    // nutritionalInfo: types.optional(nutritionalInfo, nutriInfo),
    // displayString: types.optional(types.string, "")
})
 
const customizations = types.model("customizations", {
    uuid: types.optional(types.string, ""),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""), 
    size : types.optional(types.boolean,false),   
    // tags: types.optional(types.array(tags), [{ uuid: "", name: "", keyName: "" }]),
    options: types.optional(types.array(options), []),
    minPermitted: types.optional(types.number, 0),
    maxPermitted: types.optional(types.number, 0)
})

const review = types.model("review", {
    name:types.optional(types.string, ""),
    lang:types.optional(types.string, "en"),
    photo:types.optional(types.string, ""),
    description:types.optional(types.string, ""),
    relative_time:types.optional(types.string, ""),
    time: types.optional(types.number,0),
    rating: types.optional(types.number,0)
})

const reviews = types.model("reviews", {
    reviews: types.optional(types.array(review),[]),
    total: types.optional(types.number,0),
    average:types.optional(types.number,0)
});
 
const layout = types.model("layout", {
    primTxtColor: types.optional(types.string, ""),
    prodPage: types.optional(types.string, "")
});


const location = types.model("location", {
    title: types.optional(types.string, ""),
    disableDelivery: types.optional(types.boolean, true)
});

const storeObj = types.model("storeObj", {
    _id: types.optional(types.string, ""),
    slug: types.optional(types.string, ""),
    title: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    open: types.optional(types.boolean,true),
    logoImageUrl: types.optional(types.string, ""),
    layout: types.optional(layout,{}),
    canScheduleOrder : types.optional(types.boolean,false),
    closedEtaMessage : types.optional(types.string,""),
    preOrderMessage : types.optional(types.string,""),
    location: types.optional(location,{}),
});


export const Product = types.model("Product", {
    slug: types.identifier,
    sku: types.optional(types.string, ""),    
    uuid: types.optional(types.string, ""),    
    imageUrl: types.optional(types.string, ""),
    videoUrl: types.optional(types.string, ""),
    heroImage: types.optional(heroImage,{items:[]}),
    productImages: types.optional(productImages,{pItems:[]}),
    itemDescription_en: types.optional(types.string, ""),
    itemDescription_vi: types.optional(types.string, ""),
    itemDescription_ja: types.optional(types.string, ""),
    itemDescription_zh: types.optional(types.string, ""),  
    itemDescription_ko: types.optional(types.string, ""),    
    price: types.optional(types.number, 0),
    wholesale_price: types.optional(types.number, 0),
    priceBucket: types.optional(types.string, ""),
    sale: types.optional(types.number, 0),
    available: types.optional(types.boolean,true),
    allow_oversell: types.optional(types.boolean,false),
    allow_preorder: types.optional(types.boolean,false),
    preOrderMinHours: types.optional(types.number,24),
    viewable: types.optional(types.boolean,true),
    featured: types.optional(types.boolean,false),
    featuredMarketplace: types.optional(types.boolean,false),
    order: types.optional(types.number, 1),
    type: types.optional(types.string, "retail"),
    minimum: types.optional(types.number, 1),
    title_en: types.optional(types.string, ""),
    title_vi: types.optional(types.string, ""),
    title_ja: types.optional(types.string, ""),    
    title_zh: types.optional(types.string, ""),  
    title_ko: types.optional(types.string, ""),    
    categories: types.optional(types.array(categories),[]),    
    types: types.optional(types.array(types.string), []),
    menuSection:types.optional(types.array(types.string),[]),
    customizations: types.optional(types.array(customizations), []),
    reviews: types.optional(reviews, {}),
    marketplace: types.optional(types.string, 'ho_chi_minh'),
    createdAt: types.optional(types.string, ''),
    quantity:types.optional(types.array(quantity), []),
    select_quantity: types.optional(types.number, 1),
    store: types.optional(storeObj, {}),
}).actions(self => ({
    updateSelectQuantity(quantity) {
        self.select_quantity = parseInt(quantity, 10)
    },
    updateProductField(field,value) {
        self[field] = value
    }
}))
 
export const ProductStore = types
    .model("ProductStore", {
        loading: false,
        products: types.map(Product),
        searchValue: "",
        skip :0,
        limit:100,
        more:true,
        matching_item_limit: 48,
 
    })
    .views(self => ({
        get noSearchProducts() {
            return noSearchProducts(Array.from(self.products.values()), self.shop.view.selectedStoreAddress, self.searchValue)
        },
        get shop() {
            return getParent(self)
        },
        get isLoading() {
            return self.loading
        },
        get search() {
            return self.searchValue
        },
        get searchedAvailableProducts() {
            if (self.shop.view.selectedStoreAddress) {
                var prods = searchStoreProducts(Array.from(self.products.values()), self.shop.view.selectedStoreAddress, self.searchValue, self.matching_item_limit);
                return prods;
            } else {
                return searchProducts(Array.from(self.products.values()), self.searchValue, self.matching_item_limit)
            }
        },
        get storeAvailableProducts() {
            return storeProducts(Array.from(self.products.values()), self.searchValue)
        },
        get sortedAvailableProducts() {
            return sortProducts(Array.from(self.products.values()))
        },
        get featuredProductList() {
            return Array.from(self.products.values()).filter(function(prod) { 
                return (
                    prod.featuredMarketplace === true
                )
            });
        },
    }))
    .actions(self => ({
            getProduct(slug) {
                return self.products.get(slug)
            },

            // addSearch(value) {
            //     self.skip = 0
            //     self.more = true
            //     self.loading = true
            //     self.searchValue = value.toString();
            //     if (navigator.onLine)
            //         self.loadProducts();
            // }
 
            // clearSearch() {
            //     self.searchValue = "";
            // }
                
            markLoading(loading) {
                self.loading = loading
            },

            updateWholesalePrice(products){
                if(products.length > 0){
                    products.forEach(function(product, i) {
                        if(product.wholesale === true && product.customizations.length > 0){
                            products.customizations.forEach(function(customization, j) {
                                customization.options.forEach(function(option, k) {
                                    if(typeof option.wholesale_price !== "undefined")
                                        products[i].customizations[j].options[k].price = option.wholesale_price;
                                });
                            });
                        }
                    });
                }
                return products;
            },
            removeProduct(slug){
                self.products.forEach(function(product) {
                    if(product.slug === slug)
                    detach(product)
                })
            },
            addProduct(slug) {
                if(slug){
                    var slugs = [slug];
                    var address = self.shop.apiAddress;
                    
                    var domain = self.shop.domain;

                    if(self.shop.view.selectedStoreAddress !== "")
                        domain = self.shop.view.selectedStoreAddress;

                    if(self.shop.domain !=='' && !self.shop.isMarketplace){
                        address += '/api/products/shop-slugs-list';
                    }else{
                        address += '/api/products/slugs-list';
                    }

                    var region = "";
                    if(self.shop.staff.address)
                        region  = self.shop.staff.address.region;

                    // setTimeout(function() {
                    try{    
                        return axios.post(address,{
                            slugs:slugs,
                            id:self.shop.staff._id,
                            region:region,
                            domain:domain,
                            type:self.shop.cart.order_type,
                            atoken:self.shop.token
                        }).then( response => {
                            if(response.data && response.data.length > 0){
                                self.updateProducts(response.data);
                                return true;
                            }
                        });
                    }catch(error){
                        console.log(error);
                        return false;
                    }
                    // }, 500);
                }
            },
            addNewProduct(product_uuid = '',slug = '',store='') {
                if(slug){
                    var region = "";
                    if(self.shop.staff.address)
                        region  = self.shop.staff.address.region;

                    // setTimeout(function() {
                    
                    var data = {}; 
                    data['staff'] = self.shop.staff._id;
                    data['token'] = self.shop.staff.token;
                    data['atoken'] = self.shop.token;
                    data['marketplace'] = self.shop.marketplace.slug;
                    data['product_uuid'] = product_uuid;
                    data['slug'] = slug;
                    data['store'] = store;
                    // data['startDate'] = startDate;
                    // data['endDate'] = endDate;
                    data['website'] = self.shop.getLocationValue('origin');
                        try{
                            return axios.put(self.shop.apiAddress + '/api/staff/product/' + self.shop.staff._id,data).then( response => {
                                console.log(response)
                            // return axios.post(address,).then( response => {
                                if(response.data && response.data.product.length > 0){
                                    response.data.product.forEach(function(product, i) {
                                    // product.customizations.forEach(function(customization, j) {
                                    //      if(typeof customization['title_vi'] === "undefined")
                                    //         product.customizations[j]['title_vi'] = customization['title_en'];
                                    //     if(typeof customization['title_ja'] === "undefined")
                                    //         product.customizations[j]['title_ja'] = customization['title_en'];

                                    //     customization.options.forEach(function(option, k) {
                                    //         if(typeof option['title_vi'] === "undefined")
                                    //             product.customizations[j].options[k]['title_vi'] = option['title_en'];
                                    //         if(typeof option['title_ja'] === "undefined")
                                    //             product.customizations[j].options[k]['title_ja'] = option['title_en'];
                                    //     })
                                    // })    
                                        console.log(product);                    
                                        if (self.products.get(product.slug)) {
                                            self.replaceProductDetails(product)
                                        }else{
                                            self.products.put(product)
                                        }
                                    });
                                    self.markLoading(false)
                                    return true;
                                }else{
                                    return false;
                                }
                            });
                        }catch(error){
                            console.log(error);
                            return false;
                        }
                    // }, 500);
                }
            },

            // loadScroll() {
            //     if(self.more){
            //         self.skip += self.limit
            //         self.loading = true
            //         self.loadProducts();
            //     }
            // }
 
            // clearProducts() {
            //     self.products.forEach(function(product) {
            //         detach(product)
            //     })
            // }

            replaceProductDetails(product){
                Object.keys(product).forEach(function(key) {
                    if(key !== '_id')
                        self.products.get(product.slug)[key]= product[key]
                })
            },

            updateProducts(products) {

                products = self.updateWholesalePrice(products);

                if(products && products.length > 0){
                    products.forEach(function(product, i) {
                        // product.customizations.forEach(function(customization, j) {
                        //      if(typeof customization['title_vi'] === "undefined")
                        //         product.customizations[j]['title_vi'] = customization['title_en'];
                        //     if(typeof customization['title_ja'] === "undefined")
                        //         product.customizations[j]['title_ja'] = customization['title_en'];

                        //     customization.options.forEach(function(option, k) {
                        //         if(typeof option['title_vi'] === "undefined")
                        //             product.customizations[j].options[k]['title_vi'] = option['title_en'];
                        //         if(typeof option['title_ja'] === "undefined")
                        //             product.customizations[j].options[k]['title_ja'] = option['title_en'];
                        //     })
                        // })                        
                        if (self.products.get(product.slug)) {
                            self.replaceProductDetails(product)
                        }else{
                            self.products.put(product)
                        }
                    });
                    self.markLoading(false)
                }else{
                    self.more = false;
                    self.markLoading(false)
                }
            },

            getFavourites() {
                if(self.shop.staff && self.shop.staff.favouriteList && self.shop.staff.favouriteList.length > 0){
                    var slugs = self.shop.staff.favouriteList;
                    var address = self.shop.apiAddress;

                    if(self.shop.domain !=='' && !self.shop.isMarketplace){
                        address += '/api/products/shop-slugs-list';
                    }else{
                        address += '/api/products/slugs-list';
                    }

                    var region = "";
                    if(self.shop.staff.address)
                        region  = self.shop.staff.address.region;

                    setTimeout(function() {
                        try{    
                            axios.post(address,{
                                slugs:slugs,
                                id:self.shop.staff._id,
                                region:region,
                                domain:self.shop.domain,
                                type:self.shop.cart.order_type,
                                atoken:self.shop.token
                            }).then( response => {                            
                                if(response.data && response.data.length > 0)
                                    self.updateProducts(response.data);                            
                            });
                        }catch(error){
                            console.log(error)
                        }
                    }, 150);
                }
            },              
            getLocationValue(label){
                if(typeof window !=="undefined"){
                    return window.location[label];
                }else{
                    return false;
                }
            },
            getCart(cart = self.shop.cart) {
                try {
                    if(cart && cart.entries.length > 0  && self.shop.token !== ""){
                        var slugs = cart.entries.map(function(a) { if(typeof a.product.slug !=="undefined"){return a.product.slug ;}else{return a.product;}});
                        if(slugs.length > 0){

                            var address = self.shop.apiAddress;
                            
                            if(self.shop.domain !=='' && !self.shop.isMarketplace){
                                address += '/api/products/shop-slugs-list';
                            }else{
                                address += '/api/products/slugs-list';
                            }
                            self.markLoading(true);

                            var region  = "";
                            if(self.shop.staff.address)
                                region = self.shop.staff.address.suburb;
          
                            // setTimeout(function() {
                                if(cart.address && region === "")
                                    if(typeof cart.address !== "undefined")
                                        region = cart.address.suburb;
                                try{ 
                                    axios.post(address,{
                                        slugs:slugs,
                                        id:self.shop.staff._id,
                                        region:region,
                                        domain:self.shop.domain,
                                        type:cart.order_type,
                                        atoken:self.shop.token
                                    }).then( response => {
                                        if(response.data && response.data.length > 0){
                                            var products = response.data;
                                            // If response doesn't contain one one of the cart entries remove it
                                            // ca.entries.map((entry,i) => {
                                            //     var indx = products.findIndex(x => x._id === entry._id);
                                            //     console.log(indx)
                                            //     if(indx == -1)ca.removeProduct(entry)
                                            // });
                                            self.updateProducts(products);
                                            if(cart.entries.length > 0)
                                                self.shop.cart.updateCartProducts(self.updateWholesalePrice(products));
                                        }else{
                                            self.shop.cart.clearCart();                                        
                                            self.markLoading(false);
                                        }
                                    });
                                }catch(error){
                                    return false;
                                    console.log(error);
                                }
                            // }, 150);
                        }else{
                            self.markLoading(false);
                        }
                    }else{
                        self.markLoading(false);
                    }
                } catch (err) {
                    console.error("Failed to load products ", err)
                }
            },
            afterCreate(){
                self.getFavourites();
                self.getCart();               
            }
       }))
 
        function searchStoreProducts(products, store, value, matching_item_limit) {
            if (value === "")
                return products.filter(function(prod) { return (prod.store.slug === store) }).slice(0, matching_item_limit);
            return products
                .filter(function(prod) {
                    return (
                        (prod.title_en.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_en.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_vi.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_vi.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_ja.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_ja.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_ko.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_ko.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.slug.toLowerCase().indexOf(value.toLowerCase()) !== -1) && prod.store.slug === store);
                }).slice(0, matching_item_limit); //.sort((a, b) => (a.title > b.title ? 1 : a.title === b.title ? 0 : -1));
 
            // .filter(b => b.viewable)
            // .filter(b => b.title_en.indexOf(search) > -1 )
        }
 
        function searchProducts(products, value, matching_item_limit) {
            if(value.indexOf(",") > -1){
                value = value.split(",");
                return products.filter(function(prod) {
                    var found = false;
                    value.forEach(function (search) {
                        if(prod.title_en.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.itemDescription_en.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                            prod.title_vi.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.itemDescription_vi.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                            prod.title_ja.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.itemDescription_ja.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                            prod.title_zh.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.itemDescription_zh.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                            prod.title_ko.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.itemDescription_ko.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                            prod.slug.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.store.slug.toLowerCase().indexOf(search.toLowerCase()) !== -1 || prod.store.title.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                            found =  true;
                    });

                    return found

                });
            }else{
                return products
                .filter(function(prod) {
                    return (
                            
                            (prod.title_en.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_en.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_vi.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_vi.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_ja.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_ja.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.title_ko.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_ko.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                            prod.slug.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.store.slug.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.store.title.toLowerCase().indexOf(value.toLowerCase()) !== -1));
                });//.slice(0, matching_item_limit);
            }
            // .filter(b => b.viewable)
            // .filter(b => b.title_en.indexOf(search) > -1 )
        }
 
        function noSearchProducts(products, store, value) {
            if (value === "" && store !== "")
                return products.filter(function(prod) { return (prod.store.slug === store) }).length;
 
            return products.filter(function(prod) {
                return (
                    (prod.title_en.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_en.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                        prod.title_vi.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_vi.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                        prod.title_ja.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_ja.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                        prod.title_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                        prod.title_ko.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.itemDescription_ko.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                        prod.slug.toLowerCase().indexOf(value.toLowerCase()) !== -1) || prod.store.slug.toLowerCase().indexOf(value.toLowerCase()) !== -1 || prod.store.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            }).length;
        }
 
        function storeProducts(products, value) {
            return products
                .filter(function(prod) {
                    return (prod.store.slug.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                });
            // .filter(b => b.viewable)
            // .filter(b => b.title_en.indexOf(search) > -1 )
        }
 
        function sortProducts(products) {
            return products
                .filter(b => b.viewable)
                .sort((a, b) => (a.title > b.title ? 1 : a.title === b.title ? 0 : -1))
        }
