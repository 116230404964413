import React from 'react'
import { inject, observer } from "mobx-react"

import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import '../css/accountModal.css'
import StepZilla from './multistep'

import StepOne from './signup/stepone'
import StepTwo from './signup/steptwo'
import StepThree from './signup/stepthree'
import StepFour from './signup/stepfour'
import StepFive from './signup/stepfive'

export default @inject("shop","prods") @observer class AccountModal extends React.Component {
  constructor(props) {
    super(props);
    
    // this.toggle = this.toggle.bind(this);

    this.staffDetails = {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        phone: '',
        dob: '',
        address:{
            apt: '',
            label_address : '',
            form_address : '',
            street : '',
            ward : '',
            suburb : '',
            city : '',
            region : '',
            country : '',
            country_code : '',
            gps : this.props.shop.staff.gps,
        },
        facebookId:'',
        googleId:'',
        imageUrl:'',
        marketplace:'',
        token:'',
        savedToCloud: false
    };

    this.state = {initialStep:0}
  }


  getStore() {
    return this.staffDetails;
  }
  
  updateStore(update) {
    this.staffDetails = {
      ...this.staffDetails,
      ...update,
    }
  }

  staffLogin(){
    var _this = this;
    var login = this.props.shop.staff.staffLogin({email:this.refs.login_email.value,password:this.refs.login_password.value})
    // login.then(function(result){
      // if(result)
        // _this.props.shop.view.reRenderPage();
      //   window.location.reload()
    // });
  }

  staffForgottenPassword(){
    var _this = this;
    var shop = this.props.shop;
    console.log(this.refs.forgotten_email.value)
    if(this.refs.forgotten_email.value !==""){
      console.log('checkEmail',this.refs.forgotten_email.value)
      shop.staff.checkEmail({email:this.refs.forgotten_email.value}).then(function(exists) {
        if(!exists){
          shop.view.updateLoginError("Email doesn't exists")
        }else{
          shop.view.updateLoginError("")
          shop.staff.resetPassword(_this.refs.forgotten_email.value);
        }
      });    
    }else{
      shop.view.updateLoginError("Enter a valid email address")
    }
  }  

  staffResetPassword(){
    var _this = this;
    if(this.refs.reset_password.value !== ""){
      this.shop.view.updateLoginError("")
      this.shop.staff.resetPasswordValue(this.refs.reset_password.value);
    }
  }
  staffUpdate(){
    this.props.shop.staff.staffUpdate(this.staffDetails)
  }

  createStaff(){
    this.props.shop.staff.createStaff(this.staffDetails)
  }

  // shop.view.toggleLogin

  loginWithFacebook(response, openPage = true){
    var _this = this;
    if(response && typeof response.id !== "undefined"){
      return this.props.shop.staff.checkFacebook({facebookId:response.id,email:response.email,imageUrl:response.picture.data.url}).then(function(exists) {
        if(!exists){
          _this.staffDetails['password'] = Math.random().toString(36).slice(-8);
          _this.staffDetails['facebookId'] = response.id;
          _this.staffDetails['email'] = response.email;
          _this.staffDetails['firstname'] = response.name.split(" ")[0];
          _this.staffDetails['lastname'] = response.name.split(" ")[1];
          _this.staffDetails['imageUrl'] =  response.picture.data.url;
          if(openPage){
            _this.setState({initialStep:1})
            _this.props.shop.view.changeType('signup');
          }
          return true;
        }
      });     
    }
  }

  loginWithGoogle(response, openPage = true){
    var _this = this;
    if(response && typeof response.googleId !== "undefined"){
        return this.props.shop.staff.checkGoogle({googleId:response.googleId,email:response.profileObj.email,imageUrl:response.profileObj.imageUrl}).then(function(exists) {
        if(!exists){
          _this.staffDetails['password'] = Math.random().toString(36).slice(-8);
          _this.staffDetails['googleId'] = response.profileObj.googleId;
          _this.staffDetails['email'] = response.profileObj.email;
          _this.staffDetails['firstname'] = response.profileObj.givenName;
          _this.staffDetails['lastname'] = response.profileObj.familyName;
          _this.staffDetails['imageUrl'] =  response.profileObj.imageUrl;
          if(openPage){
            _this.setState({initialStep:1})
            _this.props.shop.view.changeType('signup');
          }
          return true;
        }
      });
    }
  }
  

  render() {

    var shop = this.props.shop;
    var lang = shop.staff.lang;

    var layout = shop.marketplace.layout;

    var fbLogin = shop.marketplace.facebookLogin;

    if(!shop.isMarketplace ){
      var store = shop.view.selectedStore
      if(store){
        layout = store.layout;
        fbLogin = store.facebookLogin;
      }
    }

    const steps = 
    [
      {name: 'StepOne', component: <StepOne getStore={() => (this.getStore())} loginWithFacebook={this.loginWithFacebook.bind(this)} loginWithGoogle={this.loginWithGoogle.bind(this)}  shop={shop} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'StepTwo', component: <StepTwo getStore={() => (this.getStore())} shop={shop} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'StepThree', component: <StepThree getStore={() => (this.getStore())} shop={shop} layout={layout} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'StepFour', component: <StepFour createStaff={() => (this.createStaff())} getStore={() => (this.getStore())} shop={shop} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'StepFive', component: <StepFive getStore={() => (this.getStore())} shop={shop} updateStore={(u) => {this.updateStore(u)}} closeSignup={this.props.closeSignup} />}      
    ]

    var input_style = {"transition":"all 400ms ease","fontWeight":300,"fontSize":14,"lineHeight":"24px","width":"100%","padding":"18px 2px","background":"rgb(255, 255, 255)",border:'none',borderBottom:"1px solid rgb(229, 229, 228)","outline":"none","color":"rgb(0, 0, 0)","height":"44px","boxSizing":"border-box","borderRadius":"0px", marginBottom:10};
    
    var signin_btn = {backgroundColor:layout.primTxtColor,fontSize:12,padding:"11px 20px" ,borderRadius:0,border:"2px solid" + layout.primTxtColor,textTransform:"uppercase",outline:"none",lineHeight:"18px",position:"relative", color: "#fff",margin:5, width:"100%"}

    if(shop.view.loginModalType === "signup"){      
      return (
        <Modal isOpen={shop.view.loginModal} toggle={shop.view.toggleLogin} className={this.props.className}>
            <ModalHeader toggle={shop.view.toggleLogin}>
                &nbsp;
            </ModalHeader>
            <ModalBody>
              <StepZilla startAtStep={this.state.initialStep} 
                color={layout.primTxtColor}
                preventEnterSubmission={true}
                nextTextOnFinalActionStep={"Accept"}
                dontValidate={false} 
                changeType={shop.view.changeType} 
                steps={steps}
                nextButtonText={shop.marketplace.layout.getTitle('next',lang)}
                backButtonText={shop.marketplace.layout.getTitle('previous',lang)}
                already_have_account={shop.marketplace.layout.getTitle('already_have_account',lang)}
                signin={shop.marketplace.layout.getTitle('signin',lang)}
              />
            </ModalBody>
        </Modal>
      );
    }else if(shop.view.loginModalType === 'forgotten'){
      return (
        <div>
          <Modal isOpen={shop.view.loginModal} toggle={shop.view.toggleLogin} className={this.props.className}>
            <ModalHeader toggle={shop.view.toggleLogin}>
              <span style={{fontWeight:300,fontSize:20, marginTop:20}}>{shop.marketplace.layout.getTitle('forgotten_password',lang)?shop.marketplace.layout.getTitle('forgotten_password',lang):'Forgotten Password'}</span>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <div>
                    <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('email',lang)}</label>
                    <input className="" name="email" placeholder="name@example.com" ref="forgotten_email" style={input_style} type="text" />
                  </div>
                  <div style={{textAlign:'center'}}>
                    <div style={{textAlign:'center',color:'#ff0000',fontSize:12}}>{shop.view.loginError}</div>
                    <button style={signin_btn} onClick={()=>{this.staffForgottenPassword()}}><span>{shop.marketplace.layout.getTitle('forgotten_password',lang)?shop.marketplace.layout.getTitle('forgotten_password',lang):'Forgotten Password'}</span></button>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
                <div style={{width:'100%'}}>
                  <div style={{textAlign:'center', fontSize:12}}>
                    {shop.marketplace.layout.getTitle('dont_have_account',lang)}
                    <a style={{color:layout.primTxtColor,marginLeft:5}} onClick={() => { shop.view.changeType('signup') }}>{shop.marketplace.layout.getTitle('login',lang)}</a>
                  </div>
                </div>
            </ModalFooter>
          </Modal>
        </div>
      );
    }else if(shop.view.loginModalType === 'resetpassword'){
      return (
        <div>
          <Modal isOpen={shop.view.loginModal} toggle={shop.view.toggleLogin} className={this.props.className}>
            <ModalHeader toggle={shop.view.toggleLogin}>
              <span style={{fontWeight:300,fontSize:20, marginTop:20}}>{shop.marketplace.layout.getTitle('reset_password',lang) ? shop.marketplace.layout.getTitle('reset_password',lang) : 'Reset Password'}</span>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <div>
                    <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('new_password',lang)?shop.marketplace.layout.getTitle('new_password',lang):'New Password'}</label>
                    <input className="" name="password" placeholder="" ref="reset_password" style={input_style} type="password" />
                  </div>
                  <div style={{textAlign:'center'}}>
                    <div style={{textAlign:'center',color:'#ff0000',fontSize:12}}>{shop.view.loginError}</div>
                    <button style={signin_btn} onClick={()=>{this.staffResetPassword()}}><span>{shop.marketplace.layout.getTitle('reset_password',lang)?shop.marketplace.layout.getTitle('reset_password',lang):'Reset Password'}</span></button>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
                <div style={{width:'100%'}}>
                  <div style={{textAlign:'center', fontSize:12}}>
                    {shop.marketplace.layout.getTitle('already_have_account',lang)}
                    <a style={{color:layout.primTxtColor,marginLeft:5}} onClick={() => { shop.view.changeType('login') }}>{shop.marketplace.layout.getTitle('login',lang)}</a>
                  </div>
                </div>
            </ModalFooter>
          </Modal>
        </div>
      );
    }else{
      return (
        <div>
          <Modal isOpen={shop.view.loginModal} toggle={shop.view.toggleLogin} className={this.props.className}>
            <ModalHeader toggle={shop.view.toggleLogin}>
              <span style={{fontWeight:300,fontSize:20, marginTop:20}}>{shop.marketplace.layout.getTitle('login',lang)}</span>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <div>
                    <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('email',lang)}</label>
                    <input className="" name="email" placeholder="name@example.com" ref="login_email" style={input_style} type="text" />
                  </div>
                  <div>
                    <label style={{fontSize:12, marginTop:10, fontWeight:600, marginBottom:5}}>{shop.marketplace.layout.getTitle('password',lang)}</label>
                    <input className="" name="password" ref="login_password" placeholder="At least 5 characters" style={input_style} type="password"  />
                  </div>
                  <div style={{textAlign:'center'}}>
                    <div style={{textAlign:'center',color:'#ff0000',fontSize:12}}>{shop.view.loginError}</div>
                    <button style={signin_btn} onClick={()=>{this.staffLogin()}}><span>{shop.marketplace.layout.getTitle('login',lang)}</span></button>
                  </div>
                  <div style={{textAlign:'center'}}>
                    <FacebookLogin
                      appId={fbLogin}
                      fields="name,email,picture"
                      callback={this.loginWithFacebook.bind(this)}
                      cssClass="facebook-button-class"
                      icon="fa-facebook"
                    /> 
                    &nbsp;&nbsp;
                    <GoogleLogin
                      clientId="418554825619-t91e73rmo5ttr1cgdvtkon7bqnqcj6ef.apps.googlestaffcontent.com"
                      buttonText="LOGIN WITH GOOGLE"
                      onSuccess={this.loginWithGoogle.bind(this)}
                      onFailure={this.loginWithGoogle.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
                <div style={{width:'100%'}}>
                  <div style={{textAlign:'center', fontSize:12}}>
                    {shop.marketplace.layout.getTitle('dont_have_account',lang)}
                    <a style={{color:layout.primTxtColor,marginLeft:5}} onClick={() => { shop.view.changeType('signup') }}>{shop.marketplace.layout.getTitle('register',lang)}</a><br />
                    {shop.view.loginError !== "" &&
                      <a style={{color:layout.primTxtColor,marginLeft:5}} onClick={() => { shop.view.changeType('forgotten') }}>{shop.marketplace.layout.getTitle('forgotten_password',lang)?shop.marketplace.layout.getTitle('forgotten_password',lang):'Forgotten Password'}</a>
                    }
                  </div>
                </div>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
}