import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { reaction } from "mobx";

import { getSnapshot } from "mobx-state-tree";

// import { onSnapshot, onAction, onPatch, applySnapshot, applyAction, applyPatch, getSnapshot } from "mobx-state-tree"

import createRouter from "./utils/router";
import App from "./components/App";
import "./index.css";

import { initStore } from "./stores/ShopStore";
import { initializeProdsStore } from "./stores/ProdStore";
import { initializeAccomStore } from "./stores/AccomStore";

import Layout from "./components/layout";

// import { ShopStore } from "./stores/ShopStore"

import prods from "./stores/ProdStore";

const fetcher = (url) => window.fetch(url).then((response) => response.json());

//  Redirect to http
if (window.location.protocol !== "https:" && window.location.port !== "3007")
  window.location.href =
    "https:" + window.location.href.substring(window.location.protocol.length);

const startApp = (shop, prods, accom) => {
  ReactDOM.render(
    <Provider prods={prods} shop={shop} accom={accom}>
      <Layout>
        <App />
      </Layout>{" "}
    </Provider>,
    document.getElementById("root")
  );
};

/**
 * Routing
 */

function performRoute(shop) {
  reaction(
    () => shop.view.currentUrl,
    (path) => {
      if (window.location.pathname !== path)
        window.history.pushState(null, null, path);
    }
  );

  tastyRouter(shop);
}

function tastyRouter(shop) {
  const router = createRouter({
    "/": shop.view.openHomePage,
    "": shop.view.openHomePage,
    "/orders": shop.view.openOrdersPage,
    "/customers": shop.view.openCustomersPage,
    "/staff": shop.view.openStaffPage,
    "/stores": shop.view.openStoresPage,
    "/schedule": shop.view.openSchedulePage,
    "/products": shop.view.openProductsPage,
    // "/getstarted": shop.view.openGetStartedPage,
    // "/product/:productId": ({ productId }) => shop.view.openProductPageById(productId),
    // "/blog": shop.view.openBlogPage,
    // "/post/:postId": ({ postId }) => shop.view.openPostPageById(postId),
    // "/store/:address": ({ address }) => shop.view.openStoreByAddress(address),
    // "/account": shop.view.openAccountPage,
    // "/cart": shop.view.openCartPage,
    // "/checkout": shop.view.openCheckoutPage,
    // "/order/:orderId": ({ orderId }) => shop.view.openOrderPage(orderId),
    // "/stores": shop.view.openStoresPage,
    // "/products": shop.view.openProductsPage,
    // "/products/:search": ({ search }) => shop.view.openProductsPageWithSearch(search)
  });
  window.onpopstate = function historyChange(ev) {
    if (ev.type === "popstate") router(window.location.pathname);
  };
  router(window.location.pathname);
}

// function directAccessRouter(shop){
//     var routerList = {
//         "/": shop.view.openHomePage,
//         "": shop.view.openHomePage,
//         "/product/:productId": ({ productId }) => shop.view.openProductPageById(productId),
//         "/blog": shop.view.openBlogPage,
//         "/post/:postId": ({ postId }) => shop.view.openPostPageById(postId),
//         "/account": shop.view.openAccountPage,
//         "/cart": ({cart}) => shop.view.openCart,
//         "/checkout": shop.view.openCheckoutPage,
//         "/order/:orderId": ({ orderId }) => shop.view.openOrderPage(orderId),
//         "/favourites": shop.view.openFavouritesPage,
//         "/products": shop.view.openProductsPage,
//         "/products/:search": ({ search }) => shop.view.openProductsPageWithSearch(search),
//         // "/wholesale": ({}) => shop.view.openPage("wholesale",false), // Custom page
//         // "/videos" :  ({ search }) => shop.view.openPage('videos',false), // Custom page
//         // "/delivery" :  ({ search }) => shop.view.openPage('delivery',false), // Custom page
//         // "/catering" :  ({ search }) => shop.view.openPage('catering',false), // Custom page
//     };
//     const router = createRouter(routerList)
//     window.onpopstate = function historyChange(ev) {
//         if (ev.type === "popstate") router(window.location.pathname)
//     }
//     if(!router(window.location.pathname)){
//         setTimeout(function() {
//             if(shop.view.selectedStore){
//                 var store = shop.view.selectedStore;
//                 store.layout.customPages.map(page =>
//                     routerList['/'+page.slug] = ({item}) => shop.view.openPage(page.slug,false)
//                 )
//             }
//             const router2 = createRouter(routerList)
//             window.onpopstate = function historyChange(ev) {
//                 if (ev.type === "popstate") router2(window.location.pathname)
//             }
//             router2(window.location.pathname)
//         }, 1700);
//     }
// }

initializeApp();

/*
---------------------------------------------------

Initialize App

---------------------------------------------------
*/

function initializeApp() {
  var token = "";
  var apiAddress = "http://localhost:3001";
  if (typeof window !== "undefined" && window.location.port != "3007")
    apiAddress = "https://api.thaodien.me";

  if (window.location.host.indexOf("192.168.") > -1)
    apiAddress =
      "http://" + window.location.host.replace(window.location.port, "3001");

  var marketplace = {};
  var shopObj = {};
  var shop = {};
  var product = "";

  shop = getDomain(window.location.href);

  var data = { domain: shop.domain, product: product, store: shop.store };
  fetch(apiAddress + "/api/initialize/admin", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((shopObj) => {
      if (shopObj.marketplace) {
        shop = getDomain(window.location.href);
        shop["token"] = shopObj.token;
        shopObj["shop"] = shop;

        var store = "";
        if (shopObj.stores.length === 1) store = shopObj.stores[0].slug;
        var prods_data = {
          domain: shop.domain,
          marketplace: shopObj.marketplace.slug,
          suburb: shopObj.marketplace.location.address.suburb,
          selectedStore: store,
          products: shopObj.products.products,
        };
        var accom_data = {
          marketplace: shopObj.marketplace.slug,
          suburb: shopObj.marketplace.location.address.suburb,
          accommodation: shopObj.accommodation,
        };
        shop = initStore(null, shopObj);
        const prods = initializeProdsStore(prods_data);
        const accom = initializeAccomStore(accom_data);

        performRoute(shop);
        startApp(shop, prods, accom);
      } else {
        return {};
      }
    });
}

function getDomain(href) {
  var domain;
  var imageDirectory = "https://static.thaodien.me";
  var lang = "en";
  var title = "";
  var region = "";
  var store = "";
  var isMarketplace = false;
  // var apiAddress = "https://api.thaodien.me";
  var apiAddress = "http://localhost:3001";
  if (typeof window !== "undefined" && window.location.port !== "3007")
    apiAddress = "https://api.thaodien.me";

  if (window.location.host.indexOf("192.168.") > -1)
    apiAddress = "http://" + window.location.host.replace("3007", "3001");

  if (
    href.indexOf("localhost") > -1 ||
    href.indexOf("192.168.") > -1 ||
    href.indexOf("admin.thaodien.me") > -1
  )
    href = "www.thaodien.me";
  // href = 'www.thaodien.me';

  var main_domain = [
    "www.tastysaigon.com",
    "tastysaigon.com",
    "www.thaodien.me",
    "thaodien.me",
    "sweets.vn",
    "localhost",
  ];
  var region_sub_domains = ["thao-dien", "melbourne"];

  var main_domain_filter = main_domain.filter((s) => href.includes(s));

  if (main_domain_filter.length > 0) {
    main_domain = main_domain_filter[0];
    isMarketplace = true;
  }

  domain = href.split("." + main_domain[href.indexOf(main_domain)])[0];

  if (
    href.indexOf(main_domain) === -1 &&
    href.indexOf("localhost") === -1 &&
    href.indexOf("192.168.") === -1 &&
    href.indexOf("127.0.0.1") === -1
  ) {
    domain = href.split(":")[0];
    if (
      domain.indexOf("en.") === 0 ||
      domain.indexOf("vi.") === 0 ||
      domain.indexOf("ja.") === 0 ||
      domain.indexOf("ko.") === 0 ||
      domain.indexOf("zh.") === 0
    ) {
      lang = domain.substring(domain.indexOf(".") - 2, domain.indexOf("."));
      domain = domain
        .replace("en.", "")
        .replace("vi.", "")
        .replace("ja.", "")
        .replace("ko.", "")
        .replace("zh.", "");
    }
  } else if (
    href.indexOf(main_domain) >= 0 &&
    href.indexOf("www." + main_domain) === -1 &&
    href.indexOf("thao-dien") === -1 &&
    href.indexOf("melbourne") === -1
  ) {
    domain = href.split(":")[0];

    if (
      (domain.indexOf("en") === 0 ||
        domain.indexOf("vi") === 0 ||
        domain.indexOf("ja") === 0 ||
        domain.indexOf("ko") === 0 ||
        domain.indexOf("zh") === 0) &&
      href.split(".").length <= 3
    ) {
      lang = domain.substring(domain.indexOf(".") - 2, domain.indexOf("."));
    } else {
      // var domain = domain.replace('en.','').replace('vi.','').replace('ja.','').replace('ko.','').replace('zh.','');
      title = domain;
    }
  } else if (region_sub_domains.indexOf(domain) > -1) {
    domain.split("-").forEach(function (string, index) {
      string = string.charAt(0).toUpperCase() + string.slice(1);
      region += string + " ";
    });
  }

  if (href.indexOf("file:///") > -1) {
    domain = "cheesecake-ngon";
    title = "Cheescake Ngon";
    imageDirectory = "file:///android_asset/www";
  }

  if (isMarketplace) {
    if (href.split(".").length > 0 && href.split(".")[0].length > 3) {
      store = href.split(".")[0];
      isMarketplace = false;
    }
  }
  return {
    href: href,
    domain: domain,
    region: region,
    lang: lang,
    title: title,
    store: store,
    imageDirectory: imageDirectory,
    apiAddress: apiAddress,
    isMarketplace: isMarketplace,
  };
}

// ---------------

// window.shop = shop // for playing around with the console
// window.prods = prods // for playing around with the console

/**
 * Poor man's effort of "DevTools" to demonstrate the api:
 */

// let recording = true // supress recording history when replaying

// onSnapshot(
//     shop,
//     s =>
//         recording &&
//         history.snapshots.unshift({
//             data: s,
//             replay() {
//                 recording = false
//                 applySnapshot(shop, this.data)
//                 recording = true
//             }
//         })
// )
// onPatch(
//     shop,
//     s =>
//         recording &&
//         history.patches.unshift({
//             data: s,
//             replay() {
//                 recording = false
//                 applyPatch(shop, this.data)
//                 recording = true
//             }
//         })
// )
// onAction(
//     shop,
//     s =>
//         recording &&
//         history.actions.unshift({
//             data: s,
//             replay() {
//                 recording = false
//                 applyAction(shop, this.data)
//                 recording = true
//             }
//         })
// )

// // add initial snapshot
// history.snapshots.push({
//     data: getSnapshot(shop),
//     replay() {
//         // TODO: DRY
//         recording = false
//         applySnapshot(shop, this.data)
//         recording = true
//     }
// })

// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";

// import { onPatch } from "mobx-state-tree";
// import makeInspectable from "mobx-devtools-mst";

// import { Provider } from "mobx-react";
// import TodoStore from "./models/TodoStore";

// // create store
// const todostore = TodoStore.create();

// // Provider allows to pass the store to component
// const Root = (
//   <Provider TodoStore={todostore}>
//     <App />
//   </Provider>
// );

// // Patch listener will be invoked whenever the model or any of its descendants is mutated
// onPatch(todostore, patch => {
//   console.log('PATCH', patch);
// });
// makeInspectable(todostore);

// ReactDOM.render(Root, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
